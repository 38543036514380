import React, { Component } from 'react'
import axios from 'axios';
import { LabelService } from '../Common/Translations/LabelService';
import { API } from '../Common/API/API';
import { HelperFunctions } from '../Common/Helper/Helper';
import { Loader } from './../Common/Components/Loader/loader'
import { APINotifications } from '../Common/API/APINotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Import.css";

const API_IMPORT = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();

class ImportKMEList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenFile: null,
            chosenFileName: '',
            progresstotal: 0,
            progressloaded: 0,
            isLoading: false,
            showProgress: false,
            disableUpload: true,
            imported: 0,
            firstImportedProjectNumber: null,
            modalShow: false
        }
    }


    //the chosenFile will probably not be the complete filepath, instead it will say fakepath.
    // THIS IS INTENDED BROWSER BEHAVIOUR. While uploading the browser will handle the correct path.

    updateFileInput = (e) => {
        if (e.target.files[0].size > 200000000) {
            this.throwNotValid();
            return;
        }

        let fileExtention = this.getFileExtention(e.target.value);

        if (fileExtention.toLowerCase() !== 'xls' && fileExtention.toLowerCase() !== 'xlsx') {
            this.throwNotValid();
            return;
        }

        this.setState({
            chosenFile: e.target.files[0],
            chosenFileName: e.target.value,
            disableUpload: false
        });
    }

    getFileExtention(fileName) {
        return (fileName.lastIndexOf('.') < 1) ? null : fileName.split('.').slice(-1) + '';
    };

    throwNotValid() {
        this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', 400, 'FILE_NOT_VALID'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
            buttons: this.createOKModalButtons()
        });
    }

    handleImport() {

        this.setState({
            isLoading: true,
            showProgress: true
        });

        let formData = new FormData();
        formData.append('file', this.state.chosenFile);
        formData.append('justCount', false);

        // This will update and show the progress of the UPLOAD
        var config = {
            onUploadProgress: progressEvent => {
                this.setState({
                    progresstotal: progressEvent.total,
                    progressloaded: progressEvent.loaded
                })
            },
            headers: { 'Content-Type': 'multipart/form-data;' }
        };

        //Change expected API URL HERE
        axios.post(API_IMPORT.XPART_SERVICE_URL + '/import/upload/kme', formData, config)
            .then(this.kmeImportFulfilled())
            .catch(this.kmeImportRejected())
            .finally(() => {
                this.setState({
                    isLoading: false,
                    chosenFile: null,
                    chosenFileName: '',
                    disableUpload: true
                });
            });
    }

    kmeImportFulfilled() {
        return res => {
            const { data } = res;
            const { imported, importedXpartsIds, firstImportedProjectNumber } = data

            this.setState({
                imported: imported,
                firstImportedProjectNumber: firstImportedProjectNumber
            })

            const docUrl = API_IMPORT.FILE_SERVICE_URL + '/x-part/labels'
            const payload = {
                'xpartIds': importedXpartsIds
            }
            if (importedXpartsIds.length) {
                HelperFunction.getMultiPdfDocument(docUrl, payload, this.props.clearNotifications);

                this.props.fireNotifications([{
                    type: 'INFO',
                    title: 'Import wird verarbeitet',
                    message: 'Losteil-Etiketten werden erzeugt...'
                }])
            }

            const { headerFileValidationExceptions, contentValidationExceptions } = data
            const successHeader = {
                header: NOTIFICATIONS.getNotification('de', 'UPLOAD_SUCCESS')
            }
            const errorHeader = {
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR')
            }
            const baseConfig = {
                content: this.dialogBox(data),
                type: 'wide',
                buttons: this.createOKModalButtons()
            };

            //makes a check here if the header and content validation objects are empty or contains the value to fire the modal with relevent header
            if (this.hasNoValidationExceptions(headerFileValidationExceptions, contentValidationExceptions)) {
                const redirect = { redirect: this.getRedirect() }
                const successModalConfig = Object.assign({}, baseConfig, successHeader, redirect);
                this.props.fireModal(successModalConfig)
            } else {
                const failModalConfig = Object.assign({}, baseConfig, errorHeader);
                this.props.fireModal(failModalConfig)
            }
        };
    }

    kmeImportRejected() {
        return err => {
            console.log(err);
            const { status, data } = err.response
            const error = data.errors[0]
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', status, error),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                buttons: this.createOKModalButtons(),
            });
        };
    }

    hasNoValidationExceptions(headerFileValidationExceptions, contentValidationExceptions) {
        return !this.hasHeaderFileValidationExceptions(headerFileValidationExceptions) &&
            !this.hasContentValidationExceptions(contentValidationExceptions);
    }

    hasContentValidationExceptions(contentValidationExceptions) {
        return Array.isArray(contentValidationExceptions) && contentValidationExceptions.length;
    }

    hasHeaderFileValidationExceptions(headerFileValidationExceptions) {
        return Array.isArray(headerFileValidationExceptions) && headerFileValidationExceptions.length;
    }

    // dialog box for the response from KME upload
    dialogBox(res) {
        let totalCount = res['totalCount'];
        let imported = res['imported'];
        let headerFileValidationExceptions = res['headerFileValidationExceptions'];
        let contentValidationExceptions = res['contentValidationExceptions'];
        //makes a check if both header and content exception is empty, returns the total and imported count
        if (headerFileValidationExceptions.length < 1) {
            if (contentValidationExceptions.length < 1) {
                return (
                    <div>
                        <div>Importierte Zeilen: {totalCount}</div>
                        <div>Erstellte Losteile: {imported}</div>
                    </div>
                );
            }
        }
        //makes a check for Header validation and returns the popup content 
        if (headerFileValidationExceptions.length > 0) {
            const headerExceptions = headerFileValidationExceptions.map((value) => {
                return <li>{value}</li>
            })
            return (
                <div className="list-none"><span>{LabelFile.texts['Header_missing']}</span> <ul>{headerExceptions}</ul></div>
            );

        }
        // makes a check for content validation and returns the popup content 
        if (contentValidationExceptions.length > 0) {
            const contentExceptions = contentValidationExceptions.map((item) => {
                return <li><span>{LabelFile.texts['Row']}:{item.rowNumber}</span><span>{LabelFile.texts['Column']}:{item.columnHeader}</span></li>
            })
            return (
                <div className="list-none"><span>{LabelFile.texts['Content_Missing']}</span> <ul>{contentExceptions}</ul></div>
            );
        }
    }

    handleOkButton = () => {
        this.props.closeModal();
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.handleOkButton,
        };

        btns.push(obj);

        return btns;
    }

    getRedirect() {
        if (this.state.imported > 0) {
            let redirect = {
                func: this.props.history.push,
                url: '/list/xpartOverview/1/100'
            }
            const filterItem = {
                name: `Projekt: ${this.state.firstImportedProjectNumber}`,
                dataname: this.state.firstImportedProjectNumber,
                field: 'PROJECT_NUMBER',
                type: 'string'
            }

            localStorage.setItem("xpm-filter-list-xpartOverview", JSON.stringify([filterItem]));
            return redirect;
            //  return '/list/xpartOverview/1/100'
            // this.props.history.push('/list/xpartOverview/1/100');
        }
        return null;
    }



    render() {
        const total = this.state.progresstotal;
        const loaded = this.state.progressloaded;
        let loadedPercent = 100 - (loaded * 100 / total);
        return (
            <section className="results--section">
                <section className="results--section xpartform">
                    <h1>{LabelFile.labels['KMEList']}</h1>
                    <div className="header-underline-img"><span className="header-underline-export"><FontAwesomeIcon icon={['fas', 'download']} /></span></div>
                    <hr className="header-underline"></hr>

                    <div className="input-container" id="inputMain">
                        <input
                            style={{ width: "calc(100% - 28px" }}
                            type="file"
                            accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            className="file-upload"
                            value={this.state.chosenFileName}
                            onChange={this.updateFileInput}
                        />
                        <div className="xpm-button-container">
                            <button type="button"
                                disabled={this.state.disableUpload}
                                className="xpm-button xpm-button-import"
                                onClick={(event) => this.handleImport(event)}>{LabelFile.labels['upload'] + " & " + LabelFile.labels['import']}
                            </button>
                        </div>
                        {this.state.isLoading ? <Loader /> : ''}
                        {this.state.showProgress ? <div className="ProgressBar">
                            <div className="progress-total"><div className="progress-loaded" style={{ transform: "translateX(-" + loadedPercent + "%)" }}></div></div>
                            <div className="progressNumbers"><span>{loaded + " / " + total}</span></div>
                        </div> : ""}
                    </div>
                </section>
            </section>
        )
    }
}

export default ImportKMEList;