
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserInfoComponent from './user-info-component';
import Menu from '../Menu/Menu';
import { HelperFunctions } from '../Common/Helper/Helper';
import Notifications from './Notificatons/notifications'

const HelperFunction = new HelperFunctions();

class HeaderComponent extends Component {

    state = {
        currentUser: null,
        notifications: [],
    }

    componentDidMount() {
        this.updateCurrentUser(HelperFunction.getCurrentUser());
    }

    updateCurrentUser(currentUser) {
        this.setState(Object.assign({}, this.state, { currentUser }));
    }

    updateNotifications({ notifications }) {
        this.setState(Object.assign({}, this.state, { notifications }))
    }

    render() {
        return (
            <header className="container">
                <nav className="navbar">
                    <div className="navbar-brand">
                        <Link key="0" to={'/'}>
                            <img className="navbar-item" src={window.location.origin + '/images/full_logo.png'} alt="xpm" title="XParts Manager"></img>
                        </Link>
                        <div className="currentUser">
                            <UserInfoComponent currentUser={this.state.currentUser}/>
                        </div>
                        <div>
                            <Notifications notifications={this.state.notifications}/>
                        </div>
                    </div>

                    <div className="navbar-end">
                        {this.state.currentUser !== null ? <Menu /> : ''}
                    </div>
                </nav>
            </header>
        )
    }

}
export default HeaderComponent;
