import React, { Component } from 'react'
import { withRouter } from 'react-router';
import Scan from './../../Scan/XpartScan/Scan'
import { LabelService } from '../../Common/Translations/LabelService'

const LabelFile = LabelService.getLabelsByLocal('de')

class GroupXpartScan extends Component {
  // --- object: set initial state
  initialState = [
    {
      cam: {
        result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
        flash: 'scan-container-flash'
      },
      title: {
        view: true, // --- view title in browser
        name: LabelFile.labels['storagexpartNr'] // --- view title in browser
      },
      form: {
        view: true, // --- view form in browser
        alternative: true // view form width (alternative = full width)
      },
      button: {
        view: false, // --- view button in browser
        alternative: true // view button width (alternative = full width)
      },
      status: {
        linkUrl: '/groupidxpartview/', // internal link to other component
        linkPermission: '',
        context:null
      }
    }
  ]

  render() {
    return (
      <div>
        <Scan initialState={this.initialState} fireModal={this.props.fireModal} closeModal={this.props.closeModal} />
      </div>
    )
  }
}

export default withRouter(GroupXpartScan);