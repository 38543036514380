import React, { Component } from 'react'
import { TableComponent } from './TableComponent';
import TableConfig from './tables-config.json';
import { PageHeaderComponent } from '../Common/Components/page-header-component'
import { PaginationComponent } from './PaginationComponent'
import { SelectableTableComponent } from './SelectableTableComponent';
import { TableFilter } from './TableFilter/TableFilter';
import { LabelService } from '../Common/Translations/LabelService';
import { API } from '../Common/API/API';
import axios from 'axios';
import './list.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();

export default class ListComponent extends Component {

    constructor(props) {
        super(props);
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.tableFilter = React.createRef();
    }

    isFilterNeeded(){
        return TableConfig[this.props.match.params.module].useSpecialfilter || 
        (TableConfig[this.props.match.params.module].filter && TableConfig[this.props.match.params.module].filter.length)
    }

    state = {
        generalFilter: [],
        usesFilter: this.isFilterNeeded(),
        importCount: '',
        preFilter: [],
        config: TableConfig[this.props.match.params.module],
        module: this.props.match.params.module
    }

    componentWillReceiveProps(nextProps) {
        
        if (this.props.match.params.module !== nextProps.match.params.module) {
            this.refresh();
        }
    }


    updateTable(page, parts) {
        this.props.history.push('/list/' + this.state.module + '/' + page + '/' + parts);
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    filterUpdate(data, filterFlag) {
        this.setState({
            generalFilter: data,
            usesFilter: filterFlag
        })
    }

    getFileName(contentDispositionValue) {
        var filename = "";
        if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDispositionValue);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    excelExport() {
        let filters = this.state.generalFilter;

        if (filters.length === 0) {
            filters = {};
        }
        this.tableComponent.current.updateLoading(true);

        axios.post(API_XPART.XPART_SERVICE_URL + this.state.config.dateSource + '/export' + this.tableComponent.current.getRequestParameters(), filters, {
            responseType: 'blob'
        }).then(response => {
            this.showFile(response.data, this.getFileName(response.headers['content-disposition']));
        }).finally(() => {
            this.tableComponent.current.updateLoading(false);
        })
    }

    showFile(data, fileName) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    isFilterReady(){
    }

    render() {
        return (
            <section className="results--section">
                <PageHeaderComponent text={this.state.config.pageHeader ? <h1>{LabelFile.labels[this.state.config.pageHeader]}</h1> : ''} ></PageHeaderComponent>
                {this.state.config.buttonExcelExport ? <div className="xpm-button-container excel-export"><button type="button" className="xpm-button xpm-button-export" onClick={() => this.excelExport()}>{LabelFile.labels["excelexport"]}</button></div> : ''}
                {<PaginationComponent ref={this.paginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={this.state.module} ></PaginationComponent>}
                {this.state.config.showFilter ? <TableFilter ref={this.tableFilter} module={this.state.module} filterUpdate={(data, filterFlag) => this.filterUpdate(data, filterFlag)}></TableFilter> : ""}

                <div className="list-container">

                    {this.state.config.selectable ? <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={this.state.module}
                        usesFilter={this.state.usesFilter || this.state.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.state.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}></SelectableTableComponent> :

                        <TableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                            pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                            context={this.props.match.params.context} module={this.state.module}
                            usesFilter={this.state.usesFilter || this.state.config.usePreFilter} generalFilter={this.state.generalFilter}
                            preFilter={this.state.preFilter} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}></TableComponent>}

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={this.state.module} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </section>
        )
    }

}
