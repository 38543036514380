

import React from 'react';
import WizardStep from '../Common/Components/Wizrd/WizardStep';
import { CommonWizardComponent } from '../Common/Components/Wizrd/CommonWizardComponent';
import GroupTableButtonTextComponent from './Common/GroupTableButtonTextComponent';
import Scan from '../Scan/XpartScan/Scan';
import { LabelService } from '../Common/Translations/LabelService';
import CommonPhotosComponent from '../Common/Components/Photo/CommonPhotosComponent';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import GroupSaveButtons from './Common/GroupSaveButtons';
import TableConfig from '../List/tables-config.json';
import { CameraService } from '../Camera/CameraService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

export default class ShuttleGroupCreationWizard extends CommonWizardComponent {

    moduleName = 'createShuttleGroupTable';
    config = TableConfig[this.moduleName];
    localStoragePrefix = this.config['localStoragePrefix'];


    componentDidMount() {
        if (!localStorage.getItem(this.localStoragePrefix)) {
            var dataBlank = { "content": [], "requestGroupMetaInfo": {}, "textToggle": false };
            localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataBlank));
        }
        //init all steps
        let steps = [];
        steps.push(this.getGroupTableCheckStep());
        steps.push(this.getScanStep());
        steps.push(this.getCompleteGroupStep());
        steps.push(this.getPhotoStep());
        this.initComponent(steps, steps[0]);
    }

    // after scan action is done
    postScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.getXpartData(scanValue);
    }

    updateGalleryIndex = () => {
        this.currentStepRef.setGalleryIndex('photos', this.localStoragePrefix);
    }

    goToPhotos(e) {
        this.goToStep('photo-step', this.updateGalleryIndex);
        this.setState({ wizardControlComponent: null })
    }



    getPhotoLocalStoragePrefix() {
        return this.localStoragePrefix + '-photos';
    }


    undoScan = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    }

    getXpartData(xpartNumber) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/xpartview/xpartnumber/' + xpartNumber)
            .then(res => {
                var dataCurrent = JSON.parse(localStorage.getItem(this.localStoragePrefix));
                if (!this.validateXpart(res['data'], dataCurrent)) {
                    this.currentStepRef.reset();
                    this.goToTableCheck();
                    return;

                }

                if (this.isScannedBefore(res['data']['xpartNumber'], dataCurrent)) {
                    this.handleScannedBefore(res['data']);
                    this.currentStepRef.reset();
                    this.goToTableCheck();
                    return;
                }

                // add xpart info to the data set for table 
                if (dataCurrent.content.length < 1) {
                    dataCurrent['requestGroupMetaInfo'] = res['data']['requestGroupMetaInfo'];
                }
                dataCurrent['content'].push(res['data']);
                dataCurrent['textToggle'] = true;
                localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataCurrent))
                this.goToTableCheck();

            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }

    getScanStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['storagexpartNr'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-step', scanComponent);

    }

    getGroupTableCheckStep() {

        const tableCheckComponent =
            <GroupTableButtonTextComponent
                setRef={el => this.setCurrentStepRef(el)}
                moduleName={this.moduleName}
                renderOptionalButtons={() => this.renderOptionalButtons()}
            >
            </GroupTableButtonTextComponent>

        return new WizardStep('group-table-step', tableCheckComponent);

    }

    resetTableData() {
        this.props.closeModal();
        var dataBlank = { "content": [], "requestGroupMetaInfo": {}, "textToggle": false, };
        localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataBlank));
        localStorage.removeItem(this.getPhotoLocalStoragePrefix());
        this.currentStepRef.fetchDataFromLocalStorage();
        this.goToTableCheck();
    }

    getCompleteGroupStep() {

        const completeGroupCheckComponent =
            <GroupSaveButtons
                setRef={el => this.setCurrentStepRef(el)}
                goBackToTable={() => this.goBackToTable()}
                moduleName={this.moduleName}
                goToPhotos={() => this.goToPhotos()}
                save={() => this.save()}
            >
            </GroupSaveButtons>

        return new WizardStep('group-save-step', completeGroupCheckComponent);
    }

    goToCompleteGroup() {
        this.goToStep('group-save-step');
        this.setState({ wizardControlComponent: null })
    }

    getPhotoStep() {
        const photoComponent =
            <CommonPhotosComponent
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                goBack={() => this.goToCompleteGroup()}
                setRef={el => this.setCurrentStepRef(el)}
            />
        return new WizardStep('photo-step', photoComponent);
    }


    goBackToTable() {
        this.props.closeModal();
        this.goToTableCheck();
    }

    goToTableCheck() {
        this.goToStep('group-table-step');
        this.setState({ wizardControlComponent: null })
    }

    goToScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.goToStep('scan-step');
    }

    renderOptionalButtons() {
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        return (
            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                    <button onClick={() => { this.goToScan() }}
                        className={"xpm-button"}>{LabelFile.labels[this.config.addButtonName]}</button>
                    <button onClick={() => { this.goToCompleteGroup() }}
                        className={"xpm-button "+ (!groupData.textToggle ? ' xpm-disabled ' : '')} disabled={!groupData.textToggle? true : false}>{LabelFile.labels[this.config.closeGroupButtonName]}</button>
                    <button onClick={() => { this.groupResetWarning() }}
                        className={"xpm-button "+ (!groupData.textToggle ? ' xpm-disabled ' : '')} disabled={!groupData.textToggle? true : false}>{LabelFile.labels[this.config.resetSelectButtonName]}</button>
                </div>
            </div >
        )
    }

    groupResetWarning(){
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'RESET_GROUP_CREATION_DATA_WARNING'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
            buttons: this.createWarningAfterGroupResetButton()
        });
    }

    async saveXpartInfoGroup(data) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/group/save', data);
    }


    save = () => {
        //Check if the user took Photos and scanned xparts
        if (!this.validateBeforeSave()) {
            this.goToCompleteGroup();
            return;
        }
        this.toggleLoading(true);
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        let requestedData = {
            "groupInfoDTO": {
                "active": true,
                "modeTypeId": 1,
                "typeNameId": 3,
                "validationCriteria": [
                    "SHUTTLE_GROUP_VALIDATION"
                ]
            },
            "groupMetaInfoDTO": {
                "deliveryPlaceSequence": []
            },
            "photos": [],
            "xpartsNumbers": []
        };
        let groupXparts = groupData['content'].map(obj => obj.xpartNumber);
        requestedData['photos'] = CameraService.getPhotosToSend(this.getPhotoLocalStoragePrefix());
        requestedData['xpartsNumbers'] = groupXparts;
        requestedData['groupMetaInfoDTO']['deliveryPlaceSequence'] = groupData.requestGroupMetaInfo.targetStorageCode.split('-');
        let promise = this.saveXpartInfoGroup(requestedData);

        promise.then(
            response => {
                localStorage.removeItem(this.localStoragePrefix);
                localStorage.removeItem(this.getPhotoLocalStoragePrefix());
                if (!localStorage.getItem(this.localStoragePrefix)) {
                    var dataBlank = { "content": [], "requestGroupMetaInfo": {}, "textToggle": false };
                    localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataBlank));
                }
                this.goBackToTable();
            }
        ).catch(err => {
            console.log(err)
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    /**Check if Xpart exists in current data list */
    isScannedBefore = (xpart, dataCurrent) => {
        var exists = dataCurrent['content'].some(Num => Num.xpartNumber === xpart);
        return exists;
    }


    handleScannedBefore = (xpart) => {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'XPART_COMPLETED_IN_GROUP',
                xpart.xpartNumber),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
        });
    }

    validateXpart = (xpart, dataCurrent) => {
        if (xpart.currentStatus.statusDTO.name !== 'READY_FOR_SHIPMENT' || xpart.currentStatus.contextDTO.name !== "TRANSPORTATION") {

            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'XPART_NOT_IN_EXPECTED_STATUS_READY_FOR_SHIPMENT_TRANSPORTATION',
                    LabelFile.statuses[xpart.currentStatus.statusDTO.name], LabelFile.context[xpart.currentStatus.contextDTO.name]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }
        if (xpart.requestGroupMetaInfo === null || xpart.requestGroupMetaInfo === undefined) {
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'XPART_NOT_PART_OF_REQUEST_GROUP'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }
        if (dataCurrent.content.length > 0) {
            if (xpart.requestGroupMetaInfo.targetStorageCode !== dataCurrent.requestGroupMetaInfo.targetStorageCode) {

                this.props.fireModal({
                    content: NOTIFICATIONS.getNotification('de', 'XPART_DELIVERY_DESTINATION_NOT_MATCH', xpart.requestGroupMetaInfo.targetStorageCode),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });

                return false;
            }
        }

        if (xpart.shuttleGroupMetaInfo) {
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'XPART_ALREADY_BELONGS_TO_TRANSPORT_GROUP'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }

        return true;
    }

    validateBeforeSave() {
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        if (!localStorage.getItem(this.getPhotoLocalStoragePrefix())) {
            this.toggleLoading(false);
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'SCAN_PHOTO_TO_COMPLETE_GROUP_SAVE'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }
        if (!groupData['textToggle']) {
            this.toggleLoading(false);
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'SCAN_XPART_TO_COMPLETE_GROUP_SAVE'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }
        return true;
    }

    createWarningAfterGroupResetButton() {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: () => this.goBackToTable()
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: () => this.resetTableData()
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }


}
