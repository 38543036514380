import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { API } from '../../Common/API/API';
import axios from 'axios';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { APINotifications } from '../../Common/API/APINotifications'
import TableConfig from '../../List/tables-config.json';
import { TableComponent } from '../../List/TableComponent';
import { LabelService } from '../../Common/Translations/LabelService';
import { HelperFunctions } from '../../Common/Helper/Helper';
import '../../List/list.css';

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();


class GroupIdXpartView extends Component {
    constructor(props) {
        super(props);
        this.config = TableConfig['groupIdXpartView']; // link here the place of "tables-config.json"
        this.xpartNumber = this.props.match.params.xpartnumber
        this.checkXpartCompletion(this.xpartNumber) // Api request to get group Id
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.toggleLoading();
    }


    state = {
        groupId: '',
        isLoading: false,
        module: 'groupIdXpartView',
        groupActive: Boolean
    }

    createOKModalButtons() {
        var btns = []
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        }

        btns.push(obj)

        return btns
    }

    //returns deactivate Button 
    deactivateButton() {
        let field = document.getElementById("deactivate-button");
        if (field.classList.contains("xpm-disabled")) {
            return 0;
        }
        this.toggleLoading();
        axios.post(API_XPART.XPART_SERVICE_URL + '/group/activation', { "active": !this.state.groupActive, "xpartGroupIds": [this.state.groupId] })
            .then(res => {
                this.props.fireModal({
                    content: LabelFile.labels['deactivated'] + ' ' + HelperFunction.timeConverter(res.data.deactivationDate, 'de'),
                    header: NOTIFICATIONS.getNotification('de', 'SUCCESS', 'messages'),
                    buttons: this.createOKModalButtons()
                })

                this.props.history.push('/groupxpartscan/');
            })
            .catch(error => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })

            })
            .finally(() => this.toggleLoading()
            );
    }

    //To check if all the completion values are true 
    checkCompletionStatus(completionList) {
        if (!completionList) {
            return false;
        }
        for (let i = 0; i < completionList.length; i++) {
            if (completionList[i]['completed'] === false) {
                return false;
            }
        }
        return true;
    }

    updateTable(page, parts) {
        if (!this.state.currentGroup.id) {
            this.props.history.push('/groupidxpartview/' + page + '/' + parts);
        } else {
            this.props.history.push('/groupidxpartview/' + this.xpartNumber + '/' + page + '/' + parts);
        }

    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    //Api call for group id fetch
    checkXpartCompletion = xpartNumber => {
        // --- database request
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/group/completion/xpart/' + xpartNumber)
            .then(res => {
                if (!res.data) {
                    this.setState({
                        groupId: null,
                        groupActive: false,
                        buttonShow: "xpm-disabled"
                    })
                    return;
                }
                const completed = this.checkCompletionStatus(res.data.completionList);

                this.setState({
                    groupId: res.data.id,
                    groupActive: res.data.active,
                    buttonShow: !completed ? "xpm-disabled" : ""
                })
            })
            .catch(error => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })

            })
            .finally(() => this.toggleLoading()
            );
    }

    // loading spinner
    toggleLoading() {
        if (this.tableComponent.current) {
            this.tableComponent.current.toggleLoading();
        }
    }

    render() {
        return (
            <section className="results--section">
                {/* page header */}
                <PageHeaderComponent
                    text={<h1>{LabelFile.labels['XPARTGROUPSCAN']}</h1>}
                />
                <div className="optionalButtons xpm-button-container">
                    <div className="button-alignment">
                        <button onClick={() => { this.deactivateButton() }}
                            id="deactivate-button" className={"xpm-button " + this.state.buttonShow}>{LabelFile.labels['deactivate']}</button>

                    </div>
                </div>
                <div className="list-container">
                    <TableComponent
                        ref={this.tableComponent}
                        page={this.props.match.params.page - 1}
                        size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context}
                        module={this.state.module}
                        generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter}
                        refresh={() => this.refresh()} history={this.props.history}
                        fireModal={this.props.fireModal}
                        closeModal={this.props.closeModal}
                        groupId={this.state.groupId}
                    />
                </div>
                <div id="popupContainer"></div>
            </section>
        )
    }
}
export default withRouter(GroupIdXpartView);