import React, { Component } from 'react';
import TableConfig from '../List/tables-config.json';
import { PageHeaderComponent } from '../Common/Components/page-header-component';
import { PaginationComponent } from '../List/PaginationComponent';
import { TableComponent } from '../List/TableComponent';
import { LabelService } from '../Common/Translations/LabelService';
import DatePickerDialog from './DatePickerDialog';
import { HelperFunctions } from '../Common/Helper/Helper';
import '../List/list.css';
import './kommissionieren.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();

export default class KommissionierenTableComponent extends Component {

    constructor(props) {
        super(props);
        this.config = TableConfig['kommissionierenTable'];
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.tableFilter = React.createRef();
        this.controlButtonsComponent = React.createRef();
        this.localStorageDatePrefix = this.config.localStoragePrefix + '-deliveryDate';
       
    }

    state = {
        generalFilter: [],
        usesFilter: false,
        importCount: '',
        preFilter: [],
        deliveryDate: null,
        showScanComponent: false,
        showTableComponent: true,
        displayDate:''
        }

    componentWillMount() {
        this.setState(this.state);
    }
    componentDidMount() {
        this.initialDate = this.loadAndRemoveDateFromLocalStorage();
        var fields=[];
        var deliveryDatePrefilter={};
        var searchgroup = {};
        var searchValues={};
        var searchArr = [];
        searchValues["fieldName"]= "DELIVERY_DATE";
        searchValues["fieldValue"]=HelperFunction.timeConverter(this.initialDate,'de',true);
        searchValues["searchType"]="EQUAL";
        fields.push(JSON.parse(JSON.stringify(searchValues)));
        searchgroup["fields"]=fields;
        searchArr.push(JSON.parse(JSON.stringify(searchgroup)));
        deliveryDatePrefilter["searchGroups"] = searchArr;
        this.setState({
            deliveryDate: this.initialDate,
            generalFilter: deliveryDatePrefilter
        })
    }
  
    updateTable(page, parts) {
        this.props.history.push('/Kommissionieren/KommissionierenTable/' + page + '/' + parts);
        this.refresh();
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    loadAndRemoveDateFromLocalStorage() {
        let date = null;
        if (localStorage.getItem(this.localStorageDatePrefix)) {
            date = localStorage.getItem(this.localStorageDatePrefix);
            localStorage.removeItem(this.localStorageDatePrefix);
        }
        else {
            date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        }
        return new Date(date);
    }

    addDatetoLocalStorage() {
        localStorage.setItem(this.localStorageDatePrefix, this.state.deliveryDate);
    }

    changeDate() {
        this.dateDialog();
    }

    startScan() {
        this.addDatetoLocalStorage();
        this.props.history.push('/wizard/Kommissionieren');
    }

    dateDialog() {
        this.props.fireModal({
            header: LabelFile.header["ENTER_DELIVERY_DATE"],
            content: <DatePickerDialog
                closeModal={this.props.closeModal}
                selectedDate={this.state.deliveryDate}
                datePickerResponse={(data) => this.datePickerResponse(data)}
                handleCancel={() => this.handleCancel()} />,
            type: 'wide',
            stickInOverlay: true
        });

    }

    datePickerResponse(data) {
        this.props.closeModal();
        var fields=[];
        var deliveryDatePrefilter={};
        var searchgroup = {};
        var searchValues={};
        var searchArr = [];
        searchValues["fieldName"]= "DELIVERY_DATE";
        searchValues["fieldValue"]=HelperFunction.timeConverter(data.getTime(),'de',true);
        searchValues["searchType"]="EQUAL";
        fields.push(JSON.parse(JSON.stringify(searchValues)));
        searchgroup["fields"]=fields;
        searchArr.push(JSON.parse(JSON.stringify(searchgroup)));
        deliveryDatePrefilter["searchGroups"] = searchArr;

        this.setState({
            deliveryDate: data,
            generalFilter: deliveryDatePrefilter
        });
    }

    handleCancel() {
        this.props.closeModal();
    }

    renderOptionalButtons() {
        return (
            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                    <button onClick={() => { this.changeDate() }}
                        className={"xpm-button "}>{LabelFile.labels['changeDate']}</button>
                    <button onClick={() => { this.startScan() }}
                        className={"xpm-button "}>{LabelFile.labels['scan']}</button>
                </div>
            </div >
        )
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }

    renderTable() {

        return (
            <div>
                <PageHeaderComponent text={this.config.pageHeader ? <h1>{LabelFile.labels[this.config.pageHeader]}</h1> : ''} ></PageHeaderComponent>

                {<PaginationComponent ref={this.paginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'kommissionierenTable'} ></PaginationComponent>}
                <div className="column-2 column-xs-1">
                    <span className={"info-display"}>
                        {LabelFile.labels['deliverydate']}{' : '}{HelperFunction.timeConverter(this.state.deliveryDate,'de',true)}
                    </span>
                </div>
                {this.renderOptionalButtons()}
                <div className="list-container">

                    <TableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={'kommissionierenTable'}
                        usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
                    ></TableComponent>

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'kommissionierenTable'} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </div>
        )
    }

    render() {
        return (
            <section className="results--section">
                {(this.state.showTableComponent ? this.renderTable() : '')}
            </section>

        )
    }

}