import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { API } from '../Common/API/API'
import { LabelService } from '../Common/Translations/LabelService'
import { Loader } from '../Common/Components/Loader/loader';
import './CreateUser.css'

const API_STATUS = new API();
const LabelFile = LabelService.getLabelsByLocal('de')

export default class CreateUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      companiesFromBackend: [],
      companiesCombination: [],
      company: -1,
      rolesFromBackend: []
    }
    this.checkIfInputIsOkay = this.checkIfInputIsOkay.bind(this)
  }

  componentDidMount() {
    // axios: request call to the backend to get all companies
    axios.get(API_STATUS.XPART_SERVICE_URL + '/company/')
      .then(response => {
        this.setState({ companiesFromBackend: response.data })
      })

    // axios: request call to the backend to get all groups/roles
    axios.get(API_STATUS.AUTH_SERVICE_URL + '/user/group')
      .then(response => {
        this.setState({ rolesFromBackend: response.data })
      })
  }

  createOKModalButtons() {
    var btns = []
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    }
    btns.push(obj)
    return btns
  }

  // function: show warning border or not
  warningBorder = (htmlIdArray) => {
    for (let key in htmlIdArray) {
      document.getElementById(htmlIdArray[key][0]).className = htmlIdArray[key][1] ? 'form-input-properties form-warning' : 'form-input-properties'
    }
  }

  // function: check if email input is field, then change border color
  typedInputEmail = (inputData) => {
    this.warningBorder([['form-input-email', false]])
  }

  // function: check if emailRepeat input is field, then change border color
  typedInputEmailRepeat = (inputData) => {
    this.warningBorder([['form-input-emailRepeat', false]])
  }

  // function: check if email is valid
  checkIfEmailIsOkay = async () => {
    // fetch data from email and emailRepeat input
    let email = document.getElementById('form-input-email').value
    let emailRepeat = document.getElementById('form-input-emailRepeat').value

    // trims the empty space before and after string
    const emailTrimed = await email.trim()
    const emailTrimedRepeat = await emailRepeat.trim()

    // function: check if email and emailRepeat has a valid format
    const validate = (email) => {
      // regexpression
      const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return expression.test(String(email).toLowerCase())
    }

    // check if email and emailRepeat has a valid format
    let emailIsValid = validate(emailTrimed)
    let emailRepeatIsValid = validate(emailTrimedRepeat)

    // change the border color for warning
    this.warningBorder([['form-input-email', !emailIsValid]])
    this.warningBorder([['form-input-emailRepeat', !emailRepeatIsValid]])

    if (!emailIsValid || !emailRepeatIsValid) {
      return false;
    }
    let areEqual = emailTrimed === emailTrimedRepeat;

    this.warningBorder([['form-input-email', !areEqual]])
    this.warningBorder([['form-input-emailRepeat', !areEqual]])


    return areEqual;

  }

  // function: search the companies in the database with given characters
  searchCompany = (data) => {
    let dataUpperCase = document.getElementById('form-input-company').value ? document.getElementById('form-input-company').value.toUpperCase() : data.target.value.toUpperCase()
    // change the border color for warning
    this.warningBorder([['form-input-company', false]])

    var filterdList = this.state.companiesFromBackend.filter(company => {
      return company.name.toUpperCase().match(dataUpperCase);
    });

    this.setState({ companiesCombination: filterdList, company: -1 });
  }

  // function: set the choosen data from the select field
  setCompany = (event) => {
    let companyId = event.currentTarget.dataset.id;
    document.getElementsByClassName('form-input-properties')[3].value = event.currentTarget.dataset.name;
    // change the border color for warning
    this.warningBorder([['form-input-company', false]])
    this.setState({ companiesCombination: [], company: companyId });
  }

  // function: create a new user with given parameter
  createNewUser = (email, permissionId, companyId) => {
    this.setState({ isLoading: true });
    axios.post(API_STATUS.AUTH_SERVICE_URL + '/user', { "companyId": companyId, "email": email, "groupsId": [permissionId] })
      .then(response => {
        this.props.fireModal({
          content: LabelFile.texts['userIsCreated'],
          header: LabelFile.header['SUCCESSFUL'],
          buttons: this.createOKModalButtons()
        })
      })
      .catch(error => {
        // handle error
        console.log(error)
        this.props.fireModal({
          content: LabelFile.warnings['userIsNotCreated'],
          header: LabelFile.header['ERROR'],
          buttons: this.createOKModalButtons()
        })
      })
      .finally(() => {
        this.setState({ isLoading: false });
      })
  }

  // function: check fetch parameter
  checkIfInputIsOkay = (companyId) => {
    let htmlSelect = document.getElementById('role-group-select');
    let permissionId = htmlSelect.options[htmlSelect.selectedIndex].value;

    let emailIsValid = this.checkIfEmailIsOkay()
    let isValid = true;

    if (companyId <= 0) {
      isValid = false;
      // change the border color for warning
      this.warningBorder([['form-input-company', true]])
    }

    if (!emailIsValid) {
      isValid = false;
    }

    if (isValid) {
      let email = document.getElementById('form-input-email').value;

      const emailTrimed = email.trim()

      this.createNewUser(emailTrimed, permissionId, companyId);
    }

  }

  // function: view email form
  EmailForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['emailAddress']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="email" placeholder={LabelFile.texts['exampleEmail']} onChange={this.typedInputEmail} id="form-input-email" className="form-input-properties" />
          <br />
          <br />
          <input type="email" placeholder={LabelFile.texts['exampleEmailRepeat']} onChange={this.typedInputEmailRepeat} id="form-input-emailRepeat" className="form-input-properties" />
        </div>
      </Fragment>
    )
  }

  // function: view password form
  PasswordForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['password']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="text" placeholder={LabelFile.texts['passwordAutomaticallyGenerate']} disabled className="form-input-properties" />
        </div>
      </Fragment>
    )
  }

  // function: view searchfield for company form
  SearchCompanyForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['company']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="text" placeholder={LabelFile.texts['inputCompanyName']} onChange={this.searchCompany} id="form-input-company" className="form-input-properties" />
          <div className="input-selection-container">
            <ul className="input-selection-properties">
              {/* map through array to get the object values from the array: show companies for select */}
              {this.state.companiesCombination.map(data => (
                <li key={data.id} data-id={data.id} data-name={data.name} onClick={(event) => this.setCompany(event)}>{data.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    )
  }

  // function: view selectfield for permissions
  PermissionSelectfield = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['permission']}*
            </div>
          </div>
        </div>
        <div className="form-right selectbox-properties">
          <select id="role-group-select" className="form-select-properties">
            {/* map through array to get the object values from the array: show roles for select */}
            {this.state.rolesFromBackend.map(({ id, name, roles }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <section className="createuser-container">
        <form>
          <div className="createuser-form-container">
            <div className="form-full">
              <h1>{LabelFile.header['CREATE_NEW_USER']}</h1>
            </div>
            <this.EmailForm />
            <this.PasswordForm />
            <this.SearchCompanyForm />
            <this.PermissionSelectfield />
            <div className="form-full">
              <button type="button" onClick={() => this.checkIfInputIsOkay(this.state.company)} className="createuser-button-properties">
                {LabelFile.labels['createNewUser']}
              </button>
            </div>
          </div>
        </form>
        {this.state.isLoading ? <Loader type="lock" /> : ''}
      </section>
    )
  }
}