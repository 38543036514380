import React, { Component } from 'react'
import './GroupXpart.css'
import '../../List/list.css';
import GroupXpartMenuComponent from './GroupXpartMenuComponent';
import { APINotifications } from '../../Common/API/APINotifications';

import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { PaginationComponent } from '../../List/PaginationComponent';
import { SelectableTableComponent } from '../../List/SelectableTableComponent';
import { GroupXpartControlButtonsComponent } from './GroupXpartControlButtons';
import { Loader } from '../../Common/Components/Loader/loader';

import TableConfig from '../../List/tables-config.json';

import { LabelService } from '../../Common/Translations/LabelService';
import { API } from '../../Common/API/API';
import axios from 'axios';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const API_XPART = new API();

export default class GroupXpart extends Component {
  constructor(props) {
    super(props);
    this.config = TableConfig['groupXpart']; // link here the place of "tables-config.json"
    this.paginationComponent = React.createRef();
    this.bottomPaginationComponent = React.createRef();
    this.tableComponent = React.createRef();
    this.groupInfoComponentRef = React.createRef();
    this.controlButtonsComponentRef = React.createRef();
  }

  state = {
    importCount: '',
    groupMode: [],
    groupType: [],
    isLoading: false,
    currentGroup: null,
    originalXparts: []
  }

  componentDidMount() {
    this.initCurrentGroup();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.groupid !== nextProps.match.params.groupid) {
      this.refresh();
    }
  }

  initCurrentGroup() {
    if (!this.props.match.params.groupid) {
      this.setState({ currentGroup: {} });
    } else {
      this.loadGroup(this.props.match.params.groupid);
    }
  }

  loadGroup(groupId) {
    this.toggleLoading();
    axios.get(API_XPART.XPART_SERVICE_URL + '/group/' + groupId)
      .then(res => {
        this.setState({ currentGroup: res.data });
      })
      .catch(err => {
        this.showException(err);
        this.controlButtonsComponentRef.current.goToGroups();
      })
      .finally(() => this.toggleLoading()
      );

  }


  updateTable(page, parts) {
    if (!this.state.currentGroup.id) {
      this.props.history.push('/groupxpart/' + page + '/' + parts);
    } else {
      this.props.history.push('/groupxpart/' + this.state.currentGroup.id + '/' + page + '/' + parts);
    }

  }

  pgFeedback(first, last, totalPages) {
    if (this.paginationComponent.current) {
      this.paginationComponent.current.updatePages(first, last, totalPages);
    }

    if (this.bottomPaginationComponent.current) {
      this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
    }
  }

  refresh() {
    this.props.history.replace('/refresh' + window.location.pathname);
  }

  startMultiSelect() {
    this.tableComponent.current.startMultiSelect();
  }

  stopMultiSelect() {
    this.tableComponent.current.stopMultiSelect();
  }

  updateMultiSelectState(multiSelect) {
    this.controlButtonsComponentRef.current.updateMultiSelectState(multiSelect);
  }
  clearLocalSelection() {
    this.tableComponent.current.clearLocalSelection();
  }

  showException(err) {
    this.props.fireModal({
      content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors === undefined ? '' : err.response.data.errors[0]),
      header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
      buttons: this.createOKModalButtons()
    });
  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    };
    btns.push(obj);
    return btns;
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  saveGroup() {
    const id = this.state.currentGroup.id ? this.state.currentGroup.id : null;
    const groupInfoDTO = {
      id: id,
      active: this.state.currentGroup.id ? this.state.currentGroup.active : true,
      modeTypeId: this.groupInfoComponentRef.current.getSelectedMode(),
      typeNameId: this.groupInfoComponentRef.current.getSelectedType()
    }
    const data = {
      groupInfoDTO: groupInfoDTO,
      xpartsNumbers: this.tableComponent.current.getSelectedIds()
    }

    this.toggleLoading();
    axios.post(API_XPART.XPART_SERVICE_URL + '/group/save', data)
      .then(res => {
        this.clearLocalSelection();
        this.stopMultiSelect();
        this.controlButtonsComponentRef.current.goToGroups();
      })
      .catch(err => {
        this.showException(err);
      })
      .finally(() => this.toggleLoading()
      );
  }

  render() {
    return (
      <section className="results--section">
        {/* page header */}
        <PageHeaderComponent
          text={this.state.currentGroup && this.state.currentGroup.id ? <h1>{LabelFile.labels['editGroup']}</h1> : <h1>{LabelFile.labels['createGroup']}</h1>}
        />

        {/* pagination */}
        <PaginationComponent
          ref={this.paginationComponent}
          updateTable={(page, parts) => this.updateTable(page, parts)}
          page={this.props.match.params.page}
          parts={this.props.match.params.parts}
          module={'groupXpart'}
        />

        {/* option menu */}
        {this.state.currentGroup ? <GroupXpartMenuComponent ref={this.groupInfoComponentRef} currentGroup={this.state.currentGroup} /> : ''}
        <GroupXpartControlButtonsComponent ref={this.controlButtonsComponentRef} startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
          clearLocalSelection={() => this.clearLocalSelection()} saveGroup={() => this.saveGroup()} history={this.props.history}
          currentGroup={this.state.currentGroup} />

        <div className="list-container">
          {/* multiselect */}
          {this.state.currentGroup ? <SelectableTableComponent
            ref={this.tableComponent}
            page={this.props.match.params.page - 1}
            size={this.props.match.params.parts}
            pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
            context={this.props.match.params.context}
            module={'groupXpart'}
            usesFilter={this.state.usesFilter || this.config.usePreFilter}
            generalFilter={this.state.generalFilter}
            preFilter={this.state.preFilter}
            localStoragePrefix={this.state.currentGroup && this.state.currentGroup.id ? this.config.localStoragePrefix + "-edit" : this.config.localStoragePrefix}
            refresh={() => this.refresh()} history={this.props.history}
            fireModal={this.props.fireModal}
            closeModal={this.props.closeModal}
            originalSelected={this.state.currentGroup.xpartNumbers}
            updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
          /> : ''}
        </div>

        {/* pagination */}
        <PaginationComponent
          ref={this.bottomPaginationComponent}
          updateTable={(page, parts) => this.updateTable(page, parts)}
          page={this.props.match.params.page}
          parts={this.props.match.params.parts}
          module={'groupXpart'}
        />

        <div id="popupContainer"></div>
        {this.state.isLoading ? <Loader type="lock" /> : ''}
      </section>
    )
  }

}
