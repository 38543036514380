import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LabelService} from "../../Common/Translations/LabelService";
const LabelFile = LabelService.getLabelsByLocal('de');

export class SearchTerms extends Component {
    handleClick ({ type, index }) {
        console.log('handle click', type, index)
        switch (type) {
            case 'simple-query':
                const { removeSimpleQuery } = this.props;
                removeSimpleQuery(index);
                break;
            case 'special-query':
                const { removeSpecialQuery } = this.props;
                removeSpecialQuery(index);
                break;
            default:
                console.log('Boom!!');
        }
    };

    render () {
        return (
            <div>
                <ul className="filter-list">
                    { this.renderSpecialSearchTerms() }
                    { this.renderSimpleSearchTerms() }
                </ul>
            </div>
        )
    }

    renderSpecialSearchTerms () {
        const { specialQueries } = this.props;

        return specialQueries.map((query, index) => {
            const { labelData, queryData } = query;
            const { labelKey } = labelData;
            const { fieldName, fieldValue } = queryData;
            const queryType = LabelFile.labels[labelKey];

            let labelName;
            switch (fieldName) {
                case 'XPART_CURRENT_STATUS':
                    const { statusName } = labelData;
                    labelName = LabelFile.statuses[statusName];
                    break;
                case 'XPART_CURRENT_CONTEXT':
                    const { contextName } = labelData;
                    labelName = LabelFile.context[contextName];
                    break;
                default:
                    labelName = fieldValue;
            }

            const label = `${queryType}: ${labelName}`;
            return (
                <li className="filter-list-item" >
                    { label }
                    <span className="search-bubble-delete">
                        <FontAwesomeIcon key={index}
                                         className="icon-remove"
                                         icon="times-circle"
                                         onClick={() => this.handleClick({type: 'special-query', index })} />
                    </span>
                </li>
            )
        });
    }

    renderSimpleSearchTerms () {
        const { simpleQueries} = this.props;
        return simpleQueries.map((query, index) => {
            const { queryData } = query;
            const { fieldValue } = queryData;
            return (
                <li className="filter-list-item">
                    { fieldValue }
                    <span className="search-bubble-delete">
                        <FontAwesomeIcon key={index}
                                         className="icon-remove"
                                         icon="times-circle"
                                         onClick={() => this.handleClick({ type: 'simple-query', index })}/>
                    </span>
                </li>
            )
        });
    }
}
