import React, { Component } from 'react'
import axios from 'axios';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import { HelperFunctions } from '../Common/Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmationButton } from '../Common/ConfirmationButton/confirmation-button'
import './Xpart.css'

const API_XPART = new API();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();
const NOTIFICATIONS = new APINotifications();

class XpartListTechnikum extends Component {
    constructor(props) {
        super(props);
        this.resultlistarr = [];
        this.labelFile = LabelService.getLabelsByLocal('de');
        this.handleChangedAmount = this.handleChangedAmount.bind(this);
        this.multiSelectEvent = (e) => {
            e.stopPropagation();
            e.currentTarget.classList.toggle("multiselect-selected");
            let resultlistselected = this.state.resultlistselected;
            if (!resultlistselected.includes(e.currentTarget.dataset.attr)) {
                resultlistselected.push(e.currentTarget.dataset.attr);
            } else {
                if (resultlistselected.indexOf(e.currentTarget.dataset.attr) >= 0) {
                    resultlistselected.splice(resultlistselected.indexOf(e.currentTarget.dataset.attr), 1);
                }
            }
            this.setState({
                multiSelectSelected: resultlistselected.length,
                resultlistselected: resultlistselected
            })
            localStorage.setItem("xpm-list-multiselect", JSON.stringify(resultlistselected))
        }
        this.multiSelectEventHandler = this.multiSelectEvent.bind(this);
    }

    state = {
        resultlist: [],
        resultlistselected: [],
        first: true,
        last: false,
        lastPage: 9999,
        multiSelectHide: "",
        multiSelectShow: "xpm-hidden",
        multiSelectSelected: 0,
        selectMode: false
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    formatData(data, selectedlist) {
        let lang = HelperFunction.getLocale();
        data.forEach(function (xpart) {
            if (xpart['active'] === true) {
                xpart['active'] = "Aktiv";
            } else {
                xpart['active'] = "Archiviert";
            }

            xpart['created'] = HelperFunction.timeConverter(xpart['created'], lang);
            xpart['updated'] = HelperFunction.timeConverter(xpart['updated'], lang);
            xpart['weight'] = HelperFunction.formatFloats(xpart['weight'], lang);

            var items = xpart["itemOrders"];
            var orderItemList = "";


            items.forEach(item => {
                if (item['orderNumber'] in LabelFile.spec_projects) {
                    if (item['itemNumber'] in LabelFile.spec_projects) {
                        if (LabelFile.spec_projects[item['itemNumber']] === "") {
                            orderItemList += LabelFile.spec_projects[item['orderNumber']];
                            orderItemList += "; ";
                        } else {
                            orderItemList += LabelFile.spec_projects[item['itemNumber']] + " : " + LabelFile.spec_projects[item['orderNumber']];
                            orderItemList += "; ";
                        }
                    } else {
                        orderItemList += item['itemNumber'] + " : " + LabelFile.spec_projects[item['orderNumber']];
                        orderItemList += "; ";
                    }
                } else {
                    orderItemList += item['itemNumber'] + " : " + item['orderNumber'];
                    orderItemList += "; ";
                }
            });

            if (orderItemList.length > 1) {
                orderItemList = orderItemList.substring(0, orderItemList.length - 2);
            }

            xpart['orderItem'] = orderItemList;
            xpart['status'] = LabelFile.statuses[xpart['currentStatus']['statusDTO']['name']] + " [" + LabelFile.context[xpart['currentStatus']['contextDTO']['name']] + "]";
            xpart['unit'] = LabelFile.units[xpart['unit']['name']];

            if (selectedlist.includes(xpart['id'].toString())) {
                xpart["class-selected"] = "multiselect-selected";
            } else {
                xpart["class-selected"] = "";
            }
        })
        return data;
    }

    handleLoad() {

        let pageHandle = this.props.match.params.page - 1;
        let partsHandle = this.props.match.params.parts;

        let size = "size=" + partsHandle;
        let sort = "sort=xpartNumber,desc";
        let page = "page=" + pageHandle;
        let orderfilter = "on=TECHNIKUM-PROJECT";


        axios.get(API_XPART.XPART_SERVICE_URL + '/xpartview/list?' + size + '&' + page + '&' + sort + '&' + orderfilter)
            .then(response => {
                this.resultlistarr = response['data']['content'];

                this.resultlistarr = this.formatData(this.resultlistarr, this.state.resultlistselected);

                this.setState({
                    resultlist: this.resultlistarr,
                    first: response["data"]["first"],
                    last: response["data"]["last"],
                    lastPage: response["data"]["totalPages"]
                })

            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]) + '\n' + document.getElementById("xpartScan").value,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });

        if ([10, 20, 50, 100, 200, 500].includes(parseInt(partsHandle))) {
            document.getElementById("xpartsAmountList").value = partsHandle;
        }
    }

    componentDidMount() {
        this.setSelectDefaults();
    }


    //Hacky way to update props and states if only url params, but not url itself changed
    componentDidUpdate(prevProps) {
        const { match: { params: { page } } } = this.props;
        const { match: { params: { parts } } } = this.props;
        if (prevProps.match.params.page !== page || prevProps.match.params.parts !== parts) {
            this.handleLoad();
        }
    }

    handleChangedAmount(value) {
        this.props.history.push('/xpartlist/technikum/1/' + value);
    }

    renderPageinationControls() {
        let currentPage = Number.parseInt(this.props.match.params.page);
        let prevPage = currentPage - 1;
        let nextPage = currentPage + 1;
        let currentParts = this.props.match.params.parts;

        let disablePrev = "";
        if (this.state.first) {
            disablePrev = "disabled-link";
        }

        let disableNext = "";
        if (this.state.last) {
            disableNext = "disabled-link";
        }


        return (
            <div class="paginationControls">
                <Link key="990" class={disablePrev} id="paginationFirst" to={'/xpartlist/technikum/1/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-double-left']} /></Link>
                <Link key="991" class={disablePrev} id="paginationPrev" to={'/xpartlist/technikum/' + prevPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-left']} /></Link>
                <Link key="992" class="disabled-link" id="paginationCurrent" to={'/xpartlist/technikum/' + currentPage + '/' + currentParts}><span>{currentPage}</span></Link>
                <Link key="993" class={disableNext} id="paginationNext" to={'/xpartlist/technikum/' + nextPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-right']} /></Link>
                <Link key="994" class={disableNext} id="paginationLast" to={'/xpartlist/technikum/' + this.state.lastPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-double-right']} /></Link>

                <span>{LabelFile.labels["xparts"]}: </span>
                <select onChange={(event) => this.handleChangedAmount(event.target.value)} value={currentParts} id="xpartsAmountList">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                </select>
            </div>
        )
    }

    confirmationButtonClick() {
        let context = 6; //Abruf
        let status = 1; //Erstellt
        let storagetarget = ["KME", "Technikum"]; //Fixed for Technikum
        let xparts = JSON.parse(localStorage.getItem("xpm-list-multiselect"));

        let data = {
            "contextId": context,
            "statusId": status,
            "destinationStoragePlace": storagetarget,
            "xpartIds": xparts
        }

        axios.post(API_XPART.XPART_SERVICE_URL + '/list/status', data)
            .then(response => {

                localStorage.removeItem("xpm-list-multiselect");
                this.stopMultiSelect();

                this.props.history.replace('/refresh' + window.location.pathname); //Nice hacky way to refresh the content, but not the browser

            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: "ERROR",
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });

    }

    startMultiSelect() {
        this.setState({
            multiSelectHide: "xpm-hidden",
            multiSelectShow: "",
            selectMode: !this.state.selectMode
        })

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.addEventListener('click', this.multiSelectEventHandler);
        })
    }

    stopMultiSelect() {
        this.setState({
            multiSelectHide: "",
            multiSelectShow: "xpm-hidden",
            selectMode: !this.state.selectMode
        })

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.removeEventListener('click', this.multiSelectEventHandler);
        });
    }
    createCountMessage() {
        return this.labelFile['texts']['COUNT_OF_SELECTED_XPARTS_TO_REQUEST'].replace('$COUNT', this.state.multiSelectSelected);
    }

    clearLocalSelection() {
        if (localStorage.getItem("xpm-list-multiselect")) {
            localStorage.removeItem("xpm-list-multiselect");
            this.props.history.replace('/refresh' + window.location.pathname);
        }
    }

    renderOptionalButtons() {
        return (
            <div className="optionalButtons xpm-button-container">
                <button onClick={() => { this.startMultiSelect() }}
                    className={"xpm-button " + this.state.multiSelectHide}>{this.labelFile.labels["requestparts"]}</button>
                <button onClick={() => { this.stopMultiSelect() }}
                    className={"xpm-button " + this.state.multiSelectShow}>{this.labelFile.labels["cancel"]}</button>
                <button onClick={() => { this.clearLocalSelection() }}
                    className={"xpm-button " + this.state.multiSelectShow}>{this.labelFile.labels["reset"]}</button>

                {!this.state.selectMode || this.state.multiSelectSelected < 1 ? null : <ConfirmationButton
                    expandedStateContent={this.createCountMessage()}
                    compressedStateContent={this.createCountMessage()}
                    mobileStateContent={this.state.multiSelectSelected + " " + this.labelFile.labels["xparts"]}
                    onClick={this.confirmationButtonClick.bind(this)} />}
            </div>
        )
    }

    setSelectDefaults() {
        if (localStorage.getItem("xpm-list-multiselect")) {
            let resultmulti = JSON.parse(localStorage.getItem("xpm-list-multiselect"));
            this.setState({
                resultlistselected: resultmulti,
                multiSelectSelected: resultmulti.length
            })
        }
        this.handleLoad();
    }

    render() {
        let warningtext = "";

        if (!this.state.selectMode && this.state.multiSelectSelected > 0) {
            warningtext = this.labelFile.texts["localrequest"] + ": " + this.state.multiSelectSelected;
        } else if (this.state.selectMode) {
            warningtext = this.labelFile.texts["requestselectmodetext"];
        }
        return (
            <section className="results--section">
                {this.renderOptionalButtons()}
                <span className={"xpm-warning-span"}>{warningtext}</span>
                {this.renderPageinationControls()}
                <div className="list-container">
                    <div class="list-grid">
                        <div class="list-grid-row list-grid-row-headline list-grid-technikum">
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['xpart']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['desc_de']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['desc_en']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['quantity']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['unit']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['weight']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['status']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['storage']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['storagetarget']}</span>
                            </div>
                        </div>
                        {this.state.resultlist.map(result => (
                            <div className={"list-grid-row list-grid-technikum multi-select " + result["class-selected"]} data-attr={result['id']}>
                                <div data-name={LabelFile.labels['xpart']} class="list-grid-item">
                                    <Link to={'/xpart/show/' + result['xpartNumber'] + "/readonly" + HelperFunction.getContextOrNot(this.props.match.params.context)}><span>{result['xpartNumber']}</span></Link>
                                </div>
                                <div data-name={LabelFile.labels['desc_de']} class="list-grid-item">
                                    <span title={result['descriptionDe']}>{result['descriptionDe']}</span>
                                </div>
                                <div data-name={LabelFile.labels['desc_en']} class="list-grid-item">
                                    <span title={result['descriptionEn']}>{result['descriptionEn']}</span>
                                </div>
                                <div data-name={LabelFile.labels['quantity']} class="list-grid-item">
                                    <span>{result['quantity']}</span>
                                </div>
                                <div data-name={LabelFile.labels['unit']} class="list-grid-item">
                                    <span>{result['unit']}</span>
                                </div>
                                <div data-name={LabelFile.labels['weight']} class="list-grid-item">
                                    <span>{result['weight']}</span>
                                </div>
                                <div data-name={LabelFile.labels['status']} class="list-grid-item">
                                    <span>{result['status']}</span>
                                </div>
                                <div data-name={LabelFile.labels['storage']} class="list-grid-item">
                                    <span>{result['storage'] !== null ? result['storage'].storageCode : ''}</span>
                                </div>
                                <div data-name={LabelFile.labels['storagetarget']} class="list-grid-item">
                                    <span>{result['storage'] !== null ? result['storage'].destinationStorageCode : ''}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {this.renderPageinationControls()}
            </section>
        )
    }
}

export default withRouter(XpartListTechnikum);
