export default class ManageQueryStorage {
    constructor(module, component) {
        this.module = module;
        this.component = component;
    }

    get storageKey() {
        return `xpm-${this.component}-filter-list-${this.module}`;
    }
    
    setQueries = (queryList) => {
        const toJson = JSON.stringify(queryList);
        localStorage.setItem(this.storageKey, toJson);
    };

    getQueries = () => {
        const storageItem = localStorage.getItem(this.storageKey);
        return JSON.parse(storageItem);
    };
}
