import React, { Component } from 'react'
import axios from 'axios';
import { withRouter } from 'react-router';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import { FieldGenerator } from '../Common/Helper/FieldGenerator';
import { APINotifications } from '../Common/API/APINotifications';
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router-dom';
import { HelperFunctions } from '../Common/Helper/Helper';
import './Xpart.css'
import { Loader } from '../Common/Components/Loader/loader';

const API_XPART = new API();
const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();

class Xpart extends Component {

    constructor(props) {
        super(props);

        this.units = [
            {
                "id": 1,
                "name": LabelFile.labels["piece"]
            },
            {
                "id": 2,
                "name": LabelFile.labels["meter"]
            },
            {
                "id": 3,
                "name": LabelFile.labels["liter"]
            }
        ];

        this.status = [];
    }

    state = {
        statusDD: "",
        mode: 0,
        wrt_btn_text: LabelFile.labels['save'],
        dlt_btn_class: "xpm-hidden",
        xpart_data: {},
        notUploadedPhotos: 0,
        phototooltip: "",
        isLoading: false,
        nextModule: 'xpartOverview',
        page: 1,
        parts: 100
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }

    componentDidMount() {
        this.changeMode();
        //this.generateStatusDropdown();
        if (this.props.match.params.xpart) {
            this.xPartRequested(this.props.match.params.xpart);
        }
        if (this.props.match.params.xpart) {
            this.handleOverrides();
        }
    }

    handleSave() {
        if (Fields.validateInputs()) {
            var data = {
                'descriptionDe': document.getElementById('desc_de').value,
                'descriptionEn': document.getElementById('desc_en').value,
                'quantity': document.getElementById('quantity').value,
                'weight': document.getElementById('weight').value,
                'active': true,
                'unitID': document.getElementById('unit').value,
                'id': document.getElementById('id').value,
                'statusID': this.state.xpart_data['currentStatus']['statusDTO']['id'],
                'contextId': this.state.xpart_data['currentStatus']['contextDTO']['id'],
                'customerMaterialNumber': document.getElementById('customerMaterialNumber').value
            }
            this.toggleLoading(true)
            axios.post(API_XPART.XPART_SERVICE_URL + '/one/properties', data)
                .then(res => {

                    this.props.fireModal({
                        content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                        header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                        buttons: this.createOKModalButtons()
                    });

                    this.props.history.replace('/xpart/show/' + res.data["xpartNumber"] + '/edit' + HelperFunction.getContextOrNot(this.props.match.params.context))
                })
                .catch(err => {
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
                .finally(() => this.toggleLoading(false));
        }
    }

    handleClear() {
        var allInputs = document.getElementsByClassName("xpm-input");
        for (var i = 0; i < allInputs.length; i++) {
            allInputs[i].setAttribute('class', 'xpm-input');
            allInputs[i].value = "";
            if (allInputs[i].nextSibling) {
                allInputs[i].nextSibling.setAttribute('class', 'xpm-warnings xpm-warnings-inactive');
            }
            if (document.getElementById('write-btn')) {
                document.getElementById('write-btn').innerText = LabelFile.labels["save"];
            }
            if (document.getElementById('delete-btn')) {
                document.getElementById('delete-btn').classList.add('xpm-hidden');
            }
        }
        // this.props.history.push('/xpart');
    }

    // warning popup before deletion 
    handleDelete() {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'DELETE_XPART_WARNING', this.state.xpart_data["xpartNumber"]),
            header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
            buttons: this.createDeleteWarningButtons()
        });
    }
    createDeleteWarningButtons() {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.props.closeModal,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: this.confirmDelete,
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }
    // delete request of Xpart.
    confirmDelete = () => {
        this.props.closeModal();
        this.toggleLoading(true)
        axios.post(API_XPART.XPART_SERVICE_URL + '/one/inactive/' + document.getElementById('id').value)
            .then(res => {
                this.props.history.push('/list/' + this.state.nextModule + '/' + this.state.page + '/' + this.state.parts);
                this.handleClear();
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.toggleLoading(false));
    }

    generateStatusDropdown() {
        if (!document.getElementById("status")) {
            this.toggleLoading(true)
            axios.get(API_XPART.XPART_SERVICE_URL + '/status/')
                .then(res => {
                    this.status = res['data'];
                    this.status.forEach(status => {
                        status['name'] = this.statusLabels[status['name']];
                    })
                    let statuslist = Fields.generateInputSelect('status', this.status, true, [LabelFile.warnings['required']], this.state.mode)
                    var temp = ReactDOMServer.renderToString(statuslist);
                    document.getElementById("inputMain").insertAdjacentHTML('afterbegin', temp);

                    if (this.props.match.params.xpart) {
                        this.xPartRequested(this.props.match.params.xpart);
                    }
                })
                .catch(err => {
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
                .finally(() => this.toggleLoading(false));
        }
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    changeMode() {
        if (this.props.match.params.mode) {
            if (this.props.match.params.mode === "readonly") {
                this.setState({
                    mode: 1
                })
            }
        }
    }

    calculateNotUploadedPhotos() {
        let photos = 0;
        if (localStorage.getItem("xpm-gallery-list-" + this.state.xpart_data["xpartNumber"])) {
            let result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.state.xpart_data["xpartNumber"]));
            photos = result.length;
        }
        this.setState({
            notUploadedPhotos: photos
        })
        this.generatePhotoTooltip();
    }

    getOrderAndProjects() {
        let orders = "";
        let projects = "";
        this.toggleLoading(true)
        axios.get(API_XPART.XPART_SERVICE_URL + '/xpartview/xpartnumber/' + this.state.xpart_data["xpartNumber"])
            .then(response => {
                let itemOrders = response["data"]["itemOrders"];
                itemOrders.forEach(item => {
                    if (item["orderType"]["id"] === 1) {
                        orders += HelperFunction.getItemValueView(item, ['orderNumber', 'itemNumber'], '/')
                    }
                    if (item["orderType"]["id"] === 2) {
                        projects += HelperFunction.getItemValueView(item, ['orderNumber', 'itemNumber'], '/')
                    }
                })
                let xpart_data = this.state.xpart_data;
                xpart_data["Orders"] = orders;
                xpart_data["Projects"] = projects;
                this.setState({
                    xpart_data: xpart_data
                })
                document.getElementById('project').value = projects;
                document.getElementById('order').value = orders;
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.toggleLoading(false));
    }

    xPartRequested(xpartid) {
        this.toggleLoading(true)
        axios.get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + xpartid)
            .then(response => {
                document.getElementById('status').value = LabelFile.statuses[response.data['currentStatus']['statusDTO']['name']] + " [" + LabelFile.context[response.data['currentStatus']['contextDTO']['name']] + "]";
                document.getElementById('xpart').value = response.data["xpartNumber"];
                document.getElementById('desc_de').value = response.data["descriptionDe"];
                document.getElementById('desc_en').value = response.data["descriptionEn"];
                document.getElementById('quantity').value = response.data["quantity"];
                document.getElementById('weight').value = response.data["weight"];
                document.getElementById('customerMaterialNumber').value = response.data["customerMaterialNumber"];
                document.getElementById('unit').value = response.data["unit"]['id'];
                document.getElementById('id').value = response.data["id"]

                var items = response.data["items"];
                var orderItemList = "";

                items.forEach(item => {
                    orderItemList += item['number'] + " : ";

                    let orders = item['orders'];
                    orders.forEach(order => {
                        orderItemList += order['number'] + ", "
                    });
                    orderItemList = orderItemList.slice(0, -2);
                    orderItemList += "; <br>";
                });

                document.getElementById('orderItemList').innerHTML = orderItemList;

                if (response.data["storagePlace"]) {
                    let storage = "";
                    let storage_arr = response.data["storagePlace"]["parents"].reverse();
                    storage_arr.forEach(function (parent) {
                        storage += parent["name"] + "-";
                    });
                    document.getElementById('storage').value = storage + response.data["storagePlace"]["name"];
                }

                //document.getElementById('status').value = response.data["status"]['id'];

                this.setState({
                    xpart_data: response.data
                })

                this.calculateNotUploadedPhotos();
                this.getOrderAndProjects();

            })
            .catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.toggleLoading(false));
    }

    handleOverrides() {
        this.setState({
            wrt_btn_text: LabelFile.labels["update"],
            dlt_btn_class: ""
        })
    }

    generatePhotoTooltip() {
        let serverFiles = this.state.xpart_data["filesCount"];
        let localFiles = this.state.notUploadedPhotos;
        let text = LabelFile.texts["serverfiles"] + ": " + serverFiles + ", " + LabelFile.texts["localfiles"] + ": " + localFiles;
        this.setState({
            phototooltip: text
        })
    }

    renderInputContainer() {
        let existingXpart = false;
        if (this.props.match.params.xpart) {
            existingXpart = true;
        }
        return (
            <section className="results--section xpartform">
                <h1>{LabelFile.labels['xparts']}</h1>
                <div className="header-underline-img"><img src={window.location.origin + "/images/circle.png"} alt="Losteile" title="Losteil-Erfassung"></img></div>
                <hr className="header-underline"></hr>

                <div className="input-container" id="inputMain">
                    {Fields.generateInputText("status", ".", false, [], true)}
                    {Fields.generateInputText("order", "[0-9/]{1,20}", true, [LabelFile.warnings['required']], true)}
                    {Fields.generateInputText("project", "{1,20}", true, [LabelFile.warnings['required']], true)}
                    {Fields.generateInputText("xpart", "[0-9]{2}-[0-9]{1,10}", false, [LabelFile.warnings['required'], LabelFile.warnings['xpart']], true)}
                    {Fields.generateInputTextarea("desc_de", "255", true, [LabelFile.warnings['required']], this.state.mode)}
                    {Fields.generateInputTextarea("desc_en", "255", true, [LabelFile.warnings['required']], this.state.mode)}
                    {Fields.generateInputNumber("quantity", "1", "10000", "1", true, [LabelFile.warnings['required'], LabelFile.warnings['number']], this.state.mode)}
                    {Fields.generateInputSelect('unit', [this.units[0], this.units[1], this.units[2]], true, [LabelFile.warnings['required']], this.state.mode)}
                    {Fields.generateInputNumber("weight", "0", "1000000", ".001", true, [LabelFile.warnings['required'], LabelFile.warnings['number']], this.state.mode)}
                    {Fields.generateInputText("customerMaterialNumber", "[a-zA-Z0-9]{1,20}", false, [])}
                    {Fields.generateInputText("storage", ".", false, [], true)}
                    {Fields.generateInputHidden("id")}

                    <div className="xpm-button-container">
                        <button type="button"
                            className="xpm-button xpm-button-clear"
                            onClick={this.handleClear.bind(this)}>{LabelFile.labels['dismiss']}</button>

                        <button type="button" id="write-btn"
                            className="xpm-button"
                            onClick={this.handleSave.bind(this)}>{this.state.wrt_btn_text}</button>

                        <button type="button" id="delete-btn"
                            className={this.state.dlt_btn_class + " delete-button xpm-button"}
                            onClick={this.handleDelete.bind(this)}>{LabelFile.labels['delete']}</button>
                    </div>

                    {existingXpart ? (
                        <Link class="xpm-button xpm-go-to-photos-button" title={LabelFile.texts["PHOTO_BTN_XPART"] + " " + this.state.phototooltip}
                            to={'/photo/gallery/' + this.state.xpart_data['xpartNumber'] + HelperFunction.getContextOrNot(this.props.match.params.context)}><span>{LabelFile.labels["photos"] + "("}{this.state.notUploadedPhotos}{")"}</span></Link>
                    ) : (
                        <div></div>
                    )
                    }

                    <div id="orderItemList" data-help="debug log for orders and items. ignore."></div>
                </div>
            </section>

        )
    }

    render() {
        return (
            <section className="results--section">
                {this.renderInputContainer()}
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </section>
        )
    }
}


export default withRouter(Xpart);
