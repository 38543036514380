

import React from 'react';
import WizardStep from '../Common/Components/Wizrd/WizardStep';
import { CommonWizardComponent } from '../Common/Components/Wizrd/CommonWizardComponent';
import GroupTableButtonTextComponent from './Common/GroupTableButtonTextComponent';
import Scan from '../Scan/XpartScan/Scan';
import { LabelService } from '../Common/Translations/LabelService';
import CommonPhotosComponent from '../Common/Components/Photo/CommonPhotosComponent';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import GroupSaveButtons from './Common/GroupSaveButtons';
import TableConfig from '../List/tables-config.json';
import { CameraService } from '../Camera/CameraService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

export default class ShuttleGroupReceptionWizard extends CommonWizardComponent {

    moduleName = 'receiveShuttleGroupTable';
    config = TableConfig[this.moduleName];
    localStoragePrefix = this.config['localStoragePrefix'];


    componentDidMount() {
        if (!localStorage.getItem(this.localStoragePrefix)) {
            var dataBlank = { "content": [], "textToggle": false, "buttonToggle": false, "xpartGroupId": null, "singleXpart": false ,"requestGroupMetaInfo": {}};
            localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataBlank));
        }
        //init all steps
        let steps = [];
        steps.push(this.getGroupTableCheckStep());
        steps.push(this.getScanStep());
        steps.push(this.getCompleteGroupStep());
        steps.push(this.getPhotoStep());
        this.initComponent(steps, steps[0]);
    }

    // after scan action is done
    postScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.getXpartData(scanValue);
    }

    updateGalleryIndex = () => {
        this.currentStepRef.setGalleryIndex('photos', this.localStoragePrefix);
    }

    goToPhotos(e) {
        this.goToStep('photo-step', this.updateGalleryIndex);
        this.setState({ wizardControlComponent: null })
    }



    getPhotoLocalStoragePrefix() {
        return this.localStoragePrefix + '-photos';
    }


    undoScan = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    }


    getXpartData(xpartNumber) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/xpartview/xpartnumber/' + xpartNumber)
            .then(res => {
                var dataCurrent = { "content": [], "textToggle": false, "buttonToggle": false, "xpartGroupId": null };
                if (!this.validateXpart(res['data'])) {
                    this.currentStepRef.reset();
                    this.goToTableCheck();
                    return;
                }
                if (res['data'].shuttleGroupMetaInfo) {
                    let groupID = res['data'].shuttleGroupMetaInfo.xpartGroupId;
                    this.fetchGroupData(groupID);
                    return;
                }
                
                // add xpart info to the data set for table 
                dataCurrent['requestGroupMetaInfo'] = res['data']['requestGroupMetaInfo'];
                dataCurrent['content'].push(res['data']);
                dataCurrent['buttonToggle'] = true;
                dataCurrent['singleXpart'] = true;
                dataCurrent['textToggle'] = true;
                localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataCurrent))
                this.goToTableCheck();


            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }


    fetchGroupData(xpartGroupId) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/xpartview/group/' + xpartGroupId)
            .then(res => {
                var dataCurrent = { "content": [], "textToggle": false, "buttonToggle": false, "xpartGroupId": 0, "singleXpart": false ,"requestGroupMetaInfo": {} };
                dataCurrent['requestGroupMetaInfo'] = res['data'][0]['requestGroupMetaInfo'];
                dataCurrent['textToggle'] = true;
                dataCurrent['content'] = res['data'];
                dataCurrent['buttonToggle'] = true;
                dataCurrent['xpartGroupId'] = xpartGroupId;
                localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataCurrent))
                this.goToTableCheck();

            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }



    getScanStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['storagexpartNr'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-step', scanComponent);

    }

    getGroupTableCheckStep() {

        const tableCheckComponent =
            <GroupTableButtonTextComponent
                setRef={el => this.setCurrentStepRef(el)}
                moduleName={this.moduleName}
                renderOptionalButtons={() => this.renderOptionalButtons()}
            >
            </GroupTableButtonTextComponent>

        return new WizardStep('group-table-step', tableCheckComponent);

    }

    getCompleteGroupStep() {

        const completeGroupCheckComponent =
            <GroupSaveButtons
                setRef={el => this.setCurrentStepRef(el)}
                goBackToTable={() => this.goBackToTable()}
                moduleName={this.moduleName}
                goToPhotos={() => this.goToPhotos()}
                save={() => this.save()}
            >
            </GroupSaveButtons>

        return new WizardStep('group-save-step', completeGroupCheckComponent);
    }

    goToCompleteGroup() {
        this.goToStep('group-save-step');
        this.setState({ wizardControlComponent: null })
    }

    getPhotoStep() {
        const photoComponent =
            <CommonPhotosComponent
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                goBack={() => this.goToCompleteGroup()}
                setRef={el => this.setCurrentStepRef(el)}
            />
        return new WizardStep('photo-step', photoComponent);
    }


    goBackToTable() {
        this.goToTableCheck();
    }

    goToTableCheck() {
        this.goToStep('group-table-step');
        this.setState({ wizardControlComponent: null })
    }

    goToScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.goToStep('scan-step');
    }

    renderOptionalButtons() {
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        return (
            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                    <button onClick={() => { this.goToScan() }}
                        className={"xpm-button"}>{LabelFile.labels[this.config.addButtonName]}</button>
                    <button onClick={() => { this.goToCompleteGroup() }}
                        className={"xpm-button " + (!groupData.buttonToggle ? ' xpm-disabled ' : '')} disabled={!groupData.buttonToggle ? true : false}>{LabelFile.labels[this.config.closeGroupButtonName]}</button>
                </div>
            </div >
        )
    }


    async saveXpartInfoGroup(data,requestURL) {
        return await axios.post(API_XPART.XPART_SERVICE_URL +requestURL , data);
    }


    save = () => {
        //Check if the user took Photos and scanned xparts
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        let requestURL='';
        if (!this.validateBeforeSave()) {
            this.goToCompleteGroup();
            return;
        }
        this.toggleLoading(true);
        let requestedData = this.getRequestedData();
        (groupData.singleXpart? requestURL='/receive': requestURL='/group/convenience');
        let promise = this.saveXpartInfoGroup(requestedData,requestURL);

        promise.then(
            response => {
                localStorage.removeItem(this.localStoragePrefix);
                localStorage.removeItem(this.getPhotoLocalStoragePrefix());
                if (!localStorage.getItem(this.localStoragePrefix)) {
                    var dataBlank = { "content": [], "textToggle": false, "buttonToggle": false, "xpartGroupId": 0, "singleXpart": false,"requestGroupMetaInfo": {} };
                    localStorage.setItem(this.localStoragePrefix, JSON.stringify(dataBlank));
                }
                this.goBackToTable();
            }
        ).catch(err => {
            console.log(err)
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    getRequestedData() {
        let groupData = JSON.parse(localStorage.getItem(this.localStoragePrefix));
        //here check for the single xpart or the group, return the request object accordingly
        if (groupData.singleXpart) {
            // request object for the single xpart
            let requestedData = {
                "files": [
                    "string"
                ],

                "storageSequence": [
                    "KME", "WARENANNAHME"
                ],
                "validationDTO": {
                    "expectedContextsIds": [
                        10
                    ],
                    "expectedStatusIds": [
                        2
                    ]
                },
                "xpartProperties": {
                    "active": true,
                    "contextId": 10,
                    "customerMaterialNumber": "string",
                    "descriptionDe": "string",
                    "descriptionEn": "string",
                    "id": 0,
                    "quantity": 0,
                    "statusID": 4,
                    "unitID": 0,
                    "weight": 0
                }
            }

            requestedData['files'] = CameraService.getPhotosToSend(this.getPhotoLocalStoragePrefix());
            requestedData['xpartProperties']['customerMaterialNumber'] = groupData['content'][0].customerMaterialNumber;
            requestedData['xpartProperties']['descriptionDe'] = groupData['content'][0].descriptionDe;
            requestedData['xpartProperties']['descriptionEn'] = groupData['content'][0].descriptionEn;
            requestedData['xpartProperties']['id'] = groupData['content'][0].id;
            requestedData['xpartProperties']['quantity'] = groupData['content'][0].quantity;
            requestedData['xpartProperties']['unitID'] = groupData['content'][0].unit.id;
            requestedData['xpartProperties']['weight'] = groupData['content'][0].weight;
            return requestedData;

        }

        //request Object for the group
        let requestedData = {
            "contextId": 10,
            "statusId": 4,
            "storageSequence": [
                "KME", "WARENANNAHME"
            ],
            "validationDTO": {
                "expectedContextsIds": [
                    10
                ],
                "expectedStatusIds": [
                    2
                ]
            },
            "xpartGroupId": 0,
            "deactivate": true
        }
        requestedData['photos'] = CameraService.getPhotosToSend(this.getPhotoLocalStoragePrefix());
        requestedData['xpartGroupId'] = groupData.xpartGroupId;
        return requestedData;
    }


    validateXpart = (xpart) => {
        if (xpart.currentStatus.statusDTO.name !== 'READY_FOR_SHIPMENT' || xpart.currentStatus.contextDTO.name !== "TRANSPORTATION") {

            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'XPART_NOT_IN_EXPECTED_STATUS_READY_FOR_SHIPMENT_TRANSPORTATION',
                    LabelFile.statuses[xpart.currentStatus.statusDTO.name], LabelFile.context[xpart.currentStatus.contextDTO.name]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }

        return true;
    }

    validateBeforeSave() {
        if (!localStorage.getItem(this.getPhotoLocalStoragePrefix())) {
            this.toggleLoading(false);
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'SCAN_PHOTO_TO_COMPLETE_GROUP_SAVE'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }
        return true;
    }

}
