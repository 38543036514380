import React, { Component } from 'react'
import { withRouter } from 'react-router';
import './Reception.css';
import Scan from './../Scan/XpartScan/Scan';
import { LabelService } from './../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de')


class Reception extends Component {

  constructor(props) {
    super(props)
    if(this.props.match.params.context==='TECHNIKUM'){
      this.initialState=this.initialState1;
    }
    else{
      this.initialState=this.initialState2;
    }
  }
  initialState1 = [
    {
      cam: {
        result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
        flash: 'scan-container-flash'
      },
      title: {
        view: true, // --- view title in browser
        name: LabelFile.labels['storagexpartNr'] // --- view title in browser
      },
      form: {
        view: true, // --- view form in browser
        alternative: true // view form width (alternative = full width)
      },
      button: {
        view: false, // --- view button in browser
        alternative: true // view button width (alternative = full width)
      },
      status: {
        linkUrl: '/reception/receptionxpartstorage/', // internal link to other component
       context: null,
       linkPermission: '/setstorage/KME-Technikum'
      }
    }
  ]

  initialState2 = [
    {
      cam: {
        result: LabelFile.labels['storagexpartNr'], // --- camera or textinput change the state
        flash: 'scan-container-flash'
      },
      title: {
        view: true, // --- view title in browser
        name: LabelFile.labels['storagexpartNr'] // --- view title in browser
      },
      form: {
        view: true, // --- view form in browser
        alternative: true // view form width (alternative = full width)
      },
      button: {
        view: false, // --- view button in browser
        alternative: true // view button width (alternative = full width)
      },
      status: {
        linkUrl: '/reception/receptionxpart/', // internal link to other component
       context: this.props.match.params.context      
      }
    }
  ]

  render() {
    return (
      <div>
        <Scan initialState={this.initialState} fireModal={this.props.fireModal} closeModal={this.props.closeModal} />
      </div>
    )
  }
}

export default withRouter(Reception);