import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Project.css';

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

/**
 * The class generates the page to input Project number, so that user can view the xparts related to the pertaining Project
 */
class Project extends Component {
    constructor(props) {
        super(props);
        this.labelFile = LabelService.getLabelsByLocal('de');
    }

    fetchProjectDetails() {
        this.setState({
            result: document.getElementById("projectId").value
        })
        axios.get(API_XPART.XPART_SERVICE_URL + '/package/xparts?orderNumber=' + document.getElementById("projectId").value + '&orderType=2')
            .then(res => {
                this.props.history.push('/project/projectxpart/' + document.getElementById("projectId").value,{editMode:false});
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de',  err.response.status, err.response.data.errors[0]) + '\n' + document.getElementById("projectId").value,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'PROJECT_NUMBER_NOT_FOUND'),
                    buttons: this.createOKModalButtons()
                });
            })

    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    keyPress(e) {
        e.stopPropagation();

        if (e.keyCode === 13) {
            this.fetchProjectDetails();
        }
        e.stopPropagation();
    }

    render() {
        return (
            <section className="results--section">
                <div className="input-container">

                    <div className="xpm-scanner-input-container">
                        <label className="xpm-scanner-label"> <span>{this.labelFile.texts["PROJECT_NUMBER"]}</span> </label>
                        <input className="xpm-scanner-input" type="text" id="projectId" onKeyDown={this.keyPress.bind(this)}></input>
                        <button type="button" id="val-btn"
                            className="xpm-button xpm-scanner-button" onClick={this.fetchProjectDetails.bind(this)}
                        ><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(Project);