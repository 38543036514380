import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../../Common/API/API';
import TableConfig from '../../List/tables-config.json';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { PaginationComponent } from '../../List/PaginationComponent';
import { SelectableTableComponent } from '../../List/SelectableTableComponent';
import { LabelService } from '../../Common/Translations/LabelService';
import { APINotifications } from '../../Common/API/APINotifications';
import { SupplierPackagePickControlButtonComponent } from './SupplierPackagePickControlButtonComponent';
import '../../List/list.css';
import '../../Project/Project.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const API_XPART = new API();

export default class SupplierSendPackagePick extends Component {
    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.packagetypes = [];
        this.config = TableConfig['supplierPackageSend'];
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.controlButtonsComponent = React.createRef();
    }

    state = {
        generalFilter: [],
        usesFilter: false,
        importCount: '',
        preFilter: [],
        holidayList: null,
        nextAvailabelDate: null,
        mode: 0
    }

    componentWillMount() {
        this.setState(this.state);
    }


    updateTable(page, parts) {
        this.props.history.push('/packages/supplier/sendpackages/' + page + '/' + parts);
        this.refresh();
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    filterUpdate(data, filterFlag) {
        this.setState({
            generalFilter: data,
            usesFilter: filterFlag
        })
    }

    startMultiSelect() {
        this.tableComponent.current.startMultiSelect();
    }

    stopMultiSelect() {
        this.tableComponent.current.stopMultiSelect();
    }

    updateMultiSelectState(multiSelect) {
        this.controlButtonsComponent.current.updateMultiSelectState(multiSelect);
    }
    clearLocalSelection() {
        this.tableComponent.current.clearLocalSelection();
    }

    updateLoading(loading) {
        if (this.tableComponent.current) {
            this.tableComponent.current.updateLoading(loading);
        }
    }

    onConfirmSuccess() {
        localStorage.removeItem(this.config.localStoragePrefix);
        this.stopMultiSelect();
        this.refresh();
    }

    saveSupplierXpartGroupModel() {
        const saveObject = {
            "contextId": 2,
            "groupsIds": [
                0
            ],
            "statusId": 3,
            "validationDTO": {
                "expectedContextsIds": [
                    2
                ],
                "expectedStatusIds": [
                    2
                ]
            }
        }

        saveObject.groupsIds=JSON.parse(localStorage.getItem(this.config.localStoragePrefix));

        this.updateLoading(true)
        axios.post(API_XPART.XPART_SERVICE_URL + '/status/group', saveObject)
            .then(res => {
                this.props.closeModal();
                this.onConfirmSuccess();
                this.showAutomaticallyGeneratedNumber(res.data.transportGroupMetaData.packUnitNumber)
            })
            .catch(err => {
                this.showException(err);
            })
            .finally(() => this.updateLoading(false)
            );

    }




    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }

    goToRequestedList() {

        this.props.history.push(this.config.requestedPageURL);
    }

    onCountSuccess(count) {
        const notificationName = count > 1 ? 'UPDATING_XPARTS_MULTI' : 'UPDATING_XPARTS_ONE';
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', notificationName, count),
            header: NOTIFICATIONS.getHeaderDescription('de', 'INFO', 'COMMON_INFO'),
            buttons: [],
            closeModal: this.closeModal,
            stickInOverlay: true
        });
        this.startSyn();
    }

    closeModal() {
        this.props.closeModal();
    }



    render() {
        return (
            <section className="results--section">

                <div className="header-column-wrapper">
                    <div className="header-column-left"></div>
                    <div className="header-column-middle">
                        <PageHeaderComponent text={this.config.pageHeader
                            ?
                            <h1>{LabelFile.header[this.config['pageHeader']]}</h1>
                            :
                            ''} >
                        </PageHeaderComponent>
                    </div>

                    <div className="header-column-pagination">
                        {<PaginationComponent ref={this.paginationComponent}
                            updateTable={(page, parts) => this.updateTable(page, parts)}
                            page={this.props.match.params.page}
                            parts={this.props.match.params.parts}
                            module={'supplierPackageSend'}>
                        </PaginationComponent>}
                    </div>
                </div>

                <div className="list-container">
                    <SupplierPackagePickControlButtonComponent ref={this.controlButtonsComponent} saveSupplierXpartGroupModel={() => this.saveSupplierXpartGroupModel()}
                        startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
                        clearLocalSelection={() => { this.clearLocalSelection() }} goToRequestedList={() => { this.goToRequestedList() }}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        activeSelection={this.state.nextAvailabelDate && this.state.holidayList}>
                    </SupplierPackagePickControlButtonComponent>

                    <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={'supplierPackageSend'}
                        usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        updateConfirmButton={(e) => this.updateConfirmButton(e)}></SelectableTableComponent>

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'partsAssemblyCommissions'} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </section>
        )
    }
}
