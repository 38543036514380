
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserInfoComponent extends Component {

    render() {
        return (
            <div> {
                this.props.currentUser !== null ?
                    <Link access="userPermission" to={'/setting'} className="setting-style">
                        <div className="email-properties">{this.props.currentUser.userName}</div>
                        <FontAwesomeIcon icon={['fas', 'user']} className="user-icon-properties" />
                    </Link> : ''
            }
            </div>
        )
    }

}
export default UserInfoComponent;
