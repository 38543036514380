import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../../API/API';
import { APINotifications } from '../../API/APINotifications';
import { LabelService } from '../../Translations/LabelService';
import 'react-html5-camera-photo/build/css/index.css';
import { Loader } from '../Loader/loader';
import { CameraService } from '../../../Camera/CameraService';


const API_PHOTO = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');

export default class CommonGalleryComponent extends Component {

    state = {
        resultlist: [],
        serverresultlist: []
    }

    getLocalStoragePrefix() {
        return this.props.moduleName + '-' + this.props.galleryIndex;
    }

    componentDidMount() {
        let result = CameraService.getFotosFromLocalStorage(this.getLocalStoragePrefix());

        this.setState({
            resultlist: result,
            localimagecheck: true
        });

        document.getElementById("LocalGallery").addEventListener("click", e => {
            if (e.target && (e.target.className === "photo-gallery-img-delete")) {
                let index = e.target.parentNode.firstChild.getAttribute("data-attr");
                this.handleDelete(index);
            }
        });

        this.getServerImages();
    }

    getServerImages() {
        let filetype = "fileTypeId=1";
        let xpart = "xpartNumber=" + this.props.galleryIndex;
        this.toggleLoading(true)
        axios.get(API_PHOTO.XPART_SERVICE_URL + '/file?' + filetype + "&" + xpart)
            .then(response => {
                this.setState({
                    serverresultlist: response["data"],

                })
                if (this.state.serverresultlist.length > 0) {
                    this.setState({
                        serverimagecheck: true
                    })
                }
            })
            .catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.toggleLoading(false));
    }

    handleDelete(i) {
        let results = this.state.resultlist;
        results.splice(i, 1);
        this.setState({
            resultlist: results
        })
        if (this.state.resultlist.length === 0) {
            localStorage.removeItem(this.getLocalStoragePrefix());
        } else {
            localStorage.setItem(this.getLocalStoragePrefix(), JSON.stringify(this.state.resultlist));
        }
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }


    saveBtnClass() {
        if (this.state.btnActive) {
            return ""
        } else {
            return " xpm-disabled"
        }
    }

    render() {

        return (
            <div>
                <h2>{LabelFile.labels["serverphotos"] + " (" + this.state.serverresultlist.length + ")"}</h2>
                <div className="gallery" id="ServerGallery">
                    {this.state.serverresultlist.map((result, index) => (
                        <div>
                            <img alt="Gallery" data-attr={index} src={API_PHOTO.FILE_SERVICE_URL + '/file/' + result["fileId"]} />
                            <span style={{ color: 'black' }}>{result["fileName"]}</span>
                        </div>
                    ))}
                </div>
                {this.state.isLoading ? <Loader /> : ''}
                <h2 className="xpm-clear">{LabelFile.labels["localphotos"] + " (" + this.state.resultlist.length + ")"}</h2>
                <div className="gallery" id="LocalGallery">
                    {this.state.resultlist.map((result, index) => (
                        <div>
                            <img alt="Gallery" data-attr={index} src={result} />
                            <span className="photo-gallery-img-delete">x</span>
                        </div>
                    ))}
                </div>
                <div className="photo-gallery-button-container xpm-button-container">

                    {this.state.showSave ?
                        <div class={"xpm-button " + this.state.optionalClass + this.saveBtnClass()} onClick={this.handleUpload.bind(this)}>
                            {LabelFile.labels["save"]}
                        </div> : ''
                    }

                </div>
                {this.state.isAfterSavingLoading ? <Loader type="lock" /> : ''}
            </div>
        )
    }

}
