import React, { Component } from 'react'
import './error-page.css'

export class Page403 extends Component {
    render() {
        return (
            <div className="error-page-content">
                403 Forbidden
            </div>
        )
    }
}