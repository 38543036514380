
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './camera-switcher.css';


const PLACEHOLDER = 'Please select a value'
const NO_SELECTION = -1
const NULL_DEVICE = {
    description: 'Disable scanner',
    deviceId: 0,
    groupId: null,
    kind: 'null device'
}
const mapDeviceToName = (device, idx) => {
    const description = device.label || `camera ${idx + 1}`
    return {
        description,
        deviceId: device.deviceId,
        groupId: device.groupId,
        kind: device.kind
    }
}

export class CameraSwitcherComponent extends Component {

    constructor(props) {
        super(props)
        this.handleToggleClick = this.handleToggleClick.bind(this)
        this.state = {
            isOpen: false,
            selection: NO_SELECTION,
            devices: []
        }
    }

    componentDidMount() {
     
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                const cameras = devices.filter(device => device.kind === 'videoinput')
                    .map(mapDeviceToName)
                this.setState({
                    isOpen: this.state.isOpen,
                    selection: this.state.selection,
                    devices: [...cameras, NULL_DEVICE]
                })
            })
            .catch(error => {
                console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name)
            })
    }

    initSelectedCamera() {

        if (!this.props.preselectedCameraPrefix || localStorage[this.props.preselectedCameraPrefix] === undefined) {
            return;
        }
        let selectedCamerId = JSON.parse(localStorage[this.props.preselectedCameraPrefix]);
        if (selectedCamerId === 0 || selectedCamerId) {
            this.changeCamera(selectedCamerId);
            this.setState({selection:selectedCamerId})
        }
    }

    afterCameraSelected(){
        if(this.props.onCameraSelected){
            this.props.onCameraSelected();
        }
    }

    handleToggleClick() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            selection: (!prevState.isOpen) ? NO_SELECTION : prevState.selection
            // selection: (!prevState.isOpen) ? NULL_DEVICE.deviceId : prevState.selection
        }))
    }
    stopVideo() {
        let stream = document.querySelector('video').srcObject;
        if (!stream) {
            return;
        }
        let tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        document.querySelector('video').srcObject = null;
    }

    changeCamera(deviceId){
        if (deviceId === 0) {
            this.stopVideo();
        } else {
            this.getStream(deviceId);
        }
    }

    handleItemClick(deviceId) {

        this.changeCamera(deviceId);
        this.setState(Object.assign({}, this.state, { selection: deviceId }))
        this.handleToggleClick();

        if(this.props.preselectedCameraPrefix){
            localStorage.setItem(this.props.preselectedCameraPrefix, JSON.stringify(deviceId))
        }

        this.afterCameraSelected();
    }


    getStream(deviceId) {
        this.stopVideo();
        const constraints = {
            video: { deviceId: deviceId ? { exact: deviceId } : undefined }
        };
        return navigator.mediaDevices.getUserMedia(constraints)
            .then(this.gotStream).catch(this.handleError);
    }

    gotStream(stream) {
        window.stream = stream; // make stream available to console
        let videoElement = document.querySelector('video');
        videoElement.srcObject = stream;
        videoElement.play();
    }

    handleError(error) {
        console.error('Error: ', error);
    }

    render() {
        const { isOpen, selection } = this.state;
        let ctrl,
            isShown;
        if (isOpen) {
            ctrl = <CloseSelectBox />
            isShown = 'show'
        } else {
            ctrl = <OpenSelectBox />
            isShown = 'hide'
        }
        const options = this.state.devices.map(({ deviceId, description }) => {
            return <OptionItem key={deviceId} description={description} selectItem={this.handleItemClick.bind(this, deviceId)} />
        })

        const title = this.state.devices.filter(device => device.deviceId === selection)
            .map(device => device.description)
        return (

            <div className="cameraselector">
                <div className="cameraselector__selectbox" onClick={this.handleToggleClick}>
                    <SelectTitle title={title} />
                    {ctrl}
                </div>
                <div className={`cameraselector__optionbox--${isShown}`}>
                    <ul className="cameraselector__optionlist">
                        {options}
                    </ul>
                </div>
            </div>
        )
    }
}

function OpenSelectBox(props) {
    return (
        <div className="cameraselector__ctrl--open">
            <FontAwesomeIcon icon={['fas', 'caret-down']} />
        </div>
    );
}

function CloseSelectBox(props) {
    return (
        <div className="cameraselector__ctrl--close">
            <FontAwesomeIcon icon={['fas', 'caret-up']} />
        </div>

    );
}

const SelectTitle = ({ title }) => {
    if (title.length) {
        return <div className="cameraselector__title">{title}</div>
    }
    return <div className="cameraselector__title--empty">{PLACEHOLDER}</div>
}

const OptionItem = ({ description, selectItem }) => {
    return <li className="cameraselector__optionitem" onClick={selectItem}>{description}</li>
}
