import React, { Component } from 'react'
import './Home.css'

class Home extends Component {
    render() {
        return (
            <section className="home--section">
                <div className="container">
                    <h1>xpm - XParts Management</h1>
                </div>
            </section>
        )
    }
}

export default Home;