import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { HelperFunctions } from '../Common/Helper/Helper';
import { NewXpartInputMask } from './NewXpartInputMask';
import { Loader } from '../Common/Components/Loader/loader';
import { Link } from 'react-router-dom';
import SwitchToggleComponent from "../List/SwitchToggle/SwitchToggle";
import './orderItemList.css';

const API_ORDER = new API();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class OrderItemList extends Component {
    constructor(props) {
        super(props);
        this.labels = LabelFile.labels;
        this.statusLabels = LabelFile.statuses;
        this.warnings = LabelFile.warnings;
        this.texts = LabelFile.texts;
        this.resultlistarr = [];
        this.orderInfos = {};
        this.xpart = {};
        this.itemNumber = 10;
        this.checked = false;
    }

    state = {
        orderInfo: {},
        resultlist: [],
        isLoading: false
    }

    formatData(data) {
        let lang = HelperFunction.getLocale();
        data.forEach(function (itemOrder) {
            itemOrder['itemDeliveryDate'] = itemOrder['deliveryDate'] === null ? null : HelperFunction.timeConverter(itemOrder['deliveryDate'], lang, true);
            itemOrder['status'] = itemOrder['itemStatus'] ? LabelFile.statuses[itemOrder['itemStatus']['name']] : " ";
        })
        return data;
    }

    loadItems() {
        let ordernumber = this.props.match.params.ordernumber;

        axios.get(API_ORDER.XPART_SERVICE_URL + '/item/inorder/?orderNumber=' + ordernumber + '&orderType=1')
            .then(response => {
                this.resultlistarr = response['data'];
                this.resultlistarr = this.formatData(this.resultlistarr);

                this.setState({
                    resultlist: this.resultlistarr,
                    orderInfo: this.state.orderInfo
                })
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }


    componentDidMount() {
        this.loadOrderInfo();
        this.loadItems();
    }

    loadOrderInfo() {
        let ordernumberx = this.props.match.params.ordernumber;
        let ordernumber;
        let deliverdate;
        let orderitemsCounts;
        let orderdate;
        let xpartsQuantity;
        let orderStatus;
        let lang = HelperFunction.getLocale();

        axios.get(API_ORDER.XPART_SERVICE_URL + '/order/' + ordernumberx + '/1')
            .then(response => {
                ordernumber = response.data["orderNumber"];
                xpartsQuantity = response.data["xpartsQuantity"];
                orderitemsCounts = response.data["itemsCount"];
                orderdate = HelperFunction.timeConverter(response.data["created"], lang, true);
                deliverdate = response.data["deliveryDate"] === null ? null : HelperFunction.timeConverter(response.data["deliveryDate"], lang, true);
                orderStatus = response.data["orderStatus"] === null ? '' : LabelFile.statuses[response.data['orderStatus']['name']];
                response.data.companies.forEach(res => {
                    if (res["type"]["name"] === "CUSTOMER") {
                        this.orderInfos.customer = res["name"];
                    }
                    if (res["type"]["name"] === "SUPPLIER") {
                        this.orderInfos.supplier = res["name"];
                    }
                })

                this.orderInfos.ordernumber = ordernumber;
                this.orderInfos.orderitemsCounts = orderitemsCounts;
                this.orderInfos.orderdate = orderdate;
                this.orderInfos.deliverydate = deliverdate;
                this.orderInfos.xpartsQuantity = xpartsQuantity;
                this.orderInfos.orderStatus = orderStatus;
                this.setState({
                    orderInfo: this.orderInfos
                })
            })

            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }

    saveNewXpart(data) {
        data['orderNumber'] = this.props.match.params.ordernumber;
        data['itemNumber'] = this.itemNumber;
        data['orderTypeId'] = 1;
        this.toggleLoading(true)
        axios.post(API_ORDER.XPART_SERVICE_URL + '/one', data)
            .then(res => {
                HelperFunction.getPdfDocument(API_ORDER.FILE_SERVICE_URL + '/x-part/' + res.data.xpartNumber)
                    .then(() => {
                        this.props.history.replace('/refresh' + window.location.pathname);
                        this.toggleLoading(false);
                        this.props.closeModal();
                    })
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
    }

    openModalXpartConvertMask(index) {
        let convert = this.state.resultlist[index];
        this.itemNumber = convert['itemNumber'];
        this.xpart.descriptionDe = convert['itemDescription'];
        this.xpart.descriptionEn = (convert['itemDescriptionEn'] === 'empty description' ? '' : convert['itemDescriptionEn']);
        this.xpart.quantity = convert['quantity'];
        this.xpart.weight = convert['weight'];
        this.xpart.id = null;
        this.xpart.xpartsQuantity = convert['xpartsQuantity'];
        this.xpart.unit = { id: 1 };
        this.xpart.customerMaterialNumber = convert['materialNumbers'] !== null && convert['materialNumbers'].length > 0 ? convert['materialNumbers'][0] : '';
        this.xpart.supplierMaterialNumber = null;
        this.xpart.cus = '';
        this.xpart.convert = true;

        this.props.fireModal({
            content: <NewXpartInputMask
                xpart={this.xpart}
                saveNewXpart={(data) => this.saveNewXpart(data)}
                closeModal={this.props.closeModal}
            />,
            type: 'wide'
        })
    }

    //Change completion status
    async changeOrderItemCompletionStatus(requested) {
        return await axios.post(API_ORDER.XPART_SERVICE_URL + '/item/completed', { "completed": !requested.rowData[0].completed, "items": requested.objectId });
    }

    renderHeaderInfoBox() {
        return (
            <section >
                <div class="info-table">
                    <div class="info-table-title">{this.labels['ordertitle'] + " " + this.props.match.params.ordernumber}</div>
                    <div className="info-table-inner-container">
                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['customer']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.customer}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['supplier']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.supplier}</div>
                            </div>
                        </div>
                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['orderdate']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderdate}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['deliverydate']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.deliverydate}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['amountoforderitems']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderitemsCounts}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['status']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderStatus}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    mapColumns() {
        return this.state.resultlist.map((result, index) => (

            <div class="list-grid-row list-grid-xpart-general order-item-list-grid-row-override">
                <div data-name={this.labels['order_item_number']} class="list-grid-item">
                    <span>
                        <Link to={'/order/' + this.props.match.params.ordernumber + '/orderitem/' + result['itemNumber'] + '/overview' + HelperFunction.getContextOrNot(this.props.match.params.context)}><span>{result['itemNumber']}</span></Link>
                    </span>
                </div>

                <div data-name={this.labels['projectnumber']} class="list-grid-item">
                    <span>{result['projectNumber']}</span>
                </div>

                <div data-name={this.labels['pspElement']} class="list-grid-item">
                    <span>{result['pspElement']}</span>
                </div>

                <div data-name={this.labels['desc_de']} class="list-grid-item">
                    <span>{result['itemDescription']}</span>
                </div>

                <div data-name={this.labels['quantity']} class="list-grid-item">
                    <span>{result['quantity']}</span>
                </div>

                <div data-name={this.labels['deliverydate']} class="list-grid-item list-grid-date-cell">
                    <span>{result['itemDeliveryDate']}</span>
                </div>

                <div data-name={this.labels['status']} class="list-grid-item">
                    <span>{result['status']}</span>
                </div>
                <div data-name={this.labels['status']} class="list-grid-item">
                    <span>{result['xpartsQuantity']}</span>
                </div>
                <div data-name={this.labels['status']} class="list-grid-item order-item-completion-status">
                    
                    
                        <span class="function-column-space" onClick={() => this.openModalXpartConvertMask(index)}><FontAwesomeIcon  icon={['fas', 'plus-square']} title="Bestellposition zu Xpart konvertieren" /></span>
                        <span class="function-column-space"><SwitchToggleComponent id={'toggle-' + result.id} rowData={result} checked={result.completed ? result.completed : this.checked} onColor="#EF476F"
                                objectId={result.id} handleToggle={(e) => this.changeOrderItemCompletionStatus(e)} disabled={false}
                                postChange={null} /></span> 
                        
                    </div>
            </div>
        ))
    }

    render() {
        return (
            <section className="results--section">
                {this.renderHeaderInfoBox()}
                <div className="order-item-list-container">
                    <div class="list-grid list-container">
                        <div class="list-grid-row order-item-list-grid-row-override list-grid-row-headline">
                            <div class="list-grid-item">
                                <span>{this.labels['order_item_number']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['projectnumber']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['pspElement']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['desc_de']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['quantity']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['deliverydate']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['status']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['quantity']}&nbsp;{LabelFile.labels['xparts']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>Funktionen</span>
                            </div>
                        </div>
                        {this.mapColumns()}
                    </div>
                </div>
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </section>
        )
    }
}

export default withRouter(OrderItemList);
