import React, { Component } from 'react'
import '../../List/list.css';
import ConvenienceInputFieldsComponent from './convenience-input-fields-component';
import { APINotifications } from '../../Common/API/APINotifications';

import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { TableComponent } from '../../List/TableComponent';
import { ConvenienceControlButtonsComponent } from './convenience-control-buttons-component';
import { Loader } from '../../Common/Components/Loader/loader';

import TableConfig from '../../List/tables-config.json';

import { LabelService } from '../../Common/Translations/LabelService';
import { API } from '../../Common/API/API';
import axios from 'axios';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const API_XPART = new API();

export default class ConvenienceOverviewComponent extends Component {
  constructor(props) {
    super(props);
    this.config = TableConfig['xpartsInConvenienceGroup'];
    this.paginationComponent = React.createRef();
    this.bottomPaginationComponent = React.createRef();
    this.tableComponent = React.createRef();
    this.groupInfoComponentRef = React.createRef();
    this.controlButtonsComponentRef = React.createRef();
  }

  state = {
    importCount: '',
    groupMode: [],
    groupType: [],
    isLoading: false,
    currentGroup: null,
    originalXparts: []
  }

  componentDidMount() {
    this.initCurrentGroup();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.groupId !== nextProps.match.params.groupId) {
      this.refresh();
    }
  }

  initCurrentGroup() {
    if (!this.props.match.params.groupId) {
      this.setState({ currentGroup: {} });
    } else {
      this.loadGroup(this.props.match.params.groupId);
    }
  }

  loadGroup(groupId) {
    this.toggleLoading();
    axios.get(API_XPART.XPART_SERVICE_URL + '/group/' + groupId)
      .then(res => {
        this.setState({ currentGroup: res.data });
      })
      .catch(err => {
        this.showException(err);
        this.controlButtonsComponentRef.current.goToGroups();
      })
      .finally(() => this.toggleLoading()
      );
  }


  refresh() {
    this.props.history.replace('/refresh' + window.location.pathname);
  }

  showException(err) {
    this.props.fireModal({
      content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors === undefined ? '' : err.response.data.errors[0]),
      header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
      buttons: this.createOKModalButtons()
    });
  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    };
    btns.push(obj);
    return btns;
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  saveGroup() {
    

    const xpartGroupId = this.state.currentGroup.id ? this.state.currentGroup.id : null;
    let storageLocation = this.groupInfoComponentRef.current.getStorageLocation();

    if(storageLocation){
      storageLocation = storageLocation.split('-');
    } else{
      storageLocation = null;
    }
    const data = {
      xpartGroupId: xpartGroupId,
      statusId: this.groupInfoComponentRef.current.getSelectedStatus(),
      contextId: this.groupInfoComponentRef.current.getSelectedContext(),
      storageSequence: storageLocation
    }

    this.toggleLoading();
    axios.post(API_XPART.XPART_SERVICE_URL + '/group/convenience', data)
      .then(res => {
        this.refresh();
      })
      .catch(err => {
        this.showException(err);
      })
      .finally(() => this.toggleLoading()
      );
  }

  render() {
    return (
      <section className="results--section">
        {/* page header */}
        <PageHeaderComponent
          text={<h1>{LabelFile.labels['xpartsInGroup']}</h1>}
        />

        {/* option menu */}
        {this.state.currentGroup ? <ConvenienceInputFieldsComponent ref={this.groupInfoComponentRef} currentGroup={this.state.currentGroup} /> : ''}

        <ConvenienceControlButtonsComponent ref={this.controlButtonsComponentRef}
          saveGroup={() => this.saveGroup()} history={this.props.history}
          currentGroup={this.state.currentGroup} />

        <div className="list-container">
          {this.state.currentGroup ? <TableComponent
            ref={this.tableComponent}
            groupId={this.state.currentGroup.id}
            module={'xpartsInConvenienceGroup'}
            usesFilter={false}
            generalFilter={this.state.generalFilter}
            preFilter={this.state.preFilter}
            refresh={() => this.refresh()} history={this.props.history}
            fireModal={this.props.fireModal}
            closeModal={this.props.closeModal}
          /> : ''}
        </div>

        <div id="popupContainer"></div>
        {this.state.isLoading ? <Loader type="lock" /> : ''}
      </section>
    )
  }

}
