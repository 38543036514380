import React, { Component } from "react";

import "./loader.css";

/**
 * @typedef {Object} LoaderSpinner
 * @property {string} loaderClass Loader css class implemented in loader.css
 * @property {'lock' | 'info'} type Loader type
 */

/**
 * @extends {Component<LoaderSpinner>}
 */

export class Loader extends Component {
  static defaultProps = {
    loaderClass: "loader-small",
    type: "info"
  };

  renderLoaderSpinner() {
    switch (this.props.type) {
      case "lock":
        return (
          <div className={`loader-container loader-lock-container`}>
            <img
              className='loader-medium'
              src="/images/loader.svg"
              alt=""
            />
          </div>
        );
      default:
        return (
          <div className={`loader-container`}>
            <img
              className={this.props.loaderClass}
              src="/images/loader.svg"
              alt=""
            />
          </div>
        );
    }
  }

  render() {
    return (
      <>
        {this.props.type === "lock" ? <div className="loader-lock-overlay" /> : ""}
        {this.renderLoaderSpinner()}
      </>
    );
  }
}
