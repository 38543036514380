
/**
 * This class is just a holder for step information
 * it has the component of the step and the name of the step.
 */
export default class WizardStep {
    constructor(stepName, stepComponent) {
        this.stepName = stepName;
        this.stepComponent = stepComponent;
    }
}
