import React, { Component } from 'react';
import TableConfig from './../tables-config.json';

import { LabelService } from "../../Common/Translations/LabelService";
import axios from "axios";
import { API } from "../../Common/API/API";
import ManageQueryStorage from "./helpers/ManageQueryStorage";

const LabelFile = LabelService.getLabelsByLocal('de');
const API_STATUS = new API();

export default class SpecialSearch extends Component {
    constructor(props) {
        super(props);

        const { specialFilterList } = TableConfig[this.props.module];

        this.state = specialFilterList.map(filter => {
            const { display, field } = filter;
            return ({
                specialQuery: {
                    fieldName: field,
                    fieldValue: ''
                },
                labelKey: display
            })
        }).reduce((form, input) => {
            const { fieldName } = input.specialQuery;
            if (fieldName === "XPART_CURRENT_STATUS" || fieldName === "XPART_CURRENT_CONTEXT") {
                input = Object.assign({}, input, { data: [] })
            }
            form[fieldName.toLowerCase()] = input;
            return form;
        }, {});

        this.keyTimer = ''; // TODO: should be in state
        this.manageSpecialQueryStorage = new ManageQueryStorage(this.props.module, 'special-search');

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        this.initStatusFilter();
        this.initContextFilter();
    }

    areFiltersReady() {
        return this.isFilterDataReady('xpart_current_status') &&
            this.isFilterDataReady('xpart_current_context')
    }

    isFilterDataReady(filterName) {
        if (!this.state[filterName]) {
            return true;
        }
        return this.state[filterName].data.length > 0;
    }

    initStatusFilter() {

        if (this.isFilterDataReady('xpart_current_status')) {
            return;
        }

        axios.get(API_STATUS.XPART_SERVICE_URL + "/status/").then(response => {
            this.setState({
                'xpart_current_status': this.updateStatuses(response)
            }, this.initFilterStartValues);
        })

    }


    initContextFilter() {
        if (this.isFilterDataReady('xpart_current_context')) {
            return;
        }
        axios.get(API_STATUS.XPART_SERVICE_URL + "/context/").then(response => {
            this.setState({
                'xpart_current_context': this.updateContext(response)
            }, this.initFilterStartValues);
        })

    }

    initFilterStartValues() {
        if (!this.areFiltersReady()) {
            return
        }
        const storedQueries = this.manageSpecialQueryStorage.getQueries() || [];
        // set state
        let newState = Object.assign({}, this.state);
        storedQueries.forEach(query => {
            const { queryData } = query;
            const { fieldName, fieldValue } = queryData;

            const field = fieldName.toLowerCase();
            this.assignOldValues(field, fieldValue, newState);
            // Object.assign(newState, { [field]: newInputField });
            //   this.updateFieldValue(field, fieldValue, this.handleFormSubmit);
        });
        this.setState(newState, this.handleFormSubmit);
    }



    clearFieldValue({ queryData }) {
        const { fieldName } = queryData;
        this.updateFieldValue(fieldName.toLowerCase(), '');
    }

    assignOldValues(field, fieldValue, newState) {
        const inputField = this.state[field];
        const { specialQuery } = inputField;
        const newSpecialQuery = Object.assign({}, specialQuery, { fieldValue });
        const newInputField = Object.assign({}, inputField, { specialQuery: newSpecialQuery });
        Object.assign(newState, { [field]: newInputField });
    }

    async updateAllFieldValues(specialQueries) {
        for (let i = 0; i < specialQueries.length; i++) {
            let removedSpecialQuery = specialQueries[i];
            let { queryData } = removedSpecialQuery;
            await this.clearFieldValue({queryData})
        }
    }

    async updateFieldValue(field, fieldValue, fn) {
        const inputField = this.state[field];
        const { specialQuery } = inputField;
        const newSpecialQuery = Object.assign({}, specialQuery, { fieldValue });
        const newInputField = Object.assign({}, inputField, { specialQuery: newSpecialQuery });
        await this.setState(Object.assign({}, this.state, { [field]: newInputField }),
            () => {
                if (typeof fn === "function") {
                    fn();
                }
            });
    }

    updateStatuses(statusResponse) {
        const status = statusResponse.data;
        const prevStatus = this.state['xpart_current_status'];
        return Object.assign({}, prevStatus, { data: status });
    }

    updateContext(contextResponse) {
        const context = contextResponse.data;
        const prevContext = this.state['xpart_current_context'];
        return Object.assign({}, prevContext, { data: context });
    }

    handleChange(e, field) {
        e.preventDefault();
        const fieldValue = e.target.value;
        if (field === 'xpart_current_status' || field === 'xpart_current_context') {
            this.updateFieldValue(field, fieldValue, this.handleFormSubmit);
            return;
        }
        this.updateFieldValue(field, fieldValue)
    }

    handleFormSubmit(e = document.createEvent('Event')) {
        e.preventDefault();
        clearTimeout(this.keyTimer);
        this.props.submitFilters();
    }

    getSpecialQueries(){
        const specialQueries = this.extractSpecialQueries();
        return this.buildSpecialQueries(specialQueries);
    }

    extractSpecialQueries() {
        return Object.keys(this.state).reduce((queries, field) => {
            const { specialQuery, labelKey } = this.state[field];
            const { fieldValue } = specialQuery;
            if (!fieldValue.length) {
                return queries;
            }
            const defaultQueryData = Object.assign({},
                specialQuery,
                { labelKey },
                { statusName: '' },
                { contextName: '' });
            switch (field) {
                case 'xpart_current_status':
                    const statusName = this.lookupStatusName(parseInt(fieldValue, 10));
                    const statusQueryData = Object.assign({}, defaultQueryData, { statusName });
                    return [...queries, statusQueryData];
                case 'xpart_current_context':
                    const contextName = this.lookupContextName(parseInt(fieldValue, 10));
                    const contextQueryData = Object.assign({}, defaultQueryData, { contextName });
                    return [...queries, contextQueryData];
                default:
                    return [...queries, defaultQueryData];
            }
        }, []);
    }

    buildSpecialQueries(specialQueries) {
        return specialQueries.map(queryData => {
            const { fieldName, fieldValue, labelKey, statusName, contextName } = queryData;

            const defaultQuery = {
                labelData: {
                    labelKey,
                },
                name: 'special-query',
                queryData: {
                    fieldName,
                    fieldValue
                }
            };

            if (statusName.length) {
                const labelData = Object.assign({}, { labelKey }, { statusName });
                return Object.assign({}, defaultQuery, { labelData });
            }

            if (contextName.length) {
                const labelData = Object.assign({}, { labelKey }, { contextName });
                return Object.assign({}, defaultQuery, { labelData });
            }

            return defaultQuery;
        })
    }

    lookupStatusName(statusId) {
        if (!this.state['xpart_current_status'].data) {
            return ''
        }
        const { data } = this.state['xpart_current_status'];
        const statusData = data.filter(status => {
            return status.id === statusId
        });
        const { name } = statusData ? statusData[0] : '';
        return name;
    }

    lookupContextName(contextId) {
        if (!this.state['xpart_current_context'].data) {
            return ''
        }
        const { data } = this.state['xpart_current_context'];
        const contextData = data.filter(context => context.id === contextId);
        const { name } = contextData[0] ? contextData[0] : '';
        return name;
    }

    render() {

        if (!this.areFiltersReady()) {
            return '';
        }
        const { isShown } = this.props;
        return (
            <form autoComplete="off">
                <div id="specialFilter"
                    className={isShown ? "specialFilter" : "specialFilter xpm-hidden"}>
                    <div id="specialFilterBorder">
                        <ul>
                            {this.renderTextFields()}
                            {this.renderDropDowns()}
                        </ul>
                    </div>
                    <button className="button-add-item" onClick={(e) => this.handleFormSubmit(e)} />
                </div>
            </form>
        )
    }

    renderTextFields() {
        return Object.keys(this.state)
            .filter(field => field !== 'xpart_current_status' && field !== 'xpart_current_context')
            .map(field => {
                const { labelKey } = this.state[field];

                const { fieldValue } = this.state[field].specialQuery;
                const inputLabel = LabelFile.labels[labelKey];

                return (
                    <li key={field} className="special-filter-list-item">
                        <label>{inputLabel}</label>
                        <input className="specialfilter-item"
                            value={fieldValue}
                            onChange={(e) => this.handleChange(e, field)} />
                    </li>);
            });
    }

    renderDropDowns() {
        return Object.keys(this.state)
            .filter(field => field === 'xpart_current_status' || field === 'xpart_current_context')
            .map(field => {
                const { labelKey } = this.state[field];
                const { fieldValue } = this.state[field].specialQuery;
                const inputLabel = LabelFile.labels[labelKey];

                return (
                    <li key={field} className="special-filter-list-item">
                        <label>{inputLabel}</label>
                        <select
                            className='specialfilter-item'
                            id={field}
                            value={fieldValue}
                            onChange={(e) => this.handleChange(e, field)}>
                            <option />
                            {this.renderOptions(field)}
                        </select>
                    </li>
                )
            });
    }

    renderOptions(field) {
        const { data } = this.state[field];
        const optionLabels = (field === 'xpart_current_status') ? LabelFile.statuses : LabelFile.context;
        return data.map((fieldType) => {
            const { id, name } = fieldType;
            const labelName = optionLabels[name];
            return <option key={id} value={id}>{labelName}</option>;
        });
    }
}
