import { HelperFunctions } from '../Common/Helper/Helper';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';

const HelperFunction = new HelperFunctions();
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

export class ExtraDataService {

    functions = {
        getDeliverydate: this.getDeliverydate,
        changeXpartApproval: this.changeXpartApproval,
        changeXpartGroupActive: this.changeXpartGroupActive,
        handleChangeGroupActivation: this.handleChangeGroupActivation,
        changeCompletionGroup: this.changeCompletionGroup
    }


    async changeXpartApproval(requested) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/qa', { xpartIds: requested.objectId, qaApproved: requested.targetStatus });
    }

    async changeXpartGroupActive(requested) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/group/activation', { xpartGroupIds: requested.objectId, active: requested.targetStatus });
    }

    async changeCompletionGroup(requested) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/group/completion', { completed: !requested.rowData[0].completionGroup.completed, xpartGroupId: requested.rowData[0].completionGroup.xpartGroupId, xpartNumbers: [requested.rowData[0].completionGroup.xpartNumber] });
    }

    // A function To check if all the completion values are true 
    checkCompletionStatus(completionList) {
        for (let i = 0; i < completionList.length; i++) {
            if (completionList[i]['completed'] === false) {
                return false;
            }
        }
        return true;
    }

    //Post change function for group completion control
    handleChangeCompletionGroup(response, representFieldId) {
    var rowCompletionData = response.data.completionList;
        let buttonflag = this.checkCompletionStatus(rowCompletionData);
        let field = document.getElementById(representFieldId);
        if (buttonflag) {
            field.classList.remove("xpm-disabled");
        } else {
            field.classList.add("xpm-disabled");
        }
    }

    handleChangeGroupActivation(response, representFieldId) {

        for (let i in response.data.xpartGroupIds) {

            const id = response.data.xpartGroupIds[i];
            let field = document.getElementById(representFieldId + '-' + id);

            const htmlString = response.data.deactivationDate ? HelperFunction.timeConverter(response.data.deactivationDate, 'de') : '';
            field.innerHTML = htmlString;

            let editIcon = document.getElementById('toEdit-icon-' + id);

            if (!editIcon) {
                return;
            }
            if (response.data.active) {
                editIcon.classList.remove("not-active-element");
                editIcon.classList.add("active-element");
            } else {
                editIcon.classList.add("not-active-element");
                editIcon.classList.remove("active-element");
            }
        }

    }

    getDeliverydate() {
        let orderIds = [];
        document.querySelectorAll('div[data-id]').forEach(row => {
            orderIds.push(row.getAttribute('data-id'));
        })

        let orderString = orderIds.join("&orders=");
        let lang = HelperFunction.getLocale();
        axios.get(API_XPART.XPART_SERVICE_URL + '/order/info?orders=' + orderString)
            .then(response => {
                response.data.forEach(order => {
                    let element = document.querySelector('div[data-id="' + order.orderId + '"]').querySelector('div[data-name="deliverydate"]');
                    if (element) {
                        element.innerHTML = HelperFunction.timeConverter(order['deliveryDate'], lang, true);
                    }
                })

            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });

    }
}
