


import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../../Common/API/API';
import TableConfig from '../../List/tables-config.json';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { AssemblyRequestControlButtonsComponent } from './AssemblyRequestControlButtonsComponent';
import { PaginationComponent } from '../../List/PaginationComponent';
import { SelectableTableComponent } from '../../List/SelectableTableComponent';
import { TableFilter } from '../../List/TableFilter/TableFilter';
import { LabelService } from '../../Common/Translations/LabelService';
import { APINotifications } from '../../Common/API/APINotifications';
import { DeliveryDialog } from './DeliveryDialog';
import '../../List/list.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();

const API_XPART = new API();
export default class AssemblyRequestXpartsViewComponent extends Component {


    constructor(props) {
        super(props);
        this.config = TableConfig['partsAssemblyKME'];
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.tableFilter = React.createRef();
        this.controlButtonsComponent = React.createRef();
        this.deliveryPlace = null;
        this.deliveryDate = null;
        this.storagePrefixForDeliveryPlace = 'partsAssemblyKME_delivery_place';
    }

    state = {
        generalFilter: [],
        usesFilter: false,
        importCount: '',
        preFilter: [],
        holidayList: null,
        nextAvailabelDate: null
    }


    componentWillMount() {
        this.setState(this.state);
        this.initDates();
    }

    initDates() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/delivery-date/kme/next')
            .then(response => {
                const firstActiveDate = response.data;
                this.setState({ nextAvailabelDate: firstActiveDate})

            }).then(
                axios.get(API_XPART.XPART_SERVICE_URL + '/holidays').then(response => {
                    this.setState({ holidayList: response.data.map(e => e.date) })
                })
            )
    }


    updateTable(page, parts) {
        this.props.history.push('/assembly-request/' + page + '/' + parts);
      //  this.refresh();
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    filterUpdate(data, filterFlag) {
        this.setState({
            generalFilter: data,
            usesFilter: filterFlag
        })
    }


    startMultiSelect() {
        this.tableComponent.current.startMultiSelect();
    }

    stopMultiSelect() {
        this.tableComponent.current.stopMultiSelect();
    }

    updateMultiSelectState(multiSelect) {
        this.controlButtonsComponent.current.updateMultiSelectState(multiSelect);
    }
    clearLocalSelection() {
        this.tableComponent.current.clearLocalSelection();
    }

    updateLoading(loading) {
        if (this.tableComponent.current) {
            this.tableComponent.current.updateLoading(loading);
        }
    }

    requestAssebly() {

        this.updateLoading(true)

        let context = this.config.targetContextId;
        let status = this.config.targetStatusId;
        let xparts = JSON.parse(localStorage.getItem(this.config.localStoragePrefix));

        // if: checks if xpart is empty
        if (xparts === null || xparts.length <= 0 || !this.deliveryPlace || !this.deliveryDate) {
            this.refresh();
            return;
        }


        const deliveryPlaceSequence = this.deliveryPlace.split('-');
        const groupInfoDTO = {
            active: true,
            modeTypeId: 3,
            typeNameId: 2
        }

        const groupMetaInfoDTO = {
            deliveryDate: this.deliveryDate,
            deliveryPlaceSequence: deliveryPlaceSequence
        }

        const groupInfo = {
            groupInfoDTO: groupInfoDTO,
            groupMetaInfoDTO: groupMetaInfoDTO
        }

        // initial data object
        let data = {
            "contextId": context,
            "statusId": status,
            "validationStatusId": this.config.validationStatusId,
            "validationContextId": this.config.validationContextId,
            "xpartIds": xparts,
            "groupInfo": groupInfo
        }

        // request: send data object to backend
        axios.post(API_XPART.XPART_SERVICE_URL + this.config.changeStatusURL, data)
            .then(response => {
                this.tableComponent.current.onConfirmSuccess();
            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            }).finally(e => this.updateLoading(false));
    }

    // delivery dialog component call

    deliveryDialog() {
        /***here we can make axios request to get updated possible delivery date from the server and pass into the props */

        this.props.fireModal({
            header: LabelFile.header["ENTER_DELIVERY_DETAILS"],
            content: <DeliveryDialog
                config={this.config}
                storagePrefixForDeliveryPlace={this.storagePrefixForDeliveryPlace}
                nextAvailabelDate = {this.state.nextAvailabelDate}
                holidayList = {this.state.holidayList}
                closeModal={this.props.closeModal}
                deliveryResponse={(data) => this.deliveryResponse(data)}
            />,
            type: 'wide',
            stickInOverlay: true
        });

    }

    deliveryResponse(data) {
        this.props.closeModal();
        if (data.startMultiSelect) {
            this.startMultiSelect()
            this.deliveryPlace = data.deliveryLocation.name;
            this.deliveryDate = data.deliveryDate;
        }
    }


    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }
    goToRequestedList() {
        this.props.history.push(this.config.requestedPageURL);
    }


    render() {
        return (
            <section className="results--section">
                <PageHeaderComponent text={this.config.pageHeader ? <h1>{LabelFile.labels['requestparts']}</h1> : ''} ></PageHeaderComponent>

                {<PaginationComponent ref={this.paginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'partsAssemblyKME'} ></PaginationComponent>}

                <div className="list-container">
                    <AssemblyRequestControlButtonsComponent ref={this.controlButtonsComponent} requestAssebly={() => this.requestAssebly()}
                        startMultiSelect={() => this.deliveryDialog()} stopMultiSelect={() => this.stopMultiSelect()}
                        clearLocalSelection={() => { this.clearLocalSelection() }} goToRequestedList={() => { this.goToRequestedList() }}
                        activeSelection={this.state.nextAvailabelDate && this.state.holidayList}>
                    </AssemblyRequestControlButtonsComponent>

                    <TableFilter ref={this.tableFilter} module={'partsAssemblyKME'} filterUpdate={(data, filterFlag) => this.filterUpdate(data, filterFlag)}></TableFilter>

                    <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={'partsAssemblyKME'}
                        usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        updateConfirmButton={(e) => this.updateConfirmButton(e)}></SelectableTableComponent>

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'partsAssemblyKME'} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </section>
        )
    }

}