import React, { Component } from 'react'
import FormGenerate from '../Form/FormGenerate.js'

class FormFieldAssembly extends Component {

  constructor(props) {
    super(props)
    this.result = '';
    this.receiveVariables = this.getReceiveVariables();
  }

  //checks localStorage for stored pictures
  componentWillMount() {
    if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpartnumber)) {
      this.result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpartnumber));
    }
  }

  getReceiveVariables() {
    let formParameters = [{ name: 'weight', htmlId: 'weight', isNumber: true },{ name: 'descriptionEn', htmlId: 'desc_en', isNumber: false },];
    let receiveVariables = {
      formParameters: formParameters,
      needPhoto: true,
      successURI: '/assembly'
    };

    return receiveVariables;
  }


  render() {
    return (
      <FormGenerate
        fireModal={this.props.fireModal}
        closeModal={this.props.closeModal}
        formname="receiveXpartAssembly"
        header="ASSEMBLY"
        DataSetIdName={this.props.match.params.xpartnumber} // fetch the xpartnumber and fills the generate formfield
        prefill="true"
        pdfPrint="false"
        warningSave="proveAssembly"
        pathAfterSave="/assembly"
        amountOfLocalPhotos={this.result.length}
        flag={'receiveAssembly'} // set flag for the back-button
        receiveVariables={this.receiveVariables}
      />
    )
  }
}

export default FormFieldAssembly