import React, { Component } from 'react'
import { withRouter } from 'react-router';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { HelperFunctions } from '../Common/Helper/Helper';
import { CameraService } from '../Camera/CameraService';


const HelperFunction = new HelperFunctions();

class Photos extends Component {
    state = {
        resultlist: []
    }
    componentDidMount() {
        if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
            let result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart));

            this.setState({
                resultlist: result
            })
        }
        this.enablePhotoMode();
    }

    generateBackLink() {
        console.log(this.props.match.params.module);
        if (this.props.match.params.module) {
            return "/" + this.props.match.params.module + '/photo/gallery/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context);
        } else {
            return '/photo/gallery/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context);
        }
    }

    onTakePhoto(dataUri) {
        let results = this.state.resultlist;
        results.push(dataUri);
        this.setState({
            resultlist: results
        })

        localStorage.setItem("xpm-gallery-list-" + this.props.match.params.xpart, JSON.stringify(this.state.resultlist));

        CameraService.killCamera();
        this.disablePhotoMode();
        this.props.history.push(this.generateBackLink(), this.props.match.context);
    }


    enablePhotoMode() {
        let element = document.getElementById('page-wrap');
        element.classList.add('xpm-camera-mode');
        element = document.getElementsByClassName("topheader")[0];
        element.classList.add('xpm-camera-mode');
    }

    disablePhotoMode() {
        let element = document.getElementById('page-wrap');
        element.classList.remove('xpm-camera-mode');
        element = document.getElementsByClassName("topheader")[0];
        element.classList.remove('xpm-camera-mode');
    }

    componentWillUnmount() {
        CameraService.killCamera();
    }

    stopCamera() {
        let stream = document.querySelector('video').srcObject;
        if (!stream) {
            return;
        }
        let tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        document.querySelector('video').srcObject = null;
    }


    render() {
        return (
            <div className="App">
                <div className="cameralayout">
                <Camera
                    onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
                    isFullscreen={false}
                    imageType={IMAGE_TYPES.JPG}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{ width: 1920, height: 1440 }}
                    isImageMirror={false}
                    isMaxResolution={true}
                />
                </div>
            </div>
        );
    }
}

export default withRouter(Photos);