import React, { Component } from 'react'
import './Print.css';
import printJS from 'print-js';
import { API } from '../Common/API/API';

const API_REG = new API();

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

class Print extends Component {

    state = {
        xpartId: ''
    }

    showFile(blob) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" });

        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, 'xpm_' + this.state.xpartId + '.pdf');
            return;

        }

        const url = window.URL.createObjectURL(newBlob);
        if (isMobile.any()) {
            window.open(url, '_blank');
        } else {
            printJS(url);
        }
    }


    getPdfDocument(docUrl) {
        fetch(docUrl)
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.blob();
            })
            .then(this.showFile.bind(this))
            .catch(function (err) {
                alert('Error getting the pdf file!')
                console.log(err);
            });
    }

    handleClick() {
        this.getPdfDocument(API_REG.FILE_SERVICE_URL + '/x-part/' + this.state.xpartId);
    }
    handleInputChange(event) {
        this.setState({ xpartId: event.target.value })
    }

    keyPress(e) {
        e.stopPropagation();

        if (e.keyCode === 13) {
            this.handleClick();
        }
        e.stopPropagation();
    }

    render() {
        return (
            // <section className="results--section">
            //     <div className="print-container">

            //         <input type="text" name="xpartId" placeholder="X-part ID"
            //             value={this.state.xpartId}
            //             onChange={this.handleInputChange.bind(this)}
            //             onKeyDown={this.keyPress.bind(this)} />
            //         <button type="button"
            //             className="print-button xpm-button"
            //             onClick={this.handleClick.bind(this)}>Print</button>123

            //     </div>
            // </section>
            <section className="reault--section">
                <div className="print-container">
                    <div className="print-input">
                        <input
                            type="text"
                            name="xpartId"
                            placeholder="X-part ID"
                            value={this.state.xpartId}
                            onChange={this.handleInputChange.bind(this)}
                            onKeyDown={this.keyPress.bind(this)}
                            className="print-input-properties"
                        />
                    </div>
                    <div className="print-button">
                        <button
                            type="button"
                            onClick={this.handleClick.bind(this)}
                            className="xpm-button print-button-properties"
                        >
                            Print
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}

export default Print;
