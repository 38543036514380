


import React from 'react'
import axios from 'axios';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import { Loader } from '../Common/Components/Loader/loader';
import { HelperFunctions } from '../Common/Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelectComponent } from '../Common/MultiSelectComponent';
import { Constants } from '../Common/Helper/Constants';

const API_XPART = new API();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class PackageOverViewTableComponent extends MultiSelectComponent {

    state = {
        packagesList: [],
        selectedIds: []
    }

    changeSelectMode(isMultiSelect) {
        if (isMultiSelect) {
            this.startMultiSelect();
        } else {
            this.stopMultiSelect();
        }
    }

    componentDidMount() {
        this.loadData();
        this.multiSelectEventHandler = this.multiSelectEvent.bind(this);
    }

    multiSelectEvent = (e) => {
        e.stopPropagation();
        const packageID = parseInt(e.currentTarget.dataset.attr.replace(this.props.selectedItemPrefix, ''));
        const badPackage = this.findBadPackageById(packageID, this.state.packagesList);

        if ((badPackage === null) || this.state.selectedIds.includes(packageID)) {
            const toSelectList = [...this.state.selectedIds];
            this.updateLocalStorage(packageID, toSelectList);
            this.setState({
                selectedIds: toSelectList
            });
            this.props.changeSelectedCount(toSelectList.length);
        } else {
            this.props.showEditPackagePopup(badPackage);
        }
    }

    updatePackage(updatedPackage) {
        let newPackageList = [...this.state.packagesList];
        this.replaceBadPackageById(updatedPackage, newPackageList);
        let toSelectList = [...this.state.selectedIds];
        this.updateLocalStorage(updatedPackage.id, toSelectList);
        this.setState({
            selectedIds: toSelectList,
            packagesList: newPackageList
        });
        this.props.changeSelectedCount(toSelectList.length);
    }


    updateLocalStorage(packageId, selectedIds) {
        if (!localStorage[this.props.selectedItemPrefix + this.props.projectNumber]) {
            localStorage.setItem(this.props.selectedItemPrefix + this.props.projectNumber, JSON.stringify([]));
        }

        if (selectedIds.indexOf(packageId) >= 0) {
            selectedIds.splice(selectedIds.indexOf(packageId), 1);
        } else {
            selectedIds.push(packageId);
        }

        localStorage.setItem(this.props.selectedItemPrefix + this.props.projectNumber, JSON.stringify(selectedIds))
    }

    getPrevSelectedIds() {
        let prevSelectedIds = [];

        if (!localStorage[this.props.selectedItemPrefix + this.props.projectNumber]) {
            return prevSelectedIds;
        }

        let listLocalStorage = JSON.parse(localStorage[this.props.selectedItemPrefix + this.props.projectNumber]);

        for (let i = 0; i < listLocalStorage.length; i++) {
            prevSelectedIds.push(parseInt(listLocalStorage[i]));

        }
        return prevSelectedIds;
    }

    initData(packagesList) {
        let originalPackageIds = [];
        let prevSelected = this.getPrevSelectedIds();

        let selectedIds = [];

        for (let i = 0; i < packagesList.length; i++) {
            let pack = packagesList[i];
            if (pack.transportationObject !== null && pack.transportationObject.id === parseInt(this.props.deliveryIdToEdit)) {
                originalPackageIds.push(pack.id);
                if (!prevSelected.includes(pack.id)) {
                    this.updateLocalStorage(pack.id, prevSelected);
                }
                selectedIds.push(pack.id);
            } else if (prevSelected.includes(pack.id)) {
                selectedIds.push(pack.id);
            }
        }

        this.setState({
            packagesList,
            originalPackageIds,
            selectedIds
        });

        this.props.changeSelectedCount(selectedIds.length);

        return originalPackageIds;
    }

    hasEmptyField(pack) {
        return pack['grossWeight'] === null || pack['height'] === null || pack['length'] === null || pack['width'] === null;
    }


    async getDataFromSource() {
        if (this.props.deliveryIdToEdit !== undefined) {
            return axios.get(API_XPART.XPART_SERVICE_URL + '/transportation/edit/' + this.props.deliveryIdToEdit + '/' + this.props.projectNumber);
        }
        return axios.get(API_XPART.XPART_SERVICE_URL + '/package/inorder?orderNumber=' + this.props.projectNumber + '&orderType=2');
    }


    //load packages from db
    loadData() {
        //    this.props.toggleLoading();
        this.getDataFromSource()
            .then(response => {
                this.initData(response['data']);
            })
            .catch(err => {
                this.props.showException(err);
            })
            .finally(() => this.props.toggleLoading());
    }

    findBadPackageById(packageID, packagesList) {
        for (let i = 0; i < packagesList.length; i++) {
            var pack = packagesList[i];
            if (pack['id'] === packageID && this.hasEmptyField(pack)) {
                return pack;
            }
        }

        return null;
    }

    replaceBadPackageById(goodPackage, packagesList) {
        for (let i = 0; i < packagesList.length; i++) {
            var pack = packagesList[i];
            if (pack['id'] === goodPackage['id']) {

                packagesList[i] = goodPackage;
            }
        }

        return null;
    }

    getSelectedClass(id) {
        return this.state.selectMode && this.state.selectedIds.includes(id) ? 'multiselect-selected' : '';
    }

    getPackagesToAdd() {
        let packagesToAdd = [];
        for (let i = 0; i < this.state.selectedIds.length; i++) {
            if (!this.state.originalPackageIds.includes(this.state.selectedIds[i])) {
                packagesToAdd.push(this.state.selectedIds[i]);
            }
        }

        return packagesToAdd;
    }

    getPackagesToRemove() {
        let packagesToRemove = [];
        for (let i = 0; i < this.state.originalPackageIds.length; i++) {
            if (!this.state.selectedIds.includes(this.state.originalPackageIds[i])) {
                packagesToRemove.push(this.state.originalPackageIds[i]);
            }
        }
        return packagesToRemove;
    }

    clearLocalSelection() {
        localStorage.removeItem(this.props.selectedItemPrefix + this.props.projectNumber);
        this.props.goToOverview();
    }

    editpackage(packData) {
        const { selectedItemPrefix } = this.props
        if (selectedItemPrefix === Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX) {
            return
        }
        this.props.goToEditPackage(packData);
    }

    showDeleteIcon(pack) {
        return !pack.transportationObject && true;
    }

    deleteButton(content) {
        const { selectedItemPrefix } = this.props
        if (selectedItemPrefix === Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX) {
            return
        }
        const deleteItem = async (content) => {
            await axios.delete(API_XPART.XPART_SERVICE_URL + '/package/?packageID=' + content.id)
                .then((res) => {
                    this.clearLocalSelection();
                    this.props.closeModal()
                })
                .catch(err => {
                    this.props.showException(err);
                })
        }

        const deletePage = (content) => {
            return (
                <div className="delete-item-container">
                    <div className="delete-item-content">
                        <span>
                            Das Paket enthält <b>{content.xpartsCount}</b> Losteile. Wollen Sie das Paket wirklich löschen? Sie können auch die Editier-Funktion nutzen, um einzelne Losteile zu entfernen.
                            </span>
                    </div>
                    <div className="delete-item-button">
                        <button type="button" className="xpm-button" onClick={() => deleteItem(content)}>
                            {LabelFile.labels["delete"]}
                        </button>
                    </div>
                    <div className="delete-item-button">
                        <button type="button" className="xpm-button" onClick={() => this.props.closeModal()}>
                            {LabelFile.labels["cancel"]}
                        </button>
                    </div>
                </div>
            )
        }

        this.props.fireModal({
            content: deletePage(content), // open the jsy layout and passthrough given properties
            header: 'Löschen',
            type: 'wide'
        })
    }

    getDeleteIcon(pack) {
        if (this.showDeleteIcon(pack)) {
            return (<FontAwesomeIcon className={"icon-properties " + this.iconClass(Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX)} icon="trash" onClick={() => this.deleteButton(pack)} />);
        }

        return ('');
    }

    renderTableContent() {
        return (
            this.state.packagesList.map(result => (
                <div className={"list-grid-row xpart-list-grid-row-override packagelist multi-select " + this.getSelectedClass(result.id)} data-attr={this.props.selectedItemPrefix + result['id']}>
                    <div data-name={LabelFile.labels['packagenumber']} className="list-grid-item ">
                        <span>{result['packageNumber']}</span>
                    </div>
                    <div data-name={LabelFile.packages[result['packageTypeName']]} className="list-grid-item ">
                        <span>{LabelFile.packages[result['packageTypeName']]}</span>
                    </div>
                    <div data-name={LabelFile.labels['created']} className="list-grid-item">
                        <span>{result['created'] ? HelperFunction.timeConverter(result['created'], 'de-DE') : null}</span>
                    </div>
                    <div data-name={LabelFile.labels['updated']} className="list-grid-item">
                        <span>{result['updated'] ? HelperFunction.timeConverter(result['updated'], 'de-DE') : null}</span>
                    </div>
                    <div data-name={LabelFile.labels['xpartcount']} className="list-grid-item">
                        <span>{result['xpartsCount']}</span>
                    </div>
                    <div data-name={LabelFile.labels['netweight']} className="list-grid-item">
                        <span>{result['netWeight'] ? HelperFunction.formatFloats(result['netWeight'], "de-DE") : null}</span>
                    </div>
                    <div data-name={LabelFile.labels['grossweight']} className="list-grid-item">
                        <span>{result['grossWeight'] ? HelperFunction.formatFloats(result['grossWeight'], "de-DE") : null}</span>
                    </div>
                    <div data-name={LabelFile.labels['length']} className="list-grid-item">
                        <span>{result['length']}</span>
                    </div>
                    <div data-name={LabelFile.labels['width']} className="list-grid-item">
                        <span>{result['width']}</span>
                    </div>
                    <div data-name={LabelFile.labels['height']} className="list-grid-item">
                        <span>{result['height']}</span>
                    </div>
                    <div data-name={LabelFile.labels['functions']} className="list-grid-item">
                        <div>
                            <FontAwesomeIcon className={"icon-properties " + this.iconClass(Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX)} icon="edit" onClick={() => this.editpackage(result)} />
                            {this.getDeleteIcon(result)}
                        </div>
                    </div>
                </div>
            ))
        )
    }

    iconClass(modeSelector) {
        return (modeSelector === this.props.selectedItemPrefix) || this.state.selectMode ? 'disabled-icon' : 'active-icon';
    }

    render() {
        return (
            <div className="list-container" id="package-table">
                <div className="list-grid">
                    <div className="list-grid-row xpart-list-grid-row-override list-grid-row-headline packagelist ">
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['packagenumber']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['packagetype']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['created']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['updated']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['xpartcount']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['netweight']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['grossweight']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['length']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['width']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['height']}</span>
                        </div>
                        <div className="list-grid-item">
                            <span>{LabelFile.labels['functions']}</span>
                        </div>
                    </div>
                </div>
                {this.renderTableContent()}
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </div>
        )
    }
}

export default PackageOverViewTableComponent;