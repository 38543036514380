import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelService } from "../../Common/Translations/LabelService";
import ManageQueryStorage from "./helpers/ManageQueryStorage";

const LabelFile = LabelService.getLabelsByLocal('de');

export default class UniversalSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textFieldValue: '',
        };

        this.keyTimer = ''; // TODO: should be in state
        this.manageUniversalQueryStorage = new ManageQueryStorage(this.props.module, 'universal-search');
    }

    componentDidMount() {
        const storedQueries = this.manageUniversalQueryStorage.getQueries() || [];

        const { addSimpleQuery } = this.props;
        storedQueries.forEach(query => addSimpleQuery(query));
    }

    updateTextFieldValue(textFieldValue) {
        this.setState(Object.assign({}, this.state, { textFieldValue }));
    }

    clearTextFieldValue() {
        this.setState(Object.assign({}, this.state, { textFieldValue: '' }));
    }

    handleChange(e) {
        e.preventDefault();
        const textFieldValue = e.target.value;
        this.updateTextFieldValue(textFieldValue);
    }

    handleKeyUp() {
        if (this.keyTimer) {
            clearTimeout(this.keyTimer);
        }
        this.keyTimer = setTimeout(() => {
            const { textFieldValue } = this.state;
            if (textFieldValue === '') {
                return;
            }

            const { doLiveSimpleQuery } = this.props;
            doLiveSimpleQuery(this.buildSimpleQuery(textFieldValue));
        }, 500)
    }

    handleFormSubmit(e) {
        e.preventDefault();
        const { textFieldValue } = this.state;
        clearTimeout(this.keyTimer);
        this.props.submitFilters(this.buildSimpleQuery(textFieldValue));
    }

    getSimpleQuery() {
        const { textFieldValue } = this.state;
        return this.buildSimpleQuery(textFieldValue);
    }

    buildSimpleQuery(fieldValue) {
        if (fieldValue === '') {
            return null;
        }
        return ({
            name: 'simple-query',
            queryData: {
                fieldValue
            }
        });
    }

    handleRemovingSearchedTerms() {
        const { removeAllQuery } = this.props;
        removeAllQuery();
    };

    render() {
        const { textFieldValue } = this.state;
        const { toggleSpecialFilter } = this.props;
        return (
            <form id="filterForm" autoComplete="off">
                <div className="filter-form">
                    <input
                        type="text"
                        className="input"
                        id="filterInput"
                        placeholder={LabelFile.labels["search"] + "..."}
                        autoFocus="autofocus"
                        value={textFieldValue}
                        onKeyUp={() => this.handleKeyUp()}
                        onClick={() => toggleSpecialFilter()}
                        onChange={(e) => this.handleChange(e)} />

                    {this.renderInputIcon()}

                    <button className="button-add-item" onClick={(e) => this.handleFormSubmit(e)} />
                </div>
            </form>
        )
    }

    renderInputIcon() {
        let iconFlag = false

        // it is checked whether a simpleQueries or specialQueries search is completed
        if (0 < this.props.simpleQueries.length || 0 < this.props.specialQueries.length) {
            iconFlag = true
        }

        return (iconFlag) ? <FontAwesomeIcon className="icon-remove" icon="times-circle" onClick={() => this.handleRemovingSearchedTerms()} />
            : <FontAwesomeIcon className="icon-remove" icon="search" />
    }
}
