import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { HelperFunctions } from '../Common/Helper/Helper';
import { Loader } from '../Common/Components/Loader/loader';
import { Constants } from '../Common/Helper/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Project.css';

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();

class DeliveryView extends Component {
    constructor(props) {
        super(props);
        this.resultListArr = [];
        this.deliveryPackageNumber = [];
        if (this.props.location.state !== undefined) {
            if (this.props.location.state['packageNumber'] !== undefined) {
                this.packageNumber = this.props.location.state['packageNumber'];
            }
        }

        this.multiSelectEvent = (e) => {
            e.stopPropagation();
            this.handleMultiSelectEvent(this.packageNumber, "delivery-" + this.state.projectNumber, e, this.state.resultlistselected);
        }
        this.multiSelectEventHandler = this.multiSelectEvent.bind(this);
    }

    state = {
        projectNumber: this.props.match.params.projectnr,
        deliveryList: [],
        resultlistselected: [],
        multiSelectHide: "",
        multiSelectShow: "xpm-hidden",
        multiSelectSelected: 0,
        selectMode: false,
        isLoading: true
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };
        btns.push(obj);

        return btns;
    }

    projectXpartList() {
        this.props.history.push('/project/'+this.state.projectNumber+'/package/overview');
    }

    deliveryList() {
        this.props.history.push('/project/deliveryview/' + this.state.projectNumber);
    }

    loaddata() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/transportation/list')
            .then(response => {
                this.resultListArr = response['data'];
                this.setState({
                    deliveryList: this.resultListArr
                })
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.setState({
                isLoading: false
            }));
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }

    clearLocalSelection() {
        localStorage.removeItem(this.props.selectedItemPrefix + this.state.projectNumber);
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    deleteButton(content) {
        const deleteItem = async (content) => {
            await axios.delete(API_XPART.XPART_SERVICE_URL + '/transportation/?Id=' + content.id)
                .then((res) => {
                    this.toggleLoading(false);
                    this.clearLocalSelection();
                    this.props.closeModal()
                })
                .catch(err => {
                    this.toggleLoading(false);
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
        }

        const deletePage = (content) => {
            return(
                <div className="delete-item-container">
                    <div className="delete-item-content">
                        <span>
                            Die Lieferung <b>{content.id}</b> enthält <b>{content.packagesCount}</b> Pakete. Wollen Sie die Lieferung wirklich löschen? Sie können auch die Editier-Funktion nutzen, um einzelne Pakete zu entfernen.
                        </span>
                    </div>
                    <div className="delete-item-button">
                        <button type="button" className="xpm-button" onClick={() => deleteItem(content)}>
                            {LabelFile.labels["delete"]}
                        </button>
                    </div>
                    <div className="delete-item-button">
                        <button type="button" className="xpm-button" onClick={() => this.props.closeModal()}>
                            {LabelFile.labels["cancel"]}
                        </button>
                    </div>
                </div>
            )
        }

        this.props.fireModal(
            {
                content: deletePage(content),
                header: 'Löschen',
                type: 'wide'
            }
        )
    }

    renderTableContent() {
        return (
            this.state.deliveryList.map(result => (
                    <div key={result.id} className={"list-grid-row xpart-list-grid-row-override deliverylist multi-select " + result["class-selected"]} data-attr={"delivery-" + result['id']}>
                        <div data-name="created" className="list-grid-item">
                            <span>{result['created'] ? HelperFunction.timeConverter(result['created'], 'de-DE') : null}</span>
                        </div>

                        <div data-name="" className="list-grid-item">
                            <span>{result['packagesCount']}</span>
                        </div>

                        <div data-name={LabelFile.labels['functions']} className="list-grid-item">
                            <FontAwesomeIcon className="icon-properties" icon="edit" onClick={() => this.editDelivery(result)} />
                            <FontAwesomeIcon className="icon-properties" icon="trash" onClick={() => this.deleteButton(result)}/>
                        </div>
                    </div>
                )
            ))
    }

    componentDidMount() {
        this.loaddata();
    }

    editDelivery(delivery) {
        const { id } = delivery
        const { projectNumber } = this.state
        localStorage.removeItem(`${Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX}${projectNumber}`);
        this.props.history.push('/project/' + projectNumber + '/delivery/edit/' + id);
    }

    render() {
        return (
            <section className="results--section">
                <h1>{LabelFile.labels['delivery_overview']}</h1>
                <div className="optionalButtons xpm-button-container">
                    <button onClick={() => { this.projectXpartList() }}
                            className={"xpm-button "}><FontAwesomeIcon icon={['fas', 'arrow-left']} />&nbsp;{LabelFile.labels['deliverybackbutton']}</button>
                </div>
                <div className="list-container">
                    <div className="list-grid">
                        <div className="list-grid-row xpart-list-grid-row-override list-grid-row-headline deliverylist ">

                            <div className="list-grid-item">
                                <span>{LabelFile.labels['created']}</span>
                            </div>
                            <div className="list-grid-item">
                                <span>{LabelFile.labels['amountofpackages']}</span>
                            </div>
                            <div className="list-grid-item">
                                <span>{LabelFile.labels['functions']}</span>
                            </div>
                        </div>
                    </div>
                    {this.renderTableContent()}
                    {this.state.isLoading ? <Loader type="lock" /> : ''}
                </div>
            </section>
        )
    }
}

export default withRouter(DeliveryView);