export class CameraService {

    static killCamera() {

        try {
            let stream = document.querySelector('video').srcObject;
            if (!stream) {
                return;
            }
            let tracks = stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
            document.querySelector('video').srcObject = null;

        } catch (error) {
            console.log(error)
        }
    }

    static getFotosFromLocalStorage(index) {

        let photos = JSON.parse(localStorage.getItem(index));
        if (photos === null) {
            photos = [];
        }
        return photos;
    }

    static getPhotosToSend(index) {

        let photos = JSON.parse(localStorage.getItem(index));
        if (photos === null) {
            photos = [];
        }

        let toSend = [];
        for (let i in photos) {
            toSend.push(photos[i].replace(photos[i].substring(0, photos[i].indexOf(';base64') + 7), ""));
        }

        return toSend;
    }
}