import React, { Component } from 'react'
import axios from 'axios';
import { API } from '../Common/API/API';
import TableConfig from '../List/tables-config.json';
import { PageHeaderComponent } from '../Common/Components/page-header-component';
import { CommissionRequestedControlButtonComponent } from './CommissionRequestedControlButtonComponent';
import { PaginationComponent } from '../List/PaginationComponent';
import { SelectableTableComponent } from '../List/SelectableTableComponent';
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import '../List/list.css'
import './CommissionRequested.css'
import './headerColumnWrapper.css'

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const API_XPART = new API();

export class CommissionRequestedViewComponent extends Component {
  constructor(props) {
    super(props);
    this.config = TableConfig['partsAssemblyCommissions'];
    this.paginationComponent = React.createRef();
    this.bottomPaginationComponent = React.createRef();
    this.tableComponent = React.createRef();
    this.controlButtonsComponent = React.createRef();
    this.deliveryPlace = null;
    this.deliveryDate = null;
    this.storagePrefixForDeliveryPlace = 'partsAssemblyKME_delivery_place';
  }

  state = {
    generalFilter: [],
    usesFilter: false,
    importCount: '',
    preFilter: [],
    holidayList: null,
    nextAvailabelDate: null
  }

  componentWillMount() {
    this.setState(this.state);
  }


  updateTable(page, parts) {
    this.props.history.push('/commission-requested/' + page + '/' + parts);
    this.refresh();
  }

  pgFeedback(first, last, totalPages) {
    if (this.paginationComponent.current) {
      this.paginationComponent.current.updatePages(first, last, totalPages);
    }

    if (this.bottomPaginationComponent.current) {
      this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
    }
  }

  refresh() {
    this.props.history.replace('/refresh' + window.location.pathname);
  }

  filterUpdate(data, filterFlag) {
    this.setState({
      generalFilter: data,
      usesFilter: filterFlag
    })
  }

  startMultiSelect() {
    this.tableComponent.current.startMultiSelect();
  }

  stopMultiSelect() {
    this.tableComponent.current.stopMultiSelect();
  }

  updateMultiSelectState(multiSelect) {
    this.controlButtonsComponent.current.updateMultiSelectState(multiSelect);
  }
  clearLocalSelection() {
    this.tableComponent.current.clearLocalSelection();
  }

  updateLoading(loading) {
    if (this.tableComponent.current) {
      this.tableComponent.current.updateLoading(loading);
    }
  }

  onConfirmSuccess() {
    localStorage.removeItem(this.config.localStoragePrefix);
    this.stopMultiSelect();
    this.refresh();
  }

  requestAssebly() {
    this.updateLoading(true)

    let context = this.config.targetContextId;
    let status = this.config.targetStatusId;
    let xparts = JSON.parse(localStorage.getItem(this.config.localStoragePrefix));

    // if: checks if xpart is empty
    if (xparts === null || xparts.length <= 0) {
      this.refresh();
      return;
    }


    let data = {
      "contextId": context,
      "statusId": status,
      "validationStatusId": this.config.validationStatusId,
      "validationContextId": this.config.validationContextId,
      "xpartIds": xparts
    }

    // request: send data object to backend
    axios.post(API_XPART.XPART_SERVICE_URL + this.config.changeStatusURL, data)
      .then(response => {
        this.onConfirmSuccess();
      })
      .catch(err => {
        console.log(err);
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      }).finally(e => this.updateLoading(false));
  }



  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    };

    btns.push(obj);
    return btns;
  }
  goToRequestedList() {
    this.props.history.push(this.config.requestedPageURL);
  }

  onCountSuccess(count) {
    const notificationName = count > 1 ? 'UPDATING_XPARTS_MULTI' : 'UPDATING_XPARTS_ONE';
    this.props.fireModal({
      content: NOTIFICATIONS.getNotification('de', notificationName, count),
      header: NOTIFICATIONS.getHeaderDescription('de', 'INFO', 'COMMON_INFO'),
      buttons: [],
      closeModal: this.closeModal,
      stickInOverlay: true
    });
    this.startSyn();
  }

  closeModal() {
    this.props.closeModal();
  }

  startSyn() {
    this.updateLoading(true);

    axios.post(API_XPART.MIGRATION_SERVICE_URL + '/quick/migrate', { status: [6], statusInfos: [8] })
      .then(response => {
        this.closeModal();
        this.refresh();
      })
      .catch(err => {
        this.closeModal();
        console.log(err);
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', 'ERROR', 'ERROR'),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      }).finally(e => this.updateLoading(false));

  }

  synchronize() {
    axios.post(API_XPART.MIGRATION_SERVICE_URL + '/quick/migrate/count', { status: [6], statusInfos: [8] })
      .then(response => {
        this.onCountSuccess(response.data.migrationTotalCount);
      })
      .catch(err => {
        console.log(err);
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', 'ERROR', 'ERROR'),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      }).finally(e => this.updateLoading(false));
  }

  excelExport() {
    let filters = { "searchGroups": [{ "fields": [{ "fieldName": "XPART_CURRENT_STATUS", "fieldValue": "1", "searchType": "LIKE" }] }, { "fields": [{ "fieldName": "XPART_CURRENT_CONTEXT", "fieldValue": "10", "searchType": "LIKE" }] }] }
    this.tableComponent.current.updateLoading(true);

    axios.post(API_XPART.XPART_SERVICE_URL + this.config.dateSource + '/pick/export?page=0&size=1000&sort=metaInfoRequestList.deliveryDate&sort=storage.storageCode&sort=projectItemsViewList.orderNumber&sort=xpartNumber', filters, {
      responseType: 'blob'
    }).then(response => {
      this.showFile(response.data, this.getFileName(response.headers['content-disposition']));
    }).finally(() => {
      this.tableComponent.current.updateLoading(false);
    })
  }

  showFile(data, fileName) {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getFileName(contentDispositionValue) {
    var filename = "";
    if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  extraButtons() {
    return (
      <div className="xpm-button-container excel-export extraButtons" >
        <button onClick={() => { this.synchronize() }}
          className="xpm-button xpm-button-export" >{LabelFile.labels['synchronize']}</button>
        <button onClick={() => { this.excelExport() }}
          className="xpm-button xpm-button-export" >{LabelFile.labels['excelexport']}</button>
      </div>
    )
  }

  extraButtonsForOptionalTasks() {
    return (
      <div className="extra-button-wrapper">
        <button onClick={() => { this.synchronize() }} className="xpm-button extra-Button" >
          {LabelFile.labels['synchronize']}
        </button>
        <button onClick={() => { this.excelExport() }} className="xpm-button extra-Button" >
          {LabelFile.labels['excelexport']}
        </button>
      </div>
    )
  }


  render() {
    return (
      <section className="results--section">

        <div className="header-column-wrapper">
          <div className="header-column-left"></div>
          <div className="header-column-middle">
            <PageHeaderComponent text={this.config.pageHeader
              ?
              <h1>{LabelFile.labels['requestXpartCommissions']}</h1>
              :
              ''} >
            </PageHeaderComponent>
          </div>
          <div className="header-column-right">
            {this.extraButtonsForOptionalTasks()}
          </div>
          <div className="header-column-pagination">
            {<PaginationComponent ref={this.paginationComponent}
              updateTable={(page, parts) => this.updateTable(page, parts)}
              page={this.props.match.params.page}
              parts={this.props.match.params.parts}
              module={'partsAssemblyCommissions'}>
            </PaginationComponent>}
          </div>
        </div>

        <div className="list-container">
          <CommissionRequestedControlButtonComponent ref={this.controlButtonsComponent} requestAssebly={() => this.requestAssebly()}
            startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
            clearLocalSelection={() => { this.clearLocalSelection() }} goToRequestedList={() => { this.goToRequestedList() }}
            updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
            synchronize={() => { this.synchronize() }} excelExport={() => { this.excelExport() }}
            activeSelection={this.state.nextAvailabelDate && this.state.holidayList}>
          </CommissionRequestedControlButtonComponent>

          <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
            pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
            context={this.props.match.params.context} module={'partsAssemblyCommissions'}
            usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
            preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
            refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
            updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
            updateConfirmButton={(e) => this.updateConfirmButton(e)}></SelectableTableComponent>

        </div>
        {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
          page={this.props.match.params.page} parts={this.props.match.params.parts}
          module={'partsAssemblyCommissions'} ></PaginationComponent>}

        <div id="popupContainer"></div>
      </section>
    )
  }
}

export default CommissionRequestedViewComponent
