import React, { Component } from 'react'
import axios from 'axios';
import { withRouter } from 'react-router';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { APINotifications } from '../Common/API/APINotifications';
import './Xpart.css'
import { Loader } from '../Common/Components/Loader/loader';
import { HelperFunctions } from '../Common/Helper/Helper';

const API_XPART = new API();
const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();
const NOTIFICATIONS = new APINotifications();
const Helper = new HelperFunctions();

class XpartTechnikkum extends Component {

    constructor(props) {
        super(props);

        this.units = [
            {
                "id": 1,
                "name": "Stück"
            },
            {
                "id": 2,
                "name": "Meter"
            },
            {
                "id": 3,
                "name": "Liter"
            }
        ];

        this.status = [];
    }

    state = {
        statusDD: "",
        xpart_data: {},
        isLoading: false
    }

    handleSave() {
        if (Fields.validateInputs()) {
            document.getElementsByTagName('body')[0].classList.add("soft-progress");
            var data = {
                'descriptionDe': document.getElementById('desc_de').value,
                'descriptionEn': document.getElementById('desc_en').value,
                'quantity': document.getElementById('quantity').value,
                'weight': document.getElementById('weight').value,
                'active': true,
                'customerMaterialNumber': document.getElementById('customerMaterialNumber').value,
                'unitID': document.getElementById('unit').value,
                'statusID': 1, //Always "Created",
                'contextId': 3, //Always TECHNIKUM
                "orderTypeId": 2, //Always "Project"
                "orderNumber": "TECHNIKUM",
                "itemNumber": "TECHNIKUM"
            }
            this.setState({ isLoading: true })
            axios.post(API_XPART.XPART_SERVICE_URL + '/one', data)
                .then(res => {
                    Helper.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/x-part/' + res.data.xpartNumber)
                    .then(()=> this.props.history.push('/list/technikum/1/100')) 
                })
                .catch(err => {
                    document.getElementsByTagName('body')[0].classList.remove("soft-progress");
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                    this.setState({ isLoading: false })
                });
        }
    }

    handleCancel() {
        this.props.history.push('/list/technikum/1/100');
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    componentDidMount() {

    }

    /*

    spec_projects":{
      "TECHNIKUM-PROJECT":"Technikum",
      "TECHNIKUM-ITEM":""


    */


    render() {
        return (
            <section className="results--section">
                <section className="results--section xpartform">
                    <h1>{LabelFile.labels['xparts']} {LabelFile.spec_projects['TECHNIKUM-PROJECT']}</h1>
                    <div className="header-underline-img"><img src={window.location.origin + "/images/circle.png"} alt="Losteile" title="Losteil-Erfassung"></img></div>
                    <hr className="header-underline"></hr>
                    <div className="input-container" id="inputMain">
                        {Fields.generateInputTextarea("desc_de", "255", true, [LabelFile.warnings['required']], false)}
                        {Fields.generateInputTextarea("desc_en", "255", true, [LabelFile.warnings['required']], false)}
                        {Fields.generateInputNumber("quantity", "1", "10000", "1", true, [LabelFile.warnings['required'], LabelFile.warnings['number']], false)}
                        {Fields.generateInputSelect('unit', [this.units[0], this.units[1], this.units[2]], true, [LabelFile.warnings['required']], false)}
                        {Fields.generateInputNumber("weight", "0", "1000000", ".001", true, [LabelFile.warnings['required'], LabelFile.warnings['number']], false)}

                        {Fields.generateInputText("suppliernr", "[a-zA-Z0-9]{1,20}", true, [LabelFile.warnings['required'], LabelFile.warnings["limit_twenty"]], false)}
                        {Fields.generateInputHidden("id")}

                        <div className="xpm-button-container">
                            <button type="button" id="write-btn"
                                className="save-button xpm-button"
                                onClick={this.handleSave.bind(this)}>{LabelFile.labels["save"]}</button>

                            <button type="button"
                                className="cancel-button xpm-button"
                                onClick={this.handleCancel.bind(this)}>{LabelFile.labels['cancel']}</button>
                        </div>
                    </div>
                </section>
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </section>
        )
    }
}

export default withRouter(XpartTechnikkum);
