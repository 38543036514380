

import React from 'react';
import WizardStep from '../Common/Components/Wizrd/WizardStep';
import { CommonWizardComponent } from '../Common/Components/Wizrd/CommonWizardComponent';
import EvsKommissionierenButtonsComponent from './EvsKommissionierenButtonsComponent';
import Scan from '../Scan/XpartScan/Scan';
import { LabelService } from '../Common/Translations/LabelService';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import FormGenerate from '../Form/FormGenerate.js'
import { CameraService } from '../Camera/CameraService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

export default class KommissionierenWizard extends CommonWizardComponent {

    currentXpart = null;
    moduleName = 'kommissionierenTable';

    componentDidMount() {
        //init all steps
        let steps = [];
        steps.push(this.getScanStep());
        steps.push(this.getFormGeneratorStep());
        steps.push(this.getScanStorageStep());
        this.initComponent(steps, steps[0]);
        this.currentXpart = null;
        this.currentSoragePlace = null;
        this.storageLocationChangedFlag = false;
    }


    // after scan action is done
    postScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.getXpartData(scanValue);
    }

    postStorageScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.currentSoragePlace = scanValue;
        this.handleSaveStorage();

    }

    goToForm() {
        CameraService.killCamera();
        this.goToStep('form-step', this.fillForm);

        const controlButtonsComponent = <EvsKommissionierenButtonsComponent
            saveXpart={(e) => this.saveXpart(e)} goToStorageScan={() => this.goToStorageScan()} goToScan={() => this.goToScan()} />;

        this.setState({
            wizardControlComponent: controlButtonsComponent
        })
    }

    fillForm = () => {
        this.currentStepRef.fillForm(this.currentXpart)
    }




    checkWarnings = (xpart) => {
        if ((xpart.currentStatus.contextDTO.name === "TRANSPORTATION") && (xpart.currentStatus.statusDTO.name === "ORDERED")) {

            return false;
        }
        else {
            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'XPART_NOT_IN_EXPECTED_STATUS_ORDERED_TRANSPORTATION'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
                buttons: this.createWarningAfterXpartScanButtons(xpart)
            });

            return true;
        }

    }

    undoScan = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    }

    accecptScannedXpart = (xpart) => {
        this.props.closeModal();
        this.currentXpart = xpart;
        this.goToForm();
    }

    getXpartData(xpartNumber) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + xpartNumber)
            .then(res => {
                if (this.checkWarnings(res['data'])) {
                    return;
                }
                this.accecptScannedXpart(res['data']);
            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }

    getScanStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['storagexpartNr'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-step', scanComponent);

    }

    getScanStorageStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['change_storage'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['change_storage'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postStorageScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-storage-step', scanComponent);
    }



    getFormGeneratorStep() {
        const formComponent =
            <FormGenerate
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                formname="kommissionieren"
                header="orderPicking"
                prefill="false"
                pdfPrint="false"
                receiveVariables={null}
                setRef={el => this.setCurrentStepRef(el)}
            />

        return new WizardStep('form-step', formComponent);
    }

    getXpartInfo = () => {
        let xpartProperies = null;

        if (this.currentStepRef.passDataToParent) {
            xpartProperies = this.currentStepRef.passDataToParent();
        } else {
            xpartProperies = this.getXpatFormFromCurrentXpart();
        }


        if (!xpartProperies) {
            return null;
        }

        xpartProperies.id = this.currentXpart.id;
        xpartProperies.active = true;

        let data = {
            storageSequence: null,
            xpartProperties: xpartProperies
        }

        return data;
    }


    saveXpart = (e) => {
        (!this.storageLocationChangedFlag ? this.storageLocationNotChangedWarning() : this.save());
    }

    goToScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.goToStep('scan-step');
    }

    getXpatFormFromCurrentXpart() {

        if (!this.currentXpart) {
            return null;
        }

        let xpartProperties = {
            id: this.currentXpart.id,
            active: this.currentXpart.active,
            contextId: this.currentXpart.currentStatus.contextDTO.id,
            statusID: this.currentXpart.currentStatus.statusDTO.id,
            unitID: this.currentXpart.unit.id,
            weight: this.currentXpart.weight,
            xpartNumber: this.currentXpart.xpartNumber,
            quantity: this.currentXpart.quantity,
            descriptionDe: this.currentXpart.descriptionDe,
            descriptionEn: this.currentXpart.descriptionEn,
            storagePlace: this.currentXpart.storagePlace

        }

        return xpartProperties;
    }

    goToStorageScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.currentSoragePlace = null;
        this.goToStep('scan-storage-step');
    }




    async saveXpartInfo(data) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/receive', data);
    }

    save = () => {
        let xpartInfo = this.getXpartInfo();
        this.props.closeModal();
        //TRANSPORTATION
        xpartInfo.xpartProperties.contextId = 10;
        // ORDERED
        xpartInfo.xpartProperties.statusID = 2;
        xpartInfo.storageSequence = xpartInfo.xpartProperties.storagePlace.split('-');
        xpartInfo.files = [];
        let promise = this.saveXpartInfo(xpartInfo);


        promise.then(
            response => {
                this.clearCache();
                this.props.history.push('/Kommissionieren/KommissionierenTable/1/100');
            }
        ).catch(err => {
            console.log(err)
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    clearCache() {
        this.currentXpart = null;
        this.currentSoragePlace = null;
        this.currentGroup = null;
    }

    async saveStorageLocation(data) {
        return await axios.patch(API_XPART.XPART_SERVICE_URL + '/one/storage', data);
    }

    updateStorageLocation = () => {
        let newStorage = this.currentSoragePlace.split('-');
        this.storageLocationChangedFlag = true;

        var storagePlaceObj = {};
        var parentsArr = [];
        for (let i = newStorage.length - 2; i >= 0; i--) {
            var storageObj = {};
            storageObj["name"] = newStorage[i];
            parentsArr.push(JSON.parse(JSON.stringify(storageObj)));
        }
        storagePlaceObj["parents"] = parentsArr;
        storagePlaceObj["name"] = newStorage[newStorage.length - 1];
        this.currentXpart["storagePlace"] = storagePlaceObj;
        this.goToForm();
    }

    handleSaveStorage = () => {
        // this.toggleLoading(true);
        this.updateStorageLocation();
        this.props.closeModal();
    };

    undoStorage = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    };

    storageLocationNotChangedWarning() {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'STORAGE_NOT_CHANGED'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
            buttons: this.createWarningstorageLocationNotChangedButtons()
        });
    }

    createWarningstorageLocationNotChangedButtons() {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.props.closeModal,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: this.save,
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }

    createWarningAfterXpartScanButtons(xpart) {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.undoScan,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: () => this.accecptScannedXpart(xpart),
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }


}
