import React, { Component } from 'react'
import axios from 'axios';
import { API } from '../../Common/API/API';
import TableConfig from '../../List/tables-config.json';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { SupplierXpartKommissionierenControlButtonComponent } from './SupplierXpartKommissionierenControlButtonComponent';
import { PaginationComponent } from '../../List/PaginationComponent';
import { SelectableTableComponent } from '../../List/SelectableTableComponent';
import { LabelService } from '../../Common/Translations/LabelService';
import { FieldGenerator } from '../../Common/Helper/FieldGenerator';
import '../../List/list.css';
import '../../Project/Project.css';

import './../kommissionieren.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const Fields = new FieldGenerator();

export default class SupplierXpartKommissionieren extends Component {
    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.packagetypes = [];
        this.config = TableConfig['supplierXpartsKommissionieren'];
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.controlButtonsComponent = React.createRef();
    }

    state = {
        generalFilter: [],
        usesFilter: false,
        importCount: '',
        preFilter: [],
        holidayList: null,
        nextAvailabelDate: null,
        mode: 0
    }

    componentWillMount() {
        this.setState(this.state);
        this.getPackages();
    }

    // load packagetypes from database for generating a select-field
    getPackages() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/package/type')
            .then(response => {
                let packagetype = response['data'];
                for (var i = 0; i < packagetype.length; i++) {
                    let label = packagetype[i]['name'];
                    if (!LabelFile.packages[label]) {
                        packagetype[i]['name'] = label;
                    } else {
                        packagetype[i]['name'] = LabelFile.packages[label];
                    }
                }
                this.packagetypes = packagetype;
            })
            .catch(err => {
                console.log(err)
            });
    }


    updateTable(page, parts) {
        this.props.history.push('/Kommissionieren/supplier/KommissionierenTable/' + page + '/' + parts);
        //  this.refresh();
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    filterUpdate(data, filterFlag) {
        this.setState({
            generalFilter: data,
            usesFilter: filterFlag
        })
    }

    startMultiSelect() {
        this.tableComponent.current.startMultiSelect();
    }

    stopMultiSelect() {
        this.tableComponent.current.stopMultiSelect();
    }

    updateMultiSelectState(multiSelect) {
        this.controlButtonsComponent.current.updateMultiSelectState(multiSelect);
    }
    clearLocalSelection() {
        this.tableComponent.current.clearLocalSelection();
    }

    updateLoading(loading) {
        if (this.tableComponent.current) {
            this.tableComponent.current.updateLoading(loading);
        }
    }

    onConfirmSuccess() {
        localStorage.removeItem(this.config.localStoragePrefix);
        this.stopMultiSelect();
        this.refresh();
    }

    saveSupplierXpartGroupModel() {
        let xpartsNumbers = this.tableComponent.current.getSelectedIds();
        let netWeight;
        // getting total weight from the backend
        this.updateLoading(true)
        axios.post(API_XPART.XPART_SERVICE_URL + '/weight', xpartsNumbers)
            .then(res => {
                netWeight = res['data'];
                this.props.fireModal({
                    content: this.renderSaveDialog(),
                    header: LabelFile.texts["inputpackages"],
                    type: 'wide',
                    stickInOverlay: true
                })
            })
            .catch(err => {
                this.showException(err);
            })
            .finally(() => {
                this.updateLoading(false);
                document.getElementById('netweight').value = netWeight;
            }

            );

    }

    showAutomaticallyGeneratedNumber(packUnitNumber) {
        this.props.fireModal({
            content: this.renderAutomativallyGeneratedNumberDialog(packUnitNumber),
            header: LabelFile.labels["packing_unit_number"],
            type: 'wide',
            stickInOverlay: true
        })
    }

    saveSupplierXpartGroup() {

        if (!Fields.validateInputs()) {
            return;
        }

        const groupInfoDTO = {
            "active": true,
            "modeTypeId": 2,
            "typeNameId": 6,
            "validationCriteria": [
                "TRANSPORTATION_GROUP_VALIDATION_SUPPLIER"
            ]
        };

        const transportGroupMetaInfo = {
            "grossWeight": document.getElementById('grossweight').value,
            "height": document.getElementById('height').value,
            "length": document.getElementById('length').value,
            "netWeight": document.getElementById('netweight').value,
            "packUnitNumber": document.getElementById('packing_unit_number').value,
            "packageTypeId": document.getElementById('packagetype').value,
            "width": document.getElementById('width').value
        }

        const data = {
            groupInfoDTO: groupInfoDTO,
            transportGroupMetaInfo: transportGroupMetaInfo,
            xpartsNumbers: this.tableComponent.current.getSelectedIds(),
            targetContextId: 2,
            targetStatusId: 2
        }

        this.updateLoading(true)
        axios.post(API_XPART.XPART_SERVICE_URL + '/group/save', data)
            .then(res => {
                this.props.closeModal();
                this.onConfirmSuccess();
                this.showAutomaticallyGeneratedNumber(res.data.transportGroupMetaData.packUnitNumber)
            })
            .catch(err => {
                this.showException(err);
            })
            .finally(() => this.updateLoading(false)
            );

    }

    renderSaveDialog() {
        return (
            <div className="input-container newpackagemask" id="inputMain">
                <div className="infield field1">
                    {Fields.generateInputText("packing_unit_number", ".*", true, [this.warnings['required']], true, false, LabelFile.texts["numberWillBeAutomaticallyGenerate"])}
                </div>
                <div className="infield field2">
                    {Fields.generateInputNumber("netweight", "0", "1000000", ".001", false, [this.warnings['number']], this.state.mode)}
                </div>
                <div className="infield field3">
                    {Fields.generateInputNumber("grossweight", "0", "1000000", ".001", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field4">
                    {Fields.generateInputSelect("packagetype", this.packagetypes, true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field5">
                    {Fields.generateInputNumber("length", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field7">
                    {Fields.generateInputNumber("width", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field6">
                    {Fields.generateInputNumber("height", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.saveSupplierXpartGroup()}>
                        {LabelFile.labels["save"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }

    // modal to show the automatically generated Kollonummer
    renderAutomativallyGeneratedNumberDialog(packUnitNumber) {
        return (
            <div className="input-container newpackagemask" id="inputMain">
                {LabelFile.texts["automaticallyGeneratedPackUnitNumber1"]} {packUnitNumber} {LabelFile.texts["automaticallyGeneratedPackUnitNumber2"]}
                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}>
                        {LabelFile.labels["closeDialog"]}
                    </button>
                </div>
            </div>
        )
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }
    goToRequestedList() {
        /***
         * TODO:
         * Add URL link to the next page
         */
        this.props.history.push(this.config.requestedPageURL);
    }

    closeModal() {
        this.props.closeModal();
    }

    render() {
        return (
            <section className="results--section">

                <div className="header-column-wrapper">
                    <div className="header-column-left"></div>
                    <div className="header-column-middle">
                        <PageHeaderComponent text={this.config.pageHeader
                            ?
                            <h1>{LabelFile.header[this.config['pageHeader']]}</h1>
                            :
                            ''} >
                        </PageHeaderComponent>
                    </div>

                    <div className="header-column-pagination">
                        {<PaginationComponent ref={this.paginationComponent}
                            updateTable={(page, parts) => this.updateTable(page, parts)}
                            page={this.props.match.params.page}
                            parts={this.props.match.params.parts}
                            module={'supplierXpartsKommissionieren'}>
                        </PaginationComponent>}
                    </div>
                </div>

                <div className="list-container">
                    <SupplierXpartKommissionierenControlButtonComponent ref={this.controlButtonsComponent} saveSupplierXpartGroupModel={() => this.saveSupplierXpartGroupModel()}
                        startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
                        clearLocalSelection={() => { this.clearLocalSelection() }} goToRequestedList={() => { this.goToRequestedList() }}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        activeSelection={this.state.nextAvailabelDate && this.state.holidayList}>
                    </SupplierXpartKommissionierenControlButtonComponent>

                    <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={'supplierXpartsKommissionieren'}
                        usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        updateConfirmButton={(e) => this.updateConfirmButton(e)}></SelectableTableComponent>

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'partsAssemblyCommissions'} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </section>
        )
    }
}
