import React, { Component, Fragment } from 'react'
import './GroupXpart.css'
import axios from 'axios';
import { API } from '../../Common/API/API';
import { FieldGenerator } from '../../Common/Helper/FieldGenerator'
import { LabelService } from '../../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const Fields = new FieldGenerator();

export default class GroupXpartMenuComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      groupType: [],
      groupMode: []
    }
    this.warnings = LabelFile.warnings;
  }

  // react function: mount 
  componentDidMount() {

    // function: get data from Backend
    axios.get(API_XPART.XPART_SERVICE_URL + '/group/type')
      .then(response => {
        const type = response.data
        this.setState({ groupType: type })
      })

    // function: get data from Backend
    axios.get(API_XPART.XPART_SERVICE_URL + '/group/mode')
      .then(response => {
        const mode = response.data
        this.setState({ groupMode: mode })
      });
  }


  getSelectedMode() {
    return document.getElementById('select-mode').value;
  }

  getSelectedType() {
    return document.getElementById('select-type').value;
  }

  preFillData() {
    if (!this.props.currentGroup.id) {
      return;
    }

    document.getElementById('select-type').value = this.props.currentGroup.type.id;
    document.getElementById('select-mode').value = this.props.currentGroup.mode.id;
  }

  render() {
    return (
      <Fragment>
        <div className="edit-container">
          <div className="infield field3">
            {Fields.generateInputSelect("select-type", this.state.groupType, true, [this.warnings['required']], false,
              this.props.currentGroup.type ? this.props.currentGroup.type.id : null)}
          </div>
          <div className="infield field4">
            {Fields.generateInputSelect("select-mode", this.state.groupMode, true, [this.warnings['required']], false,
              this.props.currentGroup.mode ? this.props.currentGroup.mode.id : null)}
          </div>
        </div>
      </Fragment>
    )
  }
}
