import React, { Component } from 'react'
import axios from 'axios';
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import { API } from '../Common/API/API';
import { HelperFunctions } from '../Common/Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('de', de);

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class OrderExport extends Component {
    constructor(props) {
        super(props);
        let midnight = new Date();
        midnight.setHours(0, 0, 0, 0);
        this.state = {
            startDate: midnight,
            endDate: new Date(),
            startTime: midnight,
            endTime: new Date(),
            startTimeState: true,
            endTimeState: true,
            lastImportDate: '',
            lastExportDate: '',
            importCount: ''
        };
        this.initLastImportDate();
        this.initLastExportDate();
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    }

    handleExport() {
        let query = 'from=' + this.getWithTimeOrDateFrom().getTime() + '&to=' + this.getWithTimeOrDateTo().getTime();

        axios.get(API_XPART.XPART_SERVICE_URL + '/item/imported/count?' + query)
            .then(response => {
                this.setState({
                    importCount: response.data
                })
            }).then(() => {
                if (this.state.importCount) {
                    this.fireActiveExportModal();
                } else {
                    this.fireNotActiveExportModal();
                }
            })
    }

    fireActiveExportModal() {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'EXPORT_QUESTION', this.state.importCount),
            header: NOTIFICATIONS.getHeaderDescription('de', 'INFO', 'EXPORT_ITEMS_INFO'),
            buttons: this.createWarningStatusModalButtons()
        });
    }

    fireNotActiveExportModal() {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'NO_EXPORT_ITEM_DATA'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'INFO', 'EXPORT_ITEMS_INFO'),
            buttons: this.createOKModalButtons()
        });
    }

    initLastImportDate() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/item/imported/max')
            .then(response => {
                let lastImportDate = '';
                if (response.data) {
                    lastImportDate = HelperFunction.timeConverter(response.data);
                }
                this.setState({
                    lastImportDate: lastImportDate
                })
            })
    }

    initLastExportDate() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/item/exported/last')
            .then(response => {
                let lastExportDate = '';
                if (response.data) {
                    lastExportDate = HelperFunction.timeConverter(response.data);
                    this.setState({
                        startDate: new Date(response.data),
                        startTime: new Date(response.data)
                    })
                }

                this.setState({
                    lastExportDate: lastExportDate
                })
            });
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    createWarningStatusModalButtons() {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.props.closeModal,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: this.handleWarningStatusYesButton,
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }

    getFileName(contentDispositionValue) {
        var filename = "";
        if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDispositionValue);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    handleWarningStatusYesButton = () => {
        let query = 'from=' + this.getWithTimeOrDateFrom().getTime() + '&to=' + this.getWithTimeOrDateTo().getTime();
        axios.get(API_XPART.FILE_SERVICE_URL + '/export/imported/items?' + query, {
            responseType: 'blob'
        })
            .then(response => {
                this.showFile(response.data, this.getFileName(response.headers['content-disposition']));
                this.props.closeModal();
            }).then(() => {
                let currentDate = new Date();
                this.setState({
                    endDate: currentDate,
                    startDate: currentDate,
                    endTime: currentDate,
                    startTime: currentDate,
                    lastExportDate: HelperFunction.timeConverter(currentDate.getTime())
                })
            }
            )
    }

    handleStartDateChange(date) {
        this.setState({
            startDate: date
        });
    }
    handleEndDateChange(date) {
        this.setState({
            endDate: date,
        });
    }


    handleStartTimeChange(date) {
        this.setState({
            startTime: date
        });
    }
    handleEndTimeChange(date) {
        this.setState({
            endTime: date,
        });
    }

    getWithTimeOrDateFrom() {

        let responseDate = new Date(this.state.startDate.getTime());
        responseDate.setHours(0, 0, 0, 0);

        if (!this.state.startTimeState) {
            return responseDate;
        }

        this.copyTime(this.state.startTime, responseDate);
        return responseDate;
    }

    copyTime(dateFromCopy, dateToCopy) {
        dateToCopy.setHours(dateFromCopy.getHours());
        dateToCopy.setMinutes(dateFromCopy.getMinutes());
        dateToCopy.setSeconds(dateFromCopy.getSeconds());
        dateToCopy.setMilliseconds(dateFromCopy.getMilliseconds());
    }

    getWithTimeOrDateTo() {
        let responseDate = new Date(this.state.endDate.getTime());

        if (!this.state.endTimeState) {
            responseDate.setDate(responseDate.getDate() + 1);
            responseDate.setHours(0, 0, 0, 0);
            return responseDate;
        }

        this.copyTime(this.state.endTime, responseDate);

        return responseDate;
    }

    toggle(id) {
        if (id === "time_from") {
            document.getElementById('time_from').classList.toggle('time_from_inaktiv');
            this.setState({
                startTimeState: !this.state.startTimeState
            })
        }

        if (id === "time_to") {
            document.getElementById('time_to').classList.toggle('time_to_inaktiv');
            this.setState({
                endTimeState: !this.state.endTimeState
            })
        }
    }

    showFile(data, fileName) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    renderDateTimeContainer() {
        return (
            <div className="form-export">
                <div>
                    <label>{LabelFile.labels["date_from"]}</label>
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        locale="de"
                        dateFormat="dd.MM.yyyy"
                        id="date_from"
                    />
                </div>
                <div>
                    <label>{LabelFile.labels["time_from"]}</label>
                    <input type="checkbox" defaultChecked={true} id={"checkbox_time_from"} onChange={(event) => this.toggle("time_from")}></input>
                    <DatePicker
                        selected={this.state.startTime}
                        onChange={this.handleStartTimeChange}
                        locale="de"
                        id="time_from"
                        className="xpm-input-with-checkbox"
                        showTimeSelect="false"
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeCaption="Uhrzeit"
                        readOnly={!this.state.startTimeState}
                    />

                </div>
                <div>
                    <label>{LabelFile.labels["date_until"]}</label>
                    <DatePicker
                        selected={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        locale="de"
                        dateFormat="dd.MM.yyyy"
                        id="date_to"
                    />
                </div>
                <div>
                    <label>{LabelFile.labels["time_until"]}</label>
                    <input type="checkbox" defaultChecked={true} id={"checkbox_time_to"} onChange={(event) => this.toggle("time_to")}></input>
                    <DatePicker
                        selected={this.state.endTime}
                        onChange={this.handleEndTimeChange}
                        locale="de"
                        id="time_to"
                        className="xpm-input-with-checkbox"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        timeCaption="Uhrzeit"
                        readOnly={!this.state.endTimeState}
                    />

                </div>
            </div>
        )
    }

    render() {
        return (
            <section className="results--section">
                <section className="results--section xpartform">
                    <h1>{LabelFile.labels['orderexport']}</h1>
                    <div className="header-underline-img"><span class="header-underline-export"><FontAwesomeIcon icon={['fas', 'download']} /></span></div>
                    <hr className="header-underline"></hr>

                    <div className="input-container" id="inputMain">

                        {this.renderDateTimeContainer()}

                        <div className="xpm-button-container">
                            <button type="button"
                                className="xpm-button xpm-button-export"
                                onClick={(event) => this.handleExport(event)}>{LabelFile.labels['export']}</button>
                        </div>
                        <div>

                            <div>
                                <span className="infoLastDates">{LabelFile.labels['lastImportDate']}</span>: {HelperFunction.timeConverter(this.state.lastImportDate, 'de-DE')}

                            </div>
                            <div>
                                <span className="infoLastDates">{LabelFile.labels['lastExportDate']}</span>: {HelperFunction.timeConverter(this.state.lastExportDate, 'de-DE')}
                            </div>
                        </div>
                    </div>



                </section>
            </section>
        )
    }
}

export default OrderExport;