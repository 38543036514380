import React, { Component } from 'react';
import { LabelService } from '../../Common/Translations/LabelService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './confirmation-button.css'

const LabelFile = LabelService.getLabelsByLocal('de');
export class ConfirmationButton extends Component {

    state = this.getInitialState(this.props);

    getInitialState(props) {
        return {
            content: this.isMobile() ? props.mobileStateContent : props.compressedStateContent,
            expandedStateContent: props.expandedStateContent,
            compressedStateContent: props.compressedStateContent
        }
    }

    isButtonExpanded = false;
    isButtonMobile = this.isMobile();

    onResize = () => this.toggleView()

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    componentWillReceiveProps(props) {
        this.setState(this.getInitialState(props));
    }

    isMobile() {
        return window.innerWidth <= 768;
    }

    toggleView() {
        if (this.isMobile() && !this.isButtonMobile) {
            this.isButtonMobile = true;
            this.setState({
                content: this.props.mobileStateContent
            })
        } else {
            if (!this.isMobile() && this.isButtonMobile) {
                this.isButtonMobile = false;
                this.setState({
                    content: this.isButtonExpanded
                        ? this.props.expandedStateContent
                        : this.props.compressedStateContent
                })
            }
        }
    }

    resizeButton(event) {
        this.isButtonExpanded = !this.isButtonExpanded

        if (!this.isMobile()) {
            this.setState({
                content: this.isButtonExpanded
                    ? this.props.expandedStateContent
                    : this.props.compressedStateContent
            });
        }
    }

    render() {


        return (
            <button onClick={() => { this.props.onClick() }}
                className={"xpm-button button-info "}>
                <div className="button-info-line-top">{this.state.content}</div>
                {!this.props.dynamic ?
                    <div className="button-info-line-bottom">
                        <FontAwesomeIcon icon={['fas', 'save']} />&nbsp;{LabelFile.labels["selection"]}&nbsp;{!this.props.saveLabel?LabelFile.labels["save"]:LabelFile.labels[this.props.saveLabel]}
                    </div> :
                    <div className="button-info-line-bottom">
                        <FontAwesomeIcon icon={['fas', this.props.icon]} />&nbsp;&nbsp;{LabelFile.labels[this.props.label]}
                    </div>
                }


            </button>
        )
    }
}