import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './Modal.css'

 export class ModalButton extends Component {

    propTypes = {
        customClass: PropTypes.string,
        text: PropTypes.string,
        callback: PropTypes.func,
    };

    defaultProps = {
        customClass: '',
        text: '',
        callback: () => (false)
    };


    render() {
        return (<div>
            <button className={`button ${this.props.customClass}`} onClick={this.props.callback}>
                {this.props.text}
            </button>
        </div>
        );
    }
}

export default ModalButton;
