import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import axios from 'axios'
import { API } from '../../Common/API/API'
import { APINotifications } from '../../Common/API/APINotifications'
import { withRouter } from 'react-router'
import { HelperFunctions } from '../../Common/Helper/Helper';
import { LabelService } from '../../Common/Translations/LabelService'
import './ScanStyle.css'
import { CameraSwitcherComponent } from '../../Camera/camera-switcher-component'
import { Constants } from '../../Common/Helper/Constants';

const API_XPART = new API()
const NOTIFICATIONS = new APINotifications()
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class Scan extends Component {
  // --- props: fetched from previous site
  state = { ...this.props.initialState[0] }
  cameraSwitcherComponentRef = React.createRef();
  lastInputValue = '';

  createOKModalButtons() {
    var btns = []
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    }

    btns.push(obj)

    return btns
  }

  componentDidMount(){

    if(this.props.setRef){
      this.props.setRef(this)
    }
  }

  // --- function: check if xpart is in database avaible
  checkIfXpartIsAvailable = xpartNumber => {

    if (this.props.postScan) {
      this.props.postScan(xpartNumber);
      return;
    }
    // --- database request
    axios
      .get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + xpartNumber)
      .then(res => {
        if (this.state.status.context !== null) {
          this.props.history.push(this.state.status.linkUrl + this.state.cam.result + '/' + this.state.status.context);

        } else {
          this.props.history.push(this.state.status.linkUrl + this.state.cam.result + this.state.status.linkPermission + HelperFunction.getContextOrNot(this.props.match.params.context))

        }
        // --- link: route to next site: optional with permission or context
      })
      .catch(error => {
        console.error(error)
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
          buttons: this.createOKModalButtons()
        })
        this.reset();
      })
  }

  reset() {
    document.getElementById("text-input-after-scan").value = "";
    this.lastInputValue = "";
    this.setState({
      cam: {
        result: this.lastInputValue
      },
      button: {
        view: false,
        alternative: true,
        value: this.lastInputValue
      }
    });
    this.focusOnInputField();
  }

  // --- function: fetch text input from formfield
  fetchInput = inputData => {
    if (inputData) {
      let trimmed = inputData.target.value.trim();
      this.setState({
        cam: {
          result: trimmed
        },
        button: {
          view: true,
          alternative: true,
          value: trimmed + ' ' + LabelFile.labels['confirm']
        }
      })

      if (trimmed.length > this.lastInputValue.length + 5) {
        this.checkIfXpartIsAvailable(trimmed)
      }

      this.lastInputValue = trimmed;

    }
  }

  focusOnInputField = () => {
    if (document.getElementById('text-input-after-scan')) {
      document.getElementById("text-input-after-scan").focus();
    }
  }

  onCameraSelected = () => {
    this.focusOnInputField();
  }

  // --- function: fetch scan by camera
  handleScan = data => {
    if (data) {
      this.setState({
        cam: {
          result: data
        },
        button: {
          view: true,
          alternative: true,
          value: data + ' ' + LabelFile.labels['confirm']
        }
      })
      this.checkIfXpartIsAvailable(data)
    }
  }

  // --- function: handle error from camera
  handleError = err => {
    console.error(err)
  }

  keyPress(e) {
    e.stopPropagation();

    if (e.keyCode === 13) {
      this.checkIfXpartIsAvailable(this.state.cam.result)
    }
    e.stopPropagation();
  }

  onQrLoad() {
    if (this.cameraSwitcherComponentRef.current) {
      this.cameraSwitcherComponentRef.current.initSelectedCamera();
    }
    this.focusOnInputField();
  }

  componentWillUnmount() {
    if (this.cameraSwitcherComponentRef.current) {
      this.cameraSwitcherComponentRef.current.stopVideo();
    }
  }

  killCamera(){
    if (this.cameraSwitcherComponentRef.current) {
      this.cameraSwitcherComponentRef.current.stopVideo();
    }
  }

  render() {
    return (
      <section className="scan-container">
        <div className="scan-container-cam">
          <QrReader onLoad={() => this.onQrLoad()} delay={300} onError={this.handleError} onScan={this.handleScan} style={{ width: '100%' }} />
        </div>
        {/* --- ScanTitle */}
        {this.state.title.view ? (
          <div className="scan-container-title">
            <div className="scan-title-properties">{this.state.title.name}</div>
          </div>
        ) : (
            ''
          )}

        {/* --- ScanForm */}
        {this.state.form.view ? (
          <div className={this.state.form.alternative ? 'scan-container-form-alternativ' : 'scan-container-form'}>

            <input onChange={this.onInputFieldChange} id='text-input-after-scan' type="text" placeholder={this.state.cam.result} onInput={this.fetchInput} className="scan-form-properties" onKeyDown={this.keyPress.bind(this)} />

          </div>
        ) : (
            ''
          )}
        {/* --- ScanButton */}
        {this.state.button.view ? (
          <div className={this.state.button.alternative ? 'scan-container-button-alternative' : 'scan-container-button'}>
            <button type="button" className="scan-button-properties xpm-button" onClick={() => this.checkIfXpartIsAvailable(this.state.cam.result)}>
              {this.state.button.value}
            </button>
          </div>
        ) : (
            ''
          )}
        <div className="scan-container-button-alternative">
          <CameraSwitcherComponent preselectedCameraPrefix={Constants.SELECTED_CAMERA_SCAN_PREFIX}
            ref={this.cameraSwitcherComponentRef}
            onCameraSelected={() => this.onCameraSelected()} ></CameraSwitcherComponent>
        </div>
      </section>
    )
  }
}

export default withRouter(Scan)
