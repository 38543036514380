/* A Helper Class for commonly used functions */

import { LabelTranslations } from '../Translations/Labels';
import axios from 'axios';
import printJS from 'print-js';


const Labels = new LabelTranslations();

export class HelperFunctions{

    /**
     * gets the current user info.
     * @return {UserInfoObject} the language COde, e.g. "de-DE" for german
     */
    getCurrentUser(){
        return JSON.parse(localStorage.getItem('xpm-user-info'));
    }

    /**
     * gets the Browserlocale. Hint: in Chrome it's the OS locale.
     * @return {string} the language COde, e.g. "de-DE" for german
     */
    getLocale() {
        let lang = navigator.language || navigator.userLanguage;
        return  lang ? lang : 'de';
    }

    /**
     * Formats Float values according to a given locale
     * @param {number} numberToFormat, a float value
     * @param {string} lang, a language string, e.g. "de-DE" for german
     * @return {string} the formatted number as string
     */
    formatFloats(numberToFormat, lang){
        if (lang ==="de-DE" || lang ==="de"){
            return numberToFormat.toString().replace(',','#').replace('.',',').replace('#','.');
        } else {
            return numberToFormat.toString();
        }
    }

    //accepts a UNIX timestamp and returns a formatted datestring
    /**
     * converts a UNIX timestamp to a readable date in the chosen locale
     * @param {number} UNIX_timestamp
     * @param {string} lang, a language string, e.g. "de-DE" for german
     * @return {string} the formatted date as string
     */
    timeConverter(UNIX_timestamp, lang, short = false){
        var a = new Date(UNIX_timestamp);
        var year = this.formatTimeDigits(a.getFullYear());
        var month = this.formatTimeDigits(a.getMonth()+1);
        var date = this.formatTimeDigits(a.getDate());
        var hour = this.formatTimeDigits(a.getHours());
        var min = this.formatTimeDigits(a.getMinutes());
        var sec = this.formatTimeDigits(a.getSeconds());

        if (lang ==="de-DE" || lang ==="de"){
            if (!short){
                return date + '.' + month + '.' + year + ' ' + hour + ':' + min + ':' + sec ;
            } else {
                return date + '.' + month + '.' + year;
            }

        }
        if (!short){
            return year + '-' + month + '-' + date + ' ' + hour + ':' + min + ':' + sec ;
        } else {
            return year + '-' + month + '-' + date;
        }
    }

    /**
     * adds a leading 0 to 1-digit numbers
     * @param {number} number, should be integer
     * @return {string} the formatted number as string
     */
    formatTimeDigits(number){
        var result = "";
        if (number <= 9){
            result = "0"+number.toString();
        } else {
            result = number.toString();
        }
        return result;
    }

    /**
     * creates a string of values from selected fields
     * @param {{}} item object with values
     * @param {string[]} fields array of field names (order is important)
     * @param {string} separator separator between values
     * @return {string} formatted string of values
     */
    getItemValueView(item, fields, separator = ':') {
        let result = "";
        let fieldsValues = [];

        fields.forEach(fieldName => {
            fieldsValues.push(
                item[fieldName] in Labels.spec_projects
                    ? `${Labels.spec_projects[item[fieldName]]}`
                    : `${item[fieldName]}`
            );
        });

        fieldsValues.forEach(value => {
            if (result.length !== 0 && value) {
                result += ` ${separator} `;
            }
            result += value;
        });

        return result;
    }

    /**
     * returns the url context parameter or njust empty string.
     * @param {string} context separator between values
     * @return {string} context url parameter
     */
    getContextOrNot(context) {
        return context ? '/' + context : '';
    }
    /**
     * Generates the PDF printable File for Xparts
     */

    async getPdfDocument(docUrl) {
        return await  axios.get(docUrl, {
            responseType: 'blob'
        })
            .then(this.getOnPDFLabelsFulfilled())
            .then(this.showFile.bind(this))
            .catch(this.getOnPDFLabelsRejected())
    }

    async getMultiPdfDocument(docUrl, payload, clearNotifications) {
       return await  axios.post(docUrl, payload, {
           responseType: 'blob'
       })
           .then(this.getOnPDFLabelsFulfilled(clearNotifications))
           .then(this.showFile.bind(this))
           .catch(this.getOnPDFLabelsRejected)
   }

    getOnPDFLabelsFulfilled(onPrintFinishedHandler) {
        return function (response) {
            const {status, data} = response
            if (status !== 200) {
                throw Error(response.statusText);
            }
            return({
                blob: data,
                onPrintFinishedHandler
            })
        };
    }

    getOnPDFLabelsRejected() {
        return function (err) {
            document.getElementsByTagName('body')[0].classList.remove("soft-progress");
            alert('Error getting the pdf file!')
            console.log(err);
        };
    }

    showFile({ blob, onPrintFinishedHandler }) {
        console.log('blob', blob)
        console.log('onPrintFinishedHandler', onPrintFinishedHandler)

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" });

        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, 'xpm_' + this.state.xpartId + '.pdf');
            return;
        }
        let handleFinishedPrinting = (res => {
            document.getElementsByTagName('body')[0].classList.remove("soft-progress");
            if (typeof onPrintFinishedHandler === 'function') {
                onPrintFinishedHandler()
            }
        })

        const url = window.URL.createObjectURL(newBlob);
        printJS({
            printable:url,
            type:'pdf',
            onPrintDialogClose: handleFinishedPrinting,
            onPdfOpen: handleFinishedPrinting
        });
    }

}
