import axios from "axios";

axios.interceptors.request.use(
  request => {
    let userData = JSON.parse(localStorage.getItem("xpm-user-info"));
    if (userData && userData.token) {
      request.headers.Authorization = `Bearer ${userData.token}`;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && 401 === error.response.status &&
    ((error.response.data === null) || error.response.data.message == null || error.response.data.message !== 'BAD_CREDENTIALS' )) {
    localStorage.removeItem('xpm-user-info');
    window.location = "/";
  }

  return Promise.reject(error);
});
