import React, { Component } from 'react';
// import ReactDOMServer from 'react-dom/server'
import { APINotifications } from '../../Common/API/APINotifications';
import './SwitchToggle.css';
import { DataParsingService } from '../DataParsingService';

const NOTIFICATIONS = new APINotifications();
export default class SwitchToggleComponent extends Component {

  constructor(props) {
    super(props);
    this.parsing = new DataParsingService(this.props.fireModal, this.props.closeModal);
  }

  state = {
    checked: false
  }

  componentDidMount() {
    this.setState({ checked: this.props.checked })
  }

  componentWillReceiveProps(newProps) {
    if (this.props.checked !== newProps.checked) {
      this.setState({ checked: newProps.checked })
    }
  }


  onClick(e) {
    e.stopPropagation();

    let obj = {
      objectId: [this.props.objectId],
      targetStatus: !this.state.checked,
      rowData:[this.props.rowData] //rowdata props
    };

    this.props.handleToggle(obj).then(res => {
      if (this.props.postChange) {
        this.props.postChange(res, this.props.representFieldId);
      }
      this.forceUpdate();

      // if (this.props.representFieldId) {
      //   let field = document.getElementById(this.props.representFieldId + this.props.objectId);

      //   const htmlString = ReactDOMServer.renderToStaticMarkup(
      //     this.parsing.mapTruth(!this.state.checked, null, null, this.props.representFieldId + this.props.objectId)
      //   );
      //   field.innerHTML = htmlString;
      // }

      this.setState({ checked: !this.state.checked })
    }).catch(ex => {
      this.props.fireModal({
        content: NOTIFICATIONS.getErrorDescription('de', 'COMMON_ERROR'),
        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
        buttons: this.createOKModalButtons()
      });
    })

  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    };

    btns.push(obj);
    return btns;
  }


  render() {
    return (
      <div className="switchtoggle-wrapper">
        <input
          checked={this.state.checked}
          onClick={(e) => { this.onClick(e) }}
          className="react-switch-checkbox "
          id={this.props.id}
          type="checkbox"
          disabled={this.props.disabled}
        />
        <label
          style={{ background: this.state.checked && '#06D6A0' }}
          className={"react-switch-label toggle-properties " + (this.props.disabled ? "toggle-disabled" : "")}
          htmlFor={this.props.id}
        >
          <span className={`react-switch-button`} />
        </label>
      </div>
    );
  }

}
