import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { API } from '../Common/API/API'
import { LabelService } from '../Common/Translations/LabelService'
import { Loader } from '../Common/Components/Loader/loader'
import './Auth.css'

const LabelFile = LabelService.getLabelsByLocal('de')
const GLOBAL_API = new API();

const AUTH_DATA = [
  {
    token: 'zl2svtcybin1njxf576',
    userName: 'admin@test.de',
    password: 'admin123'
  },
  {
    token: '7j978qsdz8tixoizwa9',
    userName: 'kme',
    password: 'KmB007'
  },
  {
    token: 'rgrkb2pqt6fi29t47fe',
    userName: 'kme-technikum',
    password: 'technikum11'
  },
  {
    token: 'jr8dirifkfirkghe44u1',
    userName: 'ikn',
    password: 'IkN@474'
  },
  {
    token: 'nbjd2u32u7njt4be8nin',
    userName: 'kme-supplier',
    password: 'KmBSup3'
  },
  {
    token: 'jsaiuai8jak02ksj1177',
    userName: 'ikn-supplier',
    password: 'suppIkn17'
  },
  {
    token: 'jwuv87ai4h4ja7adjaoj',
    userName: 'importer',
    password: 'Imp0R#r7'
  },
  {
    token: 'ki!3jq12hhqwejslkja',
    userName: 'kb-evs',
    password: 'KbEvs78#1'
  },
  {
    token: 'uwhakaslksk87u3jkaio',
    userName: 'pm-kme',
    password: 'pmKME363!'
  }
]

export class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isResponseReceived: false,
      emailInput: '',
      passwordInput: '',
      isLoading: false,
      pushvalue: false,
      loginToken: false
    }

    this.loginButton = this.loginButton.bind();
    this.tokenLong = false;
  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);
    return btns;
  }

  login() {
    let token = this.props.match.params.token;
    let loginData = AUTH_DATA.find((data) => data.token === token)
    if (loginData) {
      this.tokenLong = true;
      this.getLoginUserInfo(loginData.userName, loginData.password)
    }
  }

  componentWillMount() {
    localStorage.removeItem('xpm-user-info');
    this.login();
  }

  // on component mount set state with Original URL if it exists else redirect to home 
  componentDidMount() {

    this.props.updateMenuAndUserDesplay(false, false);

    let prevLocation = this.props.location.prevLocation !== undefined ? this.props.location.prevLocation['URL'] : '/';

    this.setState(
      {
        prevLocation: prevLocation
      }
    )
  }

  getLoginUserInfo(email, password) {
    axios.post(GLOBAL_API.AUTH_SERVICE_URL + '/login', { userName: email, password: password })
      .then(response => {
        // handle success
        localStorage.setItem('xpm-user-info', JSON.stringify(response.data));
        this.setState({
          pushvalue: true
        });
        this.props.onCurrentUserUpdate(response.data);
      })
      .catch(error => {
        let content = error.response.data.message !== null ? error.response.data.message : error.response.data.errors[0];

        // handle error
        this.props.fireModal({
          content: LabelFile.warnings[content],
          header: LabelFile.header['ERROR'],
          buttons: this.createOKModalButtons()
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });

        this.props.history.push(this.state.prevLocation);
        this.props.updateMenuAndUserDesplay(true, true);

      })
  }

  // function: fetch the typed email input and put it into state
  emailInput = (data) => {
    this.setState({ emailInput: data.target.value })
  }

  // function: fetch the typed password input and put it into state
  passwordInput = (data) => {
    this.setState({ passwordInput: data.target.value })
  }

  // function: send email and password to backend
  loginButton = (email, password) => {
    // trims the empty space before and after string
    const emailTrimed = email.trim()
    const passwordTrimes = password.trim()

    this.setState({
      isLoading: true
    });
    if ((emailTrimed.length > 0 && passwordTrimes.length > 0) || this.tokenLong === true) {
      this.getLoginUserInfo(emailTrimed, passwordTrimes)
    }
  }

  handleClick() {
    this.loginButton(this.state.emailInput, this.state.passwordInput)
  }

  // simulates ENTER-taste
  keyPress(e) {

    e.stopPropagation();

    if (e.keyCode === 13) {
      this.handleClick();
    }
    e.stopPropagation();
  }

  linkToResetPasswordPage = () => {
    this.props.history.push('/resetpassword')
  }

  // function: view loginfield
  LoginForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['emailAddress']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="text" id="username" onChange={this.emailInput} className="form-input-properties" />
        </div>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['password']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="password" id="password" onChange={this.passwordInput} className="form-input-properties" />
        </div>
      </Fragment>
    )
  }

  render() {

    return (
      <section className="loginuser-container">
        {!this.tokenLong ?

          <div className="loginuser-form-container">
            <div className="form-full">
              <h1>{LabelFile.header['LOGIN_USER']}</h1>
            </div>
            <this.LoginForm />
            <div className="form-full">
              <button type="button" onKeyDown={this.keyPress.bind(this)} onClick={() => this.loginButton(this.state.emailInput, this.state.passwordInput)} className="createuser-button-properties">
                {LabelFile.labels['login']}
              </button>
            </div>
            <div className="form-full">
              <div onClick={() => this.linkToResetPasswordPage()} className="form-forgetpassword-alignment">{LabelFile.labels['passwordForgotten']}</div>
            </div>
          </div> : ''
        }

        {this.state.isLoading ? <Loader /> : ''}
      </section>
    )
  }
}