

import React, { Component } from 'react'
import '../../Xpart/Xpart.css'
import '../../styles/common.css'
import { TableComponent } from '../../List/TableComponent';
import TableConfig from '../../List/tables-config.json';
import { LabelService } from '../../Common/Translations/LabelService';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';


const LabelFile = LabelService.getLabelsByLocal('de')


export default class EvsReceiveGroupTableCheckComponent extends Component {

    module = 'completionCheckTable';

    constructor(props) {
        super(props);
        this.config = TableConfig[this.module];
        this.tableComponent = React.createRef();
    }

    state = {
        groupId: null,
        completedAll: null
    }

    setGroupId(groupId) {
        this.setState({ groupId: groupId });
    }

    componentWillMount() {

        if (this.props.setRef) {
            this.props.setRef(this);
        }
    }


    checkTruth = (sum, el) => {
        return el.completionGroup.completed && sum;
    }

    isCompleted(xpartList) {
        return xpartList.reduce(this.checkTruth, true);
    }

    setCompletion(xparts) {
        let completed = null;
        if (xparts.length > 0) {
            completed = this.isCompleted(xparts);
        }
        if (this.state.completedAll !== completed) {
            this.setState({ completedAll: completed });
        }
    }


    showButtonCompletion() {

        if (this.state.completedAll === null) {
            return '';
        } else if (this.state.completedAll) {
            return (
                <button onClick={()=>this.props.goToStorageScan()}
                    id="next-button" className={"xpm-button"}>{LabelFile.labels['done']}</button>)
        }
        return (
            <button onClick={()=>this.props.goToScan()}
                id="next-button" className={"xpm-button"}>{LabelFile.labels['forward']}</button>)

    }

    render() {
        return (
            <section className="results--section">
                {/* page header */}
                <PageHeaderComponent
                    text={<h1>{LabelFile.labels['XPARTGROUPSCAN']}</h1>}
                />
                <div className="optionalButtons xpm-button-container">
                    <div className="button-alignment">
                        <button onClick={() => { this.props.goToForm() }}
                            id="back-button" className={"xpm-button"}>{LabelFile.labels['back']}</button>

                        {this.showButtonCompletion()}
                    </div>
                </div>
                {this.state.groupId ?
                    <div className="list-container">
                        <TableComponent
                            ref={this.tableComponent}
                            module={this.module}
                            preFilter={false}
                            fireModal={this.props.fireModal}
                            closeModal={this.props.closeModal}
                            groupId={this.state.groupId}
                            afterFetch={(e) => this.setCompletion(e)}
                        />
                    </div>
                    : ''}
                <div id="popupContainer"></div>
            </section>
        )
    }

}
