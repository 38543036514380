import React from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { FieldGenerator } from '../Common/Helper/FieldGenerator';
import { Loader } from '../Common/Components/Loader/loader';
import { ConfirmationButton } from '../Common/ConfirmationButton/confirmation-button';
import { NewPackageInputMask } from './NewPackageInputMask';
import { MultiSelectComponent } from '../Common/MultiSelectComponent';
import './Project.css';
import './ProjectTest.css'

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();

/**
 * This is the second level page for viewing and selecting xparts related to a Project to create a package, where the user is navigated to after entering a project number at first level (i.e.Project.js). 
 */
class ProjectXpartPackage extends MultiSelectComponent {
    constructor(props) {
        super(props);
        this.newpackageinputmask = React.createRef();
        this.editMode = false;
        this.resultlistarr = [];
        this.packagetype = [];
        this.projectlistarr = [];
        this.xpartsToAdd = [];
        this.xpartsToRemove = [];
        this.projectnumber = this.props.match.params.projectnr;
        if (this.props.location.state !== undefined) {
            this.editMode = this.props.location.state['editMode'];
            if (this.props.location.state['packageNumber'] !== undefined) {
                this.packageNumber = this.props.location.state['packageNumber'];
            }
        }
        // Multiselect event handler
        this.multiSelectEvent = (e) => {
            e.stopPropagation();

            this.handleMultiSelectEvent(this.packageNumber, this.projectnumber, e, this.state.resultlistselected);
        }
        this.multiSelectEventHandler = this.multiSelectEvent.bind(this);
    }

    state = {
        projectlist: [],
        isLoading: true,
        resultlistselected: [],
        multiSelectHide: "",
        multiSelectShow: "xpm-hidden",
        multiSelectSelected: 0,
        selectMode: false,
        packagetypes: [],
        packageID: ""
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);

        return btns;
    }

    // check for previous selected and add selection class to them so that they appear selected when screen is loaded
    prevSelected(data, selectedlist) {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < selectedlist.length; j++) {
                if (data[i]['id'] === JSON.parse(selectedlist[j])) {
                    data[i]["class-selected"] = "multiselect-selected";
                }
            }
            if (!data[i]['class-selected']) {
                data[i]["class-selected"] = "";
            }
        }
        return data;
    }

    getIds(data) {
        let prevId = [];
        for (let i = 0; i < data.length; i++) {
            prevId.push(JSON.stringify(data[i]['id']));
        }
        return prevId;
    }

    // function to get old assigned xparts
    getOldXparts() {

        axios.get(API_XPART.XPART_SERVICE_URL + '/package/inpackage?packageNumber=' + this.packageNumber)
            .then(response => {
                this.resultlistarr = this.getIds(response['data']);
                this.setState({
                    resultlistselected: this.resultlistarr,
                })
                localStorage.setItem(this.packageNumber, JSON.stringify(this.resultlistarr))
                this.prevXparts = [...this.resultlistarr];

            }).catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
    }

    // Load Data, while loading data it checks for editmode flag upon which it fires corresponding API request  

    loadItems() {
        if (this.editMode) {
            this.getOldXparts();
            //Editmode: axios request fetches all xparts belonging to the project
            axios.get(API_XPART.XPART_SERVICE_URL + '/package/edit?packageNumber=' + this.packageNumber)
                .then(response => {
                    this.resultlistarr = response['data'];
                    let z = 0;
                    for (let x = 0; x < this.resultlistarr.length; x++) {
                        for (let y = 0; y < this.resultlistarr[x]['itemOrders'].length; y++) {
                            if (this.resultlistarr[x]['itemOrders'][y]['orderType']['name'] === 'PROJECT') {
                                this.projectlistarr[z] = {};
                                this.projectlistarr[z]['projectnumber'] = this.resultlistarr[x]['itemOrders'][y]['orderNumber'];
                                this.projectlistarr[z]['position'] = this.resultlistarr[x]['itemOrders'][y]['itemNumber'];
                                this.projectlistarr[z]['xpartNumber'] = this.resultlistarr[x]['xpartNumber'];
                                this.projectlistarr[z]['descriptionDe'] = this.resultlistarr[x]['descriptionDe'];
                                this.projectlistarr[z]['id'] = this.resultlistarr[x]['id'];
                                z++;
                            }
                        }
                    }
                    this.projectlistarr = this.prevSelected(this.projectlistarr, this.prevXparts);


                    this.setState({
                        projectlist: this.projectlistarr,
                        multiSelectSelected: this.state.resultlistselected.length
                    })
                })
                .catch(err => {
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
                .finally(() => this.setState({
                    isLoading: false
                }));
        }
        else {
            axios.get(API_XPART.XPART_SERVICE_URL + '/package/xparts/?orderNumber=' + this.projectnumber + '&orderType=2')
                .then(response => {
                    this.resultlistarr = response['data'];
                    let z = 0;
                    for (let x = 0; x < this.resultlistarr.length; x++) {
                        for (let y = 0; y < this.resultlistarr[x]['itemOrders'].length; y++) {
                            if (this.resultlistarr[x]['itemOrders'][y]['orderType']['name'] === 'PROJECT') {
                                this.projectlistarr[z] = {};
                                this.projectlistarr[z]['projectnumber'] = this.resultlistarr[x]['itemOrders'][y]['orderNumber'];
                                this.projectlistarr[z]['position'] = this.resultlistarr[x]['itemOrders'][y]['itemNumber'];
                                this.projectlistarr[z]['xpartNumber'] = this.resultlistarr[x]['xpartNumber'];
                                this.projectlistarr[z]['descriptionDe'] = this.resultlistarr[x]['descriptionDe'];
                                this.projectlistarr[z]['id'] = this.resultlistarr[x]['id'];
                                z++;
                            }
                        }
                    }
                    this.projectlistarr = this.prevSelected(this.projectlistarr, this.state.resultlistselected);


                    this.setState({
                        projectlist: this.projectlistarr
                    })
                })
                .catch(err => {
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
                .finally(() => this.setState({
                    isLoading: false
                }));
        }
    }
    // load packagetypes from database for generating a select-field
    getPackages() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/package/type')
            .then(response => {
                this.packagetype = response['data'];
                for (var i = 0; i < this.packagetype.length; i++) {
                    let label = this.packagetype[i]['name'];
                    if (!LabelFile.packages[label]) {
                        this.packagetype[i]['name'] = label;
                    } else {
                        this.packagetype[i]['name'] = LabelFile.packages[label];
                    }
                }
                this.setState({ packagetypes: this.packagetype });
            })
            .catch(err => {
                console.log(err)
            });
    }

    // prefill edit-form
    loadPackageDates() {

        axios.get(API_XPART.XPART_SERVICE_URL + '/package/?packageNumber=' + this.packageNumber)
            .then(response => {
                this.setState({ packageID: response['data']['id'] });
                document.getElementById('packagenumber').value = this.packageNumber;
                document.getElementById('netweight').value = response['data']['netWeight'];
                document.getElementById('grossweight').value = response['data']['grossWeight'];
                document.getElementById('length').value = response['data']['length'];
                document.getElementById('height').value = response['data']['height'];
                document.getElementById('width').value = response['data']['width'];
                document.getElementById('packagetype').value = response['data']['packageTypeId'];
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.setState({
                isLoading: false
            }));
    }

    componentDidMount() {
        this.getPackages();
        this.setSelectDefaults();
        if (this.editMode) {
            this.loadPackageDates();
        }
    }

    // Table data rendering
    renderTableContent() {
        return (
            this.state.projectlist.map(result => (
                <div className={"list-grid-row xpart-list-grid-row-override xpartpackage multi-select " + result["class-selected"]} data-attr={result['id']}>
                    <div data-name={LabelFile.labels['project_ProjectItem']} className="list-grid-item list-grid-item-block">
                        <span>{result['projectnumber'] + ' / ' + result['position']}</span>
                    </div>

                    <div data-name={LabelFile.labels['xpartsingle']} className="list-grid-item">
                        <span>{result['xpartNumber']}</span>
                    </div>

                    <div data-name={LabelFile.labels['desc_de']} className="list-grid-item">
                        <span title={result['descriptionDe']}>{result['descriptionDe']}</span>
                    </div>
                </div>

            )
            ))
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }


    // Creates new package with API request and after creation redirects to packages page
    saveNewPackage(data) {

        let xpartsIds = JSON.parse(localStorage.getItem(this.projectnumber));

        let dates = {};
        dates["packageInfo"] = data;
        dates["xpartsIds"] = xpartsIds;

        this.toggleLoading(true)
        axios.post(API_XPART.XPART_SERVICE_URL + '/package/xparts', dates)
            .then(res => {
                this.toggleLoading(false);
                this.clearLocalSelection();
                this.stopMultiSelect();
                this.props.closeModal();
                this.props.history.push('/project/'+this.projectnumber+'/package/overview');
            })
            .catch(err => {
                this.toggleLoading(false);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })


    }

    // to save existing package 
    saveOldPackage() {

        let xpartsToAdd = JSON.parse(localStorage.getItem(this.packageNumber));
        let xpartsToRemove = [...this.prevXparts];
        let data = {};

        //remove all xparts that are in the localstorage from xpartstoremovelist
        xpartsToAdd.forEach((element) => {
            if (xpartsToRemove.includes(element)) {
                xpartsToRemove = xpartsToRemove.filter(e => e !== element)
            }
        })

        //remove all old xparts from xpartstoadd
        this.prevXparts.forEach((element) => {
            if (xpartsToAdd.includes(element)) {
                xpartsToAdd = xpartsToAdd.filter(e => e !== element)
            }
        })

        let packageInfo = {};

        packageInfo["grossWeight"] = document.getElementById("grossweight").value;
        packageInfo["height"] = document.getElementById("height").value;
        packageInfo["id"] = this.state.packageID;
        packageInfo["length"] = document.getElementById("length").value;
        packageInfo["netWeight"] = document.getElementById("netweight").value;
        packageInfo["packageNumber"] = document.getElementById("packagenumber").value;
        packageInfo["packageTypeId"] = document.getElementById("packagetype").value;
        packageInfo["width"] = document.getElementById("width").value;
        data["packageInfo"] = packageInfo;
        data["xpartsToAdd"] = xpartsToAdd;
        data["xpartsToRemove"] = xpartsToRemove;

        console.log(data);

        axios.put(API_XPART.XPART_SERVICE_URL + '/package/edit', data)
            .then(res => {
                this.toggleLoading(false);
                this.clearLocalSelection();
                this.stopMultiSelect();
                this.props.closeModal();
                this.props.history.push('/project/'+this.projectnumber+'/package/overview');
            })
            .catch(err => {
                this.toggleLoading(false);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })

            .finally(() => this.setState({
                isLoading: false
            }));
    }

    savebutton() {
        return (
            <div className="input-container" >
                <div className="xpm-button-container">
                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.saveOldPackage()}
                    >{LabelFile.labels["save"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }

    // firing the modal for Package number input from the User
    confirmationButtonClick() {
        if (this.editMode) {
            if (Fields.validateInputs()) {
                this.props.fireModal({
                    content: this.savebutton(),
                    header: LabelFile.texts["savepackages"],
                    type: 'wide'
                })
            }
        }
        else {
            let packdata = {
                packageTypeId: '',
                packageTypeName: '',
                packageNumber: ''
            };

            this.props.fireModal({
                content: <NewPackageInputMask ref={this.newpackageinputmask}
                    saveNewPackage={(data) => this.saveNewPackage(data)}
                    closeModal={this.props.closeModal}
                    packdata={packdata}
                    packagetypelist={this.state.packagetypes}
                />,
                header: LabelFile.texts["inputpackages"],
                type: 'wide'
            })
        }
    }

    // function to rout to the list of packages in 
    packageList() {
        this.props.history.push('/project/'+this.projectnumber+'/package/overview');
    }

    // Multiselect message coutner
    createCountMessage() {
        return LabelFile['texts']['COUNT_OF_SELECTED_XPARTS_TO_PACK'].replace('$COUNT', this.state.multiSelectSelected);
    }
    //Clearout local storage
    clearLocalSelection() {
        if (this.editMode) {
            if (localStorage.getItem(this.packageNumber)) {
                let xpartsAdded = JSON.parse(localStorage.getItem(this.packageNumber));
                let xpartsToUpdate = JSON.parse(localStorage.getItem(this.projectnumber));

                if (xpartsToUpdate === null) {
                    xpartsToUpdate = [];
                }
                // A small check to make sure that when the xpart selected in project-package view is packed in the editmode,
                // it is removed from the localstorage of project-package view 
                xpartsAdded.forEach((element) => {
                    if (xpartsToUpdate.includes(element)) {
                        xpartsToUpdate = xpartsToUpdate.filter(e => e !== element)
                    }
                })
                localStorage.setItem(this.projectnumber, JSON.stringify(xpartsToUpdate));
                localStorage.removeItem(this.packageNumber);
                this.props.history.replace('/refresh' + window.location.pathname);
            }
        }
        else if (localStorage.getItem(this.projectnumber)) {
            localStorage.removeItem(this.projectnumber);
            this.props.history.replace('/refresh' + window.location.pathname);
        }
    }
    //Rendering the Multiselect start,stop, counter and clearout buttons
    renderOptionalButtons() {
        return (
            <div className="optionalButtons xpm-button-container">
                <button onClick={() => { this.startMultiSelect() }}
                        className={"xpm-button " + this.state.multiSelectHide}>
                    {this.editMode ? LabelFile.labels["editpacked"] : LabelFile.labels["requestpacking"]}
                </button>
                <button onClick={() => { this.packageList() }}
                        className={"xpm-button " + this.state.multiSelectHide}>
                    {LabelFile.labels["editpackage"]}
                </button>
                <button onClick={() => { this.stopMultiSelect() }}
                        className={"xpm-button " + this.state.multiSelectShow}>
                    {LabelFile.labels["cancel"]}
                </button>
                <button onClick={() => { this.clearLocalSelection() }}
                        className={"xpm-button " + this.state.multiSelectShow}>
                    {LabelFile.labels["reset"]}
                </button>

                {!this.state.selectMode || this.state.multiSelectSelected < 1 ? null : <ConfirmationButton
                    expandedStateContent={this.createCountMessage()}
                    compressedStateContent={this.createCountMessage()}
                    mobileStateContent={this.state.multiSelectSelected + " " + LabelFile.labels["xparts"]}
                    onClick={this.confirmationButtonClick.bind(this)} />}
            </div>
        )
    }


    // to reload the user's previous selection of xparts from the local storage
    setSelectDefaults() {
        if (localStorage.getItem(this.projectnumber)) {
            let resultmulti = JSON.parse(localStorage.getItem(this.projectnumber));
            this.setState({
                resultlistselected: resultmulti,
                multiSelectSelected: resultmulti.length
            })
        }
        this.loadItems();
    }

    renderEditMode() {
        return (
            <div className="edit-container">
                <div className="column-1 edit-field">
                    {Fields.generateInputText("packagenumber", ".*", true, [LabelFile.warnings['required']])}
                </div>

                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputNumber("netweight", "0", "100000", "0.01", false, this.state.mode)}
                </div>
                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputNumber("grossweight", "0", "100000", "0.01", true, [LabelFile.warnings['required']], this.state.mode)}
                </div>
                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputSelect("packagetype", this.state.packagetypes, true, [LabelFile.warnings['required']], this.state.mode)}
                </div>

                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputNumber("length", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
                </div>
                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputNumber("width", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
                </div>
                <div className="column-1 column-xs-1 edit-field">
                    {Fields.generateInputNumber("height", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
                </div>
            </div>

            // <div className="edit-mask" id="edit-mode">
            //     <div className="field editfield1">
            //         {Fields.generateInputText("packagenumber", ".*", true, [LabelFile.warnings['required']])}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputNumber("netweight", "0", "100000", "0.01", true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputNumber("grossweight", "0", "100000", "0.01", true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputSelect("packagetype", this.state.packagetypes, true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputNumber("length", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputNumber("width", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            //     <div className="field">
            //         {Fields.generateInputNumber("height", "0", "100000", "1", true, [LabelFile.warnings['required']], this.state.mode)}
            //     </div>
            // </div>
        )
    }

    // this function delete selected items


        // this function delete selected items
        deleteButton(content) {
            // fetch api port for outside communiciation
            
            const deleteItem = async (content) => {
                await axios.delete(API_XPART.XPART_SERVICE_URL + '/package/?packageID=' + content)
                .then((res) => {
                    this.toggleLoading(false);
                    this.clearLocalSelection();
                    this.props.closeModal()
                })
                .catch(err => {
                    this.toggleLoading(false);
                    this.props.fireModal({
                        content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                        header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                        buttons: this.createOKModalButtons()
                    });
                })
            }
    
            // jsx layout for the overlay screen
            const deletePage = (content) => {
                return(
                    <div className="delete-item-container">
                        <div className="delete-item-content">
                            <span>
                                Das Paket enthält <b>{content.xpartsCount}</b> Losteile. Wollen Sie das Paket wirklich löschen? Sie können auch die Editier-Funktion nutzen, um einzelne Losteile zu entfernen.
                            </span>
                        </div>
                        <div className="delete-item-button">
                            <button type="button" className="xpm-button" onClick={() => deleteItem(content)}>
                                {LabelFile.labels["delete"]}
                            </button>
                        </div>
                        <div className="delete-item-button">
                            <button type="button" className="xpm-button" onClick={() => this.props.closeModal()}>
                                {LabelFile.labels["cancel"]}
                            </button>
                        </div>
                    </div>
                )
            }
    
            // start the instance for the overlay
            this.props.fireModal(
                {
                    content: deletePage(content), // open the jsy layout and passthrough given properties
                    header: 'Löschen',
                    type: 'wide'
                }
            )
        }

    render() {
        return (
            <section className="content-container">
                <div className="column-2 column-xs-1">
                    <span className={"info-display"}>
                        {LabelFile.labels['projectnumber']}{' : '}{this.projectnumber}
                    </span>
                </div>
                <div className="column-4 column-xs-1 header-button-alignment">
                    {this.editMode ?
                        <button type="button" className="xpm-button header-button" onClick={() => this.deleteButton(this.state.packageID)}>
                            {LabelFile.labels["delete"]}
                        </button> : <span></span>
                    }
                    {this.editMode ?
                        <button type="button" className="xpm-button header-button" onClick={() => this.confirmationButtonClick()}>
                            {LabelFile.labels["save"]}
                        </button> : <span></span>
                    }
                </div>

                <div className="column-6 column-xs-1">
                    {this.editMode ? this.renderEditMode() : <span></span>}
                </div>
                <div className="column-6 column-xs-1">
                    {this.renderOptionalButtons()}
                </div>
                <div className="column-6 column-xs-1 list-container">
                    <div className="list-grid">
                        <div className="list-grid-row xpart-list-grid-row-override list-grid-row-headline xpartpackage">
                            <div className="list-grid-item">
                                <span>{LabelFile.labels['project_ProjectItem']}</span>
                            </div>
                            <div className="list-grid-item">
                                <span>{LabelFile.labels['xpartsingle']}</span>
                            </div>
                            <div className="list-grid-item">
                                <span>{LabelFile.labels['desc_de']}</span>
                            </div>
                        </div>
                    </div>
                    {this.renderTableContent()}
                    {this.state.isLoading ? <Loader type="lock" /> : ''}
                </div>
            </section>

            // <section className="results--section">
            //     <span className={"info-display"}>{LabelFile.labels['projectnumber']}{' : '}{this.projectnumber}</span>
            //     {this.editMode ? <button type="button"
            //         className="xpm-button packageedit"
            //         onClick={() => this.confirmationButtonClick()}
            //     >{LabelFile.labels["save"]}</button> : <span></span>}
            //     {this.editMode ? this.renderEditMode() : <span></span>}
            //     {this.renderOptionalButtons()}
            //     <span className={"xpm-warning-span"}>{warningtext}</span>
            //     <div className="list-container">
            //         <div className="list-grid">
            //             <div className="list-grid-row xpart-list-grid-row-override list-grid-row-headline xpartpackage">
            //                 <div className="list-grid-item">
            //                     <span>{LabelFile.labels['project_ProjectItem']}</span>
            //                 </div>
            //                 <div className="list-grid-item">
            //                     <span>{LabelFile.labels['xpartsingle']}</span>
            //                 </div>
            //                 <div className="list-grid-item">
            //                     <span>{LabelFile.labels['desc_de']}</span>
            //                 </div>
            //             </div>
            //         </div>
            //         {this.renderTableContent()}
            //         {this.state.isLoading ? <Loader type="lock" /> : ''}
            //     </div>
            // </section>
        )
    }
}


export default withRouter(ProjectXpartPackage);