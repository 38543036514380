import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { API } from '../Common/API/API'
import { LabelService } from '../Common/Translations/LabelService'
// import { Loader } from '../Common/Components/Loader/loader'
import './ResetPassword.css'

const GLOBAL_API = new API();
const LabelFile = LabelService.getLabelsByLocal('de')

export default class ResetPassword extends Component {
  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'resetpassword-button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);
    return btns;
  }

  // function: linkt to login page
  linkToLoginPage = () => {
    this.props.closeModal()
    this.props.history.push('/login')
  }

  // function: simulates ENTER-taste
  keyPress = (e) => {
    e.stopPropagation();

    // if: is ENTER pressed then fire resetPassword function
    if (e.keyCode === 13) {
      this.resetPassword()
    }

    e.stopPropagation();
  }

  // function: show warning border or not
  warningBorder = (htmlIdArray) => {
    for (let key in htmlIdArray) {
      document.getElementById(htmlIdArray[key][0]).className = htmlIdArray[key][1] ? 'form-input-properties form-warning' : 'form-input-properties'
    }
  }

  // function: check if email input is filled, then change border color
  typedInputEmail = (inputData) => {
    this.warningBorder([['form-input-email', false]])
  }

  // function: check if email is valid
  checkIfEmailIsOkay = (typedInputEmail) => {
    // function: check if email and emailRepeat has a valid format
    const validate = (email) => {
      // regexpression
      const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return expression.test(String(email).toLowerCase())
    }

    // check if email and emailRepeat has a valid format
    let emailIsValid = validate(typedInputEmail)

    // change the border color for warning
    this.warningBorder([['form-input-email', !emailIsValid]])

    return emailIsValid;
  }

  // function: costum firemodal content
  fireModalCustom(content) {
    // jsx layout for the overlay screen
    const fireModalCustomContent = () => {
      return (
        <div className="firemodal-overlay-container">
          <div className="content-container">
            <span>
              {LabelFile.texts['resetPasswordSuccesfully']}
            </span>
          </div>
          <div className="button-container">
            <button type="button" onClick={() => this.linkToLoginPage()} className="button-properties">OK</button>
          </div>
        </div>
      )
    }

    // start the instance for the overlay
    this.props.fireModal(
      {
        content: fireModalCustomContent(), // open the jsy layout and passthrough given properties
        header: LabelFile.header['RESET_PASSWORD'],
        type: 'wide'
      }
    )
  }

  // function: reset the password
  resetPassword = () => {
    // fetch data from email
    let typedInputEmail = document.getElementById('form-input-email').value

    // check if email format is true / false
    let emailIsOkay = this.checkIfEmailIsOkay(typedInputEmail)

    // if: is the email address true, then fire to backend that the backend create a new password
    if (emailIsOkay) {
      axios.post(GLOBAL_API.AUTH_SERVICE_URL + '/user/password/forget', { email: typedInputEmail })
        .then(response => {
          // open fireModal
          this.fireModalCustom()
        })
        .catch(error => {
          let content = error.response.data.message !== null ? error.response.data.message : error.response.data.errors[0];

          // handle error
          this.props.fireModal({
            content: LabelFile.warnings[content],
            header: LabelFile.header['ERROR'],
            buttons: this.createOKModalButtons()
          })
        })
        .finally(() => {
          this.setState({
            isLoading: false
          });
        })
    }
  }

  // component: form for reset password
  ComponentForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['emailAddress']}*
            </div>
          </div>
        </div>
        <div className="form-right">
          <input type="text" id="form-input-email" onKeyDown={this.keyPress} onChange={this.typedInputEmail} className="form-input-properties" />
        </div>
      </Fragment>
    )
  }

  // component: button for reset password
  ComponentButton = () => {
    return (
      <div className="form-full">
        <button type="button" onKeyDown={this.keyPress} onClick={() => this.resetPassword()} className="createuser-button-properties">
          {LabelFile.header['RESET_PASSWORD']}
        </button>
      </div>
    )
  }

  // component: infromation for reset password
  ComponentInformation = () => {
    return (
      <div className="form-full">
        {LabelFile.texts['resetPasswordInformation1']}
        <span onClick={() => this.linkToLoginPage()} className="linktologinpage">
          {LabelFile.texts['resetPasswordInformation2']}
        </span>
      </div>
    )
  }

  render() {
    return (
      <section className="resetpassword-container">
        <div className="resetpassword-form-container">
          <div className="form-full">
            <h1>{LabelFile.header['RESET_PASSWORD']}</h1>
          </div>
          <this.ComponentForm />
          <this.ComponentButton />
          <this.ComponentInformation />
        </div>
      </section>
    )
  }
}
