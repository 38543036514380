
import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import './Scan.css'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { HelperFunctions } from '../Common/Helper/Helper';


const HelperFunction = new HelperFunctions();

class Scan extends Component {
  constructor(props) {
    super(props);
    this.resultlistarr = [];
  }

  state = {
    result: 'Kein Scan',
    resultlist: [],
    flashanim: 'scanner-container'
  }

  handleScan = data => {
    this.setState({
      flashanim: 'scanner-container'
    });
    if (data) {
      if (this.resultlistarr.indexOf(data) < 0) {
        this.resultlistarr.push(data);
      }
      this.setState({
        result: data,
        resultlist: this.resultlistarr,
        flashanim: 'scanner-container scanner-container-flash'
      })
    }
  }
  handleError = err => {
    console.error(err)
  }
  render() {
    return (
      <section className="results--section">
        <div className="input-container">
          <div class={this.state.flashanim}>
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%' }}
            />
          </div>
          <input type="text" class="scan-input" value={this.state.result} />

          <div className="xpm-button-container">
            {this.state.resultlist.map(result => (
                <Link className="xpm-button" to={'/xpart/show/' + result + '/readonly' + HelperFunction.getContextOrNot(this.props.match.params.context)}><span>{result}</span></Link>
              ))}
          </div>
        </div>

       </section>
    )
  }
}

export default withRouter(Scan);