
import LABEL_DE from '../../static-res/labels/labels-de.json';


export class LabelService {

  static  getLabelsByLocal(local) {
        if (local === 'de') {
            return LABEL_DE;
        }
    }

}
