import React, { Component } from 'react'
import axios from 'axios';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import { HelperFunctions } from '../Common/Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Xpart.css'
import { Loader } from '../Common/Components/Loader/loader';

/**************************************************
 * 
 *              OLD RELEASE!!!!!!!!!!!
 * 
 *            REPLACED BY GENERIC TABLE
 *
 **************************************************/

const API_XPART = new API();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();
const NOTIFICATIONS = new APINotifications();

class XpartList extends Component {
    constructor(props) {
        super(props);
        this.resultlistarr = [];
        this.handleChangedAmount = this.handleChangedAmount.bind(this);
    }

    state = {
        resultlist: [],
        first: true,
        last: false,
        lastPage: 9999,
        isLoading: true
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    formatData(data) {
        let lang = HelperFunction.getLocale();
        data.forEach(xpart => {
            if (xpart['active'] === true) {
                xpart['active'] = "Aktiv";
            } else {
                xpart['active'] = "Archiviert";
            }

            xpart['created'] = HelperFunction.timeConverter(xpart['created'], lang);
            xpart['updated'] = HelperFunction.timeConverter(xpart['updated'], lang);
            xpart['weight'] = HelperFunction.formatFloats(xpart['weight'], lang);

            var items = xpart["itemOrders"];
            var orderItemList = "";

            items.forEach(item => {
                if (orderItemList.length !== 0) {
                    orderItemList += '; '
                }
                orderItemList += HelperFunction.getItemValueView(item, ['itemNumber', 'orderNumber']) 
            });

            xpart['orderItem'] = orderItemList;
            xpart['status'] = LabelFile.statuses[xpart['currentStatus']['statusDTO']['name']] + " [" + LabelFile.context[xpart['currentStatus']['contextDTO']['name']] + "]";
        })
        return data;
    }

    handleLoad() {
        let pageHandle = this.props.match.params.page - 1;
        let partsHandle = this.props.match.params.parts;

        let size = "size=" + partsHandle;
        let sort = "sort=xpartNumber,desc";
        let page = "page=" + pageHandle;

        this.setState({
            isLoading: true
        })
        axios.get(API_XPART.XPART_SERVICE_URL + '/xpartview/list?' + size + '&' + page + '&' + sort)
            .then(response => {
                this.resultlistarr = response['data']['content'];

                this.resultlistarr = this.formatData(this.resultlistarr);

                this.setState({
                    resultlist: this.resultlistarr,
                    first: response["data"]["first"],
                    last: response["data"]["last"],
                    lastPage: response["data"]["totalPages"]
                })
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]) + '\n' + document.getElementById("xpartScan").value,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.setState({
                isLoading: false
            }));

        if ([10, 20, 50, 100, 200, 500].includes(parseInt(partsHandle))) {
            document.getElementById("xpartsAmountList").value = partsHandle;
        }
    }

    componentDidMount() {
        this.handleLoad();
    }


    //Hacky way to update props and states if only url params, but not url itself changed
    componentDidUpdate(prevProps) {
        const { match: { params: { page } } } = this.props;
        const { match: { params: { parts } } } = this.props;
        if (prevProps.match.params.page !== page || prevProps.match.params.parts !== parts) {
            this.handleLoad();
        }
    }

    handleChangedAmount(value) {
        this.props.history.push('/xpartlist/1/' + value);
    }


    renderPageinationControls() {
        let currentPage = Number.parseInt(this.props.match.params.page);
        let prevPage = currentPage - 1;
        let nextPage = currentPage + 1;
        let currentParts = this.props.match.params.parts;

        let disablePrev = "";
        if (this.state.first) {
            disablePrev = "disabled-link";
        }

        let disableNext = "";
        if (this.state.last) {
            disableNext = "disabled-link";
        }


        return (
            <div class="paginationControls">
                <Link key="990" class={disablePrev} id="paginationFirst" to={'/xpartlist/1/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-double-left']} /></Link>
                <Link key="991" class={disablePrev} id="paginationPrev" to={'/xpartlist/' + prevPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-left']} /></Link>
                <Link key="992" class="disabled-link" id="paginationCurrent" to={'/xpartlist/' + currentPage + '/' + currentParts}><span>{currentPage}</span></Link>
                <Link key="993" class={disableNext} id="paginationNext" to={'/xpartlist/' + nextPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-right']} /></Link>
                <Link key="994" class={disableNext} id="paginationLast" to={'/xpartlist/' + this.state.lastPage + '/' + currentParts}><FontAwesomeIcon icon={['fas', 'angle-double-right']} /></Link>

                <span class="paginationText">{LabelFile.labels["xparts"]}: </span>
                <select onChange={(event) => this.handleChangedAmount(event.target.value)} value={currentParts} id="xpartsAmountList">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                </select>
            </div>
        )
    }

    renderTableContent() {
        return (
            this.state.resultlist.map(result => (
                <div class="list-grid-row xpart-list-grid-row-override">
                    <div data-name={LabelFile.labels['id']} class="list-grid-item">
                        <span>{result['id']}</span>
                    </div>
                    <div data-name={LabelFile.labels['item']} class="list-grid-item list-grid-item-block">
                        <span>{result['orderItem']}</span>
                    </div>
                    <div data-name={LabelFile.labels['xpart']} class="list-grid-item">
                        <Link to={'/xpart/show/' + result['xpartNumber'] + "/readonly"+HelperFunction.getContextOrNot(this.props.match.params.context)}><span>{result['xpartNumber']}</span></Link>
                    </div>
                    <div data-name={LabelFile.labels['desc_de']} class="list-grid-item">
                        <span title={result['descriptionDe']}>{result['descriptionDe']}</span>
                    </div>
                    <div data-name={LabelFile.labels['desc_en']} class="list-grid-item">
                        <span title={result['descriptionEn']}>{result['descriptionEn']}</span>
                    </div>
                    <div data-name={LabelFile.labels['quantity']} class="list-grid-item">
                        <span>{result['quantity']}</span>
                    </div>
                    <div data-name={LabelFile.labels['weight']} class="list-grid-item">
                        <span>{result['weight']}</span>
                    </div>
                    <div data-name={LabelFile.labels['active']} class="list-grid-item">
                        <span>{result['active']}</span>
                    </div>
                    <div data-name={LabelFile.labels['status']} class="list-grid-item">
                        <span>{result['status']}</span>
                    </div>
                    <div data-name={LabelFile.labels['updated']} class="list-grid-item list-grid-date-cell">
                        <span>{result['updated']}</span>
                    </div>
                    <div data-name={LabelFile.labels['storage']} class="list-grid-item">
                        <span>{result['storage'].storageCode}</span>
                    </div>
                    <div data-name={LabelFile.labels['functions']} class="list-grid-item">
                    <FontAwesomeIcon className="active-element" icon="print" onClick={()=>HelperFunction.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/x-part/' + result['xpartNumber'])}/>
                    </div>
                </div>
            ))
        )
    }

    render() {
        return (
            <section className="results--section">
                {this.renderPageinationControls()}
                <div className="list-container">
                    <div class="list-grid xpart-list-grid">
                        <div class="list-grid-row xpart-list-grid-row-override list-grid-row-headline">
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['id']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['itemshort']} - {LabelFile.labels['order']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['xpart']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['desc_de']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['desc_en']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['quantity']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['weightshort']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['active']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['status']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['updated']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['storage']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{LabelFile.labels['functions']}</span>
                            </div>

                        </div>
                        {this.renderTableContent()}
                    </div>
                    {this.state.isLoading ? <Loader type="lock"/> : ''}
                </div>
                {this.renderPageinationControls()}
            </section>
        )
    }
}

export default withRouter(XpartList);
