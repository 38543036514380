import React, { Component } from 'react'
import FormGenerate from '../Form/FormGenerate.js'

class FormfieldOnTransportarea extends Component {

  constructor(props) {
    super(props);
    this.result = '';
    this.receiveVariables = this.getReceiveVariables();

  }

  getReceiveVariables() {
    let formParameters = [{ name: 'weight', htmlId: 'weight' ,isNumber: true}];
    let receiveVariables = { formParameters: formParameters,
    needPhoto: true,
    successURI: '/xpartontransportarea' };

    return receiveVariables;
  }

  componentWillMount() {
    if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpartnumber)) {
      this.result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpartnumber));
    }
  }

  render() {
    return (
      <FormGenerate
        fireModal={this.props.fireModal}
        closeModal={this.props.closeModal}
        formname="xpartAreaEdit"
        header="RECEIVED_AREA_KME"
        DataSetIdName={this.props.match.params.xpartnumber} // fetch the xpartnumber and fills the generate formfield
        prefill="true"
        pdfPrint="false"
        warningSave="proveKMEOrdered"
        amountOfLocalPhotos={this.result.length}
        pathAfterSave="/xpartontransportarea"
        flag={'receiveKME'} // set flag for the back-button
        receiveVariables={this.receiveVariables}
      />
    )
  }
}

export default FormfieldOnTransportarea
