import React, { Component } from 'react'
import './Modal.css'
import { ModalButton } from './ModalButton'

/**
 * @typedef {Object} Modal
 * @property {'dialog' | 'wide'} modalType modal type
 */

/**
 * @extends {Component<Modal>}
 */
class GenericModal extends Component {
    /**
     * @type Modal
     */
    state = {
        modalShow: false,
        modalContent: '',
        modalHeader: '',
        modalButtons: [],
        modalType: 'dialog',
        modalCloseX: false,
        redirect: null,
        stickInOverlay: false
    }


    updateModal(props) {
        let state = {
            modalShow: props.modalShow,
            modalContent: props.modalContent,
            modalHeader: props.modalHeader,
            modalButtons: props.modalButtons,
            modalType: props.modalType,
            modalCloseX: props.modalCloseX,
            redirect: props.redirect,
            stickInOverlay: props.stickInOverlay
        }

        this.setState(state);
    }

    getModalSizeClass() {
        switch (this.state.modalType) {
            case 'wide':
                return 'modal-wide';
            default:
                return 'modal-dialog'
        }
    }

    getRedirect() {
        return this.state.redirect;
    }


    createButtons() {
        if (!this.state.modalButtons) {
            return '';
        }

        var btns = this.state.modalButtons.map(function (btn) {

            return <div>{<ModalButton customClass={btn.customClass} text={btn.text} callback={btn.callback}></ModalButton>}
            </div>
        });
        return <div>{btns}</div>
    }

    renderModalCloseX() {
        return this.state.modalCloseX ? <div className="modal-close-button" onClick={this.props.closeCallback}>x</div> : ''
    }


    render() {
        return (<div>
            <div className={`modal ${this.props.customClass}`} style={{ display: this.state.modalShow ? 'block' : 'none' }}>
                <div className="overlay" onClick={!this.state.stickInOverlay ? this.props.closeCallback : null}></div>

                <div className={`modal-content-background ${this.getModalSizeClass()}`}>

                    {this.renderModalCloseX()}
                    <div className="modal_content modal_mobile">
                        {this.state.modalHeader ? <div className="topnav">{this.state.modalHeader}</div> : ''}
                        <div className="modal_content_text modal_mobile">
                            {this.state.modalContent}
                        </div>
                        <div className="center">
                            {this.createButtons()}
                            {/* <ModalButton customClass={this.props.okBotton.customClass} text={this.props.okBotton.text} callback={this.props.okBotton.callback}></ModalButton> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default GenericModal;
