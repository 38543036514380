import React, { Component } from 'react';
import { LabelService } from '../Common/Translations/LabelService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LabelFile = LabelService.getLabelsByLocal('de');

class PackageOperationButtonsComponent extends Component {

    state = {
        multiSelectHide: "",
        multiSelectShow: "xpm-hidden",
    }

    startSelect(){
        this.updateComponentState('xpm-hidden','');
        this.props.multiSelectSwitch(true);
    }

    cancelSelect(){
        this.updateComponentState('','xpm-hidden');
        this.props.multiSelectSwitch(false);
    }

    updateComponentState(multiSelectHide, multiSelectShow) {
        this.setState({
            multiSelectHide: multiSelectHide,
            multiSelectShow: multiSelectShow
        });
    }

    deliveryList() {
        this.props.deliveryList();
    }

    projectXpartList() {
        this.props.projectXpartList();
    }

    render() {
        return (
            <div>
                <div className="optionalButtons xpm-button-container">
                    <div className="button-alignment">
                        <button onClick={() => { this.projectXpartList() }}
                                className={"xpm-button " + this.state.multiSelectHide}>
                            <FontAwesomeIcon icon={['fas', 'arrow-left']} />&nbsp;{LabelFile.labels["packagebackbutton"]}
                        </button>
                        <button onClick={() => { this.startSelect()}}
                                className={"xpm-button " + this.state.multiSelectHide}>
                            <FontAwesomeIcon icon={['fas', 'dolly']} />&nbsp;{this.props.selectButtonName}
                        </button>
                        <button onClick={() => { this.cancelSelect() }}
                                className={"xpm-button " + this.state.multiSelectShow}>
                            <FontAwesomeIcon icon={['fas', 'reply']} />&nbsp;{LabelFile.labels["cancel"]}
                        </button>
                        <button onClick={() => { this.props.clearLocalSelection() }}
                                className={"xpm-button " + this.state.multiSelectShow}>
                            <FontAwesomeIcon icon={['fas', 'reply-all']} />&nbsp;{LabelFile.labels["reset"]}
                        </button>
                        <button onClick={() => { this.deliveryList() }}
                                className={"xpm-button button-delivery " + this.state.multiSelectHide}>
                            {LabelFile.labels["delivery_overview"]}&nbsp;<FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}
export default PackageOperationButtonsComponent