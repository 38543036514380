import React, { Component } from 'react';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { Loader } from '../Common/Components/Loader/loader';
import { PageHeaderComponent } from '../Common/Components/page-header-component';
import { NewPackageInputMask } from '../Project/NewPackageInputMask';
import PackageOverViewTableComponent from './package-overview-table-component';
import PackageViewInfoComponent from './package-view-info-component';
import PackageSaveButtonComponent from './package-save-button-component';
import PackageOperationButtonsComponent from './package-operation-buttons-component';
import { Constants } from '../Common/Helper/Constants';

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');

export default class PackageOverviewComponent extends Component {

    constructor(props) {
        super(props);
        this.packageTableRef = React.createRef();
        this.packageInfoRef = React.createRef();
        this.packageSaveButtonRef = React.createRef();
    }

    componentDidMount() {
        this.initPackageTypes();
        if(this.state.selectedItemPrefix === Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX){
            localStorage.removeItem(`${Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX}${this.state.projectNumber}`);
        }
    }


    state = {
        projectNumber: this.props.match.params.projectnr,
        selectedItemPrefix: this.props.match.params.id !== undefined ? Constants.DELIVERY_EDIT_MODE_SELECT_PREFIX : 'delivery-package-create-',
        isLoading: true,
        deliveryIdToEdit: this.props.match.params.id
    }

    multiSelectSwitch(multiselect) {
        if (this.packageTableRef.current) {
            this.packageTableRef.current.changeSelectMode(multiselect);
            this.changeInfoDisplay(!multiselect);
        }
    }

    showEditPackagePopup(packageObject) {
        this.props.fireModal({
            content: <NewPackageInputMask ref={this.newpackageinputmask}
                saveMissingData={(data) => this.saveMissingData(data)}
                closeModal={this.props.closeModal}
                packdata={packageObject}
                workingMode={'edit'}
                packagetypelist={this.translatedPackageTypes}
            />,
            header: LabelFile.texts["inputpackages"],
            type: 'wide'
        })
    }

    //save datasets which hasn't all dates
    saveMissingData(data) {
        for (let i = 0; i < this.packagetypelist.length; i++) {
            let type = this.packagetypelist[i];
            if (parseInt(data['packageTypeId']) === type.id) {
                data['packageTypeName'] = type.name;
            }
        }

        let dates = {};
        dates["packageInfo"] = data;
        dates["xpartsToAdd"] = [];
        dates["xpartsToRemove"] = [];

        this.toggleLoading();
        axios.put(API_XPART.XPART_SERVICE_URL + '/package/edit', dates)
            .then(res => {
                this.packageTableRef.current.updatePackage(data);
                this.props.closeModal();
            })
            .catch(err => {
                this.showException(err);
            })
            .finally(() => this.toggleLoading()
            );
    }

    changeInfoDisplay(display) {
        if (this.packageInfoRef.current) {
            this.packageInfoRef.current.changeInfoDisplay(display);
        }

        if (this.packageSaveButtonRef.current) {
            this.packageSaveButtonRef.current.changeDisplay(!display);
        }
    }

    changeSelectedCount(count) {
        if (this.packageInfoRef.current) {
            this.packageInfoRef.current.changeSelectedCount(count);
        }

        if (this.packageSaveButtonRef.current) {
            this.packageSaveButtonRef.current.changeSelectedCount(count);
        }
    }

    // load packagetypes from database for generating a select-field
    initPackageTypes() {
        axios.get(API_XPART.XPART_SERVICE_URL + '/package/type')
            .then(response => {
                let transList = []
                for (let i = 0; i < response['data'].length; i++) {
                    let label = response['data'][i]['name'];
                    let type = {
                        id: response['data'][i].id,
                        name: LabelFile.packages[label]
                    }
                    transList.push(type);
                }

                this.translatedPackageTypes = transList;
                this.packagetypelist = response['data'];
            })
            .catch(err => {
                console.log(err)
            });
    }

    toggleLoading() {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    getPackagesToAdd() {
        return this.packageTableRef.current.getPackagesToAdd();
    }

    getPackagesToRemove() {
        return this.packageTableRef.current.getPackagesToRemove();
    }

    getDeliveryObjectToEdit() {
        return this.state.deliveryIdToEdit;
    }

    clearLocalSelection() {
        return this.packageTableRef.current.clearLocalSelection();
    }

    goToOverview() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    deliveryList() {
        this.props.history.push('/project/deliveryview/' + this.state.projectNumber);
    }

    getHeaderText() {
        return this.state.deliveryIdToEdit === undefined ? LabelFile.labels['packages_overview'] : LabelFile.labels['deliveryEdit']
    }

    getSelectButtonName() {
        return this.state.deliveryIdToEdit === undefined ? LabelFile.texts["assembledelivery"] : LabelFile.labels["deliveryEdit"]
    }
    goToEditPackage(packData) {
        this.props.history.push('/project/projectxpart/' + this.state.projectNumber, { editMode: true, packageNumber: packData['packageNumber'] });
    }

    projectXpartList() {
        this.props.history.push('/project/projectxpart/' + this.state.projectNumber);
    }

    showException(err) {
        this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors === undefined ? '' : err.response.data.errors[0]),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
            buttons: this.createOKModalButtons()
        });
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };
        btns.push(obj);
        return btns;
    }

    render() {
        return (
            <section className="results--section">

                <PageHeaderComponent text={this.getHeaderText()} ></PageHeaderComponent>

                <span className="info-display info-display-list">{LabelFile.labels['projectnumber']}{' : '}{this.state.projectNumber}</span>
                <PackageOperationButtonsComponent multiSelectSwitch={(x) => this.multiSelectSwitch(x)} deliveryList={() => this.deliveryList()}
                    selectButtonName={this.getSelectButtonName()} projectXpartList={() => this.projectXpartList()}
                    clearLocalSelection={() => this.clearLocalSelection()}></PackageOperationButtonsComponent>

                <PackageViewInfoComponent ref={this.packageInfoRef}></PackageViewInfoComponent>

                <PackageSaveButtonComponent ref={this.packageSaveButtonRef} getPackagesToAdd={() => this.getPackagesToAdd()}
                    getPackagesToRemove={() => this.getPackagesToRemove()} toggleLoading={() => this.toggleLoading()}
                    clearLocalSelection={() => this.clearLocalSelection()} getDeliveryObjectToEdit={() => this.getDeliveryObjectToEdit()}
                    showException={(x) => this.showException(x)} >
                </PackageSaveButtonComponent>

                <PackageOverViewTableComponent ref={this.packageTableRef} projectNumber={this.state.projectNumber}
                    selectedItemPrefix={this.state.selectedItemPrefix} showEditPackagePopup={(x) => this.showEditPackagePopup(x)}
                    toggleLoading={() => this.toggleLoading()} changeSelectedCount={(x) => this.changeSelectedCount(x)}
                    goToOverview={(x) => this.goToOverview(x)} deliveryIdToEdit={this.state.deliveryIdToEdit} goToEditPackage={(x) => this.goToEditPackage(x)}
                    showException={(x) => this.showException(x)} fireModal={this.props.fireModal} closeModal={this.props.closeModal}>
                </PackageOverViewTableComponent>

                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </section>
        )
    }
}
