import React, { Component } from 'react'

export class PageHeaderComponent extends Component {

    state = {
    }

    render() {

        return (
            <div><h1>{this.props.text}</h1></div>
        )
    }
}