import React, { Component } from 'react'
import TableConfig from '../../List/tables-config.json';
import { ConfirmationButton } from '../../Common/ConfirmationButton/confirmation-button'
import { LabelService } from '../../Common/Translations/LabelService';
import '../../List/list.css';

const LabelFile = LabelService.getLabelsByLocal('de');

export class ConvenienceControlButtonsComponent extends Component {

    constructor(props) {
        super(props);
        this.config = TableConfig['xpartsInConvenienceGroup'];
    }

    state = {
    }

    renderOptionalButtons() {
        return (
            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                </div>

                {
                    (!this.props.currentGroup || !this.props.currentGroup.xpartNumbers) ? null : <ConfirmationButton
                        expandedStateContent={this.createCountMessage()}
                        compressedStateContent={this.createCountMessage()}
                        mobileStateContent={this.props.currentGroup.xpartNumbers.length + " " + LabelFile.labels["xparts"]}
                        onClick={this.confirmationButtonClick.bind(this)}
                        dynamic={false} icon={'check'} label={'toGroup'} />
                }
            </div >
        )
    }

    goToGroups(){
        this.props.history.push(this.config.requestedPageURL);
    }

    createCountMessage() {
        return LabelFile['texts']['COUNT_OF_SELECTED_XPARTS'].replace('$COUNT', this.props.currentGroup.xpartNumbers.length);
    }

    confirmationButtonClick() {
        this.props.saveGroup();
    }


    render() {
        return (
            <div>
            {this.renderOptionalButtons()}
        </div>
        )
    }

}