

import axios from 'axios';
import { API } from '../API/API';

const API_XPART = new API();

export class ContextService {

    async getCurrentContext(contextName) {

        return await axios.get(API_XPART.XPART_SERVICE_URL + '/context/name/'+contextName);
      }
  
  }
