import React, { Component } from 'react';
import './App.css';
import Home from './Home/Home';
import Scan from './Scan/Scan';
import Photo from './Scan/photo';
import Print from './Print/Print';
import XpartKME from './Xpart/XpartKME';
import XpartShowKME from './Xpart/XpartShowKME';
import Xpart from './Xpart/Xpart';
import GenericModal from './Common/Modal/GenericModal';
import XpartList from './Xpart/XpartList';
import Reception from './Reception/Reception';
import ReceptionXPart from './Reception/ReceptionXpart';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Storage from './Storage/Storage';
import StorageXpart from './Storage/StorageXpart';
import ChangeStorage from './Storage/ChangeStorage';
import OrderItemList from './Order/OrderItemList';
import OrderItemOverview from './Order/OrderItemOverview';
import PhotoGallery from './Photo/PhotoGallery';
import Photos from './Photo/Photo';
import XpartSearch from './Xpart/XpartSearch';
import XpartTechnikum from './Xpart/XpartTechnikum';
import XpartListTechnikum from './Xpart/XpartListTechnikum';
import XpartListTechnikumDemand from './Xpart/XpartListTechnikumDemand';
import OrderExport from './Export/OrderExport';
import ListComponent from './List/ListComponent';
import QaApproveComponent from './QA/qa-approve';
import { Auth } from './Auth/Auth';
import { Page403 } from './ErrorPages/page-403';
import { AuthService } from './Common/Service/AuthService';
import { Redirect } from 'react-router-dom';
import Project from './Project/Project';
import ProjectXpartPackage from './Project/ProjectXpartPackage';
import ImportIKNList from './Import/ImportIKNList';
import ImportKMEList from './Import/ImportKMEList';
import ImportSupplierList from './Import/ImportSupplierList';
import DeliveryView from './Project/DeliveryView';
import PackageOverViewComponent from './Packages/packages-overview-component';

import XpartScan from './Scan/XpartScan/XpartScan';
import XpartReception from './Reception/XpartReception/XpartReception';
import XpartOnTransportarea from './TransportArea/XpartOnTransportarea';
import FormfieldOnTransportarea from './TransportArea/FormfieldOnTransportarea';
import XpartAssembly from './Assembly/XpartAssembly';
import FormFieldAssembly from './Assembly/FormFieldAssembly';
import HeaderComponent from './Header/header-component';
import Setting from './Setting/Setting'
import CreateUser from './CreateUser/CreateUser'
import ResetPassword from './ResetPassword/ResetPassword'
import ConfirmPassword from './ResetPassword/ConfirmPassword'
import GroupXpartScan from './GroupXpart/GroupControl/groupXpartScan';
import GroupIdXpartView from './GroupXpart/GroupControl/groupIdXpartView'
import GroupXpart from './GroupXpart/Overview/GroupXpart'
import ConvenienceOverviewComponent from './GroupXpart/Convenience/convenience-overview-component';
import TransportAreaReceiveWizard from './ChangeStatus/TransportArea/TransportAreaReceiveWizard';
import EvsReceiveWizard from './ChangeStatus/EvsReceive/EvsReceiveWizard';
import AssemblyRequestXpartsViewComponent from './Assembly/Request/AssemblyRequestXpartsViewComponent';
import KommissionierenWizard from './Kommissionieren/KommissionierenWizard';
import KommissionierenTableComponent from './Kommissionieren/KommissionierenTableComponent';
import CommissionRequestedViewComponent from './CommissionRequested/CommissionRequestedViewComponent';
import AssemblyRequestEVSXpartsViewComponent from './Assembly/EVS/AssemblyRequestEVSXpartsViewComponent';
import ShuttleGroupCreationWizard from './GroupCreation/ShuttleGroupCreationWizard';
import ShuttleGroupReceptionWizard from './GroupCreation/ShuttleGroupReceptionWizard';
import SupplierXpartKommissionieren from './Kommissionieren/SupplierXpartKommissionieren/SupplierXpartKommissionieren';
import SupplierSendPackagePick from './Packages/SupplierSendPackage/SupplierSendPackagePick';
import TestImport from './Import/ImportTest';
import XpartToDeliverGroupOverviewComponent from './GroupXpart/XpartToDeliverGroup/XpartToDeliverGroup-overview-component';

library.add(fas, far);

const authService = new AuthService();

/**
 * @typedef {Object} IRendererConfig
 * @property {any} content modal content
 * @property {string} header modal header
 * @property {{}} buttons modal buttons
 * @property {'dialog' | 'wide'} type type of modal
 * @property {boolean} modalCloseX determines if a button is needed
 */

class App extends Component {
  constructor(props) {
    super(props);
    this.GenericModalComponent = React.createRef();
    this.HeaderComponentRef = React.createRef();
  }

  state = {
    modalShow: false,
    modalContent: '',
    modalButtons: [],
    currentUserName: '',
    showMenu: true,
    showUser: true,
    notifications: [],
  };



  handleModalState = ({ content, header, buttons, type, modalCloseX, redirect, stickInOverlay }) => {
    let modalProps = {
      modalShow: !this.state.modalShow,
      modalContent: content,
      modalHeader: header,
      modalButtons: buttons,
      modalType: type,
      modalCloseX: modalCloseX,
      redirect: redirect,
      stickInOverlay: stickInOverlay
    };

    this.GenericModalComponent.current.updateModal(modalProps);
  };

  closeModal = () => {
    let modalProps = {
      modalShow: false
    };
    let redirect = this.GenericModalComponent.current.getRedirect();
    this.GenericModalComponent.current.updateModal(modalProps);
    if (redirect !== null && redirect !== undefined) {
      redirect.func(redirect.url);
    }
  };

  modalActions = {
    closeModal: this.closeModal,
    /**
     * @type {(config: IRendererConfig) => void}
     */
    fireModal: config => this.handleModalState(config)
  };

  handleNotificationState = (notifications) => {
    const notificationProps = {
      notifications
    }
    this.HeaderComponentRef.current.updateNotifications(notificationProps)
  }

  clearNotifications = () => {
    this.HeaderComponentRef.current.updateNotifications({ notifications: [] })
  }

  notificationActions = {
    clearNotifications: this.clearNotifications,
    fireNotifications: notifications => this.handleNotificationState(notifications)
  }

  componentActions = {
    updateMenuAndUserDesplay: (user, menu) => this.updateMenuAndUserDesplay(user, menu)
  }

  userActions = {
    onCurrentUserUpdate: currentUser => this.onCurrentUserUpdate(currentUser)
  };

  onCurrentUserUpdate(currentUser) {
    if (this.HeaderComponentRef && this.HeaderComponentRef.current) {
      this.HeaderComponentRef.current.updateCurrentUser(currentUser);
    }
  }


  updateMenuAndUserDesplay(showUser, showMenu) {
    this.setState({
      showMenu: showMenu,
      showUser: showUser
    });
  }

  render() {
    //Hacky workaround for refreshing pages without reloading everything
    const Refresh = ({ path = '/' }) => (
      <Route
        path={path}
        component={({ history, location, match }) => {
          history.replace({
            ...location,
            pathname: location.pathname.substring(match.path.length)
          });
          return null;
        }}
      />
    );

    const ProtectedRoute = ({ component: ProtectedComponent, permissions: pageGroup, additionalPermissions: permission, componentProps, ...rest }) => (
      <Route
        {...rest}
        render={props => {
          return authService.isAccessAllowed(pageGroup, permission, props.match.params) ? (
            <ProtectedComponent {...props} {...this.modalActions} {...componentProps} {...this.notificationActions} />
          ) : (
              authService.isAuthenticated() ? (<Redirect to={{ pathname: "/403" }} />) // check if user is logged in then redirect to forbidden page
                : (<Redirect to={{ pathname: "/login/", prevLocation: { URL: props.history.location.pathname } }} />) // If user not logged in, send to login page and send requested URL in props
            );
        }}
      />
    );
    return (
      <Router>
        <div className="outer-container" id="outer-container">
          <div className="topheader">
            <HeaderComponent ref={this.HeaderComponentRef}></HeaderComponent>

            <div>
              <GenericModal
                ref={this.GenericModalComponent}
                children={this.state.modalContent}
                show={this.state.modalShow}
                closeCallback={this.closeModal}
                header={this.state.modalHeader}
                customClass="waiting"
                buttons={this.state.modalButtons}
              ></GenericModal>
            </div>
          </div>

          <main id="page-wrap">
            <ProtectedRoute path="/" exact={true} component={Home} permissions="SETTING"></ProtectedRoute>
            <Route path="/login/:token?" render={props => <Auth {...this.componentActions} {...props} {...this.modalActions} {...this.userActions} />}></Route>
            <ProtectedRoute path="/scan/:context?" component={Scan} permissions="SCAN"></ProtectedRoute>
            <ProtectedRoute path="/xpartscan/:context?" component={XpartScan} permissions="SCAN"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/photo/:context?" component={Photo} permissions="PHOTO"></ProtectedRoute>
            <ProtectedRoute path="/print/:context?" component={Print} permissions="PRINT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartkme" component={XpartKME} permissions="XPART_KME"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpart" component={Xpart} permissions="XPART"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpart/show/:xpart/:mode/:context?" component={Xpart} permissions="XPART_SHOW"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartKME/show/:XpartNumber/:mode?/:context?" component={XpartShowKME} permissions="XPART"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpart/technikum" component={XpartTechnikum} permissions="XPART_TECHNIKUM"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartsearch/:context?" component={XpartSearch} permissions="XPART_SEARCH"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartlist/:page/:parts" component={XpartList} permissions="XPART_LIST"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartlist/technikum/:page/:parts" component={XpartListTechnikum} permissions="XPART_LIST_TECHNIKUM"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartlist/technikum_demand/:page/:parts" component={XpartListTechnikumDemand} permissions="XPART_LIST_TECHNIKUM_DEMAND"></ProtectedRoute>
            <ProtectedRoute path="/reception/xscan/:context?" component={Reception} permissions="RECEPTION"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/reception/receptionxpart/:xpart/:context?" component={ReceptionXPart} permissions="RECEPTION"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/reception/:localstorage?/receptionxpartstorage/:xpart/setstorage/:storage/:context?" component={ReceptionXPart} permissions="RECEPTION"></ProtectedRoute>
            <ProtectedRoute path="/reception/:localstorage?/changestorage/:xpart/:mode/:context?" component={ChangeStorage} permissions="RECEPTION"></ProtectedRoute>
            <ProtectedRoute path="/storage/xscan/:context?" component={Storage} permissions="STORAGE"></ProtectedRoute>
            <ProtectedRoute path="/storage/storagexpart/:xpart/:context?" component={StorageXpart} permissions="STORAGE"></ProtectedRoute>
            <ProtectedRoute path="/storage/changestorage/:xpart/:mode/:context?" component={ChangeStorage} permissions="STORAGE"></ProtectedRoute>
            <ProtectedRoute path="/orderitem/list/:ordernumber/:context?" component={OrderItemList} permissions="ORDER_LIST"></ProtectedRoute>
            <ProtectedRoute path="/order/:ordernumber/orderitem/:orderitemnumber/overview/:context?" component={OrderItemOverview} permissions="ORDER_LIST"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/photo/gallery/:xpart/:context?" component={PhotoGallery} permissions="PHOTO"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/:module/photo/:localstorage?/gallery/:xpart/:context?" component={PhotoGallery} permissions="PHOTO"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/photo/new/:xpart/:context?" component={Photos} permissions="PHOTO"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/:module/photo/new/:xpart/:context?" component={Photos} permissions="PHOTO"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/list/:module/:page/:parts" component={ListComponent} permissions="SETTING" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/orderexport" component={OrderExport} permissions="EXPORT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/project" component={Project} permissions="PACKAGE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/project/projectxpart/:projectnr" component={ProjectXpartPackage} permissions="PACKAGE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/project/:projectnr/package/overview" component={PackageOverViewComponent} permissions="PACKAGE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/project/:projectnr/delivery/edit/:id" component={PackageOverViewComponent} permissions="PACKAGE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/iknimport" component={ImportIKNList} permissions="IKNIMPORT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/kmeimport" component={ImportKMEList} permissions="KMEIMPORT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/supplierimport" component={ImportSupplierList} permissions="SUPPLIERIMPORT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/project/deliveryview/:projectnr" component={DeliveryView} permissions="PACKAGE"></ProtectedRoute>
            <ProtectedRoute path="/reception/xpartscan/:context?" component={XpartReception} permissions="RECEPTION"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartontransportarea" component={XpartOnTransportarea} permissions="SHUTTLE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/convenience-overview/:groupId" component={ConvenienceOverviewComponent} permissions="KMEIMPORT"></ProtectedRoute>
            <ProtectedRoute path="/formfieldontransportarea/:xpartnumber/:context?" component={FormfieldOnTransportarea} permissions="SHUTTLE"></ProtectedRoute>
            <ProtectedRoute path="/assembly/:context?" component={XpartAssembly} permissions="ASSEMBLY"></ProtectedRoute>
            <ProtectedRoute path="/formfieldassembly/:xpartnumber/:context/:storagePlace?" component={FormFieldAssembly} permissions="ASSEMBLY"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/qa-approve/:page/:parts" component={QaApproveComponent} permissions="QA_KME"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/assembly-request/:page/:parts" component={AssemblyRequestXpartsViewComponent} permissions="ASSEMBLY_KME"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/assembly-request-evs/:page/:parts" component={AssemblyRequestEVSXpartsViewComponent} permissions="ASSEMBLY"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/groupxpart/:groupid?/:page/:parts" component={GroupXpart} permissions="GROUPXPARTCONTROL" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/groupxpartscan/" component={GroupXpartScan} permissions="GROUPXPARTCONTROL" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/groupidxpartview/:xpartnumber?/:page?/:parts?" component={GroupIdXpartView} permissions="GROUPXPARTCONTROL" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/wizard/transport-area" component={TransportAreaReceiveWizard} permissions="GROUPXPARTCONTROL" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/wizard/evs-receive" component={EvsReceiveWizard} permissions="LOGISTICS_RECEIVE_GOODS" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/wizard/Kommissionieren" component={KommissionierenWizard} permissions="PICKING_MOBILE" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/Kommissionieren/KommissionierenTable/:page/:parts" component={KommissionierenTableComponent} permissions="PICKING_MOBILE" ></ProtectedRoute>
            <ProtectedRoute exact={true} path="/commission-requested/:page/:parts" component={CommissionRequestedViewComponent} permissions="ASSEMBLY"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/wizard/shuttleGroupCreate" component={ShuttleGroupCreationWizard} permissions="SHUTTLE_GROUP_CREATE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/wizard/shuttleGroupReceive" component={ShuttleGroupReceptionWizard} permissions="SHUTTLE_GROUP_RECEIVE"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/Kommissionieren/supplier/KommissionierenTable/:page/:parts" component={SupplierXpartKommissionieren} permissions="PREPARE_SHIPMENT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/packages/supplier/sendpackages/:page/:parts" component={SupplierSendPackagePick} permissions="PREPARE_SHIPMENT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/test-import" component={TestImport} permissions="TEST_IMPORT"></ProtectedRoute>
            <ProtectedRoute exact={true} path="/xpartgroup/xpart-to-delivery-group/:page/:parts" component={XpartToDeliverGroupOverviewComponent} permissions="XPART_DELIVERY_GROUP"></ProtectedRoute>
            
            <ProtectedRoute path="/setting:context?" component={Setting} permissions="SETTING" componentProps={this.userActions}></ProtectedRoute>
            <ProtectedRoute path="/createuser:context?" component={CreateUser} permissions="USER_CREATE"></ProtectedRoute>
            <Route path="/resetpassword:context?" render={props => <ResetPassword {...this.componentActions} {...props} {...this.modalActions} {...this.userActions} />}></Route>
            <Route path="/reset/password/:uuid" render={props => <ConfirmPassword {...this.componentActions} {...props} {...this.modalActions} {...this.userActions} {...this.handleNotificationState} {...this.clearNotifications} {...this.notificationActions} />}></Route>
            <Route path="/403" component={Page403}></Route>
            <Refresh path="/refresh" />
            {/* <Redirect exact={true} from="/" to="/home" /> */}
          </main>
        </div>
      </Router >
    );
  }
}

export default App;
