import React from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { ConfirmationButton } from '../Common/ConfirmationButton/confirmation-button'
import { TableComponent } from './TableComponent';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LocalStorageService } from '../Common/Helper/LocalStorageService';


const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const LocalStorageHelper = new LocalStorageService();

export class SelectableTableComponent extends TableComponent {

    componentWillMount() {

        let multiSelect = {
            resultlistselected: [],
            multiSelectHide: "",
            multiSelectShow: "xpm-hidden",
            selectMode: false,
            multiSelectSelected: 0,
            localStoragePrefix: this.props.localStoragePrefix
        }

        let newState = this.state;
        newState.multiSelect = multiSelect;

        this.setState(newState);
    }

    initLocalStorage(toSelectList) {
        this.clearLocalSelection();
        localStorage.setItem(this.state.multiSelect.localStoragePrefix, JSON.stringify(toSelectList));
    }


    componentDidMount() {
        this.init();
    }

    getState() {
        return this.state;
    }

    updateConfButton(obj) {
        if (this.props.updateConfirmButton) {
            this.props.updateConfirmButton(obj);
        }
    }

    updateMultiSelectState(multiSelect) {
        if (this.props.updateMultiSelectState) {
            this.props.updateMultiSelectState(multiSelect);
        }
    }

    init() {
        if (this.props.originalSelected && this.props.originalSelected.length > 0) {
            this.initLocalStorage(this.props.originalSelected);
        }
        if (this.state.multiSelect.localStoragePrefix && this.config.validationStatusId) {
            LocalStorageHelper.removeExtraXparts(this.state.multiSelect.localStoragePrefix,
                 this.config.validationStatusId, this.config.validationContextId, this.config.rowIdField)
                .then(response => {
                    this.initMultiSelect();
                    this.fetchData();
                })
        } else {
            this.initMultiSelect();
            this.fetchData();
        }
    }

    updateLocalStoragePrefix(prefix) {
        let multiSelect = this.state.multiSelect;
        multiSelect.localStoragePrefix = prefix;
        this.setState({ multiSelect: multiSelect });
    }

    startMultiSelect() {
        let multiSelect = this.state.multiSelect;
        multiSelect.multiSelectHide = "xpm-hidden";
        multiSelect.multiSelectShow = "";
        multiSelect.selectMode = !this.state.multiSelect.selectMode;

        let newState = this.state;
        newState.multiSelect = multiSelect;

        this.setState(newState);

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.addEventListener('click', this.multiSelectEventHandler);
        });

        const iconColors = document.getElementsByClassName('icon-properties');

        Array.from(iconColors).forEach((iconColor) => {
            let svg = iconColor.getElementsByTagName('svg')[0];
            svg.style.color = '#B8B8B8';
            svg.style.cursor = 'default';

            let toggle = iconColor.getElementsByClassName("switchtoggle-wrapper")[0];
            if (toggle) {
                toggle.getElementsByTagName('input')[0].setAttribute('disabled', 'disabled')
                toggle.getElementsByTagName('label')[0].classList.add("toggle-disabled");
            }

        })

        const links = document.getElementsByClassName('link-properties');

        Array.from(links).forEach((link) => {
            let a = link.getElementsByTagName('a')[0];
            a.style.color = '#B8B8B8'
            a.style.cursor = 'default';
            a.addEventListener('click', this.blankHrefHandler);
        })

        this.updateMultiSelectState(newState.multiSelect);
    }

    blankHrefHandler(e) {
        e.preventDefault();
    }


    stopMultiSelect() {

        let multiSelect = this.state.multiSelect;
        multiSelect.multiSelectHide = "";
        multiSelect.multiSelectShow = "xpm-hidden";
        multiSelect.selectMode = !this.state.multiSelect.selectMode;

        let newState = this.state;
        newState.multiSelect = multiSelect;

        this.setState(newState);

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.removeEventListener('click', this.multiSelectEventHandler);
        });

        const iconColors = document.getElementsByClassName('icon-properties');

        Array.from(iconColors).forEach((iconColor) => {
            let svg = iconColor.getElementsByTagName('svg')[0];
            svg.style.color = 'rgb(93, 187, 161)'
            svg.style.cursor = 'pointer'

            let toggle = iconColor.getElementsByClassName("switchtoggle-wrapper")[0];
            if (toggle) {
                toggle.getElementsByTagName('input')[0].removeAttribute('disabled')
                toggle.getElementsByTagName('label')[0].classList.remove("toggle-disabled");
            }
        })

        const links = document.getElementsByClassName('link-properties');

        Array.from(links).forEach((link) => {
            let a = link.getElementsByTagName('a')[0];
            a.style.color = 'rgb(93, 187, 161)';
            a.style.cursor = 'pointer';
            a.removeEventListener('click', this.blankHrefHandler);
            //    a.setAttribute('href', a.hrefActive);
        })
        this.updateMultiSelectState(newState.multiSelect);

    }
    createCountMessage() {
        return this.LabelFile['texts'][this.config.confirmButtonMessage].replace('$COUNT', this.state.multiSelect.multiSelectSelected);
    }

    clearLocalSelection() {
        if (localStorage.getItem(this.state.multiSelect.localStoragePrefix)) {
            localStorage.removeItem(this.state.multiSelect.localStoragePrefix);

            let multiSelect = this.state.multiSelect;
            multiSelect.multiSelectSelected = 0;
            multiSelect.resultlistselected = [];

            this.setState({ multiSelect: multiSelect });
            this.updateMultiSelectState(multiSelect);
        }
    }

    setSelectDefaults() {
        if (localStorage.getItem(this.state.multiSelect.localStoragePrefix)) {
            let resultmulti = JSON.parse(localStorage.getItem(this.state.multiSelect.localStoragePrefix));

            let multiSelect = this.state.multiSelect;
            multiSelect.resultlistselected = resultmulti;
            multiSelect.multiSelectSelected = resultmulti.length;
            this.setState({
                multiSelect: multiSelect
            })

            this.updateMultiSelectState(multiSelect);

        }
    }

    initMultiSelect() {

        this.setSelectDefaults();

        this.multiSelectEvent = (e) => {
            e.stopPropagation();
            e.currentTarget.classList.toggle("multiselect-selected");
            let resultlistselected = this.state.multiSelect.resultlistselected;

            if (!resultlistselected.includes(e.currentTarget.dataset.attr)) {
                resultlistselected.push(e.currentTarget.dataset.attr);
            } else {
                if (resultlistselected.indexOf(e.currentTarget.dataset.attr) >= 0) {
                    resultlistselected.splice(resultlistselected.indexOf(e.currentTarget.dataset.attr), 1);
                }
            }

            let multiSelect = this.state.multiSelect;
            multiSelect.multiSelectSelected = resultlistselected.length;
            multiSelect.resultlistselected = resultlistselected;

            let newState = this.state;
            newState.multiSelect = multiSelect;

            localStorage.setItem(this.state.multiSelect.localStoragePrefix, JSON.stringify(resultlistselected));

            this.setState(newState);
            this.updateMultiSelectState(multiSelect);

        }
        this.multiSelectEventHandler = this.multiSelectEvent.bind(this);
        this.blankHrefHandler = this.blankHrefHandler.bind(this);

    }


    getSelectedIds() {
        return JSON.parse(localStorage.getItem(this.state.multiSelect.localStoragePrefix));
    }

    onConfirmSuccess() {
        localStorage.removeItem(this.state.multiSelect.localStoragePrefix);
        localStorage.removeItem(this.config.deliveryLocationPrefix);
        this.stopMultiSelect();
        this.props.refresh();
    }

    confirmationButtonClick() {
        let context = this.config.targetContextId;
        let status = this.config.targetStatusId;
        let storagetarget = this.config.storagetarget;
        let xparts = JSON.parse(localStorage.getItem(this.state.multiSelect.localStoragePrefix));
        let targetStoragePlace = null;
        let removeFromRequestGroup = this.config.removeFromRequestGroup ? this.config.removeFromRequestGroup : false;

        // if: checks if xpart is empty
        if (xparts === null || xparts.length <= 0) {
            this.props.refresh();
            return;
        }

        // if: checks if targetStoragePlace is avaible
        if (this.config.targetStoragePlace) {
            targetStoragePlace = this.config.targetStoragePlace.split('-')

        }

        // initial data object
        let data = {
            "contextId": context,
            "statusId": status,
            "validationStatusId": this.config.validationStatusId,
            "validationContextId": this.config.validationContextId,
            "destinationStoragePlace": storagetarget,
            "xpartIds": xparts,
            "storageSequence": targetStoragePlace,
            "removeFromRequestGroup": removeFromRequestGroup
        }

        // request: send data object to backend
        axios.post(API_XPART.XPART_SERVICE_URL + this.config.changeStatusURL, data)
            .then(response => {
                this.onConfirmSuccess();
            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });

    }

    goToRequestedView() {
        this.props.history.push(this.config.requestedPageURL);
        this.props.refresh();
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }

    renderOptionalButtons() {
        return (


            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                    <button onClick={() => { this.startMultiSelect() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectHide}>{this.LabelFile.labels[this.config.startSelectButtonName]}</button>
                    <button onClick={() => { this.stopMultiSelect() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectShow}>{this.LabelFile.labels[this.config.cancelSelectButtonName]}</button>
                    <button onClick={() => { this.clearLocalSelection() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectShow}>{this.LabelFile.labels[this.config.resetSelectButtonName]}</button>
                    {this.config.requestedPageURL ?
                        <button onClick={() => { this.goToRequestedView() }}
                            className={"xpm-button"}>{this.LabelFile.labels[this.config.goBackButtonName]}</button>
                        // className={"xpm-button xpm-button-right "}>{this.LabelFile.labels[this.config.goBackButtonName]}</button>
                        : ''
                    }
                </div>

                {
                    !this.state.multiSelect.selectMode || this.state.multiSelect.multiSelectSelected < 1 ? null : <ConfirmationButton
                        expandedStateContent={this.createCountMessage()}
                        saveLabel={this.config.saveLabel}
                        compressedStateContent={this.createCountMessage()}
                        mobileStateContent={this.state.multiSelect.multiSelectSelected + " " + this.LabelFile.labels["xparts"]}
                        onClick={this.confirmationButtonClick.bind(this)} />
                }
            </div >
        )
    }


    renderButtons() {
        let warningtext = "";

        if (!this.state.multiSelect.selectMode && this.state.multiSelect.multiSelectSelected > 0) {
            warningtext = this.LabelFile.texts[this.config.viewModeHint] + ": " + this.state.multiSelect.multiSelectSelected;
        } else if (this.state.multiSelect.selectMode) {
            warningtext = '';
        }

        return <div>
            {this.renderOptionalButtons()}
            <span className={"xpm-warning-span"}>{warningtext}</span>
        </div>
    }


    render() {
        return (
            <div>
                {!this.config.hideControlButtons ? this.renderButtons() : ''}
                {this.renderTable()}
            </div>
        );
    }

}
withRouter(SelectableTableComponent);