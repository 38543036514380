import React, { Component, Fragment } from 'react';
import { LabelService } from '../../Common/Translations/LabelService';
import { Loader } from '../../Common/Components/Loader/loader';
import { APINotifications } from '../../Common/API/APINotifications';
import axios from 'axios';
import { API } from '../../Common/API/API';

const LabelFile = LabelService.getLabelsByLocal('de');
const GLOBAL_API = new API();
const NOTIFICATIONS = new APINotifications();

class ChangePasswordComponent extends Component {

    state = {
        isLoading: false
    }

    updateLoading = (isLoading) => {
        this.setState({ isLoading: isLoading });
    }


    changePasswordValidInputClass(id, isValid) {
        document.getElementById(id).className = isValid ? 'form-input-properties' : 'form-input-properties form-warning';
    }

    changePassword = () => {
        let oldPasword = document.getElementById('old-password').value;
        let newPasword = document.getElementById('new-password').value;
        let newPasswordConfirm = document.getElementById('new-password-confirm').value;

        if (!this.validateInputs(oldPasword, newPasword, newPasswordConfirm) || !this.checkPasswordEqual(newPasword, newPasswordConfirm)) {
            return;
        }
        this.saveNewPassword(oldPasword, newPasword, newPasswordConfirm);
    }

    isPasswordValid = (password) => {
        return password !== null && password.length > 5;
    }

    validateInputs = (oldPasword, newPasword, newPasswordConfirm) => {
        let oldPasswordValid = this.isPasswordValid(oldPasword);
        let newPasswordValid = this.isPasswordValid(newPasword);
        let newPasswordConfirmValid = this.isPasswordValid(newPasswordConfirm);

        this.changePasswordValidInputClass('old-password', oldPasswordValid);
        this.changePasswordValidInputClass('new-password', newPasswordValid);
        this.changePasswordValidInputClass('new-password-confirm', newPasswordConfirmValid);

        return oldPasswordValid && newPasswordValid && newPasswordConfirmValid;
    }
    checkPasswordEqual = (newPasword, newPasswordConfirm) => {

        if (newPasword === newPasswordConfirm) {
            return true;
        }

        this.changePasswordValidInputClass('new-password', false);
        this.changePasswordValidInputClass('new-password-confirm', false);
        return false;
    }

    saveNewPassword = (oldPasword, newPasword, newPasswordConfirm) => {

        let data = {
            userName: this.props.currentUser.userName,
            oldPassword: oldPasword,
            newPassword: newPasword,
            confirmNewPassword: newPasswordConfirm
        }
        this.updateLoading(true);

        axios.post(GLOBAL_API.AUTH_SERVICE_URL + '/user/password', data)
            .then(response => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getNotification('de', 'SUCCESS_PASSWORD_CHANGED'),
                    header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                    buttons: this.createOKModalButtons()
                });
            })
            .catch(error => {
                let content = error.response.data.message !== null ? error.response.data.message : error.response.data.errors[0];

                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, content),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => {
                this.updateLoading(false);
            })
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);
        return btns;
    }

    render() {
        return (
            <Fragment>
                <div className="form-left">
                    <div className="form-left-alignment">
                        <div className="form-title-left">
                            {LabelFile.labels['changePassword']}
                        </div>
                    </div>
                </div>
                <div className="form-right">
                    <input id='old-password' type="password" placeholder={LabelFile.labels['old-password']} className="form-input-properties" />
                    <br />
                    <br />
                    <input id='new-password' type="password" placeholder={LabelFile.labels['new-password-six-signs']} className="form-input-properties" />
                    <br />
                    <br />
                    <input id='new-password-confirm' type="password" placeholder={LabelFile.labels['new-password-confirm']} className="form-input-properties" />
                    <br />
                    <br />
                    <button type="button" onClick={this.changePassword} className="setting-button-properties">
                        {LabelFile.labels['change']}
                    </button>
                </div>
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </Fragment>
        )
    }

}
export default ChangePasswordComponent;
