import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();

export default class PackageSaveButtonComponent extends Component {

    state = {
        show: false,
        selectedCount: 0
    }

    changeSelectedCount(count) {
        this.setState({selectedCount: count});
    }

    changeDisplay(show) {
        this.setState({ show: show });
    }

    createCountMessage() {
        return LabelFile['texts']['COUNT_OF_SELECTED_PACKAGES_TO_DELIVER'].replace('$COUNT', this.state.selectedCount);
    }

    saveDelivery() {
        const packagesToAdd = this.props.getPackagesToAdd();
        const packagesToRemove = this.props.getPackagesToRemove();
        let deliveryObjectToDelete = this.props.getDeliveryObjectToEdit();

        deliveryObjectToDelete = deliveryObjectToDelete === undefined ? null : deliveryObjectToDelete;

        const info = { id: deliveryObjectToDelete };

        const payload = {
            info,
            packagesToAdd,
            packagesToRemove
        };

        this.props.toggleLoading(true);
        axios.post(API_XPART.XPART_SERVICE_URL + '/transportation/', payload)
            .then(res => {
                this.props.clearLocalSelection();
            })
            .catch(err => {
                this.props.toggleLoading();
                this.props.showException(err);
            })
    }

    renderButton() {
        return (
            <button onClick={() => { this.saveDelivery() }}
                    className={"xpm-button button-info "}>
                <div className="button-info-line-top">{this.createCountMessage()}</div>
                <div className="button-info-line-bottom">
                    <FontAwesomeIcon icon={['fas', 'save']} />&nbsp;{LabelFile.labels["selection"]}&nbsp;{LabelFile.labels["save"]}
                </div>
            </button>
        );
    }

    render() {
        return (this.state.show && this.state.selectedCount > 0) ? this.renderButton() : null
    }
}
