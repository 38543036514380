import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { LabelService } from '../Common/Translations/LabelService';
import { HelperFunctions } from '../Common/Helper/Helper';
import './orderItemOverview.css';
import { NewXpartInputMask } from './NewXpartInputMask';
import { Loader } from '../Common/Components/Loader/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchToggleComponent from "../List/SwitchToggle/SwitchToggle";

const API_ORDER = new API();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class OrderItemOverview extends Component {
    constructor(props) {
        super(props);
        this.labels = LabelFile.labels;
        this.resultlistarr = [];
        this.orderInfo = {};
        this.infoBoxOrderItemInfos = {};
        this.checked = false;
    }

    state = {
        resultlist: [],
        resultlistarr: [],
        infoBoxOrderItemInfo: {},
        orderInfo: {},
        isLoading: false
    }

    componentDidMount() {
        this.handleLoad();
        this.loadInfoBoxOrder();
        this.loadInfoBoxOrderItem();
        /*
        console.log('this.props.match.params.ordernumber:'+ this.props.match.params.ordernumber);
        console.log('this.props.match.params.itemNumber:' + this.props.match.params.orderitemnumber);        
        */
    }

    formatData(data) {
        let lang = HelperFunction.getLocale();
        data.forEach(function (xpart) {

            xpart['weight'] = HelperFunction.formatFloats(xpart['weight'], lang);

            xpart['status'] = LabelFile.statuses[xpart['currentStatus']['statusDTO']['name']] + " [" + LabelFile.context[xpart['currentStatus']['contextDTO']['name']] + "]";


            xpart["additionalInfo"] = LabelFile.labels['created'] + ": " + HelperFunction.timeConverter(xpart['created'], lang) + "; " +
                LabelFile.labels['updated'] + ": " + HelperFunction.timeConverter(xpart['updated'], lang);
        })
        return data;
    }

    handleLoad() {

        let orderHandle = this.props.match.params.ordernumber;
        let orderItemHandle = this.props.match.params.orderitemnumber;

        let order = "orderNumber=" + orderHandle;
        let item = "itemNumber=" + orderItemHandle;
        let type = "orderType=1";

        axios.get(API_ORDER.XPART_SERVICE_URL + '/xpartview/in-order-item?' + order + '&' + item + '&' + type)
            .then(response => {
                this.resultlistarr = response["data"];
                this.resultlistarr = this.formatData(this.resultlistarr);

                this.setState({
                    resultlist: this.resultlistarr.sort((a, b) => this.xpartNumberSortFunction(a.xpartNumber, b.xpartNumber)),
                })
            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    xpartNumberSortFunction(xpart1, xpart2) {
        let xpart1Elements = xpart1.split('-')
        let xpart2Elements = xpart2.split('-')
        if (+xpart1Elements[0] === +xpart2Elements[0]) {
            return +xpart1Elements[1] - +xpart2Elements[1]
        }
        return +xpart1Elements[0] - +xpart2Elements[0]
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }



    loadInfoBoxOrder() {
        let ordernumberx = this.props.match.params.ordernumber;
        let ordernumber;
        let deliverdate;
        let orderitemsCounts;
        let orderdate;
        let orderStatus;
        let lang = HelperFunction.getLocale();

        axios.get(API_ORDER.XPART_SERVICE_URL + '/orderview/' + ordernumberx + '/1')
            .then(response => {

                ordernumber = response.data["orderNumber"];
                orderitemsCounts = response.data["itemsCount"];
                orderdate = HelperFunction.timeConverter(response.data["created"], lang, true);
                deliverdate = response.data["deliveryDate"] === null ? null : HelperFunction.timeConverter(response.data["deliveryDate"], lang, true);
                orderStatus = response.data['orderStatus'] ? LabelFile.statuses[response.data['orderStatus']['name']] : "";
                response.data.companies.forEach(res => {
                    if (res["type"]["name"] === "CUSTOMER") {
                        this.orderInfo.customer = res["name"];
                    }
                    if (res["type"]["name"] === "SUPPLIER") {
                        this.orderInfo.supplier = res["name"];
                    }
                })
                this.orderInfo.orderStatus = orderStatus;
                this.orderInfo.ordernumber = ordernumber;
                this.orderInfo.orderitemsCounts = orderitemsCounts;
                this.orderInfo.orderdate = orderdate;
                this.orderInfo.deliverydate = deliverdate;

                this.setState({
                    orderInfo: this.orderInfo
                })
            })

            .catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    loadInfoBoxOrderItem() {
        let ordernumberx = this.props.match.params.ordernumber;
        let orderitemNumberx = this.props.match.params.orderitemnumber;
        let ordernumber;
        let orderItemnumber;
        let projectnumber;
        let itemProjectNumber;
        let itemDescription;
        let quantity;
        let alreadyClosed;
        let deliverdate;
        let xpartsQuantity;
        let status;
        let id;
        let lang = HelperFunction.getLocale();
        let materialNumbers;
        let completed;

        axios.get(API_ORDER.XPART_SERVICE_URL + '/item/?orderNumber=' + ordernumberx + '&itemNumber=' + orderitemNumberx + '&typeName=ORDER')
            .then(response => {
                console.log(response)
                ordernumber = response.data["orderNumber"];
                orderItemnumber = response.data["itemNumber"];
                projectnumber = response.data["projectNumber"];
                itemDescription = response.data["itemDescription"];
                id = response.data["id"];
                quantity = response.data["quantity"];
                alreadyClosed = "";
                deliverdate = response.data["deliveryDate"] === null ? null : HelperFunction.timeConverter(response.data["deliveryDate"], lang, true);
                xpartsQuantity = response.data["xpartsQuantity"];
                status = response.data['itemStatus'] ? LabelFile.statuses[response.data['itemStatus']['name']] : "";
                itemProjectNumber = response.data["itemProjectNumber"];
                materialNumbers = response.data["materialNumbers"];
                completed = response.data["completed"];

                this.infoBoxOrderItemInfos.completed = completed;
                this.infoBoxOrderItemInfos.id = id;
                this.infoBoxOrderItemInfos.orderNumber = ordernumber;
                this.infoBoxOrderItemInfos.orderItemNumber = orderItemnumber;
                this.infoBoxOrderItemInfos.projectNumber = projectnumber;
                this.infoBoxOrderItemInfos.itemDescription = itemDescription;
                this.infoBoxOrderItemInfos.quantity = quantity;
                this.infoBoxOrderItemInfos.alreadyClose = alreadyClosed;
                this.infoBoxOrderItemInfos.deliverDate = deliverdate;
                this.infoBoxOrderItemInfos.xpartsQuantity = xpartsQuantity;
                this.infoBoxOrderItemInfos.status = status;
                this.infoBoxOrderItemInfos.itemProjectNumber = itemProjectNumber;
                this.infoBoxOrderItemInfos.materialNumbers = materialNumbers;


                this.setState({
                    infoBoxOrderItemInfo: this.infoBoxOrderItemInfos
                })
            })

            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }

    updateXpart(data) {
        this.toggleLoading(true);
        axios.post(API_ORDER.XPART_SERVICE_URL + '/one/properties', data)
            .then(res => {
                this.props.history.replace('/refresh' + window.location.pathname);
                this.props.closeModal();
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.toggleLoading(false));
    }

    saveNewXpart(data) {
        data['orderNumber'] = this.props.match.params.ordernumber
        data['itemNumber'] = this.props.match.params.orderitemnumber;
        data['orderTypeId'] = 1
        this.toggleLoading(true)
        axios.post(API_ORDER.XPART_SERVICE_URL + '/one', data)
            .then(res => {
                HelperFunction.getPdfDocument(API_ORDER.FILE_SERVICE_URL + '/x-part/' + res.data.xpartNumber)
                    .then(() => {
                        this.props.history.replace('/refresh' + window.location.pathname);
                        this.props.closeModal();
                    })

            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
    }

    openModalXpartInputMask(xpart) {

        this.props.fireModal({
            content: <NewXpartInputMask
                xpart={xpart}
                saveNewXpart={(data) => this.saveNewXpart(data)}
                closeModal={this.props.closeModal}
                updateXpart={(data) => this.updateXpart(data)} />,
            type: 'wide'
        })
    }

    renderAddXpartButton() {
        if (this.state.isLoading) {
            return ''
        }
        return (
            <div onClick={() => this.openModalXpartInputMask()} className="xpm-button add-xpart-button">{LabelFile.labels["addxpart"]}</div>
        )
    }

    //Change completion status
    async changeOrderItemCompletionStatus(requested) {
        return await axios.post(API_ORDER.XPART_SERVICE_URL + '/item/completed', { "completed": !requested.rowData[0].completed, "items": requested.objectId });
    }

    renderOrderItemCompletionToggle() {
        let result = this.infoBoxOrderItemInfos;
        if (this.state.isLoading) {
            return ''
        }
        return (
            <div>
                <SwitchToggleComponent id={'toggle-' + result.id} rowData={result} checked={result.completed ? result.completed : this.checked} onColor="#EF476F"
                    objectId={result.id} handleToggle={(e) => this.changeOrderItemCompletionStatus(e)} disabled={false}
                    postChange={null} />
            </div>)
    }

    renderHeaderInfoBoxOrder() {
        return (
            <section >
                <div class="info-table">
                    <div class="info-table-title">{this.labels['ordertitle'] + " " + this.props.match.params.ordernumber}</div>
                    <div className="info-table-inner-container">
                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['customer']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.customer}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{LabelFile.labels['supplier']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.supplier}</div>
                            </div>
                        </div>
                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['orderdate']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderdate}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['deliverydate']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.deliverydate}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['amountoforderitems']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderitemsCounts}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['status']}:</div>
                                <div class="info-table-cell">{this.state.orderInfo.orderStatus}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderHeaderInfoBoxOrderItem() {
        return (
            <div>
                <div class="info-table">
                    <div class="info-table-title">{this.labels['orderitemtitle']}&nbsp;{this.state.infoBoxOrderItemInfo.orderItemNumber}</div>

                    <div className="info-table-inner-container">
                        <div className="info-table-row">
                            <div className="info-table-attribute-container description-cell">
                                <div class="info-table-cell">{this.labels['desc_de']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.itemDescription}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['projectlabel']}&nbsp;/ {this.labels['orderitemlabel']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.projectNumber}&nbsp;/ {this.state.infoBoxOrderItemInfo.itemProjectNumber}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['deliverydate']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.deliverDate}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['amountoforderlabel']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.quantity}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['amountofxpartlabel']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.xpartsQuantity}</div>
                            </div>
                        </div>

                        <div className="info-table-row">
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['status']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.status}</div>
                            </div>
                            <div className="info-table-attribute-container">
                                <div class="info-table-cell">{this.labels['materialNnumber']}:</div>
                                <div class="info-table-cell">{this.state.infoBoxOrderItemInfo.materialNumbers}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <section className="results--section">
                {this.renderHeaderInfoBoxOrder()}
                {this.renderHeaderInfoBoxOrderItem()}
                <div className="order-item-list-container">
                    <div className="order-item-completion-status">
                        <span className="order-item-completion-status">{this.labels['completedStatus']}{':'}{this.renderOrderItemCompletionToggle()}</span>
                    </div>
                    {this.renderAddXpartButton()}
                    <div class="list-grid">
                        <div class="list-grid-row list-grid-row-headline order-item-overview-list-grid-row-override">
                            <div class="list-grid-item">
                                <span>{this.labels['xpart']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['desc_de']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['desc_en']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['quantity']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['weight']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['status']}</span>
                            </div>
                            <div class="list-grid-item">
                                <span>{this.labels['functions']}</span>
                            </div>
                        </div>
                        {this.state.resultlist.map(result => (
                            <div class="list-grid-row order-item-overview-list-grid-row-override">
                                <div data-name={this.labels['xpart']} class="list-grid-item">
                                    <span title={result["additionalInfo"]}>{result['xpartNumber']}</span>
                                </div>

                                <div data-name={this.labels['desc_de']} class="list-grid-item">
                                    <span title={result['descriptionDe']}>{result['descriptionDe']}</span>
                                </div>
                                <div data-name={this.labels['desc_en']} class="list-grid-item">
                                    <span title={result['descriptionEn']}>{result['descriptionEn']}</span>
                                </div>

                                <div data-name={this.labels['quantity']} class="list-grid-item">
                                    <span>{result['quantity']}</span>
                                </div>
                                <div data-name={this.labels['weight']} class="list-grid-item">
                                    <span>{result['weight']}</span>
                                </div>

                                <div data-name={this.labels['status']} class="list-grid-item">
                                    <span>{result['status']}</span>
                                </div>
                                <div data-name={this.labels['functions']} class="list-grid-item">
                                    <FontAwesomeIcon className="active-element" icon="edit" onClick={() => this.openModalXpartInputMask(result)} />
                                    {' '}
                                    <FontAwesomeIcon className="active-element" icon="print" onClick={() => HelperFunction.getPdfDocument(API_ORDER.FILE_SERVICE_URL + '/x-part/' + result['xpartNumber'])} />
                                </div>
                            </div>
                        ))}
                    </div>
                    {this.renderAddXpartButton()}
                </div>
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </section>
        )
    }
}

export default withRouter(OrderItemOverview);
