import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BurgerMenu from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabelService } from '../Common/Translations/LabelService'
import { AuthService } from './../Common/Service/AuthService'
import './Menu.css'

const authService = new AuthService()

class Menu extends Component {
  constructor(props) {
    super(props)
    this.labelFile = LabelService.getLabelsByLocal('de')
    this.state = {
      currentMenu: 'slide',
      side: 'left',
      menuOpen: false
    }
  }
  handleStateChange(state) {
    document.getElementsByClassName('bm-burger-button')[0].classList.toggle('bm-burger-button-click')
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }
// scan, photo and xpart scan has been commented out from the menu as they were developed for test phases and no user needs to see them
  getItems() {
    const items = [
      <Link onClick={() => this.closeMenu()} access="SETTING" key="0" to={'/'}><FontAwesomeIcon icon={['fas', 'home']} /><span>{this.labelFile.menues["HOME"]}</span></Link>,
      // <Link access="SCAN" key="1" to={'/scan'}><FontAwesomeIcon icon={['fas', 'qrcode']} /><span>{this.labelFile.menues["SCAN"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="XPART_LIST" key="6" to={'/list/xpartOverview/1/100'}><FontAwesomeIcon icon={['fas', 'newspaper']} /><span>{this.labelFile.menues["XPARTLIST"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='ORDER_LIST' key="9" to={'/list/orders/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["ORDERLIST"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="EXPORT" key="16" to={'/orderexport'}><FontAwesomeIcon icon={['fas', 'download']} /><span>{this.labelFile.menues["ORDEREXPORT"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="XPART_KME" key="18" to={'/xpartkme'}><FontAwesomeIcon icon={['fas', 'cubes']} /><span>{this.labelFile.menues["XPART"]}&nbsp;KME</span></Link>,
      <Link onClick={() => this.closeMenu()} access="XPART" key="4" to={'/xpart'}><FontAwesomeIcon icon={['fas', 'cubes']} /><span>{this.labelFile.menues["XPART"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access='XPART_SEARCH' key="5" to={'/xpartsearch'}><FontAwesomeIcon icon={['fas', 'search']} /><span>{this.labelFile.menues["XPARTSEARCH"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access='RECEPTION' key="7" to={'/reception/xscan'}><FontAwesomeIcon icon={['fas', 'cogs']} /><span>{this.labelFile.menues["RECEPTION"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="RECEPTION" key="21" to={'/reception/xpartscan'}><FontAwesomeIcon icon={['fas', 'cogs']} /><span>{this.labelFile.menues['RECEPTION']}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='STORAGE' key="8" to={'/storage/xscan'}><FontAwesomeIcon icon={['fas', 'grip-horizontal']} /><span>{this.labelFile.menues["STORAGE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='ASSEMBLY_KME' key="25" to={'/assembly-request/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["ASSEMBLY_KME"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='ASSEMBLY_KME' key="55" to={'/list/requestedKME/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["REQUESTED_KME"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='ASSEMBLY' key="24" to={'/assembly-request-evs/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["ASSEMBLY_EVS"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="PACKAGE" key="15" to={'/project'}><FontAwesomeIcon icon={['fas', 'box-open']} /><span>{this.labelFile.menues["GEN_PACKAGE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="IKNIMPORT" key="16" to={'/iknimport'}><FontAwesomeIcon icon={['fas', 'file-import']} /><span>{this.labelFile.menues["IKNIMPORT"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="KMEIMPORT" key="17" to={'/kmeimport'}><FontAwesomeIcon icon={['fas', 'file-import']} /><span>{this.labelFile.menues["KMEIMPORT"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="SUPPLIERIMPORT" key="17" to={'/supplierimport'}><FontAwesomeIcon icon={['fas', 'file-import']} /><span>{this.labelFile.menues["SUPPLIERIMPORT"]}</span></Link>,

      <Link onClick={() => this.closeMenu()} access="SHUTTLE" key="22" to={'/xpartontransportarea'}><FontAwesomeIcon icon={['fas', 'cubes']} /><span>{this.labelFile.menues['RECEIVEXPART']}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="ASSEMBLY" key="23" to={'/assembly'}><FontAwesomeIcon icon={['fas', 'cubes']} /><span>{this.labelFile.menues['ASSEMBLY']}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="QA_KME" key="27" to={'/qa-approve/1/100'}><FontAwesomeIcon icon={['fas', 'cubes']} /><span>{this.labelFile.menues['QA_APPROVE']}</span></Link>,

      <Link onClick={() => this.closeMenu()} access='ASSEMBLY' key="28" to={'/commission-requested/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["COMMISSIONING"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='ASSEMBLY' key="29" to={'/list/acceptRequestedXparts/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["ACCEPT_REQUESTED_XPARTS"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='GROUPXPARTCONTROL' key="30" to={'/groupxpart/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["XPARTGROUP"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='XPART_DELIVERY_GROUP' key="60" to={'/xpartgroup/xpart-to-delivery-group/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["XPART_TO_DELIVERY_GROUP"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='XPART_DELIVERY_GROUP' key="61" to={'/list/deliveryOverview/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["delivery_overview"]}</span></Link>,

      <Link onClick={() => this.closeMenu()} access='GROUPXPARTCONTROL' key="31" to={'/groupxpartscan/'}><FontAwesomeIcon icon={['fas', 'qrcode']} /><span>{this.labelFile.menues["XPARTGROUPSCAN"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='GROUPXPARTCONTROL' key="32" to={'/list/convenience-groups/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["GROUP_CHANGE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='GROUPXPARTCONTROL' key="33" to={'/wizard/transport-area'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["STORAGE_ACCEPTENCE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='LOGISTICS_RECEIVE_GOODS' key="34" to={'/wizard/evs-receive'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["LOGISTICS_CENTER_RECEIVE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='PICKING_MOBILE' key="35" to={'/Kommissionieren/KommissionierenTable/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["KOMMISSIONIEREN"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='SHUTTLE_GROUP_CREATE' key="36" to={'/wizard/shuttleGroupCreate'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["TRANSPORT_GROUP_CREATE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='SHUTTLE_GROUP_RECEIVE' key="37" to={'/wizard/shuttleGroupReceive'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["TRANSPORT_GROUP_RECEIVE"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='PREPARE_SHIPMENT' key="38" to={'/Kommissionieren/supplier/KommissionierenTable/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["SUPPLIER_KOMMISSIONIEREN"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='PREPARE_SHIPMENT' key="39" to={'/packages/supplier/sendpackages/1/100'}><FontAwesomeIcon icon={['fas', 'list-alt']} /><span>{this.labelFile.menues["SUPPLIER_PACKAGE_SEND"]}</span></Link>,

      <Link onClick={() => this.closeMenu()} access="XPART_TECHNIKUM" key="50" to={'/xpart/technikum'}><FontAwesomeIcon icon={['fas', 'keyboard']} /><span>{this.labelFile.menues["XPART_TECHNIKUM"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="XPART_LIST_TECHNIKUM" key="51" to={'/xpartlist/technikum/1/100'}><FontAwesomeIcon icon={['fas', 'newspaper']} /><span>{this.labelFile.menues["XPARTLIST_TECHNIKUM"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="XPART_LIST_TECHNIKUM_DEMAND" key="52" to={'/list/technikumDemand/1/100'}><FontAwesomeIcon icon={['fas', 'newspaper']} /><span>{this.labelFile.menues["XPARTLIST_TECHNIKUM_DEMAND"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access='RECEPTION' key="53" to={'/reception/xscan/EVS'}><FontAwesomeIcon icon={['fas', 'cogs']} /><span>{this.labelFile.menues["RECEPTION_SERVICE_PROVIDER"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="RECEPTION_TECHNIKUM" key="54" to={'/reception/xscan/TECHNIKUM'}><FontAwesomeIcon icon={['fas', 'cogs']} /><span>{this.labelFile.menues["RECEPTION_TECHNIKUM"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="SCAN" key="56" to={'/xpartscan'}><FontAwesomeIcon icon={['fas', 'qrcode']} /><span>{this.labelFile.menues['SCAN']}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="PHOTO" key="57" to={'/photo'}><FontAwesomeIcon icon={['fas', 'camera']} /><span>{this.labelFile.menues["PHOTO"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="PRINT" key="58" to={'/print'}><FontAwesomeIcon icon={['fas', 'print']} /><span>{this.labelFile.menues["PRINT"]}</span></Link>,
      <Link onClick={() => this.closeMenu()} access="EVS_XPART_OVERVIEW" key="6" to={'/list/evsXpartsOverview/1/100'}><FontAwesomeIcon icon={['fas', 'newspaper']} /><span>{this.labelFile.menues["XPARTLIST_EVS"]}</span></Link>,
      // <Link onClick={() => this.closeMenu()} access="TEST_IMPORT" key="59" to={'/test-import'}><FontAwesomeIcon icon={['fas', 'file-import']} /><span>{this.labelFile.menues["TEST_IMPORT"]}</span></Link>
    ];


    return items.filter(item => authService.isAccessAllowed(item.props.access));
  }
  openMenuOnClick = () => {
    document.getElementsByClassName('bm-burger-button')[0].classList.toggle('bm-burger-button-click')
  }

  getMenu() {
    const Menu = BurgerMenu[this.state.currentMenu]
    const items = this.getItems()

    const jsx = (
      <Menu
        id={this.state.currentMenu}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        {items}
      </Menu>
    )


    return jsx
  }

  render() {
    return (
      <div id="outer-container" style={{ height: '100%' }}>
        {this.getMenu()}
      </div>
    )
  }
}

export default Menu
