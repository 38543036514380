import React, { Component } from 'react'
import TableConfig from './../tables-config.json';

import { SearchTerms } from "./SearchTerms";
import UniversalSearch from "./UniversalSearch";
import SpecialSearch from './SpecialSearch';
import ManageQueryStorage from './helpers/ManageQueryStorage'

import './table-filter.css'
// import { runInThisContext } from 'vm';

export class TableFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            simpleQueries: [],
            specialQueries: [],
            iconFlag: false,
            isSpecialFilterVisible: false,
        };

        this.config = TableConfig[this.props.module];
        this.manageUniversalQueryStorage = new ManageQueryStorage(this.props.module, 'universal-search');
        this.manageSpecialQueryStorage = new ManageQueryStorage(this.props.module, 'special-search');

        this.specialSearch = React.createRef();
        this.universalSearch = React.createRef();

        this.doLiveSimpleQuery = this.doLiveSimpleQuery.bind(this);
        this.addSimpleQuery = this.addSimpleQuery.bind(this);
        this.addSpecialQueries = this.addSpecialQueries.bind(this);
        this.removeAllQuery = this.removeAllQuery.bind(this)
        this.removeSimpleQuery = this.removeSimpleQuery.bind(this);
        this.removeSpecialQuery = this.removeSpecialQuery.bind(this);

        this.toggleSpecialFilter = this.toggleSpecialFilter.bind(this);
        this.submitFilters = this.submitFilters.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState(prevState => ({
                isSpecialFilterVisible: false
            }))
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    doLiveSimpleQuery(simpleQuery) {
        const liveUpdates = { simpleQuery, specialQuery: null };
        this.updateFilters(true, liveUpdates);
    }

    addSimpleQuery(simpleQuery) {
        const simpleQueries = [...this.state.simpleQueries, simpleQuery];
        this.setState(Object.assign({}, this.state, { simpleQueries }),
            () => {
                this.updateFilters();
                this.manageUniversalQueryStorage.setQueries(this.state.simpleQueries);
            })
    }

    addSpecialQueries(specialQueries) {
        this.setState(Object.assign({}, this.state, { specialQueries }),
            () => {
                this.updateFilters();
                this.manageSpecialQueryStorage.setQueries(this.state.specialQueries);
            })
    }

    async removeAllQuery() {
        this.specialSearch.current.updateAllFieldValues(this.state.specialQueries);
        
        await this.setState(Object.assign({}, this.state, {simpleQueries: [], specialQueries: []}),
        () => {
            this.updateFilters();
            this.manageUniversalQueryStorage.setQueries(this.state.simpleQueries);
            this.manageSpecialQueryStorage.setQueries(this.state.specialQueries);
        });
    }

    removeSimpleQuery(index) {
        const { simpleQueries } = this.state;
        const updatedSimpleQueries = [
            ...simpleQueries.slice(0, index),
            ...simpleQueries.slice(index + 1)
        ];
        this.setState(Object.assign({}, this.state, { simpleQueries: updatedSimpleQueries }),
            () => {
                this.updateFilters();
                this.manageUniversalQueryStorage.setQueries(this.state.simpleQueries);
            });
    }

    removeSpecialQuery(index) {
        const { specialQueries } = this.state;
        const removedSpecialQuery = specialQueries[index];
        const { queryData } = removedSpecialQuery;

        // WARNING: use ref to mutate child
        this.specialSearch.current.clearFieldValue({ queryData });

        const updatedSpecialQueries = [
            ...specialQueries.slice(0, index),
            ...specialQueries.slice(index + 1)
        ];
        this.setState(Object.assign({}, this.state, { specialQueries: updatedSpecialQueries }),
            () => {
                this.updateFilters();
                this.manageSpecialQueryStorage.setQueries(this.state.specialQueries);
            });
    }

    mapSearchTermsToFilters(searchTerm) {
        const { filter } = this.config;

        return filter.map(fieldName => ({
            fieldName,
            fieldValue: searchTerm,
            searchType: 'LIKE'
        }));
    }

    mapSimpleQueriesToFields({ simpleQuery }) {
        const { simpleQueries } = this.state;
        const queries = (simpleQuery) ? [simpleQuery, ...simpleQueries] : simpleQueries;

        return queries.map(simpleQuery => {
            const { queryData } = simpleQuery;
            const { fieldValue } = queryData;
            const fields = this.mapSearchTermsToFilters(fieldValue);
            return ({ fields });
        })
    }

    mapSpecialQueriesToGroups({ specialQuery }) {
        const { specialQueries } = this.state;
        const queries = (specialQuery) ? specialQuery : specialQueries;
        const groups = queries.map(specialQuery => {
            const { queryData } = specialQuery;
            let group = { fields: [Object.assign({}, queryData, { searchType: 'LIKE' })] }
            return group;
        });
        return (groups.length ? groups : []);
    }

    mapSpecialQueriesToFields({ specialQuery }) {
        const { specialQueries } = this.state;
        const queries = (specialQuery) ? specialQuery : specialQueries;

        const fields = queries.map(specialQuery => {
            const { queryData } = specialQuery;
            return Object.assign({}, queryData, { searchType: 'LIKE' });
        });
        return (fields.length ? [{ fields }] : []);
    }

    buildSearchGroups({ liveUpdates }) {
        const { simpleQuery, specialQuery } = liveUpdates;
        const simpleSearchFields = this.mapSimpleQueriesToFields({ simpleQuery });
        const specialSearchGroups = this.mapSpecialQueriesToGroups({ specialQuery });
        return [...simpleSearchFields].concat(specialSearchGroups);
    }

    // TODO: what is filterVar
    updateFilters(filterVar = true, liveUpdates = { simpleQuery: null, specialQuery: null }) {
        const searchGroups = this.buildSearchGroups({ liveUpdates });
        this.props.filterUpdate({ searchGroups }, filterVar);
    }

    toggleSpecialFilter() {
        this.setState(prevState => ({
            isSpecialFilterVisible: true
        }))
    }

    submitFilters() {
        let simpleQuery;
        if (this.universalSearch.current) {
            simpleQuery = this.universalSearch.current.getSimpleQuery();
        }
        let simpleQueries = [...this.state.simpleQueries];
        if (simpleQuery) {
            simpleQueries.push(simpleQuery);
        }

        let specialQueries = [];
        if (this.specialSearch.current) {
            specialQueries = this.specialSearch.current.getSpecialQueries();
        }


        this.setState(Object.assign({}, this.state, { simpleQueries }, { specialQueries }),
            () => {
                this.updateFilters();
                this.manageUniversalQueryStorage.setQueries(this.state.simpleQueries);
                this.manageSpecialQueryStorage.setQueries(this.state.specialQueries);
                this.universalSearch.current.clearTextFieldValue();
            })
    }

    render() {
        const { useSpecialfilter } = this.config;
        const { module } = this.props;
        const { simpleQueries, specialQueries } = this.state;
        return (
            <div className="container">
                <section className="section">
                    <SearchTerms simpleQueries={simpleQueries}
                        specialQueries={specialQueries}
                        removeSimpleQuery={this.removeSimpleQuery}
                        removeSpecialQuery={this.removeSpecialQuery} />
                    <div className="search-container" ref={this.setWrapperRef}>
                        <UniversalSearch module={module}
                            addSimpleQuery={this.addSimpleQuery}
                            doLiveSimpleQuery={this.doLiveSimpleQuery}
                            toggleSpecialFilter={this.toggleSpecialFilter}
                            simpleQueries={simpleQueries}
                            specialQueries={specialQueries}
                            submitFilters={this.submitFilters}
                            ref={this.universalSearch}
                            removeAllQuery={this.removeAllQuery} />

                        {(useSpecialfilter) ? this.renderSpecialFilter() : null}
                    </div>
                </section>
            </div>
        );
    }

    renderSpecialFilter() {
        const { module, filterUpdate } = this.props;
        const { isSpecialFilterVisible } = this.state;
        return <SpecialSearch ref={this.specialSearch}
            module={module}
            isShown={isSpecialFilterVisible}
            addSpecialQueries={this.addSpecialQueries}
            doLiveSpecialQuery={this.doLiveSpecialQuery}
            filterUpdate={filterUpdate}
            submitFilters={this.submitFilters} />
    }
}