import React, { Component } from 'react'
import { LabelService } from '../../Common/Translations/LabelService';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import TableConfig from '../../List/tables-config.json';
import '../../Xpart/Xpart.css';
import '../../styles/common.css';

const LabelFile = LabelService.getLabelsByLocal('de');

export default class GroupSaveButtons extends Component {
    constructor(props) {
        super(props);
        this.module = this.props.moduleName;
        this.config = TableConfig[this.module];
    }

    state = {
    }

    componentWillMount() {


    }

    rendeSaveButtons() {
        return (

            <section className="results--section xpartform">
                <span >{LabelFile.texts[this.config.saveComponentTextLabel]} </span>

                <div className="form-container">
                    <button onClick={() => { this.props.goBackToTable() }}
                        className={'form-button-properties xpm-button'}>{LabelFile.labels['back']}</button>
                </div>
                <div className="form-container">
                    <button onClick={() => { this.props.goToPhotos() }}
                        className={'form-button-properties xpm-button'}>{LabelFile.labels['photos']}</button>
                </div>
                <div className="form-container">
                    <button onClick={() => { this.props.save() }}
                        className={'form-button-properties xpm-button'}>{LabelFile.labels['save']}</button>
                </div>
            </section>
        )
    }


    render() {

        return (
            <section className="results--section ">
                {/* page header */}
                <PageHeaderComponent
                    text={<h1>{LabelFile.labels['saveGroup']}</h1>}
                />
                {this.rendeSaveButtons()}
            </section>
        )
    }
}
