


import React, { Component } from 'react'
import TableConfig from '../../List/tables-config.json';
import { ConfirmationButton } from '../../Common/ConfirmationButton/confirmation-button'
import { LabelService } from '../../Common/Translations/LabelService';
import '../../List/list.css';

const LabelFile = LabelService.getLabelsByLocal('de');

export class AssemblyRequestControlButtonsComponent extends Component {

    constructor(props) {
        super(props);
        this.config = TableConfig['partsAssemblyKME'];
    }

    state = {
    }

    componentWillMount() {

        let multiSelect = {
            resultlistselected: [],
            multiSelectHide: "",
            multiSelectShow: "xpm-hidden",
            selectMode: false,
            multiSelectSelected: 0,
            localStoragePrefix: ''
        }

        let newState = this.state;
        newState.multiSelect = multiSelect;

        this.setState(newState);
    }


    startMultiSelect(approve) {
        this.setState({ qaApprove: approve });
        this.props.startMultiSelect(approve);

    }
    stopMultiSelect() {
        this.props.stopMultiSelect();
    }
    clearLocalSelection() {
        this.props.clearLocalSelection();
    }


    updateMultiSelectState(multiSelect) {
        this.setState({ multiSelect: multiSelect });
    }



    renderOptionalButtons() {
        return (
            <div className="optionalButtons xpm-button-container">
                <div className="button-alignment">
                    <button onClick={this.props.activeSelection ? () => { this.startMultiSelect() } : ''}
                        className={"xpm-button " + this.state.multiSelect.multiSelectHide + (this.props.activeSelection ? '' : ' xpm-disabled')}>{LabelFile.labels['requestparts']}</button>
                    <button onClick={() => { this.props.goToRequestedList() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectHide}>{LabelFile.labels[this.config.goBackButtonName]}</button>

                    <button onClick={() => { this.stopMultiSelect() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectShow}>{LabelFile.labels[this.config.cancelSelectButtonName]}</button>
                    <button onClick={() => { this.clearLocalSelection() }}
                        className={"xpm-button " + this.state.multiSelect.multiSelectShow}>{LabelFile.labels[this.config.resetSelectButtonName]}</button>
                </div>

                {
                    !this.state.multiSelect.selectMode || this.state.multiSelect.multiSelectSelected < 1 ? null : <ConfirmationButton
                        expandedStateContent={this.createCountMessage()}
                        compressedStateContent={this.createCountMessage()}
                        mobileStateContent={this.state.multiSelect.multiSelectSelected + " " + LabelFile.labels["xparts"]}
                        onClick={this.confirmationButtonClick.bind(this)}
                        dynamic={false} icon={'check'} label={'COUNT_OF_SELECTED_XPARTS_TO_REQUEST'} />
                }
            </div >
        )
    }

    createCountMessage() {
        return LabelFile['texts']['COUNT_OF_SELECTED_XPARTS_TO_REQUEST'].replace('$COUNT', this.state.multiSelect.multiSelectSelected);
    }

    confirmationButtonClick() {
        this.props.requestAssebly();
    }


    render() {

        let warningtext = "";

        if (!this.state.multiSelect.selectMode && this.state.multiSelect.multiSelectSelected > 0) {
            warningtext = LabelFile.texts[this.config.viewModeHint] + ": " + this.state.multiSelect.multiSelectSelected;
        } else if (this.state.multiSelect.selectMode) {
            warningtext = '';
        }
        return (
            <div>
                {this.renderOptionalButtons()}
                <span className={"xpm-warning-span"}>{warningtext}</span>
            </div>
        )
    }

}
