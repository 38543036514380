import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { APINotifications } from '../Common/API/APINotifications';
import { API } from '../Common/API/API';
import { HelperFunctions } from '../Common/Helper/Helper';
import { LabelService } from '../Common/Translations/LabelService';
import './Storage.css';

const API_XPART = new API();
const Fields = new FieldGenerator();
const LabelFile = LabelService.getLabelsByLocal('de');

const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();

class StorageXpart extends Component {

  state = {
    result: []
  }


  createOKModalButtons() {

    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);

    return btns;
  }


  fillInfo() {
    axios.get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + this.props.match.params.xpart)
      .then(response => { //Global Status needed - or make this subcomponent of xpart...? - LB
        document.getElementById('xpart').value = response.data["xpartNumber"];
        document.getElementById('desc_de').value = response.data["descriptionDe"];

        document.getElementById('id').value = response.data["id"]

        console.log("storage", response.data["storagePlace"]);
        if (response.data["storagePlace"]) {
          let storage = "";
          let storage_arr = response.data["storagePlace"]["parents"].reverse();
          storage_arr.forEach(function (parent) {
            storage += parent["name"] + "-";
          });
          document.getElementById('storage').value = storage + response.data["storagePlace"]["name"];
        }

        this.setState({
          result: response.data
        })
      })
      .catch(err => {
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      });
  }

  handleStorage() {
    this.props.history.push('/storage/changestorage/' + this.props.match.params.xpart + '/savestorage' + HelperFunction.getContextOrNot(this.props.match.params.context));
  }

  componentDidMount() {
    this.fillInfo();
  }

  render() {
    return (
      <section className="results--section xpartform">
        <div className="input-container" id="inputMain">
          {Fields.generateInputText("xpart", "[0-9]{2}-[0-9]{1,10}", false, [], true)}
          {Fields.generateInputTextarea("desc_de", "255", true, [], true)}

          {Fields.generateInputText("storage", "", false, [], true)}

          {Fields.generateInputHidden("id")}
        </div>
        <div className="xpm-button-container xpm-container-top">
          <button type="button" id="strg-btn"
            className="strg-button xpm-button"
            onClick={this.handleStorage.bind(this)}>{LabelFile.labels['change_storage']}</button>
        </div>  
      </section>
    )
  }
}

export default withRouter(StorageXpart);
