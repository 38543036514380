/* A translation Label Class. Every printed string that's not from the database should come from here */
/* OLD FILE!!! New file is in /static/labels-de.json !!! */

export class LabelTranslations{
    labels = {
        save                    :   "Speichern",
        update                  :   "Update",
        delete                  :   "Löschen",
        archive                 :   "Archivieren",
        clear                   :   "Form zurücksetzen",
        cancel                  :   "Abbrechen",
        xpart                   :   "Losteilnummer",
        desc_de                 :   "Beschreibung",
        desc_en                 :   "Beschreibung englisch",
        quantity                :   "Anzahl",
        unit                    :   "Einheit",
        weight                  :   "Gewicht in kg",
        suppliernr              :   "Materialnummer",
        id                      :   "ID",
        created                 :   "Erstellt am",
        updated                 :   "Aktualisiert",
        active                  :   "Aktiv",
        order                   :   "Bestellung",
        project                 :   "Projekt",
        item                    :   "Position",
        status                  :   "Status",
        xparts                  :   "Losteile",
        functions               :   "Funktionen",
        photos                  :   "Fotos",
        serverphotos            :   "Hochgeladene Fotos",
        localphotos             :   "Nicht hochgeladene Fotos",
        change_storage          :   "Lagerplatz auswählen",
        storage                 :   "Lagerplatz",
        storagexpartNr          :   "Losteil-Nr. eingeben:",
        ordernumber             :   "Bestellnummer",
        order_item_number       :   "Positionsnummer",
        projectnumber           :   "Projektnummer",
        orderdate               :   "Bestelldatum",
        deliverydate            :   "Lieferdatum",
        customer                :   "Kunde",	
        ordertitle              :   "Bestellung",	 
        ordermissionnumber      :   "Auftragsnummer",
        amountoforderitems      :   "Anzahl Bestellpositionen",
        back                    :   "Zurück",        
        orderitemtitle          :   "Bestellposition",
        amountofxpart           :   "Anzahl von Losteilen",
        alreadyclosedlabel      :   "Wurde geschlossen",
        amountoforderlabel      :   "Bestellte Anzahl",
        projectlabel            :   "Projekt",
        orderitemlabel          :   "Position",
        amountofxpartlabel      :   "Anzahl Losteile",
        fullysupplied           :   "Vollständig beliefert"    
    }
    warnings = {
        required        :   "Bitte füllen Sie dieses Feld aus. ",
        xpart           :   "Dieses Feld muss in dem folgenden Format ausgefüllt werden: YY-NNNNNN. ",
        number          :   "Dieses Feld darf nur Zahlen beinhalten. ",
        limit_twenty    :   "Dieses Feld darf maximal 20 Zeichen beinhalten. "
    }
    statuses = {
        CREATED                 :   "Erstellt",
        READY_FOR_SHIPMENT      :   "Versandbereit",
        IN_DELIVERY             :   "In Lieferung",
        RECEIVED                :   "Empfangen"
    }
    spec_projects = {
        "TECHNIKUM-PROJECT"     :   "Technikum",
        "TECHNIKUM-ITEM"        :   ""
    }
    context = {
        CUSTOMER                :   "Kunde",
        SUPPLIER                :   "Lieferant",
        TECHNIKUM               :   "Technikum",
        DEV                     :   "DEV",
        OLD                     :   "Alt"
    }
    units = {
        PIECE : "Stück",
        METER : "Meter",
        LITRE : "Liter"
    }
    menues = {
        HOME                    :   "Home",
        SCAN                    :   "Scan",
        PRINT                   :   "Print",
        XPART                   :   "Losteil",
        XPARTLIST               :   "Losteilübersicht",
        RECEPTION               :   "Empfang",
        STORAGE                 :   "Losteil Umlagern",
        ORDERLIST               :   "Bestellübersicht",
        PHOTO                   :   "Foto",
        XPARTSEARCH             :   "Losteilsuche",
        XPART_TECHNIKUM         :   "Losteilerfassung Technikum",
        XPARTLIST_TECHNIKUM     :   "Losteilübersicht Technikum",
    }
    texts = {
        RECEPTION_SAVE_BTN  :   "Bei Klick auf die Schaltfläche wird der Status auf " + this.statuses.RECEIVED + " gesetzt. Das Gewicht kann überschrieben werden.",
        PHOTO_BTN_XPART     :   "Die Zahl in Klammern gibt an, wie viele Fotos existieren, die lokal gepeichert und noch nicht hochgeladen worden sind.",
        serverfiles         :   "Hochgeladene Fotos",
        localfiles          :   "Noch nicht hochgeladene Fotos",
    }
};