

import React, { Component } from 'react';
import '../../Xpart/Xpart.css';
import '../../styles/common.css';
import './GroupTableButtonTextComponent.css';
import { TableComponent } from '../../List/TableComponent';
import TableConfig from '../../List/tables-config.json';
import { LabelService } from '../../Common/Translations/LabelService';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';


const LabelFile = LabelService.getLabelsByLocal('de')


export default class GroupTableButtonTextComponent extends Component {


    constructor(props) {
        super(props);
        this.module = this.props.moduleName;
        this.config = TableConfig[this.module];
        this.localStoragePrefix=this.config.localStoragePrefix;
        this.tableComponent = React.createRef();
        var localData=JSON.parse(localStorage.getItem(this.localStoragePrefix));
        this.state={
            dataSet:localData,
            textToggle:localData.textToggle
        }
    }



    componentDidMount() {

        if (this.props.setRef) {
            this.props.setRef(this);
        }
        this.fetchDataFromLocalStorage();
    }

    fetchDataFromLocalStorage(){
       var localData=JSON.parse(localStorage.getItem(this.localStoragePrefix));
        this.setState({
            dataSet:localData,
            textToggle:localData.textToggle
        })
    }

    renderInfoText() {
        return (
        !this.state.textToggle ? <span className="InfoText">{LabelFile.texts[this.config.infoTextLabel]} </span>: <span className="InfoText">{LabelFile.labels[this.config.infoTextAlternateLabelOne]+':'+this.state.dataSet.requestGroupMetaInfo.targetStorageCode}{' | '}{LabelFile.labels[this.config.infoTextAlternateLabelTwo]+':'+this.state.dataSet.content.length}</span>
        )
    }

    render() {
        return (
            <section className="results--section">
                {/* page header */}
                <PageHeaderComponent
                    text={<h1>{LabelFile.labels[this.config.pageHeader]}</h1>}
                />
                {this.props.renderOptionalButtons()}
                {this.renderInfoText()}
                <div className="list-container">
                    <TableComponent
                        ref={this.tableComponent}
                        module={this.module}
                        preFilter={false}
                        fireModal={this.props.fireModal}
                        closeModal={this.props.closeModal}
                        dataSet={this.state.dataSet}
                    />
                </div>
                <div id="popupContainer"></div>
            </section>
        )
    }

}
