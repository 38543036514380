
import React, { Component } from 'react'
import TableConfig from '../List/tables-config.json';
import { PageHeaderComponent } from '../Common/Components/page-header-component'
import { QaApproveControlButtonsComponent } from './qa-control-buttons-component'
import { PaginationComponent } from '../List/PaginationComponent'
import { SelectableTableComponent } from '../List/SelectableTableComponent';
import { TableFilter } from '../List/TableFilter/TableFilter';
import { LabelService } from '../Common/Translations/LabelService';
import { ExtraDataService } from '../List/ExtraDataService';
import { APINotifications } from '../Common/API/APINotifications';
import '../List/list.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const ExtraFunctions = new ExtraDataService();

export default class QaApproveComponent extends Component {

    constructor(props) {
        super(props);
        this.config = TableConfig['qaApprove'];
        this.paginationComponent = React.createRef();
        this.bottomPaginationComponent = React.createRef();
        this.tableComponent = React.createRef();
        this.tableFilter = React.createRef();
        this.controlButtonsComponent = React.createRef();
    }

    state = {
        generalFilter: [],
        usesFilter: false,
        importCount: '',
        preFilter: [],
        multiSelect: {}
    }

    componentWillMount() {
        this.setState(this.state);
    }


    updateTable(page, parts) {
        this.props.history.push('/qa-approve/' + page + '/' + parts);
      //  this.refresh();
    }

    pgFeedback(first, last, totalPages) {
        if (this.paginationComponent.current) {
            this.paginationComponent.current.updatePages(first, last, totalPages);
        }

        if (this.bottomPaginationComponent.current) {
            this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
        }
    }

    refresh() {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    filterUpdate(data, filterFlag) {
        this.setState({
            generalFilter: data,
            usesFilter: filterFlag
        })
    }


    startMultiSelect() {
        this.tableComponent.current.startMultiSelect();
    }

    stopMultiSelect() {
        this.tableComponent.current.stopMultiSelect();
    }

    updateMultiSelectState(multiSelect) {
        this.controlButtonsComponent.current.updateMultiSelectState(multiSelect);
    }
    clearLocalSelection() {
        this.tableComponent.current.clearLocalSelection();
    }

    saveQA(bool) {
        let xpartIds = this.tableComponent.current.getSelectedIds();

        ExtraFunctions.changeXpartApproval({ objectId: xpartIds, targetStatus: bool })
            .then(e => { this.tableComponent.current.onConfirmSuccess() })
            .catch(ex => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', 'COMMON_ERROR'),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
    }


    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        };

        btns.push(obj);
        return btns;
    }


    render() {
        return (
            <section className="results--section">
                <PageHeaderComponent text={this.config.pageHeader ? <h1>{LabelFile.menues['QA_APPROVE']}</h1> : ''} ></PageHeaderComponent>
                {this.config.buttonExcelExport ? <div className="xpm-button-container excel-export"><button type="button" className="xpm-button xpm-button-export" onClick={() => this.excelExport()}>{LabelFile.labels["excelexport"]}</button></div> : ''}
                {<PaginationComponent ref={this.paginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'qaApprove'} ></PaginationComponent>}
                {this.config.showFilter ? <TableFilter ref={this.tableFilter} module={'qaApprove'} filterUpdate={(data, filterFlag) => this.filterUpdate(data, filterFlag)}></TableFilter> : ""}

                <div className="list-container">
                    <QaApproveControlButtonsComponent ref={this.controlButtonsComponent} saveQA={(bool) => this.saveQA(bool)}
                        startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
                        clearLocalSelection={() => { this.clearLocalSelection() }}>
                    </QaApproveControlButtonsComponent>

                    <SelectableTableComponent ref={this.tableComponent} page={this.props.match.params.page - 1} size={this.props.match.params.parts}
                        pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
                        context={this.props.match.params.context} module={'qaApprove'}
                        usesFilter={this.state.usesFilter || this.config.usePreFilter} generalFilter={this.state.generalFilter}
                        preFilter={this.state.preFilter} localStoragePrefix={this.config.localStoragePrefix}
                        refresh={() => this.refresh()} history={this.props.history} fireModal={this.props.fireModal} closeModal={this.props.closeModal}
                        updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
                        updateConfirmButton={(e) => this.updateConfirmButton(e)}></SelectableTableComponent>

                </div>
                {<PaginationComponent ref={this.bottomPaginationComponent} updateTable={(page, parts) => this.updateTable(page, parts)}
                    page={this.props.match.params.page} parts={this.props.match.params.parts}
                    module={'qaApprove'} ></PaginationComponent>}

                <div id="popupContainer"></div>
            </section>
        )
    }

}