import React, { Component } from 'react'
import { LabelService } from '../../Common/Translations/LabelService'
import '../../Xpart/Xpart.css'
import '../../styles/common.css'


const LabelFile = LabelService.getLabelsByLocal('de')


export default class EvsReceiveButtonsComponent extends Component {

    componentDidMount() {

        if (this.props.setRef) {
            this.props.setRef(this);
        }
    }


    render() {
        return (
            <section className="results--section xpartform">
                {this.props.xpartsCount > 1 ?
                    <div>
                        <div className="form-container">
                            <div className={''}>
                                {LabelFile.labels['xpartcount'] + ': ' + this.props.xpartsCount}
                            </div>
                        </div>
                    </div>
                    : ''}

                <div className="form-container">
                    <button name='scan-cancel-button' type="button" key='group-cancel-button' id='scan-cancel-button' onClick={e=>this.props.goToScan()}
                        className={'form-button-properties xpm-button '}>
                        {LabelFile.labels['newScan']}
                    </button>

                </div>

                {this.props.photo ?
                    <div className="form-container">
                        <button name='photo-button' type="button" key='photo-button' id='photo-button' onClick={e => this.props.goToPhotos(e)}
                            className={'form-button-properties xpm-button '}>
                            {LabelFile.labels['photos'] + (this.props.photosCount ? ' (' + this.props.photosCount + ')' : '')}
                        </button>
                    </div> :
                    ''
                }

                {this.props.receive ?
                    <div className="form-container">
                        <button name='save-xpart-button' type="button" key='save-xpart-button' id='save-xpart-button'
                            className={'form-button-properties xpm-button '} onClick={e => this.props.saveXpart(e)} >
                            {LabelFile.labels['save']}
                        </button>
                    </div> :
                    ''
                }

            </section>
        );
    }


}
