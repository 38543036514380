import React, { Component } from 'react'
import { LabelService } from '../Common/Translations/LabelService';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { zonedTimeToUtc } from 'date-fns-tz';
import '../List/list.css';

const LabelFile = LabelService.getLabelsByLocal('de');
registerLocale('de', de);

export default class DatePickerDialog extends Component {


    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.timeZone = 'Europe/Berlin';
        this.state = this.initState();
    }

    getMaxDate() {
        return new Date(new Date().getFullYear() + 1, 11, 31);
    }

    initState() {
        let currentAvailableDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        let startDate = (!this.props.selectedDate ? currentAvailableDate : this.props.selectedDate)

        return {
            startDate: zonedTimeToUtc(startDate, this.timeZone)
        };
    }

    isWeekday(date) {
        let day = new Date(date).getDay();
        return day !== 0 && day !== 6;
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleNext() {
        return this.state.startDate;
    }

    render() {
        return (
            <div className="input-container" id="inputMain">
                <div>
                    <label>{LabelFile.labels["deliverydate"]}</label>
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={date => this.handleDateChange(date)}
                        locale="de"
                        maxDate={this.getMaxDate()}
                        dateFormat="dd.MM.yyyy"
                        filterDate={date => this.isWeekday(date)}
                        showDisabledMonthNavigation
                    />
                </div>

                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.props.datePickerResponse(this.handleNext())}
                    >{LabelFile.labels["forward"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.handleCancel()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }
}
