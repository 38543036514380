import React, { Component } from 'react'
import { withRouter } from 'react-router';
import { LabelService } from '../Common/Translations/LabelService';
import Scan from './../Scan/XpartScan/Scan';
import './Storage.css';

const LabelFile = LabelService.getLabelsByLocal('de');

class Storage extends Component {

    initialState = [
        {
            cam: {
                result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                flash: 'scan-container-flash'
            },
            title: {
                view: true, // --- view title in browser
                name: LabelFile.labels['storagexpartNr'] // --- view title in browser
            },
            form: {
                view: true, // --- view form in browser
                alternative: true // view form width (alternative = full width)
            },
            button: {
                view: false, // --- view button in browser
                alternative: true // view button width (alternative = full width)
            },
            status: {
                linkUrl: '/storage/storagexpart/', // internal link to other component
                context: null,
                linkPermission: ''
            }
        }
    ]

    render() {
        return (
            <div>
                <Scan initialState={this.initialState} fireModal={this.props.fireModal} closeModal={this.props.closeModal} />
            </div>
        )
    }
  }
export default withRouter(Storage);

