import React, { Component } from 'react'
import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { LabelService } from '../Common/Translations/LabelService';
import './Project.css';

const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();

/** This class generates a popup modal for user to enter a package number for xparts */
export class NewPackageInputMask extends Component {

    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.packagetype = [];
        this.packagetypeList = this.props.packagetypelist;
        this.packageId = null;
    }

    state = {
        mode: 0
    }

    handleSave() {
        if (Fields.validateInputs()) {

            var data = {
                'packageNumber': document.getElementById('packagenumber').value,
                'netWeight': document.getElementById('netweight').value,
                'grossWeight': document.getElementById('grossweight').value,
                'packageTypeId': document.getElementById('packagetype').value,
                'length': document.getElementById('length').value,
                'height': document.getElementById('height').value,
                'width': document.getElementById('width').value,
                'id': this.packageId,
                'packageTypeName': ''
            }

            if (this.props.workingMode === "edit") {
                this.props.saveMissingData(data);
            }
            else {
                this.props.saveNewPackage(data);
            }
        }
    }

    /* prefill when missing dates while collect delivery
       (workingMode = "edit") */
    prefillForm() {
        if (this.props.packdata !== null) {
            if (this.props.packdata['packageNumber']) {
                document.getElementById('packagenumber').value = this.props.packdata['packageNumber'];
            }

            if (this.props.packdata['netWeight']) {
                document.getElementById('netweight').value = this.props.packdata['netWeight'];
            }

            if (this.props.packdata['grossWeight']) {
                document.getElementById('grossweight').value = this.props.packdata['grossWeight'];
            }

            if (this.props.packdata['length']) {
                document.getElementById('length').value = this.props.packdata['length'];
            }

            if (this.props.packdata['width']) {
                document.getElementById('width').value = this.props.packdata['width'];
            }

            if (this.props.packdata['height']) {
                document.getElementById('height').value = this.props.packdata['height'];
            }

            document.getElementById('packagetype').value = this.props.packdata['packageTypeId'];

            this.packageId = this.props.packdata['id'];

        }
    }

    componentDidMount() {
        this.prefillForm();
    }

    render() {
        return (
            <div className="input-container newpackagemask" id="inputMain">
                <div className="infield field1">
                    {Fields.generateInputText("packagenumber", ".*", true, [this.warnings['required']])}
                </div>
                <div className="infield field2">
                    {Fields.generateInputNumber("netweight", "0", "1000000", ".001", false, [this.warnings['number']], this.state.mode)}
                </div>
                <div className="infield field3">
                    {Fields.generateInputNumber("grossweight", "0", "1000000", ".001", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field4">
                    {Fields.generateInputSelect("packagetype", this.packagetypeList, true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field5">
                    {Fields.generateInputNumber("length", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field7">
                    {Fields.generateInputNumber("width", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="infield field6">
                    {Fields.generateInputNumber("height", "0", "1000000", "1", true, [this.warnings['required']], this.state.mode)}
                </div>
                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.handleSave()}>
                        {LabelFile.labels["save"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }
}