import React, { Component } from 'react'

import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { LabelService } from '../Common/Translations/LabelService';
import './newXpartInputMask.css';

const LabelFile = LabelService.getLabelsByLocal('de');

const Fields = new FieldGenerator();

export class NewXpartInputMask extends Component {

    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.units = [
            {
                "id": 1,
                "name": "Stück"
            },
            {
                "id": 2,
                "name": "Meter"
            },
            {
                "id": 3,
                "name": "Liter"
            }
        ];
    }

    componentDidMount() {
        if (this.props.xpart) {
            this.fillFields(this.props.xpart);
            if (this.props.xpart.convert) {
                this.xpartsInfo(this.props.xpart);
            }
        }
    }

    fillFields(xpart) {
        document.getElementById('desc_de').value = xpart.descriptionDe;
        document.getElementById('desc_en').value = xpart.descriptionEn;
        document.getElementById('quantity').value = xpart.quantity;
        document.getElementById('weight').value = xpart.weight;
        document.getElementById('supplierMaterialNumber').value = xpart.supplierMaterialNumber;
        //document.getElementById('customerMaterialNumber').value = xpart.customerMaterialNumber;
        document.getElementById('unit').value = xpart.unit.id;
        document.getElementById('id').value = xpart.id;
    }

    getFormData() {
        return {
            'descriptionDe': document.getElementById('desc_de').value,
            'descriptionEn': document.getElementById('desc_en').value,
            'quantity': document.getElementById('quantity').value,
            'weight': document.getElementById('weight').value,
            'active': true,
            'supplierMaterialNumber': document.getElementById('supplierMaterialNumber').value,
          //  'customerMaterialNumber': document.getElementById('customerMaterialNumber').value,
            'unitID': document.getElementById('unit').value,
            'id': document.getElementById('id').value,
            'statusID': this.props.xpart && !this.props.xpart.convert ? this.props.xpart.currentStatus.statusDTO.id : 1,
            'contextId': this.props.xpart && !this.props.xpart.convert ? this.props.xpart.currentStatus.contextDTO.id : 2
        }
    }

    handleSave() {
        if (Fields.validateInputs()) {
            if (this.props.xpart && this.props.xpart.convert) {
                this.props.saveNewXpart(this.getFormData())
            } else if (this.props.xpart) {
                return this.props.updateXpart(this.getFormData())
            } else {
                this.props.saveNewXpart(this.getFormData())
            }
        }
    }

    xpartsInfo(xpart) {

        if (document.getElementById("xparts-info") === null) {
            return;
        }

        if (xpart.xpartsQuantity === 1) {
            document.getElementById("xparts-info").innerHTML = LabelFile.texts["amountofxpartdescription"] + " " + xpart.xpartsQuantity + " " + LabelFile.labels["xpartsingle"];
        } else {
            document.getElementById("xparts-info").innerHTML = LabelFile.texts["amountofxpartsdescription"] + " " + xpart.xpartsQuantity + " " + LabelFile.labels["xparts"];
        }
    }


    render() {
        let isQuantity = this.props.xpart && this.props.xpart.convert ? this.props.xpart.xpartsQuantity : 0;

        return (
            <div className="input-container" id="inputMain">
                {Fields.generateInputTextarea("desc_de", "255", true, [this.warnings['required']])}
                {Fields.generateInputTextarea("desc_en", "255", true, [this.warnings['required']])}
                {Fields.generateInputNumber("quantity", "1", "10000", "1", true, [this.warnings['required'], this.warnings['number']])}
                {Fields.generateInputSelect('unit', [this.units[0], this.units[1], this.units[2]], true, [this.warnings['required']])}
                {Fields.generateInputNumber("weight", "0", "1000000", ".001", true, [this.warnings['required'], this.warnings['number']])}
                {/* {Fields.generateInputText("customerMaterialNumber", "[a-zA-Z0-9]{1,20}", false, [])} */}
                {Fields.generateInputText("supplierMaterialNumber", "(.*?)", false, [])}
                {Fields.generateInputHidden("id")}
                <div>
                    {isQuantity ? (
                        <div id="xparts-info"></div>
                    ) : (
                            <div></div>
                        )}
                </div>

                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.handleSave(this.getFormData())}
                    >{LabelFile.labels["save"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }
}