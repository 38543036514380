import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { API } from '../Common/API/API'
import { LabelService } from '../Common/Translations/LabelService'
import './ConfirmPassword.css'

const GLOBAL_API = new API();
const LabelFile = LabelService.getLabelsByLocal('de')

class ConfirmPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailAddress: '',
      timeInRange: true
    }
  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'resetpassword-button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);
    return btns;
  }

  // function: simulates ENTER-taste
  keyPress = (e) => {
    e.stopPropagation();

    // if: is ENTER-taste pressed then fire resetPassword function
    if (e.keyCode === 13) {
      this.resetPassword()
    }

    e.stopPropagation();
  }

  // function: fetch given parameter from URL
  fetchParameterFromURL = () => {
    return this.props.match.params.uuid
  }

  // function: interact with the backend
  checkIfRequestIsInTime = () => {
    const uuid = this.fetchParameterFromURL();

    if (!uuid) {
      return;
    }
    axios.get(GLOBAL_API.AUTH_SERVICE_URL + '/user/uuid/' + uuid)
      .then(response => {
        // handle succes
        this.setState({
          emailAddress: response.data.email,
          timeInRange: true
        })
      })
      .catch(error => {
        this.setState({
          emailAddress: '',
          timeInRange: false
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });
      })

  }

  componentDidMount = () => {
    this.checkIfRequestIsInTime()
  }

  // function: link to a new page
  linkToResetPasswordPage = () => {
    this.props.history.push('/resetpassword')
  }

  linkToLoginPage = () => {
    this.props.history.push('/login')
  }

  showNotification = () => {
    // display notification for user if something is happening in the background
    this.props.fireNotifications([{
      type: 'INFO',
      title: LabelFile.texts['notificationTitleRequest'],
      message: LabelFile.texts['notificationContentPassword']
    }])

    // breaks notification after a certain time
    setTimeout(() => {
      this.props.clearNotifications()
    }, 3000)
  }

  // function: create a new password and link to login page
  createNewPassword = () => {
    const uuid = this.fetchParameterFromURL();

    if (!uuid) {
      return;
    }
    axios.post(GLOBAL_API.AUTH_SERVICE_URL + '/user/password/uuid/change', { uuid: uuid })
      .then(response => {
        // handle succes
        this.showNotification()
        this.linkToLoginPage()
      })
      .catch(error => {
        // let content = error.response.data.message !== null ? error.response.data.message : error.response.data.errors[0];

        // // handle error
        // this.props.fireModal({
        //   content: LabelFile.warnings[content],
        //   header: LabelFile.header['ERROR'],
        //   buttons: this.createOKModalButtons()
        // })
      })
      .finally(() => {
        this.setState({
          isLoading: false
        });
      })
  }

  // component: display view if the request has not expired
  ComponentRequestIsInTime = () => {
    return (
      <Fragment>
        <div className="form-full">
          <h1>{LabelFile.labels['newPassword']}</h1>
        </div>
        <div className="form-full">
          <span>
            {LabelFile.texts['requestIsInTime1']} {this.state.emailAddress},<br />
            {LabelFile.texts['requestIsInTime2']}
          </span>
        </div>
        <div className="form-full">
          <button type="button" onKeyDown={this.keyPress} onClick={() => this.createNewPassword()} className="createuser-button-properties">
            {LabelFile.labels['request']}
          </button>
        </div>
      </Fragment>
    )
  }

  // component: display view if the request has expired
  ComponentRequestIsExpired = () => {
    return (
      <Fragment>
        <div className="form-full">
          <h1>{LabelFile.labels['newPassword']}</h1>
        </div>
        <div className="form-full">
          {LabelFile.texts['requestIsExpired']}
        </div>
        <div className="form-full">
          <button type="button" onKeyDown={this.keyPress} onClick={() => this.linkToResetPasswordPage()} className="createuser-button-properties">
            {LabelFile.labels['requestAgain']}
          </button>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <div className="confirmpassword-container" >
        <div className="resetpassword-form-container">
          {this.state.timeInRange ? <this.ComponentRequestIsInTime /> : <this.ComponentRequestIsExpired />}
        </div>
      </div>
    )
  }
}

export default ConfirmPassword
