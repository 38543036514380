import React, { Component } from 'react'
import { withRouter } from 'react-router';
import FormGenerate from '../Form/FormGenerate';
import './Xpart.css'

class XpartKME extends Component {

    render() {
        return (
            <section className="results--section xpartKME ">
                <FormGenerate
                    fireModal={this.props.fireModal}
                    closeModal={this.props.closeModal}
                    formname="xpartCreateKME"
                    header="XPART_NEW_KME"
                    prefill="false"
                    pdfPrint="true"
                    pathAfterPrint="/list/xpartOverview/1/100/"
                />
            </section>

        )
    }
}
export default withRouter(XpartKME);
