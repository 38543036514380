let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'stage.xparts-management.de') {
  backendHost = 'https://stage.xparts-management.de';
} else if (hostname === 'live.xparts-management.de') {
  backendHost = 'https://live.xparts-management.de';
}
else if (hostname === 'vmd36605.contaboserver.net') {
  backendHost = 'https://vmd36605.contaboserver.net';
}
else if (hostname === 'vmd41477.contaboserver.net') {
  backendHost = 'https://vmd41477.contaboserver.net';
}
else {
  backendHost = 'http://localhost:8087';
}

export const API_ROOT = `${backendHost}/api`;
