import { Component } from 'react'

export class MultiSelectComponent extends Component {

    startMultiSelect() {
        this.setState({
            multiSelectHide: "xpm-hidden",
            multiSelectShow: "",
            selectMode: !this.state.selectMode
        })

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.addEventListener('click', this.multiSelectEventHandler);
        })

    }
    // Function to stop the multiselect and remove event listener
    stopMultiSelect() {
        this.setState({
            multiSelectHide: "",
            multiSelectShow: "xpm-hidden",
            selectMode: !this.state.selectMode
        })

        Array.from(document.getElementsByClassName("multi-select")).forEach((element) => {
            element.removeEventListener('click', this.multiSelectEventHandler);
        });
    }

    handleMultiSelectEvent(var1, var2, e, resultlistselected) {
        e.currentTarget.classList.toggle("multiselect-selected");
        if (!resultlistselected.includes(e.currentTarget.dataset.attr)) {
            resultlistselected.push(e.currentTarget.dataset.attr);
        } else {
            if (resultlistselected.indexOf(e.currentTarget.dataset.attr) >= 0) {
                resultlistselected.splice(resultlistselected.indexOf(e.currentTarget.dataset.attr), 1);
            }
        }

        this.setState({
            multiSelectSelected: resultlistselected.length,
            resultlistselected: resultlistselected
        });

        if (this.editMode) {
            localStorage.setItem(var1, JSON.stringify(resultlistselected))
        }
        else {
            localStorage.setItem(var2, JSON.stringify(resultlistselected))
        }
        if (resultlistselected.length < 1) {
            localStorage.removeItem(var2);
        }

    }

}



