import React, { Component, Fragment } from 'react'
import { LabelService } from '../Common/Translations/LabelService'
import './Setting.css';
import ChangePasswordComponent from './User/change-password-component';
import Permissions from './../page-access-config.json';
import { HelperFunctions } from '../Common/Helper/Helper';

const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();

export default class Setting extends Component {
  //viewPermission = 'admin'
  constructor(props) {
    super(props)

    this.state = {
      viewPermission: 'admin',
      currentUser: null
    }
  }

  componentDidMount() {
    this.setState({ currentUser: HelperFunction.getCurrentUser() });
  }

  // function: switch page
  gotoCreateUser = () => {
    this.props.history.push('/createuser')
  }

  // function: default is hidden. only view if admin is logged
  // TODO: hidden function can be created if roles/rights are finished!
  CreateUserForm = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.texts['createNewUser']}
            </div>
          </div>
        </div>
        <div className="form-right">
          <button type="button" onClick={this.gotoCreateUser} className="setting-button-properties">
            {LabelFile.labels['create']}
          </button>
        </div>
      </Fragment>
    )
  }

  // function: logout for the current user
  logoutCurrentUser = () => {
    localStorage.removeItem('xpm-user-info');
    this.props.history.push('/login');
    this.props.onCurrentUserUpdate(null);
    //  console.log('User is logout!')
  }

  // function: view: change the current password
  CurrentUser = () => {
    return (
      <Fragment>
        <div className="form-left">
          <div className="form-left-alignment">
            <div className="form-title-left">
              {LabelFile.labels['currentUser']}
            </div>
          </div>
        </div>
        {
          this.state.currentUser ?
            <div className="form-right">
              <input type="text" placeholder={this.state.currentUser.userName} disabled className="form-input-properties" />
            </div> :
            ''
        }

      </Fragment>
    )
  }

  hasRole = (pageRoles, userRoles) => {
    for (let i in pageRoles) {
      if (userRoles.includes(pageRoles[i])) {
        return true;
      }

    }
    return false;
  }

  render() {
    return (
      <section className="setting-container">
        <div className="setting-form-container">
          <div className="form-full">
            <h1>{LabelFile.labels['settings']}</h1>
          </div>

          {this.hasRole(Permissions["SETTING"]["roles"], JSON.parse(localStorage.getItem('xpm-user-info')).roles) ? <this.CreateUserForm /> : ''}
          <this.CurrentUser />
          <ChangePasswordComponent currentUser={this.state.currentUser} fireModal={this.props.fireModal} closeModal={this.props.closeModal} />
          <div className="form-full">
            <button type="button" onClick={this.logoutCurrentUser} className="setting-button-properties">
              {LabelFile.labels['logout']}
            </button>
          </div>
        </div>
      </section >
    )
  }
}
