import React, { Component } from 'react'
import '../../List/list.css';
import { APINotifications } from '../../Common/API/APINotifications';
import { PaginationComponent } from '../../List/PaginationComponent';
import { SelectableTableComponent } from '../../List/SelectableTableComponent';
import { PageHeaderComponent } from '../../Common/Components/page-header-component';
import { XpartToDeliverGroupControlButtonsComponent } from './XpartToDeliverGroup-control-buttons-component';
import { Loader } from '../../Common/Components/Loader/loader';
import { DeliveryGroupInfoDialog } from './DeliveryGroupInfoDialog';

import TableConfig from '../../List/tables-config.json';

import { LabelService } from '../../Common/Translations/LabelService';
import { HelperFunctions } from '../../Common/Helper/Helper';
import { API } from '../../Common/API/API';
import axios from 'axios';

const LabelFile = LabelService.getLabelsByLocal('de');
const NOTIFICATIONS = new APINotifications();
const API_XPART = new API();
const HelperFunction = new HelperFunctions();

export default class XpartToDeliverGroupOverviewComponent extends Component {
  constructor(props) {
    super(props);
    this.config = TableConfig['xpartsIntoDeliveryGroup'];
    this.paginationComponent = React.createRef();
    this.bottomPaginationComponent = React.createRef();
    this.tableComponent = React.createRef();
    this.groupInfoComponentRef = React.createRef();
    this.controlButtonsComponentRef = React.createRef();
  }

  state = {
    importCount: '',
    generalFilter: [],
    usesFilter: true,
    preFilter: [],
    groupMode: [],
    groupType: [],
    isLoading: false,
    originalXparts: [],
    textToggle: true
  }


  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.groupid !== nextProps.match.params.groupid) {
      this.refresh();
    }
  }

  updateTable(page, parts) {
    this.props.history.push('/xpartgroup/xpart-to-delivery-group/' + page + '/' + parts);
  }

  pgFeedback(first, last, totalPages) {
    if (this.paginationComponent.current) {
      this.paginationComponent.current.updatePages(first, last, totalPages);
    }

    if (this.bottomPaginationComponent.current) {
      this.bottomPaginationComponent.current.updatePages(first, last, totalPages);
    }
  }

  refresh() {
    this.props.history.replace('/refresh' + window.location.pathname);
  }

  startMultiSelect() {
    this.tableComponent.current.startMultiSelect();
  }

  stopMultiSelect() {
    this.tableComponent.current.stopMultiSelect();
  }

  updateMultiSelectState(multiSelect) {
    this.controlButtonsComponentRef.current.updateMultiSelectState(multiSelect);
  }
  clearLocalSelection() {
    this.tableComponent.current.clearLocalSelection();
  }

  showException(err) {
    this.props.fireModal({
      content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors === undefined ? '' : err.response.data.errors[0]),
      header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
      buttons: this.createOKModalButtons()
    });
  }

  createOKModalButtons() {
    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    };
    btns.push(obj);
    return btns;
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  saveGroup() {
    this.props.fireModal({
      header: LabelFile.header["ENTER_DELIVERY_DETAILS"],
      content: <DeliveryGroupInfoDialog
        closeModal={this.props.closeModal}
        deliveryResponse={(data) => this.deliveryResponse(data)}
      />,
      type: 'wide',
      stickInOverlay: true
    });

  }

  deliveryResponse(deliveryData) {
    this.props.closeModal();

    const groupInfoDTO = {
      "active": true,
      "modeTypeId": 1,
      "typeNameId": 7,
      "validationCriteria": [
          "DELIVERY_GROUP_VALIDATION"
      ]
  };

    const deliveryGroupMetaInfo = {
      "deliveryType": deliveryData.deliveryType,
      "truckSignPlate": deliveryData.truckSignPlate
    };
    const data = {
      groupInfoDTO: groupInfoDTO,
      deliveryGroupMetaInfo: deliveryGroupMetaInfo,
      xpartsNumbers: this.tableComponent.current.getSelectedIds(),
      targetContextId: 2,
      targetStatusId: 3
    }
    this.toggleLoading();
    axios.post(API_XPART.XPART_SERVICE_URL + '/group/save', data)
      .then(res => {
        this.clearLocalSelection();
        this.stopMultiSelect();
        this.toggleLoading();
        const docNumber = res.data.deliveryGroupMetaDataDTO.deliveryDocumentNumber;
        const fileId = res.data.deliveryGroupMetaDataDTO.deliveryDocumentFileId;

        this.getPdf(fileId);
        this.showAutomaticallyGeneratedNumber(docNumber);
      })
      .catch(err => {
        this.showException(err);
        this.toggleLoading();
      });
  }

  getPdf(fileId) {
    HelperFunction.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/file/' + fileId);
  }

  showAutomaticallyGeneratedNumber(docNumber) {
    this.props.fireModal({
        content: this.renderAutomativallyGeneratedNumberDialog(docNumber),
        header: LabelFile.labels["delivery_document_number"],
        type: 'wide',
        stickInOverlay: true
    })
}

    // modal to show the automatically generated docNumber
    renderAutomativallyGeneratedNumberDialog(docNumber) {
      return (
          <div className="input-container newpackagemask" id="inputMain">
              {LabelFile.texts["delivery_with_number"]} {docNumber} {LabelFile.texts["automaticallyGeneratedPackUnitNumber2"]}
              <div className="xpm-button-container">
                  <button type="button" id="write-btn"
                      className="xpm-button"
                      onClick={() => this.props.closeModal()}>
                      {LabelFile.labels["closeDialog"]}
                  </button>
              </div>
          </div>
      )
  }


  render() {
    return (
      <section className="results--section">
        {/* page header */}
        <PageHeaderComponent
          text={<h1>{LabelFile.header['XPART_TO_DELIVERY_GROUP']}</h1>}
        />

        {/* pagination */}
        <PaginationComponent
          ref={this.paginationComponent}
          updateTable={(page, parts) => this.updateTable(page, parts)}
          page={this.props.match.params.page}
          parts={this.props.match.params.parts}
          module={'groupXpart'}
        />

        {/* option menu */}
        <XpartToDeliverGroupControlButtonsComponent ref={this.controlButtonsComponentRef} startMultiSelect={() => this.startMultiSelect()} stopMultiSelect={() => this.stopMultiSelect()}
          clearLocalSelection={() => this.clearLocalSelection()} saveGroup={() => this.saveGroup()} history={this.props.history}
        />

        <div className="list-container">
          {/* multiselect */}
          {<SelectableTableComponent
            ref={this.tableComponent}
            page={this.props.match.params.page - 1}
            size={this.props.match.params.parts}
            pgFeedback={(first, last, totalPages) => this.pgFeedback(first, last, totalPages)}
            context={this.props.match.params.context}
            module={'xpartsIntoDeliveryGroup'}
            usesFilter={this.state.usesFilter || this.config.usePreFilter}
            generalFilter={this.state.generalFilter}
            preFilter={this.state.preFilter}
            localStoragePrefix={this.config.localStoragePrefix}
            refresh={() => this.refresh()} history={this.props.history}
            fireModal={this.props.fireModal}
            closeModal={this.props.closeModal}
            updateMultiSelectState={(multiSelect) => this.updateMultiSelectState(multiSelect)}
          />}
        </div>

        {/* pagination */}
        <PaginationComponent
          ref={this.bottomPaginationComponent}
          updateTable={(page, parts) => this.updateTable(page, parts)}
          page={this.props.match.params.page}
          parts={this.props.match.params.parts}
          module={'groupXpart'}
        />

        <div id="popupContainer"></div>
        {this.state.isLoading ? <Loader type="lock" /> : ''}
      </section>
    )
  }
}
