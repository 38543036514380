import React from 'react';
import { LabelService } from '../Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');

/* This Heler class should be used for rapidly generating xpm inputs,
* which can be validated. Use individual functions and buttons in the template to control these inputs.
* Remember to validate everything before saving.
*/
export class FieldGenerator {

    constructor(props) {
        this.labels = LabelFile.labels;
        this.statusLabels = LabelFile.statuses;
        this.warnings = LabelFile.warnings;
    }

    mapFunctions = {
        generateInputText: this.generateInputText,
        generateInputHidden: this.generateInputHidden,
        generateInputTextarea: this.generateInputTextarea,
        generateInputNumber: this.generateInputNumber,
        generateInputOption: this.generateInputOption,
        generateInputSelect: this.generateInputSelect,
        generateInputSelect2: this.generateInputSelect2,
        generateWarnings: this.generateWarnings,
        validateInputs: this.validateInputs,
        toggle: this.toggle
    }


    /**
      * Validates Inputs of a custom template and shows warning labels if existant
      * @return {boolean} validation success or failure
    */
    validateInputs() {
        var allInputs = document.getElementsByClassName("xpm-input");
        var isValid = true;
        for (var i = 0; i < allInputs.length; i++) {
            if (!allInputs[i].checkValidity()) {
                isValid = false;
                allInputs[i].setAttribute('class', 'xpm-input xpm-invalid');
                if (allInputs[i].nextSibling) {
                    allInputs[i].nextSibling.setAttribute('class', 'xpm-warnings');
                }
            } else {
                allInputs[i].setAttribute('class', 'xpm-input xpm-valid');
                if (allInputs[i].nextSibling) {
                    allInputs[i].nextSibling.setAttribute('class', 'xpm-warnings xpm-warnings-inactive');
                }
            }
        }

        return isValid;
    }

    /**
     * Generates a hidden warning label for xpm-inputs
     * @param {array} A list of strings - the warnings that should be shown below the input
     * @return {jsx} the warning
     */
    generateWarnings(warnings) {
        if (warnings) {
            const warning = warnings.map(val => (
                <span>{LabelFile.warnings[val]}</span>
            ));
            return (
                <div className='xpm-warnings xpm-warnings-inactive'>
                    {warning}
                </div>
            )
        }
    }

    toggle(id) {
        if (document.getElementById(id).hasAttribute("disabled")) {
            document.getElementById(id).removeAttribute("disabled");
        } else {
            document.getElementById(id).setAttribute("disabled", "true");
        }
    }

    /**
     * Generates a generic xpm input
     * @param {string} the id of the field. HAS TO CORRESPOND TO A LABEL KEY
     * @param {string} HTML5 validation pattern
     * @param {boolean} option to make the input required
     * @param {array} A list of strings, used as warning labels
     * @param {boolean} Optional - can disable inputs on TRUE
     * @param {boolean} checkbox 
     * @return {jsx} the input, including a label and warnings
     * notice: don't use empty pattern, use .* for anything
     */
    generateInputText(id, pattern, required, warnings = [], disabled = false, checkbox = false, placeHolder = undefined) {
        var opts = {};
        var requiredMarker = '';
        if (required) {
            opts['required'] = 'required';
            requiredMarker = "*";
        }
        if (disabled) {
            opts['disabled'] = true;
        }

        if (placeHolder === undefined) {
            placeHolder = ''
        }

        opts['id'] = id;
        opts['pattern'] = pattern;
        opts['className'] = "xpm-input";
        opts['type'] = 'text';

        let checkbox_html;
        if (checkbox) {
            opts['className'] = "xpm-input xpm-input-with-checkbox";
            opts['disabled'] = true;
            checkbox_html = (
                <input type="checkbox" id={"checkbox_" + opts['id']} onChange={(event) => this.toggle(opts['id'])}></input>
            )
        }

        return (
            <div>
                <label>{LabelFile.labels[id]}{requiredMarker}</label>
                <input {...opts} placeholder={placeHolder}></input>{checkbox_html}
                {this.generateWarnings(warnings)}
            </div>
        )
    }

    /**
     * Generates a generic xpm hidden input
     * @param {string} the id of the field.
     * @return {jsx} the input
     */
    generateInputHidden(id) {
        return (
            <div>
                <input type="hidden" className="xpm-input" id={id}></input>
            </div>
        )
    }

    /**
     * Generates a generic xpm textarea
     * @param {string} the id of the field. HAS TO CORRESPOND TO A LABEL KEY
     * @param {integer} the maximum number of letters allowed
     * @param {boolean} option to make the input required
     * @param {array} A list of strings, used as warning labels
     * @param {boolean} Optional - can disable inputs on TRUE
     * @return {jsx} the input, including a label and warnings
     */
    generateInputTextarea(id, maxlength, required, warnings = [], disabled = false) {
        var opts = {};
        var requiredMarker = '';
        if (required) {
            opts['required'] = 'required';
            requiredMarker = "*";
        }
        if (disabled) {
            opts['disabled'] = 'disabled';
        }
        opts['id'] = id;
        opts['maxLength'] = maxlength;
        opts['className'] = "xpm-input";

        return (
            <div>
                <label>{LabelFile.labels[id]}{requiredMarker}</label>
                <textarea {...opts} ></textarea>
                {this.generateWarnings(warnings)}
            </div>
        )
    }

    /**
     * Generates a generic xpm input (number)
     * @param {string} the id of the field. HAS TO CORRESPOND TO A LABEL KEY
     * @param {integer} the minimum number
     * @param {integer} the maximum number
     * @param {integer} the step size of the arrow buttons
     * @param {boolean} option to make the input required
     * @param {array} A list of strings, used as warning labels
     * @param {boolean} Optional - can disable inputs on TRUE
     * @return {jsx} the input, including a label and warnings
     */
    generateInputNumber(id, min, max, step, required, warnings = [], disabled = false, onchange = null) {
        var opts = {};
        var requiredMarker = '';
        if (required) {
            opts['required'] = 'required';
            requiredMarker = "*";
        }
        if (disabled) {
            opts['disabled'] = 'disabled';
        }
        opts['id'] = id;
        opts['min'] = min;
        opts['max'] = max;
        opts['step'] = step;
        opts['type'] = "number"
        opts['className'] = "xpm-input";

        return (
            <div>
                <label>{LabelFile.labels[id]}{requiredMarker}</label>
                <input {...opts} ></input>
                {this.generateWarnings(warnings)}
            </div>
        )
    }

    /**
     * Generates options for a xpm select.  ONLY USE WITH generateInputSelect!
     * @param {array} a list of arrays containing the values and display labels of the selects
     * @return {jsx} the option
     */
    generateInputOption(options) {
        const items = options.map(result => (
            <option value={result.id}>{result.name}</option>
        ));
        return items;
    }

    /**
     * Generates a generic xpm input (dropdown)
     * @param {string} the id of the field. HAS TO CORRESPOND TO A LABEL KEY
     * @param {array} a list of arrays containing the values and display labels of the selects
     * @param {boolean} option to make the input required
     * @param {array} A list of strings, used as warning labels
     * @param {boolean} Optional - can disable inputs on TRUE
     * @return {jsx} the dropdown, including a label and warnings
     * New Field for config.json, translation-file can be used
     */
    generateInputSelect2(id, options2, required, warnings = [], disabled = false) {
        var opts2 = {};
        var requiredMarker2 = '';
        if (required) {
            opts2['required'] = 'required';
            requiredMarker2 = "*";
        }
        if (disabled) {
            opts2['disabled'] = 'disabled';
        }
        opts2['id'] = id;
        opts2['className'] = "xpm-input";

        return (
            <div className="box">
                <label>{LabelFile.labels[id]}{requiredMarker2}</label>
                <select {...opts2}>
                    {options2.map(result => (
                        <option value={result.id}>{(id === "status" ? LabelFile.statuses[result.name] : (id === "context" ? LabelFile.context[result.name] : LabelFile.labels[result.name]))}</option>
                    ))}
                </select>
                {this.generateWarnings(warnings)}
            </div>
        )
    }

    /**
    * Generates a generic xpm input (dropdown)
    * @param {string} the id of the field. HAS TO CORRESPOND TO A LABEL KEY
    * @param {array} a list of arrays containing the values and display labels of the selects
    * @param {boolean} option to make the input required
    * @param {array} A list of strings, used as warning labels
    * @param {boolean} Optional - can disable inputs on TRUE
    * @return {jsx} the dropdown, including a label and warnings
    */

    generateInputSelect(id, options, required, warnings = [], disabled = false, initialValue = null) {
        var opts = {};
        var requiredMarker = '';
        if (required) {
            opts['required'] = 'required';
            requiredMarker = "*";
        }
        if (disabled) {
            opts['disabled'] = 'disabled';
        }
        opts['id'] = id;
        opts['className'] = "xpm-input";

        return (
            <div>
                <label>{LabelFile.labels[id]}{requiredMarker}</label>
                <select {...opts}>
                    {options.map(result => (
                        <option selected={result.id === initialValue} value={result.id}>{result.name}</option>
                    ))}
                </select>
                {this.generateWarnings(warnings)}
            </div>
        )
    }
}