import React, { Component } from 'react'
import FormConfig from './forms-config.json'
import { withRouter } from 'react-router'
import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { LabelService } from '../Common/Translations/LabelService'
import axios from 'axios'
import { API } from '../Common/API/API'
import { APINotifications } from '../Common/API/APINotifications'
import { HelperFunctions } from '../Common/Helper/Helper'
import './form.css'
import '../Xpart/Xpart.css'
import '../styles/common.css'
import { Loader } from '../Common/Components/Loader/loader'

const API_XPART = new API()
const Fields = new FieldGenerator()
const LabelFile = LabelService.getLabelsByLocal('de')
const NOTIFICATIONS = new APINotifications()
const Helper = new HelperFunctions()

class FormGenerate extends Component {
  constructor(props) {
    super(props);
    this.inputfields = '';
    this.button = '';
    this.header = '';
    if (this.props.header) {
      this.header = this.props.header;
    }
    this.dataSave = {};
    this.savemethod = ''
    this.formname = this.props.formname;
    this.dataSetIdName = this.props.DataSetIdName
    this.config = FormConfig[this.formname];
    this.currentXpart = {};
    this.receiveVariables = this.props.receiveVariables;
    this.selectedStorage = this.props.match.params.storagePlace;
  }

  state = {
    isLoading: true,
    result: [],
    photoAmount: ''
  }

  // Map Project-Number from database
  mapProjectNumber(response) {
    if (response.items[0].orders[0].type[0].name === 'PROJECT') {
      return response.items[0].orders[0].number
    } else {
      return ''
    }
  }

  // Map Project-Item from database
  mapProjectItem(response) {
    let proNumber = this.mapProjectNumber(response)
    if (proNumber === '') {
      return ''
    } else {
      return response.items[0].number
    }
  }

  // Map Unit-ID from database
  mapUnitID(response) {
    return response.unit.id
  }

  // Map statuses from database
  mapStatuses(response) {
    return response['status'].id
  }
  // id of Xpart
  mapXpartId(response) {
    return response["currentStatus"].xpartId;
  }

  // Map context from database
  mapContext(response) {
    return response['currentStatus']['contextDTO'].id
  }

  // Set storage-place, includes special way for 'umfuhrplatz'  
  mapStoragePlace(response) {
    if (this.savemethod === 'areaKME') {
      return ('EVS2-FL3-49');
    }
    else {
      if (response["storagePlace"] !== null) {
        return this.getStorageSeq(response["storagePlace"]);
      }
      else {
        return '';
      }
    }
  }

  getStorageSeq(storageDTO) {
    let names = '';
    for (let i = storageDTO.parents.length - 1; i >= 0; i--) {
      names += storageDTO.parents[i].name + '-';
    }
    names += storageDTO.name;
    return names;
  }

  // OK - button for modal   
  createOKModalButtons() {
    var btns = []
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal
    }
    btns.push(obj)
    return btns
  }

  // handles the YES-button
  handleWarningStatusYesButton = () => {
    this.saveStorage();
    this.props.closeModal();
  }

  // YES and NO - Button for modal
  createWarningStatusModalButtons() {

    var btns = [];
    var no = {
      customClass: 'button_no',
      text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
      callback: this.props.closeModal,
    };

    var yes = {
      customClass: 'button_yes',
      text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
      callback: this.handleWarningStatusYesButton,
    };

    btns.push(no);
    btns.push(yes);

    return btns;
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  getPhotos() {
    if (!localStorage.getItem("xpm-gallery-list-" + this.currentXpart.xpartNumber)) {
      return [];
    }
    let photos = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.currentXpart.xpartNumber));
    let toSend = [];
    for (let i in photos) {
      toSend.push(photos[i].replace(photos[i].substring(0, photos[i].indexOf(';base64') + 7), ""));
    }

    return toSend;
  }

  clearPhotos() {
    if (!localStorage.getItem("xpm-gallery-list-" + this.currentXpart.xpartNumber)) {
      return;
    }

    localStorage.removeItem("xpm-gallery-list-" + this.currentXpart.xpartNumber);
  }

  replaceFormat(result) {
    let val = '' + document.getElementById(result.htmlId).value
    if (result.isNumber) {
      val = val.replace(/[,.]/g, m => (m === ',' ? '.' : ','))
    }
    return val;
  }

  receiveXpart() {

    this.toggleLoading();

    let targetStatus = this.header === 'ASSEMBLY' ? 4 : 2;
    let targetContext = this.header === 'ASSEMBLY' ? 7 : 9;

    let xpartProperties = {
      active: true,
      contextId: targetContext,
      descriptionDe: this.currentXpart.descriptionDe,
      descriptionEn: this.currentXpart.descriptionEn,
      id: this.currentXpart.id,
      quantity: this.currentXpart.quantity,
      statusID: targetStatus,
      customerMaterialNumber: this.currentXpart.customerMaterialNumber,
      unitID: this.currentXpart.unit.id,
      weight: this.currentXpart.weight
    }

    this.receiveVariables.formParameters.map(result => (
      xpartProperties[result.name] = this.replaceFormat(result)
    ));

    let storageSequence = document.getElementById("storage").value.split("-");

    this.saveXpartReception(xpartProperties, storageSequence, this.getPhotos());

  }

  saveXpartReception(xpartProperties, storageSequence, files) {
    let data = {
      xpartProperties: xpartProperties,
      storageSequence: storageSequence,
      files: files
    };

    axios.post(API_XPART.XPART_SERVICE_URL + '/receive', data).then(
      response => {
        // condition to check if the xpart is coming from KMe then only send to Myfactory
        if (this.currentXpart['currentStatus']['contextDTO'].id === 9 && this.currentXpart['currentStatus']['statusDTO'].id === 1) {
          this.uploadXpartInfoToMF();
        } else {
          this.toggleLoading(false);
          this.prefillForm();
          this.clearPhotos();
          localStorage.removeItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber);
          localStorage.removeItem('netweight-' + this.currentXpart.xpartNumber);
          localStorage.removeItem('desc_en-' + this.currentXpart.xpartNumber);
          this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
            header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
            buttons: this.createOKModalButtons()
          });
          this.props.history.push(this.receiveVariables.successURI);
        }
      }).catch(err => {
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      })
      .finally(() => {
        this.toggleLoading(false);
      });

  }

  // return project info
  getProjectnr(xpartInfo) {
    if (xpartInfo['items'].length > 0) {
      for (let i = 0; i < xpartInfo['items'].length; i++) {
        if (xpartInfo['items'][i]['type']['name'] === 'PROJECT') {
          let projectInfo = xpartInfo['items'][i]['orders'][0]
          return projectInfo['number']
        }
      }
    } else { return '0'; }
  }

  UnitNameMapping(unit) {
    switch (unit) {
      case 'PIECE':
        return 'Stk';
      case 'LITRE':
        return 'l';
      case 'METER':
        return 'm';

      default:
        return 'Stk';
    }
  }
  // Upload xpart info to Myfactory
  uploadXpartInfoToMF() {
    let MyFObject = {
      "XPartNumber": 0,
      "CustomerCode": 0,
      "DescriptionDe": '',
      "DescriptionEn": '',
      "Quantity": 0,
      "Unit": '',
      "Weight": 0,
      "StoragePlace": '',
      "CustomerMaterialNumber": 0,
      "CustomerMaterialEAN": 0,
      "ProjectNumber": 0,
      "ItemNumber": 0
    }
    let xpartInfo = this.currentXpart;
    MyFObject["XPartNumber"] = xpartInfo["xpartNumber"];
    MyFObject["CustomerCode"] = 'KraussMaffei'
    MyFObject["DescriptionDe"] = xpartInfo["descriptionDe"];
    MyFObject["DescriptionEn"] = xpartInfo["descriptionEn"];
    MyFObject["Quantity"] = xpartInfo["quantity"];
    MyFObject["Unit"] = this.UnitNameMapping(xpartInfo["unit"]["name"]);
    MyFObject["Weight"] = xpartInfo["weight"];
    MyFObject["ProjectNumber"] = this.getProjectnr(xpartInfo);
    MyFObject["StoragePlace"] = (localStorage.getItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber) ? localStorage.getItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber) : '');

    MyFObject["CustomerMaterialNumber"] = xpartInfo["customerMaterialNumber"];
    for (let i = 0; i < xpartInfo.items.length; i++) {
      if(xpartInfo.items[i].type.name==='PROJECT'){
      MyFObject["ItemNumber"]=xpartInfo.items[i].number;
      }
    }
    console.log("MyFObject", MyFObject);
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    }

    axios.post(API_XPART.NODEJS_SERVICE_URL + '/uploadXpmXpartDataInMyF/', {
      headers: headers,
      timeout: 10000,
      data: MyFObject,
    })
      .then(res => {
        console.log("res", res);
        this.toggleLoading(false);
        // this.prefillForm();
        this.clearPhotos();
        localStorage.removeItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber);
        localStorage.removeItem('netweight-' + this.currentXpart.xpartNumber);
        localStorage.removeItem('desc_en-' + this.currentXpart.xpartNumber);
        this.props.fireModal({
          content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
          header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
          buttons: this.createOKModalButtons()
        });
        this.props.history.push(this.receiveVariables.successURI);
      })
      .catch(err => {
        console.log("error", err);
        this.props.history.push(this.receiveVariables.successURI);
        // this.props.fireModal({
        //   content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
        //   header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
        //   buttons: this.createOKModalButtons()
        // });
      })
  }

  // saves changed Storage Places
  saveStorage() {
    this.receiveXpart();
  }

  // save form and push to next site
  saveForm() {
    if ((this.config.fixedvalue && this.config.fixedvalue === 'EVS2-FL3-49') || this.header === 'ASSEMBLY') {
      this.saveStorage();        // saves Storage  (for "Empfang Umfuhrfläche KME")
    }
    else {
      if (Fields.validateInputs()) {
        let x = ''
        let fieldName = ''
        let dbName = ''
        for (let i = 0; i < this.config.fields.length; i++) {
          x = this.config.fields[i]
          fieldName = x['name']
          dbName = x['dbname']
          this.dataSave[dbName] = this.replaceFormatForField(document.getElementById(fieldName).value, x['format']);
        }
      }

      this.toggleLoading();
      axios
        .post(API_XPART.XPART_SERVICE_URL + this.config.dataDestination, this.dataSave)
        .then(res => {
          if (this.props.pdfPrint && this.props.pdfPrint === 'true') {
            Helper.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/x-part/' + res.data.xpartNumber).then(() => this.props.history.push(this.props.pathAfterPrint))
          } else {
            this.props.fireModal({
              content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
              header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
              buttons: this.createOKModalButtons()
            })
          }
          if (this.props.pathAfterSave) {
            this.props.history.push(this.props.pathAfterSave)
          }
        })
        .catch(err => {
          this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
          })
        })
        .finally(() => this.toggleLoading(false))
    }
  }

  // render form-fields from json-file
  renderForm() {
    return this.config.fields.map(e => {
      if (e.type === 'generateInputSelect2') {
        return (this.inputfields = Fields.mapFunctions[e.type](e.name, e.options, e.required, e.warnings, e.disabled))
      } else {
        return (this.inputfields = Fields.mapFunctions[e.type](e.name, e.pattern, e.required, e.warnings, e.disabled))
      }
    })
  }

  // reset form, also the hidden-fields
  resetForm() {
    let x = null
    for (let i = 0; i < this.config.fields.length; i++) {
      x = this.config.fields[i]
      document.getElementById(x['name']).value = ''
    }
  }

  // changes Storage by input or scan
  changeStorage(response) {
    if (document.getElementById('weight')) {
      localStorage.setItem('netweight-' + this.currentXpart.xpartNumber, document.getElementById('weight').value);
    }
    if (document.getElementById('desc_en')) {
      localStorage.setItem('desc_en-' + this.currentXpart.xpartNumber, document.getElementById('desc_en').value);
    }
    this.props.history.push('/storage/changestorage/' + this.dataSetIdName + '/assembly');
  }

  // goto photo component and take or make a photo
  buildFoto() {
    if (document.getElementById('weight')) {
      localStorage.setItem('netweight-' + this.currentXpart.xpartNumber, document.getElementById('weight').value);
    }
    if (document.getElementById('desc_en')) {
      localStorage.setItem('desc_en-' + this.currentXpart.xpartNumber, document.getElementById('desc_en').value);
    }
    this.props.history.push('/photo/new/' + this.dataSetIdName + '/' + this.props.flag)
  }

  // pass the fetched data from the database to the parent component
  passDataToParent() {
    if (Fields.validateInputs()) {
      let x = ''
      let fieldName = ''
      let dbName = ''

      for (let i = 0; i < this.config.fields.length; i++) {
        x = this.config.fields[i]
        fieldName = x['name']
        dbName = x['dbname']
        this.dataSave[dbName] = this.replaceFormatForField(document.getElementById(fieldName).value, x['format']);
      }

      return this.dataSave;
    }
    return null;
  }

  //proves if context and status matches, otherwise warning
  checkStatusAndContext() {

    if (!Fields.validateInputs()) {
      return;
    }
    if (this.props.warningSave && this.props.warningSave === 'proveKMEOrdered' && (document.getElementById('status').value !== '1' || document.getElementById('context').value !== '9')) {
      this.props.fireModal({
        content: NOTIFICATIONS.getNotification('de', 'STATUS_CONTEXT_WRONG'),
        header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
        buttons: this.createWarningStatusModalButtons()
      });
    } else if (this.props.warningSave && this.props.warningSave === 'proveAssembly' && (document.getElementById('status').value !== '2' || document.getElementById('context').value !== '9')) {
      this.props.fireModal({
        content: NOTIFICATIONS.getNotification('de', 'STATUS_CONTEXT_ASSEMLY_WRONG'),
        header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
        buttons: this.createWarningStatusModalButtons()
      });
    } else {
      this.saveStorage();
    }
  }

  // handles the different statuses of buttons
  handleButton(method) {
    switch (method) {
      case 'savestorage':
        return this.checkStatusAndContext;
      case 'saveassembly':
        return this.checkStatusAndContext;
      case 'save':
        return this.saveForm;
      case 'reset':
        return this.resetForm;
      case 'change_storage':
        return this.changeStorage;
      case 'photos':
        return this.buildFoto;
      default:
        break;
    }
  }

  // render button from json-file
  renderButton() {
    if (!this.config.button) {
      return '';
    }
    return (
      <div>
        {this.config.button.map(e => (
          <div className="form-container">
            <button name={e.name} type="button" key={e.id} id={e.id} onClick={this.handleButton(e.name).bind(this)}
              className={'form-button-properties xpm-button ' + (e.disabled ? ' xpm-disabled ' : '')} disabled={e.disabled ? true : false}>
              {LabelFile.labels[e.name]}{e.name === 'photos' ? this.state.photoAmount : ''}
            </button>
          </div>
        ))}
      </div>
    )
  }

  // prefill hidden fields and other formfields with empty value
  createEmptyForm() {
    let x = null
    for (let i = 0; i < this.config.fields.length; i++) {
      x = this.config.fields[i]
      if (x['type'] === 'generateInputText') {
        document.getElementById(x['name']).value = ''
      }
      if (x['type'] === 'generateInputHidden') {
        document.getElementById(x['name']).value = x['value']
      }
    }
    this.toggleLoading();
  }

  checkRecevieVariables() {

    if (this.receiveVariables !== null && this.props.match.params.storagePlace !== undefined) {
      localStorage.setItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber, this.props.match.params.storagePlace);
    }
    if (this.header === 'ASSEMBLY' && localStorage.getItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber)) {
      document.getElementById('storage').value = localStorage.getItem("xpm-storagetemp-list-" + this.currentXpart.xpartNumber);
    }

    if (localStorage.getItem('netweight-' + this.currentXpart.xpartNumber)) {
      document.getElementById('weight').value = localStorage.getItem('netweight-' + this.currentXpart.xpartNumber);
      localStorage.removeItem('netweight-' + this.currentXpart.xpartNumber);
    }
    if (localStorage.getItem('desc_en-' + this.currentXpart.xpartNumber)) {
      document.getElementById('desc_en').value = localStorage.getItem('desc_en-' + this.currentXpart.xpartNumber);
      localStorage.removeItem('desc_en-' + this.currentXpart.xpartNumber);
    }


    if (!this.config.button) {
      return;
    }


    if (this.receiveVariables !== null &&
      ((this.receiveVariables.needPhoto &&
        !localStorage.getItem("xpm-gallery-list-" + this.currentXpart.xpartNumber)) || (document.getElementById("storage").value === ''))) {
      document.getElementById('write-btn').classList.add("xpm-disabled");
      document.getElementById('write-btn').setAttribute('disabled', true);
    } else {
      document.getElementById('write-btn').removeAttribute('disabled');
      document.getElementById('write-btn').classList.remove("xpm-disabled");
      document.getElementById('write-btn')
        .addEventListener('click', this.handleButton(document.getElementById('write-btn').getAttribute('name')).bind(this))
    }
  }

  fillForm(data) {

    let x = null;
    let dataFields = '';

    for (let i = 0; i < this.config.fields.length; i++) {
      x = this.config.fields[i];
      if (x['fixedvalue'] && x['fixedvalue'] === 'EVS2-FL3-49') {
        this.savemethod = 'areaKME';
      }
      if (x['type'] === 'generateInputText' || x['type'] === 'generateInputTextarea' || x['type'] === 'generateInputSelect2' || x['type'] === 'generateInputHidden') {
        if (x['mappingFunction']) {
          let result = '';
          switch (x['mappingFunction']) {
            case 'mapProjectNumber':
              result = this.mapProjectNumber(data);
              break;
            case 'mapProjectItem':
              result = this.mapProjectItem(data);
              break;
            case 'mapUnitID':
              result = this.mapUnitID(data);
              break;
            case 'mapStatuses':
              result = this.mapStatuses(data);
              break;
            case 'mapContext':
              result = this.mapContext(data);
              break;
            case 'mapXpartId':
              result = this.mapXpartId(data);
              break;
            case 'mapStoragePlace':
              result = this.mapStoragePlace(data);
              break;
            default:
          }
          document.getElementById(x['name']).value = result;
        } else {
          if (x["fixedvalue"]) {
            document.getElementById(x['name']).value = x["fixedvalue"];
          }
          else {
            dataFields = x['dbnameGet'];
            let val = data[dataFields];
            document.getElementById(x['name']).value = this.replaceFormatForField(val, x["format"]);
          }

        }
      }
    }

    this.checkRecevieVariables();
  }

  // prefill (else...) with values from database
  prefillForm() {
    if (this.props.prefill && this.props.prefill === "false") {
      this.createEmptyForm();
    }
    else if (!this.props.initData) {

      axios.get(API_XPART.XPART_SERVICE_URL + this.config.getData + this.dataSetIdName)
        .then(response => {

          this.currentXpart = response['data'];
          this.fillForm(this.currentXpart);

        }).finally(e => {
          this.toggleLoading();
        })
    } else {
      this.fillForm(this.props.initData);
    }

  }

  replaceFormatForField(val, format) {
    if (val !== null && format === 'number') {
      val = val + '';
      val = val.replace(/[,.]/g, m => (m === ',' ? '.' : ','))
    }
    return val;
  }

  componentDidMount() {

    if (this.props.setRef) {
      this.props.setRef(this)
    }

    let photoAmount = '';
    if (this.props.amountOfLocalPhotos) {
      photoAmount = ' (' + this.props.amountOfLocalPhotos + ')';
    }
    this.setState({ photoAmount: photoAmount });

    this.prefillForm()
  }



  render() {
    return (
      <section className="results--section xpartform">
        <h1>{LabelFile.header[this.header]}</h1>
        <div className="input-container formfields">
          <form>
            <div>{this.renderForm()}</div>
            <div>{this.renderButton()}</div>
          </form>
        </div>
        {this.state.isLoading ? <Loader type="lock" /> : ''}
      </section>
    )
  }
}

export default withRouter(FormGenerate)
