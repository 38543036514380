import React, { Component } from 'react';

import './notifications.css'

class Notifications extends Component {
    render () {
        const { notifications } = this.props
        const notificationsClass = (notifications.length) ? 'notifications' : 'notifications--hide'
        const messages = notifications.map((notification, idx) => <Message key={idx} notification={notification} />);
        return (
            <div className={`${notificationsClass}`}>
                {messages}
            </div>
        );
    }
}


const Message = ({ notification }) => {
    const { type, title, message } = notification
    return (
        <div className={`notifications__message--${type.toLowerCase()}`}>
            <div className="notifications__title">
                {title}
            </div>
            <div className="notifications__content">
                <p>{message}</p>
            </div>
        </div>
    )
}

export default Notifications;
