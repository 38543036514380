import React, { Component } from 'react'

import { FieldGenerator } from '../../Common/Helper/FieldGenerator'
import { LabelService } from '../../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();

export class DeliveryGroupInfoDialog extends Component {


    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
               
    }

    
    getDialogValues() {
        let deliveryItem = {};
        deliveryItem['deliveryType'] = document.getElementById('deliveryType').value;
        deliveryItem['truckSignPlate'] = document.getElementById('truckSignPlate').value;

        return deliveryItem;
    }

  

    render() {
        return (
            <div className="input-container" id="inputMain">
               
                {Fields.generateInputText("deliveryType", "[a-zA-Z0-9]{1,20}",  true, [this.warnings['required']])}
                {Fields.generateInputText("truckSignPlate", "[a-zA-Z0-9]{1,20}", true, [this.warnings['required']])}

                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className={"xpm-button"}
                        onClick={() => this.props.deliveryResponse(this.getDialogValues())}
                    >{LabelFile.labels["save"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }
}
