import Permissions from './../../page-access-config.json'

export class AuthService {

    isAuthenticated() {
        const userInfo = JSON.parse(localStorage.getItem('xpm-user-info'));
        return !!userInfo && userInfo.company && userInfo.company.keys;
    }

    /**
     * Determines whether to use additional permissions
     * @param {string} defaultPermission - permission based on whole url
     * @param {{parameter: string, parameterValue: string, permission: string}} additionalPermission - object describing the URL parameter and additional permission for it
     * @param {{}} params - list of url parameters
     * @return {boolean} - success - access allowed, failure - denied
     */
    isAccessAllowed(defaultPermission, additionalPermission, params) {
        if (!this.isAuthenticated()) {
            return false
        }

        if (!defaultPermission) {
            return false
        }

        if (additionalPermission) {
            defaultPermission = this.isAdditionalPermissionNeeded(additionalPermission, params) ? additionalPermission.permission : defaultPermission
        }

        const userInfo = JSON.parse(localStorage.getItem('xpm-user-info'));

        return Permissions[defaultPermission]
            && Permissions[defaultPermission]["companyKeys"].some(kompanyKey => userInfo.company.keys.includes(kompanyKey))
            && userInfo.roles
                .some(role => Permissions[defaultPermission]["roles"]
                    .some(permissionRole => permissionRole === role)
                )
    }

    /**
     * Determines whether to use additional permissions
     * @param {{parameter: string, parameterValue: string, permission: string}} additionalPermission  - object describing the URL parameter and permission for it
     * @param {{}} params - list of url parameters
     * @return {boolean} - determines whether to use additional permissions
     */
    isAdditionalPermissionNeeded(additionalPermission, params) {
        return additionalPermission.parameterValue === params[additionalPermission.parameter]
    }
}
