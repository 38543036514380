import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import { APINotifications } from '../Common/API/APINotifications';
import { Link } from 'react-router-dom';
import { LabelService } from '../Common/Translations/LabelService';
import { HelperFunctions } from '../Common/Helper/Helper';
import { Loader } from '../Common/Components/Loader/loader';
import './photo.css';


const API_PHOTO = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');
const HelperFunction = new HelperFunctions();

class PhotoGallery extends Component {

    state = {
        resultlist: [],
        serverresultlist: [],
        xpartid: 0,
        localimagecheck: false,
        serverimagecheck: false,
        optionalClass: "",
        optionalBtn: "xpm-hidden",
        optionalLink: "",
        btnActive: true,
        isLoading: false,
        isAfterSavingLoading: false,
        showSave: true
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }

    componentDidMount() {

        if (this.props.match.params.context === 'receiveKME' || this.props.match.params.context === 'receiveAssembly') {
            this.setState({
                showSave: false
            })
        }

        if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
            let result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart));

            this.setState({
                resultlist: result,
                localimagecheck: true
            })
        }

        document.getElementById("LocalGallery").addEventListener("click", e => {
            if (e.target && (e.target.className === "photo-gallery-img-delete")) {
                let index = e.target.parentNode.firstChild.getAttribute("data-attr");
                this.handleDelete(index);
            }
        });

        this.getXPartId();

        if (this.props.match.params.module === "reception") {
            this.setState({
                optionalClass: "xpm-hidden",
                optionalBtn: "",
                optionalLink: "/reception"
            })
        }

        this.getServerImages();
    }

    getServerImages() {
        let filetype = "fileTypeId=1";
        let xpart = "xpartNumber=" + this.props.match.params.xpart;
        this.toggleLoading(true)
        axios.get(API_PHOTO.XPART_SERVICE_URL + '/file?' + filetype + "&" + xpart)
            .then(response => {
                this.setState({
                    serverresultlist: response["data"],

                })
                if (this.state.serverresultlist.length > 0) {
                    this.setState({
                        serverimagecheck: true
                    })
                }
                console.log(this.state.serverresultlist);
                this.handleForwarding();
            })
            .catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => this.toggleLoading(false));
    }

    handleForwarding() {
        if (this.state.serverimagecheck === false && this.state.localimagecheck === false) {
            this.props.history.push(this.state.optionalLink + '/photo/new/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context));
        }
    }

    getXPartId() {
        axios.get(API_PHOTO.XPART_SERVICE_URL + '/one/xp-number/' + this.props.match.params.xpart)
            .then(response => {
                this.setState({
                    xpartid: response.data["id"]
                })

            })
            .catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            });
    }

    handleDelete(i) {
        let results = this.state.resultlist;
        results.splice(i, 1);
        this.setState({
            resultlist: results
        })
        if (this.state.resultlist.length === 0) {
            localStorage.removeItem("xpm-gallery-list-" + this.props.match.params.xpart);
        } else {
            localStorage.setItem("xpm-gallery-list-" + this.props.match.params.xpart, JSON.stringify(this.state.resultlist));
        }
    }

    handleUpload() {
        if (!this.state.btnActive) {
            console.log("inaktiv");
            return;
        }
        console.log("nachcheck")
        this.setState({
            btnActive: false
        })
        if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
            let photos = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart));
            let photolistsize = photos.length;
            let counter = 0;
            photos.forEach((element, index) => {
                fetch(element) //let me just quickly fetch a local data uri lol
                    .then(res => res.blob())
                    .then(blob => {
                        var fd = new FormData()
                        fd.append('file', blob, 'filename')
                        this.setState({
                            isAfterSavingLoading: true
                        })
                        axios.post(API_PHOTO.FILE_SERVICE_URL + '/file', fd, { headers: { 'Content-Type': 'multipart/form-data;' } })
                            .then(img_id => {
                                let data = {
                                    "type": "IMAGE",
                                    "xpartId": this.state.xpartid,
                                    "fileId": img_id.data
                                }
                                axios.post(API_PHOTO.XPART_SERVICE_URL + '/file', data)
                                    .then(res => {
                                        this.handleDelete(index);
                                        counter += 1;
                                        if (counter === photolistsize) {
                                            this.getServerImages();
                                            localStorage.removeItem("xpm-gallery-list-" + this.props.match.params.xpart);
                                            this.props.fireModal({
                                                content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                                                header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                                                buttons: this.createOKModalButtons()
                                            });
                                            this.setState({
                                                btnActive: true
                                            })
                                        }
                                    })
                                    .catch(err => {
                                        this.props.fireModal({
                                            contnet: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                                            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                                            buttons: this.createOKModalButtons()
                                        });
                                        this.setState({
                                            btnActive: true
                                        })
                                    })
                            })
                            .catch(err => {
                                this.props.fireModal({
                                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                                    buttons: this.createOKModalButtons()
                                });
                                this.setState({
                                    btnActive: true
                                })
                            })
                            .finally(() => {
                                this.setState({
                                    isAfterSavingLoading: false
                                })
                            });
                    });
            });
        }
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }

    checkStorage() {

        if (localStorage.getItem("xpm-storagetemp-list-" + this.props.match.params.xpart)) {
            return '/reception/true/receptionxpartstorage/' + this.props.match.params.xpart + '/setstorage/' + localStorage.getItem("xpm-storagetemp-list-" + this.props.match.params.xpart) + HelperFunction.getContextOrNot(this.props.match.params.context);
        }
        else {
            return '/reception/receptionxpart/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context);
        }
    }

    saveBtnClass() {
        if (this.state.btnActive) {
            return ""
        } else {
            return " xpm-disabled"
        }
    }


    render() {
        let linkPass = ''

        if (this.props.match.params.context === 'receiveKME') {
            linkPass = '/formfieldontransportarea/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context)
        } else if ((this.props.match.params.context === 'receiveAssembly')) {
            linkPass = '/formfieldassembly/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context)
        } else {
            linkPass = '/xpart/show/' + this.props.match.params.xpart + '/edit' + HelperFunction.getContextOrNot(this.props.match.params.context)
        }

        return (
            <div>
                <h2>{LabelFile.labels["serverphotos"] + " (" + this.state.serverresultlist.length + ")"}</h2>
                <div className="gallery" id="ServerGallery">
                    {this.state.serverresultlist.map((result, index) => (
                        <div>
                            <img alt="Gallery" data-attr={index} src={API_PHOTO.FILE_SERVICE_URL + '/file/' + result["fileId"]} />
                            <span style={{ color: 'black' }}>{result["fileName"]}</span>
                        </div>
                    ))}
                </div>
                {this.state.isLoading ? <Loader /> : ''}
                <h2 className="xpm-clear">{LabelFile.labels["localphotos"] + " (" + this.state.resultlist.length + ")"}</h2>
                <div className="gallery" id="LocalGallery">
                    {this.state.resultlist.map((result, index) => (
                        <div>
                            <img alt="Gallery" data-attr={index} src={result} />
                            <span className="photo-gallery-img-delete">x</span>
                        </div>
                    ))}
                </div>
                <div className="photo-gallery-button-container xpm-button-container">
                    <Link className="xpm-button" to={this.state.optionalLink + '/photo/new/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context)}>{LabelFile.labels["photos"]}</Link>

                    <Link className={"xpm-button " + this.state.optionalBtn} to={this.checkStorage()}>{LabelFile.labels["back"]}</Link>

                    {/* <Link className={"xpm-button " + this.state.optionalClass} to={'/xpart/show/' + this.props.match.params.xpart + '/edit' + HelperFunction.getContextOrNot(this.props.match.params.context)}>{LabelFile.labels["back"]}</Link> */}

                    <Link className={"xpm-button " + this.state.optionalClass} to={linkPass}>{LabelFile.labels["back"]}</Link>
                    {this.state.showSave ?
                        <div class={"xpm-button " + this.state.optionalClass + this.saveBtnClass()} onClick={this.handleUpload.bind(this)}>
                            {LabelFile.labels["save"]}
                        </div> : ''
                    }

                </div>
                {this.state.isAfterSavingLoading ? <Loader type="lock" /> : ''}
            </div>
        )
    }
}

export default withRouter(PhotoGallery);
