import React from 'react';
import { HelperFunctions } from '../Common/Helper/Helper';
import { LabelService } from '../Common/Translations/LabelService';
import { Link } from 'react-router-dom';
import { API } from '../Common/API/API';
import { ExtraDataService } from './ExtraDataService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwitchToggleComponent from "./SwitchToggle/SwitchToggle";
import { APINotifications } from '../Common/API/APINotifications';
import './list.css'

const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const ExtraFunctions = new ExtraDataService();
const NOTIFICATIONS = new APINotifications();

export class DataParsingService {
    constructor(fireModal, closeModal, history, refresh) {
        this.fireModal = fireModal;
        this.closeModal = closeModal;
        this.history = history;
        this.refresh = refresh;

        this.mapSwitchToggle = this.mapSwitchToggle.bind(this);
        this.mapToEdit = this.mapToEdit.bind(this);
        this.mapFunctionsColumn = this.mapFunctionsColumn.bind(this);
        this.lang = HelperFunction.getLocale();
    }

    mapFunctions = {
        mapDate: this.mapDate,
        mapDateNoTime: this.mapDateNoTime,
        mapActive: this.mapActive,
        mapFloat: this.mapFloat,
        mapItemOrders: this.mapItemOrders,
        mapOrderItem: this.mapOrderItem,
        mapProjectItem: this.mapProjectItem,
        mapStatus: this.mapStatus,
        mapStoragePlaceCode: this.mapStoragePlaceCode,
        mapLink: this.mapLink,
        mapUnit: this.mapUnit,
        mapDestinationStorageCode: this.mapDestinationStorageCode,
        mapProjectNumberFromOrder: this.mapProjectNumberFromOrder,
        mapCustomer: this.mapCustomer,
        mapSupplier: this.mapSupplier,
        mapSupplierFromXpart: this.mapSupplierFromXpart,
        getCompnayNameByType: this.getCompnayNameByType,
        checkCompletionGroup: this.checkCompletionGroup,
        mapPrint: this.mapPrint,
        mapPackageNumber: this.mapPackageNumber,
        mapLinkXPart: this.mapLinkXPart,
        mapTruth: this.mapTruth,
        mapSwitchToggle: this.mapSwitchToggle,
        mapPspElement: this.mapPspElement,
        mapComment: this.mapComment,
        mapXpartGroupType: this.mapXpartGroupType,
        mapXpartGroupMode: this.mapXpartGroupMode,
        mapActiveGroup: this.mapActiveGroup,
        mapXpartCompletion: this.mapXpartCompletion,
        mapDeliveryDateFromMetaRequest: this.mapDeliveryDateFromMetaRequest,
        mapDeliveryPlaceFromMetaRequest: this.mapDeliveryPlaceFromMetaRequest,
        mapGroupCreateDateFromMetaRequest: this.mapGroupCreateDateFromMetaRequest,
        mapEvsOrderNumber: this.mapEvsOrderNumber,
        mapEvsProjectNumber: this.mapEvsProjectNumber,
        mapSupplierPackageData: this.mapSupplierPackageData,
        mapOrderStatus: this.mapOrderStatus,
        mapCombinedDescription: this.mapCombinedDescription,
        mapPackingNumber: this.mapPackingNumber,
        mapDeliveryGroupOverview: this.mapDeliveryGroupOverview,
        mapDeliveryNumber:this.mapDeliveryNumber
    }

    mapDeliveryNumber(cell, confRow, dto){
        return (dto.deliveryGroupMetaInfo ? dto.deliveryGroupMetaInfo[confRow.name] : '');
    }
    mapDeliveryGroupOverview(cell, confRow, dto) {
        
        if (confRow.name === 'startOfDelivery') {
            this.lang = HelperFunction.getLocale();
            return (dto.deliveryGroupMetaDataDTO ? HelperFunction.timeConverter(dto.deliveryGroupMetaDataDTO[confRow.name], this.lang,true) : '');
        }

        if (confRow.name === 'xpartCount') {
            return (dto ? dto.xpartNumbers.length : '');
        }

        if (confRow.name === 'status') {
            return (dto ? (dto.active ? LabelFile.statuses['IN_DELIVERY'] : LabelFile.statuses['FULFILLED']) : '');
        }

        return (dto.deliveryGroupMetaDataDTO ? dto.deliveryGroupMetaDataDTO[confRow.name] : '');
    }

    mapPackingNumber(cell, confRow, dto) {
        if (dto.packageDTO) {
            let packageNo = dto.packageDTO.packageNumber;
            let packUnitNumber = dto.transportGroupMetaInfo ? (dto.transportGroupMetaInfo.packUnitNumber ? dto.transportGroupMetaInfo.packUnitNumber : null) : null;

            if (packageNo.includes('E-')) {
                return packageNo;
            }
            packageNo = (packageNo.includes('P-') ? (packUnitNumber ? packUnitNumber : packageNo) : packageNo)
            return packageNo;
        }
        return "";
    }

    mapCombinedDescription(cell, confRow, dto) {
        let combinedText = (cell ? cell.trim() + ' | ' + (dto.descriptionEn ? dto.descriptionEn.trim() : '') : (dto.descriptionEn.trim() ? dto.descriptionEn : ''))
        return combinedText;
    }

    mapXpartGroupType(group) {
        return group.name;
    }

    mapXpartGroupMode(mode) {
        return mode.name;
    }

    mapSupplierPackageData(cell, confRow, dto) {
        return (confRow.dataName === 'xpartCount' ? dto.xpartNumbers.length : dto.transportGroupMetaData[confRow.dataName]);
    }

    mapTruth(cell, confRow, dto, outerId) {
        let id = outerId ? outerId : confRow.dataName + '-' + dto.id
        return <div id={id}>
            {cell ? <FontAwesomeIcon className="active-element xpm-yes" icon="check" /> :
                <FontAwesomeIcon className="active-element xpm-no" icon="times" />}
        </div>

    }

    mapXpartCompletion(cell, confRow, dto, outerId) {
        let id = outerId ? outerId : confRow.dataName + '-' + dto.id
        let completed = false;
        if (dto.completionGroup) {
            completed = dto.completionGroup.completed;
        }
        return <div id={id}>
            {completed ? <FontAwesomeIcon className="active-element xpm-yes" icon="check" /> :
                <FontAwesomeIcon className="active-element xpm-no" icon="times" />}
        </div>
    }

    mapDate(timestamp) {
        this.lang = HelperFunction.getLocale();
        return HelperFunction.timeConverter(timestamp, this.lang);
    }

    mapDateNoTime(timestamp) {
        this.lang = HelperFunction.getLocale();
        return HelperFunction.timeConverter(timestamp, this.lang, true);
    }

    mapActive(bool) {
        return bool ? "Aktiv" : "Archiviert";
    }

    mapFloat(float) {
        return HelperFunction.formatFloats(float, 'de-DE');
    }

    mapUnit(unit) {
        return LabelFile.units[unit.name];
    }

    mapProjectNumberFromOrder(positions) {
        var positionList = "";


        positions.forEach(item => {
            positionList += item + ",";
        });

        if (positionList.length > 1) {
            positionList = positionList.substring(0, positionList.length - 1);
        }

        return positionList;
    }

    mapItemOrders(inputList) {
        var orderItemList = "";

        inputList.forEach(item => {
            if (orderItemList.length !== 0) {
                orderItemList += '; '
            }
            orderItemList += HelperFunction.getItemValueView(item, ['itemNumber', 'orderNumber']);
        });

        return orderItemList;
    }

    mapGroupCreateDateFromMetaRequest(metaData) {
        if (!metaData || !metaData.groupCreateDate) {
            return '';
        }
        return HelperFunction.timeConverter(metaData.groupCreateDate, 'de-DE');
    }
    mapDeliveryDateFromMetaRequest(metaData) {
        if (!metaData || !metaData.deliveryDate) {
            return '';
        }
        return HelperFunction.timeConverter(metaData.deliveryDate, 'de-DE', true);
    }
    mapDeliveryPlaceFromMetaRequest(metaData) {
        if (!metaData) {
            return '';
        }
        return metaData.targetStorageCode;
    }


    mapPspElement(inputList) {
        var pspList = "";

        inputList.forEach(item => {
            let psp = item.pspElement;
            if (psp) {

                if (pspList.length !== 0) {
                    pspList += '; '
                }

                pspList += psp;
            }
        });

        return pspList;
    }


    mapEvsProjectNumber(inputList) {
        var projectList = "";

        inputList.forEach(evsOrder => {
            if (projectList.length !== 0) {
                projectList += '; '
            }

            evsOrder.parentsNumbers.forEach(project => {
                projectList += project + ';';
            })
        });
        projectList = projectList.trim();
        if (projectList.substr(projectList.length - 1, projectList.length) === ';') {
            projectList = projectList.substr(0, projectList.length - 1);
        }
        return projectList;
    }

    mapEvsOrderNumber(inputList) {
        var projectList = "";

        inputList.forEach(evsOrder => {
            if (projectList.length !== 0) {
                projectList += '; '
            }
            projectList += evsOrder.number + ';';
        });
        projectList = projectList.trim();
        if (projectList.substr(projectList.length - 1, projectList.length) === ';') {
            projectList = projectList.substr(0, projectList.length - 1);
        }
        return projectList;
    }

    mapOrderItem(inputList) {
        var orderList = "";
        var linkToOrder = "";

        inputList.forEach(orderItem => {
            if (orderList.length !== 0) {
                orderList += '; '
            }
            if (orderItem['orderType']['name'] === 'ORDER') {
                linkToOrder = "/orderitem/list/" + orderItem['orderNumber'] + '/';
                orderList += HelperFunction.getItemValueView(orderItem, ['orderNumber', 'itemNumber'], ' / ');
            }
        });
        orderList = orderList.trim();
        if (orderList.substr(orderList.length - 1, orderList.length) === ';') {
            orderList = orderList.substr(0, orderList.length - 1);
        }

        return <Link to={linkToOrder}><span>{orderList}</span></Link>
    }

    mapProjectItem(inputList) {
        var projectList = "";

        inputList.forEach(orderItem => {
            if (projectList.length !== 0) {
                projectList += '; '
            }
            if (orderItem['orderType']['name'] === 'PROJECT') {
                projectList += HelperFunction.getItemValueView(orderItem, ['orderNumber', 'itemNumber'], ' / ');
            }
        });
        projectList = projectList.trim();
        if (projectList.substr(projectList.length - 1, projectList.length) === ';') {
            projectList = projectList.substr(0, projectList.length - 1);
        }
        return projectList;
    }

    mapStatus(status) {
        return LabelFile.statuses[status['statusDTO']['name']] + " [" + LabelFile.context[status['contextDTO']['name']] + "]";
    }

    mapStoragePlaceCode(storage) {
        return storage.storageCode;
    }

    mapPackageNumber(packageDTO) {
        if (packageDTO) {
            return packageDTO.packageNumber;
        }
        return "";
    }

    mapDestinationStorageCode(storage) {
        return storage.destinationStorageCode;
    }

    mapPrint(obj, rowConf, result) {
        return <FontAwesomeIcon className="active-element" icon="print" onClick={() => HelperFunction.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/x-part/' + obj)} />
    }

    mapLink(obj, rowConf, result) {
        let linkTarget = rowConf.linkTarget;
        linkTarget = linkTarget.replace("$VAR", obj);
        linkTarget = linkTarget.replace("$CONTEXT", HelperFunction.getContextOrNot(rowConf.context));

        return <Link to={linkTarget}><span>{obj}</span></Link>
    }

    mapComment(obj, rowConf, result) {
        return <FontAwesomeIcon onClick={() => this.commentClick()} className="active-element" icon="comment" />
    }

    mapLinkXPart(obj, rowConf, result) {
        let linkTarget = rowConf.linkTarget;
        linkTarget = linkTarget.replace("$VAR", obj);
        linkTarget = linkTarget.replace("$CONTEXT", HelperFunction.getContextOrNot(rowConf.context));

        //first check whether result is valid and not undefinded
        if (result) {
            if (!result['targetLocked']) {
                return <Link to={linkTarget}><span>{obj}</span><span className='xpm-small-indicator'>*</span></Link>
            }
        }
        return <Link to={linkTarget}><span>{obj}</span></Link>
    }

    mapCustomer(companies) {
        let result = "";
        companies.forEach(res => {
            if (res["type"]["name"] === "CUSTOMER") {
                result = res["name"];
            }
        })
        return result;
    }

    mapOrderStatus(cell, rowConf, result) {
        let OrderStatus = result['orderStatus'] === null ? '' : LabelFile.statuses[result['orderStatus']['name']];
        return OrderStatus;
    }

    mapSupplier(companies) {
        let result = "";
        companies.forEach(res => {
            if (res.type.name === "SUPPLIER") {
                result = res["name"];
            }
        })
        return result;
    }

    getCompnayNameByType(companies, type) {
        let result = "";
        companies.forEach(res => {
            if (res.companyTypeName === type) {
                result = res["companyName"];
            }
        })
        return result;

    }

    mapSupplierFromXpart(itemOrders) {
        let result = "";

        itemOrders.forEach(res => {
            let supp = this.getCompnayNameByType(res.orderCompanies, 'SUPPLIER');
            if (supp) {

                if (result.length !== 0) {
                    result += '; '
                }

                result += supp;
            }
        })
        return result;
    }

    mapActiveGroup(result) {
        return result.active ? 'active-element' : 'not-active-element';
    }

    mapToEdit = (result, functionConfig) => {
        let activeClass = functionConfig.activeClassMapping ? this[functionConfig.activeClassMapping](result) : 'active-element';
        return <FontAwesomeIcon id={'toEdit-icon-' + result[functionConfig.dataName]} onClick={(e, v) => this.toEditClick(result[functionConfig.dataName], functionConfig)} className={activeClass} icon="edit" />
    }

    mapToEye = (result, functionConfig) => {
        let obj=result.deliveryGroupMetaDataDTO ? result.deliveryGroupMetaDataDTO.deliveryDocumentFileId: '';
        return <FontAwesomeIcon onClick={() => HelperFunction.getPdfDocument(API_XPART.FILE_SERVICE_URL + '/file/' + obj)}  icon="file-download" />
    }
 

    toEditClick(id, functionConfig) {
        const icon = document.getElementById('toEdit-icon-' + id)
        if (icon.classList.contains('active-element')) {
            this.history.push(functionConfig.linkTarget.replace('$VAR', id))
        }
    }

    checkCompletionGroup = (dto) => {
        let notCompleted = true;

        if (dto.mode.name !== 'COMPLETION_CONTROL') {
            return false;
        }

        dto.completionList.forEach(e => {
            notCompleted &= e.completed
        })

        return !notCompleted;
    }

    // function: display a switchtoggle (true/false) button for the QA-Approved view
    // data for switchtoggle is fetched from backend
    mapSwitchToggle = (result, functionConfig) => {
        let disabled = false;
        if (functionConfig.disableCheckFunction) {
            disabled = this[functionConfig.disableCheckFunction](result);
        }

        return <SwitchToggleComponent id={'toggle-' + result.id} rowData={result} checked={functionConfig.innerDataName ? result[functionConfig.dataName][functionConfig.innerDataName] : result[functionConfig.dataName]} onColor="#EF476F"
            objectId={result.id} handleToggle={(e) => ExtraFunctions[functionConfig.onChangeFunction](e)} disabled={disabled}
            fireModal={this.fireModal} closeModal={this.closeModal} representFieldId={functionConfig.representFieldId}
            postChange={functionConfig.postChangeFunction ? (e, v) => ExtraFunctions[functionConfig.postChangeFunction](e, v) : null} />
    }

    commentClick() {
        this.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'UNDER_DEVELOPMENT'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'INFO', 'COMMON_INFO'),
            buttons: this.createOKModalButtons()
        });
    }

    createOKModalButtons() {
        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.closeModal
        };

        btns.push(obj);
        return btns;
    }

    getFunctionElement(functionConfig, cell, confRow, dto) {

        if (functionConfig.type === 'print') {
            return this.mapPrint(cell, confRow, dto)
        } else if (functionConfig.type === 'switchToggle') {
            return this.mapSwitchToggle(dto, functionConfig)
        } else if (functionConfig.type === 'comment') {
            return this.mapComment(cell, confRow, dto)
        } else if (functionConfig.type === 'edit') {
            return this.mapToEdit(dto, functionConfig)
        } else if (functionConfig.type === 'eye') {
            return this.mapToEye(dto, functionConfig)
        }

    }

    renderFunctions(cell, confRow, dto) {

        return confRow.functions.map(result => (
            <div className="column-1">
                {
                    this.getFunctionElement(result, cell, confRow, dto)
                }
            </div>
        ))
    }

    // function: collect icons/buttons in one column to display
    // mapPrint and mapSwitchToggle
    mapFunctionsColumn = (cell, confRow, result) => {
        return (
            <div className="mapfunctioncolumn">
                {this.renderFunctions(cell, confRow, result)}
            </div>)
    }

}
