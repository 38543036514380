import React, { Component } from 'react'
import { withRouter } from 'react-router'
import FormGenerate from '../Form/FormGenerate.js'

class XpartShowKME extends Component {
  constructor(props) {
    super(props)
    this.xpartNumber = this.props.match.params.xpartnumber
  }

  render() {
    return (
      <section>
        <FormGenerate formname="xpartCreateKME" header="XPART_EDIT_KME" prefill="true" DataSetIdName={this.xpartNumber} />
      </section>
    )
  }
}
export default withRouter(XpartShowKME)
