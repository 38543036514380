export class APINotifications {

    notifications = [
        {
            local: 'de',
            messages: [
                { name: 'SUCCESS', description: 'ERFOLG' },
                { name: 'UNDER_DEVELOPMENT', description: 'Diese Funktion befindet sich noch in der Entwicklung' },
                { name: 'SUCCESS_PASSWORD_CHANGED', description: 'Passwort erfolgreich geändert!' },
                { name: 'UPLOAD_SUCCESS', description: 'Erfolgreich importiert' },
                { name: 'STORAGE_SAVE', description: 'Sie haben {$x1} ausgewählt. Speichern?' },
                { name: 'WRONG_RECEIVE_XPART_STATUS', description: 'Losteil nicht im erwarteten Status "Versendet".\n Status: {$x1}.\nTrotzdem annehmen?' },
                { name: 'EXPORT_QUESTION', description: 'Sie möchten {$x1} Bestellpositionen exportieren' },
                { name: 'NO_EXPORT_ITEM_DATA', description: 'Im angegebenen Zeitraum wurden keine Bestellpositionen aktualisiert. Bitte anderen Zeitraum wählen' },
                { name: 'STATUS_CONTEXT_WRONG', description: 'Losteil nicht im erwarteten Status "Bestellt [KME]". \nTrotzdem annehmen? ' },
                { name: 'STATUS_CONTEXT_ASSEMLY_WRONG', description: 'Losteil nicht im erwarteten Status "Versandbereit [KME]". \nTrotzdem annehmen? ' },
                { name: 'WRONG_RECEIVE_XPART_STATUS_OR_CONTEXT_KME_ORDERD', description: 'Losteil nicht im erwarteten Status und Kontext "Beauftragt [KME]".\n Status: {$x1}.\n Kontext: {$x2}' },
                { name: 'WRONG_RECEIVE_XPART_STATUS_OR_CONTEXT_KME_READY_FOR_SHIPMENT', description: 'Losteil nicht im erwarteten Status und Kontext "Versandbereit [KME]".\n Status: {$x1}.\n Kontext: {$x2}' },
                { name: 'STATUS_CONTEXT_EVS_RECIEVE_ACCEPTED', description: 'Losteil nicht im erwarteten Status "Versandbereit [KME]". \nTrotzdem annehmen? ' },
                { name: 'XPART_COMPLETED_IN_GROUP', description: 'Losteil {$x1} wurde bereits erfolgreich gescannt.' },
                { name: 'WRONG_XPART_PROJECT_GROUP_CREATION', description: 'Losteil entspricht nicht den festgelegten Kritieren:\n {$x1}.\n {$x2}' },
                { name: 'UPDATING_XPARTS_MULTI', description: 'Aktualisierung von {$x1} Losteilen ...' },
                { name: 'UPDATING_XPARTS_ONE', description: 'Aktualisierung von {$x1} Losteil ...' },
                { name: 'XPART_NOT_IN_EXPECTED_STATUS_ORDERED_TRANSPORTATION', description: 'Losteil nicht in erwartetem Status Beauftragt [Umfuhr]. Fortfahren?' },
                { name: 'STORAGE_NOT_CHANGED', description: 'Möchten Sie fortfahren, ohne den Lagerplatz zu verändern?' },
                { name: 'XPART_NOT_IN_EXPECTED_STATUS_READY_FOR_SHIPMENT_TRANSPORTATION', description: 'Losteil nicht in erwartetem Status Versandbereit [Umfuhr].' },
                { name: 'XPART_NOT_PART_OF_REQUEST_GROUP', description: 'Lieferort kann nicht ermittelt werden. Trotzdem senden an {$x1}?' },
                { name: 'XPART_DELIVERY_DESTINATION_NOT_MATCH', description: 'Lieferort weicht ab: {$x1}' },
                { name: 'SCAN_XPART_TO_COMPLETE_GROUP_SAVE', description: 'Scannen Sie mindestens einen Losteil der Transfergruppe' },
                { name: 'SCAN_PHOTO_TO_COMPLETE_GROUP_SAVE', description: 'Machen Sie mindestens ein Foto der Umfuhrgruppe' },
                { name: 'XPART_ALREADY_BELONGS_TO_TRANSPORT_GROUP', description: 'Losteil gehört bereits zu einer Umfuhr-Gruppe' },
                { name: 'XPART_NOT_PART_OF_TRANSPORT_GROUP', description: 'Losteil gehört nicht zur Umfuhr-Gruppe.' },
                { name: 'RESET_GROUP_CREATION_DATA_WARNING', description: 'Alle Eingaben gehen verloren. Fortfahren?'},
                { name: 'DELETE_XPART_WARNING', description: 'Sind Sie sicher, dass Sie losteil: {$x1} löschen möchten?'}

            ]
        }
    ]


    errors = [
        {
            local: 'de',
            codes: [
                {
                    code: 400,
                    messages: [
                        { name: 'STORAGE_SEQUENCE_IS_EMPTY', description: 'LAGERFOLGE IST LEER' },
                        { name: 'STORAGE_SEQUENCE_NOT_VALID', description: 'LAGERPLATZ IST NICHT GÜLTIG' },
                        { name: 'XPART_NUMBER_NOT_VALID', description: 'Bitte geben Sie eine gültige Losteilnummer ein.\n Die Eingabe: {$x1}.' },
                        { name: 'NO_XPARTS_SELECTED', description: 'Bitte Losteil auswählen' },
                        { name: 'PACKAGE_EXISTS', description: 'Packstück existiert bereits' },
                        { name: 'PACKAGE_NUMBER_NOT_VALID', description: 'PACKSTÜCK NUMMER IST NICHT GÜLTIG' },
                        { name: 'XPART_NOT_FOUND', description: 'LOSTEIL NICHT GEFUNDEN!' },
                        { name: 'FILE_NOT_VALID', description: 'DATEI NICHT GÜLTIG' },
                        { name: 'FILE_CONTENT_NOT_VALID', description: 'DATEIINHALT NICHT GÜLTIG' },
                        { name: 'ORDER_OR_ITEM_NOT_FOUND', description: 'PROJEKT ODER POSITION NICHT GEFUNDEN!' },
                        { name: 'QUANTITY_NOT_VALID', description: 'ANZAHL NICHT GÜLTIG' },
                        { name: 'WEIGHT_NOT_VALID', description: 'GEWICHT NICHT GÜLTIG' },
                        { name: 'PSP_ELEMENT_NOT_FOUND', description: 'PSP ELEMENT NICHT GEFUNDEN!' },
                        { name: 'NO_UNIT_FOUND', description: 'UNIT NICHT GEFUNDEN!' },
                        { name: 'ORDER_TYPE_EMPTY', description: 'BESTELLTYP LEER' },
                        { name: 'WRONG_CONTEXT', description: 'Ein Losteil hat einen falschen Context. Bitte laden Sie die Seite neu' },
                        { name: 'WRONG_STATUS', description: 'Ein Losteil hat einen falschen Status. Bitte laden Sie die Seite neu' },
                        { name: 'ACTIVE_STATUS_EMPTY', description: 'AKTIVER STATUS LEER' },
                        { name: 'DESCRIPTION_DE_IS_EMPTY', description: 'BESCHREIBUNG DEUTSCH LEER' },
                        { name: 'DESCRIPTION_DE_IS_TOO_LONG', description: 'BESCHREIBUNG DEUTSCH ZU LANG' },
                        { name: 'DESCRIPTION_EN_IS_EMPTY', description: 'BESCHREIBUNG ENGLISCH LEER' },
                        { name: 'DESCRIPTION_EN_IS_TOO_LONG', description: 'BESCHREIBUNG ENGLISCH ZU LANG' },
                        { name: 'PASSWORD_VALIDATION_FAILED', description: 'Das neue Passwort ist ungültig' },
                        { name: 'BAD_OLD_PASSWORD', description: 'Falsches altes Passwort' },
                        { name: 'XPART_NOT_COMPLETED', description: 'LOSTEIL NICHT ABGESCHLOSSEN' }
                    ]
                },
                {
                    code: 404,
                    messages: [
                        { name: 'XPART_NOT_FOUND', description: 'LOSTEIL NICHT GEFUNDEN!' },
                        { name: 'PROJECT_NUMBER_NOT_FOUND', description: 'PROJEKT NUMMER NICHT GEFUNDEN!' }

                    ]
                }
            ]
        }
    ]


    headers = [
        {
            local: 'de',
            types: [
                {
                    type: 'INFO',
                    messages: [
                        { name: 'EXPORT_ITEMS_INFO', description: 'Export Bestellpositionen' },
                        { name: 'COMMON_INFO', description: 'info' },
                    ]
                },
                {
                    type: 'WARNING',
                    messages: [
                        { name: 'COMMON_WARNING', description: 'Warnung' },
                        { name: 'CONFIG_ERROR', description: 'Fehler in Konfiguration' }
                    ]
                },
                {
                    type: 'ERROR',
                    messages: [
                        { name: 'COMMON_ERROR', description: 'Fehler' },
                        { name: 'VALIDATION_ERROR', description: 'Validierungsfehler' },
                        { name: 'FILE_CONTENT_NOT_VALID', description: 'DATEIINHALT NICHT GÜLTIG' },
                        { name: 'FILE_NOT_VALID', description: 'DATEII NICHT GÜLTIG' },
                        { name: 'XPART_NUMBER_NOT_VALID', description: 'Ungültige Losteilnummer' },
                        { name: 'PROJECT_NUMBER_NOT_FOUND', description: 'Nicht Gefunden' }
                    ]
                }
            ]
        }
    ]

    buttons = [
        {
            local: 'de',
            names: [
                {
                    name: 'YES_BUTTON',
                    description: 'JA'
                },
                {
                    name: 'NO_BUTTON',
                    description: 'NEIN'
                }
            ]
        }
    ]

    getButton(lan, xName) {
        var description = '!';
        var lanCodes = this.buttons.find(x => x.local === lan);

        if (lanCodes != null) {
            var er = lanCodes.names.find(c => c.name === xName);

            if (er != null) {
                description = er.description;
            }
        }
        return description;
    }

    getNotification(lan, xName, var1, var2) {
        var description = '-';
        var lanCodes = this.notifications.find(x => x.local === lan);

        if (lanCodes != null) {

            var er = lanCodes.messages.find(c => c.name === xName);
            if (er != null) {
                description = er.description;
            }
        }

        if (var1) {
            description = description.replace("{$x1}", var1);
        } else {
            description = description.replace("{$x1}", "");
        }
        if (var2) {
            description = description.replace("{$x2}", var2);
        } else {
            description = description.replace("{$x2}", "");
        }

        return description;
    }

    getHeaderDescription(lan, xtype, message) {

        var description = '!';
        var lanCodes = this.headers.find(x => x.local === lan);

        if (lanCodes != null) {

            var codeMessages = lanCodes.types.find(c => c.type === xtype);

            if (codeMessages != null) {
                var er = codeMessages.messages.find(c => c.name === message);
                if (er != null) {
                    description = er.description;
                }
            }
        }

        return description;
    }


    getErrorDescription(lan, code, message, var1) {

        var description = 'ERROR!';
        var lanCodes = this.errors.find(x => x.local === lan);

        if (lanCodes != null) {
            var codeMessages = lanCodes.codes.find(c => c.code === code);

            if (codeMessages != null) {
                var er = codeMessages.messages.find(c => c.name === message);
                if (er != null) {
                    description = er.description;
                }

            }
        }

        if (var1) {
            description = description.replace("{$x1}", var1);

        }
        return description;
    }
};
