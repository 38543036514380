import React, { Component } from 'react'
import axios from 'axios';
import { LabelService } from '../Common/Translations/LabelService';
import { API } from '../Common/API/API';
import { Loader } from './../Common/Components/Loader/loader'
import { APINotifications } from '../Common/API/APINotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const API_IMPORT = new API();
const NOTIFICATIONS = new APINotifications();
const LabelFile = LabelService.getLabelsByLocal('de');

class ImportIKNList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenFile: null,
            chosenFileName: '',
            progresstotal: 0,
            progressloaded: 0,
            isLoading: false,
            showProgress: false,
            disableUpload: true
        }
    }

    //the chosenFile will probably not be the complete filepath, instead it will say fakepath.
    // THIS IS INTENDED BROWSER BEHAVIOUR. While uploading the browser will handle the correct path.

    updateFileInput = (e) => {
        if (e.target.files[0].size > 200000000) {
            this.throwNotValid();
            return;
        }

        let fileExtention = this.getFileExtention(e.target.value);

        if (fileExtention.toLowerCase() !== 'xls' && fileExtention.toLowerCase() !== 'xlsx') {
            this.throwNotValid();
            return;
        }

        this.setState({
            chosenFile: e.target.files[0],
            chosenFileName: e.target.value,
            disableUpload: false
        });
    }

    getFileExtention(fileName) {
        return (fileName.lastIndexOf('.') < 1) ? null : fileName.split('.').slice(-1) + '';
    };

    throwNotValid() {
        this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', 400, 'FILE_NOT_VALID'),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
            buttons: this.createOKModalButtons()
        });
    }

    handleImport() {

        this.setState({
            isLoading: true,
            showProgress: true
        });

        let formData = new FormData();
        formData.append('file', this.state.chosenFile);

        // This will update and show the progress of the UPLOAD
        var config = {
            onUploadProgress: progressEvent => {
                this.setState({
                    progresstotal: progressEvent.total,
                    progressloaded: progressEvent.loaded
                })
            },
            headers: { 'Content-Type': 'multipart/form-data;' }
        };

        //Change expected API URL HERE
        axios.post(API_IMPORT.XPART_SERVICE_URL + '/import/upload', formData, config)
            .then(e => {

                this.props.fireModal({
                    content: NOTIFICATIONS.getNotification('de', 'UPLOAD_SUCCESS'),
                    header: NOTIFICATIONS.getNotification('de', 'UPLOAD_SUCCESS'),
                    buttons: this.createOKModalButtons()
                });
            })
            .catch(err => {
                console.log(err);
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                    chosenFile: null,
                    chosenFileName: '',
                    disableUpload: true
                })
            });
    }

    createOKModalButtons() {

        var btns = [];
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal,
        };

        btns.push(obj);

        return btns;
    }


    render() {
        const total = this.state.progresstotal;
        const loaded = this.state.progressloaded;
        let loadedPercent = 100 - (loaded * 100 / total);
        return (
            <section className="results--section">
                <section className="results--section xpartform">
                    <h1>{LabelFile.labels['IKNList']}</h1>
                    <div className="header-underline-img"><span className="header-underline-export"><FontAwesomeIcon icon={['fas', 'download']} /></span></div>
                    <hr className="header-underline"></hr>

                    <div className="input-container" id="inputMain">
                        <input
                            style={{ width: "calc(100% - 28px" }}
                            type="file"
                            accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            className="file-upload"
                            value={this.state.chosenFileName}
                            onChange={this.updateFileInput}
                        />
                        <div className="xpm-button-container">
                            <button type="button"
                                disabled={this.state.disableUpload}
                                className="xpm-button xpm-button-import"
                                onClick={(event) => this.handleImport(event)}>{LabelFile.labels['upload'] + " & " + LabelFile.labels['import']}
                            </button>
                        </div>
                        {this.state.isLoading ? <Loader /> : ''}
                        {this.state.showProgress ? <div className="ProgressBar">
                            <div className="progress-total"><div className="progress-loaded" style={{ transform: "translateX(-" + loadedPercent + "%)" }}></div></div>
                            <div className="progressNumbers"><span>{loaded + " / " + total}</span></div>
                        </div> : ""}
                    </div>
                </section>
            </section>
        )
    }
}

export default ImportIKNList;