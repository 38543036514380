import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { API } from '../../Common/API/API';
import { FieldGenerator } from '../../Common/Helper/FieldGenerator'
import { LabelService } from '../../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const Fields = new FieldGenerator();

export default class ConvenienceInputFieldsComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      statusList: [],
      contextList: []
    }
    this.warnings = LabelFile.warnings;
  }

  // react function: mount 
  componentDidMount() {

    // function: get data from Backend
    axios.get(API_XPART.XPART_SERVICE_URL + '/status/')
      .then(response => {
        const statusList = response.data
        this.setState({ statusList: statusList })
      })

    // function: get data from Backend
    axios.get(API_XPART.XPART_SERVICE_URL + '/context/')
      .then(response => {
        const contextList = response.data
        this.setState({ contextList: contextList })
      });
  }


  getSelectedStatus() {
    return document.getElementById('status').value;
  }

  getSelectedContext() {
    return document.getElementById('context').value;
  }
  getStorageLocation() {
    return document.getElementById('storage').value;
  }

  translate(list, translateSource) {
    let translated = [];

    list.forEach(element => {
      translated.push({ id: element.id, name: translateSource[element.name]});
    });

    return translated;
  }

  render() {
    return (
      <Fragment>
        <div className="edit-container">
          <div className="infield field3">
            {Fields.generateInputSelect("status", this.translate(this.state.statusList, LabelFile.statuses), true, [this.warnings['required']], false, null)}
          </div>
          <div className="infield field4">
            {Fields.generateInputSelect("context", this.translate(this.state.contextList, LabelFile.context), true, [this.warnings['required']], false, null)}
          </div>
          <div className="infield field2">
            {Fields.generateInputText("storage", "", false, [], false)}
          </div>
          
        </div>
      </Fragment>
    )
  }
}
