import React, { Component } from 'react';
import { CameraService } from '../../../Camera/CameraService';
import { LabelService } from '../../Translations/LabelService';
import 'react-html5-camera-photo/build/css/index.css';
import CommonNewPhotoComponent from './CommonNewPhotoComponent';
import CommonGalleryComponent from './CommonGalleryComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LabelFile = LabelService.getLabelsByLocal('de');


export default class CommonPhotosComponent extends Component {

    state = {
        galleryIndex: 'common-photo'
    }

    setGalleryIndex(index, moduleName) {
        this.setState({
            galleryIndex: index,
            moduleName: moduleName,
            takePhoto: true
        })
    }

    componentDidMount() {
        if (this.props.setRef) {
            this.props.setRef(this)
        }
    }

    goBack() {
        if (this.props.goBack) {
            this.props.goBack();
        }
    }
    goToGallery() {
        this.setState({ takePhoto: false })
    }

    goToNewPhoto() {
        this.setState({ takePhoto: true })
    }
    afterPhoto = () => {
        this.goToGallery();
    }

    getGalleryPhotos = () => {
        let result = JSON.parse(localStorage.getItem(this.getLocalStoragePrefix()));
        if (result === null) {
            result = [];
        }
        return result;
    }

    getLocalStoragePrefix() {
        return this.state.moduleName + '-' + this.state.galleryIndex;
    }

    componentWillUnmount() {
        CameraService.killCamera();
    }


    render() {
        return (
            <section>
                {this.state.takePhoto ?
                    <CommonNewPhotoComponent galleryIndex={this.state.galleryIndex} moduleName={this.state.moduleName}
                        afterPhoto={this.afterPhoto}>
                            
                        </CommonNewPhotoComponent> :

                    <CommonGalleryComponent galleryIndex={this.state.galleryIndex} moduleName={this.state.moduleName}></CommonGalleryComponent>
                }

                <div className="optionalButtons xpm-button-container">
                    <div className="button-alignment">
                        <button onClick={() => { this.goBack() }}
                            className={"xpm-button "}>
                            <FontAwesomeIcon icon={['fas', 'arrow-left']} />&nbsp;{LabelFile.labels["back"]}
                        </button>

                        {this.state.takePhoto ?
                            <button onClick={() => { this.goToGallery() }}
                                className={"xpm-button button-delivery "}>
                                {LabelFile.labels["gallery"]}&nbsp;<FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </button> :
                            <button onClick={() => { this.goToNewPhoto() }}
                                className={"xpm-button button-delivery "}>
                                {LabelFile.labels["newPhoto"]}&nbsp;<FontAwesomeIcon icon={['fas', 'arrow-right']} />
                            </button>}

                    </div>
                </div>
            </section>
        )
    }

}
