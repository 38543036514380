
import React, { Component } from 'react';
import { Loader } from '../Loader/loader';

/**
 * The superclass for wizards.
 * it has the render function and state for wizards.
 * stepsList -> all the steps in the wizard.
 * currentStep -> current step, the wizard working with.
 * currentStepRef -> global variable (not in state) refers to to the current active component.
 * wizardControlComponent -> the button the wizard will display under the current active step.
 */

export class CommonWizardComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stepsList: [],
            currentStep: '',
            wizardControlComponent: null
        };

        this.currentStepRef = null;
    }
    /**
     * sets the referense of the current component.
     */
    setCurrentStepRef = element => {
        this.currentStepRef = element;
    }

    initComponent(stepsList, currentStep) {

        this.setState({
            stepsList: stepsList,
            currentStep: currentStep,
            isLoading: false
        });
    }

    refresh = () => {
        this.props.history.replace('/refresh' + window.location.pathname);
    }

    /**
     * displays the step we need. just pass the stepName.
     * It also can receive a function that can be executed after the state was changed (afterStateSetFunction)
     */
    goToStep = (stepName, afterStateSetFunction) => {
        const nextStep = this.state.stepsList.filter(step => step.stepName === stepName);

        this.setState({
            currentStep: nextStep[0]
        }, () => {
            if (afterStateSetFunction) {
                afterStateSetFunction();
            }
        })
    }

    createOKModalButtons() {
        var btns = []
        var obj = {
            customClass: 'button',
            text: 'OK',
            callback: this.props.closeModal
        }

        btns.push(obj)

        return btns
    }

    toggleLoading(isLoading) {
        if (this.state.isLoading !== isLoading) {
            this.setState({
                isLoading: isLoading
            })
        }
    }


    render() {
        if (!this.state.currentStep) {
            return '';
        }

        return (
            <div>
                {this.state.currentStep.stepComponent}
                {this.state.wizardControlComponent}
                {this.state.isLoading ? <Loader type="lock" /> : ''}
            </div>
        )
    }

}
