
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';
import { API } from '../Common/API/API';
import { LabelService } from '../Common/Translations/LabelService';
import TableConfig from './tables-config.json';
import { APINotifications } from '../Common/API/APINotifications';
import { DataParsingService } from './DataParsingService';
import { Loader } from './../Common/Components/Loader/loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HelperFunctions } from '../Common/Helper/Helper';
import '../Xpart/Xpart.css'
import './list.css'

const API_XPART = new API();
const LabelFile = LabelService.getLabelsByLocal('de');

const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();

export class TableComponent extends Component {
    constructor(props) {
        super(props);
        this.validator = "";
        this.parsing = new DataParsingService(this.props.fireModal, this.props.closeModal, this.props.history, this.props.refresh);

    }
    config = TableConfig[this.props.module];
    LabelFile = LabelService.getLabelsByLocal('de');

    state = {
        table: {
            resultlist: []
        },
        isLoading: true
    }
    componentDidMount() {
        if (localStorage.getItem("xpm-filter-list-" + this.props.module) !== null) {
            let list = JSON.parse(localStorage.getItem("xpm-filter-list-" + this.props.module));
            if (list.length === 0) {
                this.fetchData();
            }
        } else {
            this.fetchData();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.config = TableConfig[nextProps.module];
        if (this.props.module !== nextProps.module) {
            let table = { resultlist: [] };
            this.setState({ table: table });
        }
        this.props = nextProps;
        this.fetchData();
    }

    updateLoading(loading) {
        this.setState({
            isLoading: loading
        })
    }

    getPaginationQuery() {
        return '?size=' + this.props.size + '&page=' + this.props.page;
    }

    getFixedFiltersQuery() {
        return this.config.fixedFilterQuery ? '&' + this.config.fixedFilterQuery : '';
    }

    getSortingQuery() {
        return this.config.orderByQuery ? '&' + this.config.orderByQuery : '';
    }

    getRequestParameters() {
        return this.getPaginationQuery() + this.getFixedFiltersQuery() + this.getSortingQuery();
    }

    getRequestExportParameters() {
        return this.getFixedFiltersQuery() + this.getSortingQuery();
    }

    getFilterData() {
        let basicFilters =  JSON.parse(JSON.stringify(this.props.generalFilter));
        if (basicFilters.length <= 0) {
            basicFilters = {
                searchGroups: []
            }
        }

        if (this.config.preFilters) {
            basicFilters.searchGroups = basicFilters["searchGroups"].concat(this.config.preFilters);
        }
        let selectedIds = this.getSelectedIds();

        basicFilters["selectedIds"] = selectedIds;
        basicFilters["idType"] = this.config.rowIdField;

        return basicFilters;
    }

    xpartGroupMapper() {
        if (!this.props.groupId) {
            return null;
        }
        return API_XPART.XPART_SERVICE_URL + this.config.dateSource + this.props.groupId;
    }

    getSourceUrl() {
        if (this.config.dataSourceMapper) {
            return this[this.config.dataSourceMapper]();
        } else {
            return API_XPART.XPART_SERVICE_URL + this.config.dateSource + this.getPaginationQuery() + this.getFixedFiltersQuery() + this.getSortingQuery()
        }
    }

    toggleLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    fetchData() {
        if(this.config.noFetchData){
            let newState = this.state;
            newState.table.resultlist = this.props.dataSet['content'];
            newState.isLoading=false;
            this.setState(newState);
            return;
        }

        this.setState({
            isLoading: true
        })
        this.closePopup();
        if (this.props.usesFilter) {
            this.validator = btoa(Math.random() + 1).slice(0, 20);

            axios.post(API_XPART.XPART_SERVICE_URL + this.config.dateSource + this.getPaginationQuery() + this.getFixedFiltersQuery() + this.getSortingQuery(), this.getFilterData(), { "headers": { "request-hash": this.validator } })
                .then(response => {

                    if (response.headers["request-hash"] === this.validator) {
                        this.props.pgFeedback(response["data"]["first"], response["data"]["last"], response["data"]["totalPages"]);

                        let newState = this.state;
                        newState.table.resultlist = response['data']['content'];
                        this.setState(newState);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        } else {
            const sourceUrl = this.getSourceUrl();
            if (!sourceUrl) {
                return;
            }
            axios.get(sourceUrl)
                .then(response => {
                    if (this.props.pgFeedback) {
                        this.props.pgFeedback(response["data"]["first"], response["data"]["last"], response["data"]["totalPages"]);
                    }
                    let newState = this.state;

                    newState.table.resultlist = !this.config.dataSourceMapper ? response['data']['content'] : response['data'];
                    this.setState(newState);
                    if (this.props.afterFetch) {
                        this.props.afterFetch(newState.table.resultlist);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        }
    }

    renderHeaders() {
        return this.config.tableHeaders.map(e => {
            return <div key={e.name} className={e.class}>
                <span>{this.LabelFile.labels[e.name]}</span>
            </div>
        });
    }

    mapCell(cell, confRow, result) {

        if (confRow.type === 'functions') {
            return this.parsing.mapFunctionsColumn(cell, confRow, result);
        }

        if (confRow.type === 'read-fixed') {
            return confRow.val;
        }

        if (cell === null) {
            return null;
        }
        if (!confRow.mappingFunction) {
            return cell;
        }
        confRow.context = this.props.context;

        return this.parsing.mapFunctions[confRow.mappingFunction](cell, confRow, result);
    }

    getSelectedIds(){
        if (this.state.multiSelect &&
            this.state.multiSelect.resultlistselected) {

            return this.state.multiSelect.resultlistselected;
        }
        return []; 
    }

    getSelectClass(id) {
        if (this.state.multiSelect &&
            this.state.multiSelect.resultlistselected &&
            this.state.multiSelect.resultlistselected.includes(id.toString())) {

            return "multiselect-selected";
        }
        return "";
    }

    getToolTip(confRow, ToolTip) {
        if (confRow.toolTip) {
            return ToolTip;
        }
    }

    getUserByName(username) {
        return username ? username : "SYSTEM"
    }

    closePopup() {
        let jsx = (<div className="xpm-hidden"></div>)
        ReactDOM.render(jsx, document.getElementById("popupContainer"));
    }

    generatePopUp(data, mouse, type) {
        if (type === "statushistory") {
            let jsx = (
                <div className="xpm-popup" style={{ left: mouse[0], top: mouse[1] }}>
                    <div className="xpm-popup-close" onClick={() => this.closePopup()}><FontAwesomeIcon icon={['far', 'times-circle']} /></div>
                    {data.map(historyEntry => {
                        return (<div className="historyRow">
                            <span>{historyEntry.status}</span>
                            <span>{historyEntry.timestamp}</span>
                            <span>{historyEntry.user}</span>
                        </div>
                        )
                    })}
                </div>
            );
            ReactDOM.render(jsx, document.getElementById("popupContainer"));
        }
    }

    calcXY(e) {
        let mouseX = e.pageX;
        let mouseY = e.pageY;

        //lower the position by a little bit
        mouseY += 5;

        //prevent getting out of bounds (right)
        if (window.innerWidth < mouseX + 650) {
            mouseX = window.innerWidth - 730;
        }

        //prevent getting out of bounds (left)
        if (mouseX < 0) {
            mouseX = 0;
        }

        return [mouseX, mouseY]
    }

    statusPopupHandler(xpart, e) {
        let lang = HelperFunction.getLocale();
        let popuparray = [];
        axios.get(API_XPART.XPART_SERVICE_URL + '/status/history?xpartNumber=' + xpart)
            .then(response => {
                response.data.forEach(status => {
                    popuparray.push({
                        "status": LabelFile.statuses[status.statusDTO.name] + " [" + LabelFile.context[status.contextDTO.name] + "]",
                        "timestamp": HelperFunction.timeConverter(status.assignDate, lang, false),
                        "user": this.getUserByName(status.currentUserName)
                    })
                    let mouseXY = this.calcXY(e);
                    this.generatePopUp(popuparray, mouseXY, "statushistory")
                })
            })
            .catch(err => {
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
    }

    renderData() {
        return this.state.table.resultlist.map(result => {
            let rowId = this.config.rowIdField ? this.config.rowIdField : 'id';
            return <div className={this.config.dataRowClassName + " " + this.getSelectClass(result[rowId])} data-attr={result[rowId]} data-id={result[rowId]} >
                {this.config.tableData.map(confRow => {
                    let clickStatus = false;
                    if (confRow.usespopup === true) {
                        clickStatus = true;
                    }
                    return <div className={confRow.class} data-name={LabelFile.labels[confRow.dataName]}>
                        {clickStatus ? <span onClick={(e) => {
                            let nativeEvent = e.nativeEvent;
                            this.statusPopupHandler(result[confRow.popupmapping], nativeEvent)
                        }
                        }
                            title={this.getToolTip(confRow, this.mapCell(result[confRow.name] ? result[confRow.name] : null, confRow, result))}>
                            {this.mapCell(result[confRow.name] ? result[confRow.name] : null, confRow, result)}
                        </span>
                            :
                            <span id={!confRow.id ? null : confRow.id + '-' + result[rowId]} title={this.getToolTip(confRow, this.mapCell(result[confRow.name] !== null ? result[confRow.name] : null, confRow, result))}>
                                {this.mapCell(result[confRow.name] !== null ? result[confRow.name] : null, confRow, result)}
                            </span>}

                    </div>
                })
                }
            </div>
        })
    }

    renderTable() {
        return <div className="list-container">
            <div className={this.config.headersRowClassName}>
                {this.renderHeaders()}
            </div>
            {this.state.isLoading ? <Loader /> : ''}
            {this.renderData()}
        </div>;
    }

    render() {
        return (
            this.renderTable()
        );
    }

}
