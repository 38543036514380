

import React, { Component } from 'react';
import { LabelService } from '../Common/Translations/LabelService';
import TableConfig from './tables-config.json';
import '../Xpart/Xpart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LabelFile = LabelService.getLabelsByLocal('de');

export class PaginationComponent extends Component {

    config = TableConfig[this.props.module];

    state = {
        first: true,
        last: false,
        lastPage: 9999
    }

    handleChangeParts(value) {
        this.props.updateTable(1, value);
    }

    handleChangePage(page) {
        this.props.updateTable(page, this.props.parts);
    }

    updatePages = (first, last, totalPages) => {
        this.setState(
            {
                first: first,
                last: last,
                lastPage: totalPages
            }
        )
    }

    isOptionSelected(val, currentParts) {
        return Number.parseInt(val) === Number.parseInt(currentParts);
    }


    renderOptions() {
        return this.config.paginationOptions.map(e => {
            return <option selected={this.isOptionSelected(e, this.props.parts)} value={e}>{e}</option>
        }
        );
    }

    render() {

        let currentPage = Number.parseInt(this.props.page);
        let prevPage = currentPage - 1;
        let nextPage = currentPage + 1;

        let disablePrev = "";
        if (this.state.first) {
            disablePrev = "disabled-link";
        }

        let disableNext = "";
        if (this.state.last) {
            disableNext = "disabled-link";
        }

        return (
            <div className="paginationControls">

                <div key="990" onClick={() => { this.handleChangePage(1) }} className={disablePrev} id="paginationFirst" ><FontAwesomeIcon icon={['fas', 'angle-double-left']} /></div>
                <div key="991" onClick={() => { this.handleChangePage(prevPage) }} className={disablePrev} id="paginationPrev"><FontAwesomeIcon icon={['fas', 'angle-left']} /></div>
                <div key="992" onClick={() => { return false; }} className="disabled-link" id="paginationCurrent"><span>{currentPage}</span></div>
                <div key="993" onClick={() => { this.handleChangePage(nextPage) }} className={disableNext} id="paginationNext"><FontAwesomeIcon icon={['fas', 'angle-right']} /></div>
                <div key="994" onClick={() => { this.handleChangePage(this.state.lastPage) }} className={disableNext} id="paginationLast"><FontAwesomeIcon icon={['fas', 'angle-double-right']} /></div>

                <span>{LabelFile.labels[this.config.paginationText]}: </span>
                <select onChange={(event) => this.handleChangeParts(event.target.value)} id="pagination-amount">
                    {this.renderOptions()}
                </select>
            </div>
        )
    }

}
