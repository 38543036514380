
import React from 'react';
import WizardStep from '../../Common/Components/Wizrd/WizardStep';
import { CommonWizardComponent } from '../../Common/Components/Wizrd/CommonWizardComponent';
import TransportAreaButtonsComponent from './TransportAreaButtonsComponent';
import Scan from '../../Scan/XpartScan/Scan';
import { LabelService } from '../../Common/Translations/LabelService';
import CommonPhotosComponent from '../../Common/Components/Photo/CommonPhotosComponent';
import axios from 'axios';
import { API } from '../../Common/API/API';
import { APINotifications } from '../../Common/API/APINotifications';
import FormGenerate from '../../Form/FormGenerate.js'
import { CameraService } from '../../Camera/CameraService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

/**
 * The implementation of CommonWizardComponent for TransportArea.
 * it has local variables moduleName for photos,currentXpart and current group(will be added)
 * 
 * CurrentStepRef will be changed each time we change the active component (active step).
 * if we need to have the referece of the current child component, the child component shoul execute the method setRef
 *  when the child component mounts we pass this function to a child in props setRef={el => this.setCurrentStepRef(el)}
 * 
 */
export default class TransportAreaReceiveWizard extends CommonWizardComponent {

    currentXpart = null;
    moduleName = 'xpart-gallery-trans-area';
    storageLocation = ['EVS2', 'FL3', '49'];
    projectDetails = null;

    componentDidMount() {
        //init all steps
        let steps = [];
        steps.push(this.getScanStep());
        steps.push(this.getFormGeneratorStep());
        steps.push(this.getPhotoStep());
        this.initComponent(steps, steps[0]);
        this.currentXpart = null;
    }

    // after scan action is done
    postScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.getXpartData(scanValue);
    }

    updateGalleryIndex = () => {
        this.currentStepRef.setGalleryIndex(this.currentXpart.xpartNumber, this.moduleName);
    }

    goToPhotos(e) {
        const xpartProperies = this.currentStepRef.passDataToParent();
        if (!xpartProperies) {
            return;
        }
        this.currentXpart.weight = xpartProperies.weight
        this.goToStep('photo-step', this.updateGalleryIndex);
        this.setState({ wizardControlComponent: null })
    }
    goToForm() {
        CameraService.killCamera();
        this.goToStep('form-step', this.fillForm);
        const photos = CameraService.getFotosFromLocalStorage(this.getPhotoLocalStoragePrefix());
        const receiveCondition = photos.length > 0;
        let xpartsCount = !this.currentGroup ? 0 : this.currentGroup.xpartsNumbers.length;
        xpartsCount++;

        const controlButtonsComponent = <TransportAreaButtonsComponent photosCount={photos.length}
            photo={true} goToPhotos={(e) => this.goToPhotos(e)} receive={receiveCondition} xpartsCount={xpartsCount}
            addXpart={(e) => this.addXpart(e)} closeGroup={(e) => this.closeGroup(e)}
            cancelGroup={(e) => this.cancelGroup(e)} cancelScan={(e) => this.cancelScan(e)} />;

        this.setState({
            wizardControlComponent: controlButtonsComponent
        })
    }

    fillForm = () => {
        this.currentStepRef.fillForm(this.currentXpart)
    }

    getPhotoLocalStoragePrefix() {
        return this.moduleName + '-' + this.currentXpart.xpartNumber;
    }

    getXpartData(xpartNumber) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + xpartNumber)
            .then(res => {
                this.setProjectDetails(res['data']);
                if (!this.validateXpart(res['data'])) {
                    this.currentStepRef.reset();
                    return;
                }
                this.currentXpart = res['data'];
                this.goToForm();

            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }

    getScanStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['storagexpartNr'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-step', scanComponent);

    }

    getFormGeneratorStep() {
        const formComponent =
            <FormGenerate
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                formname="receiveXpartTransportAreaWithGroup"
                header="ASSEMBLY"
                prefill="false"
                pdfPrint="false"
                receiveVariables={null}
                setRef={el => this.setCurrentStepRef(el)}
            />

        return new WizardStep('form-step', formComponent);
    }

    getPhotoStep() {
        const photoComponent =
            <CommonPhotosComponent
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                goBack={() => this.goToForm()}
                setRef={el => this.setCurrentStepRef(el)}
            />


        return new WizardStep('photo-step', photoComponent);
    }

    getXpartInfo = () => {
        const xpartProperies = this.currentStepRef.passDataToParent();
        const photos = CameraService.getPhotosToSend(this.getPhotoLocalStoragePrefix());
        if (!xpartProperies || photos.length < 1) {
            return null;
        }


        xpartProperies.id = this.currentXpart.id;
        xpartProperies.active = true;
        //kme
        xpartProperies.contextId = 9;
        // ready for shipment
        xpartProperies.statusID = 2;

        let data = {
            files: photos,
            storageSequence: this.storageLocation,
            xpartProperties: xpartProperies
        }

        return data;
    }

    addCurrentXpartToGroup = (request) => {

        if (!request.groupInfo) {
            return;
        }
        request.groupInfo.xpartsNumbers.push(this.currentXpart.xpartNumber);
    }

    addGroupInfoToRequest(request, groupInfo) {

        if (groupInfo) {
            request['groupInfo'] = {};
            request['groupInfo'] = JSON.parse(JSON.stringify(groupInfo));
        }
        return request;
    }

    addXpart = (e) => {
        let xpartInfo = this.getXpartInfo();

        let groupInfo = this.getOrCreateGroupInfo();
        let request = this.addGroupInfoToRequest(xpartInfo, groupInfo);

        this.addCurrentXpartToGroup(request);

        this.save(request, false);
    }

    createCompletionGroup() {
        let groupInfoDTO = {
            active: true,
            modeTypeId: 2,
            typeNameId: 3,
            validationCriteria: ['EQUAL_ITEM_PROJECT_NUMBER', 'EQUAL_PROJECT_NUMBER']
        }
        return {
            groupInfoDTO: groupInfoDTO,
            xpartsNumbers: []
        }
    }

    getOrCreateGroupInfo() {
        if (!this.currentGroup) {
            this.currentGroup = this.createCompletionGroup();
        }
        return this.currentGroup;
    }

    closeGroup = (e) => {
        let xpartInfo = this.getXpartInfo();
        let request = this.addGroupInfoToRequest(xpartInfo, this.currentGroup);

        this.addCurrentXpartToGroup(request);

        this.save(request, true);
    }

    goToScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.goToStep('scan-step');
    }

    extractProjectNumberAndItemProjectNumber = (xpart) => {

        let projectItems = xpart.items.filter(i => i.type.name === 'PROJECT');
        if (!projectItems || projectItems.length < 1) {
            return null;
        }
        let projectItemNumber = projectItems[0].number;
        let projects = projectItems[0].orders.filter(o => o.type.name === 'PROJECT');
        if (!projects || projects.length < 1) {
            return null;
        }
        let projectId = projects[0].id;

        return {
            projectItemNumber: projectItemNumber,
            projectId: projectId
        }

    }

    // Sets Project no and position in local variable for frontend validation
    setProjectDetails(data) {
        if (this.projectDetails) {
            return;
        }
        this.projectDetails = this.extractProjectNumberAndItemProjectNumber(data);
    }

    clearCahe(clearGroup) {
        localStorage.removeItem(this.getPhotoLocalStoragePrefix());
        this.currentXpart = null;
        if (clearGroup) {
            this.projectDetails = null;
            this.currentGroup = null;
        }
    }

    addValidationDTO = (request) => {
        let validationDTO = {
            expectedStatusIds: [1],
            expectedContextsIds: [9]
        }
        request['validationDTO'] = validationDTO;
    }

    updateGroupInfo(response) {
        if (!this.currentGroup) {
            return;
        }
        let activeCompletions = response.activeGroups.filter(e => e.mode.name === 'COMPLETION_CONTROL');
        if (activeCompletions.length < 1) {
            return;
        }
        let group = activeCompletions[0];

        this.currentGroup.groupInfoDTO.id = group.id;
        this.currentGroup.xpartsNumbers = group.xpartNumbers;
    }

    save = (data, clearGroup) => {

        this.addValidationDTO(data);

        console.log(this.currentGroup)

        this.toggleLoading(true);
        axios.post(API_XPART.XPART_SERVICE_URL + '/receive', data).then(
            response => {
                this.clearCahe(clearGroup);
                this.updateGroupInfo(response['data']);
                this.goToScan();

            }
        ).catch(err => {
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    validateProjectDetails(xpart) {

        if (!this.projectDetails) {
            return true;
        }
        const xpartProjectDetails = this.extractProjectNumberAndItemProjectNumber(xpart);

        let notValidCriterias = [];

        if (xpartProjectDetails.projectItemNumber !== this.projectDetails.projectItemNumber) {
            notValidCriterias.push('PROJECT_ITEM_NUMBERS_NOT_EQUAL');
        }
        if (xpartProjectDetails.projectId !== this.projectDetails.projectId) {
            notValidCriterias.push('PROJECT_NUMBER_NOT_EQUAL');
        }

        if (notValidCriterias.length < 1) {
            return true;
        }

        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'WRONG_XPART_PROJECT_GROUP_CREATION',
             !notValidCriterias[0]?null:LabelFile.texts[notValidCriterias[0]], !notValidCriterias[1]?null:LabelFile.texts[notValidCriterias[1]]),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
        });

        return false;

    }

    validateXpart = (xpart) => {

        if (xpart.currentStatus.statusDTO.name !== 'ORDERED' || xpart.currentStatus.contextDTO.name !== 'KME') {

            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'WRONG_RECEIVE_XPART_STATUS_OR_CONTEXT_KME_ORDERD',
                    LabelFile.statuses[xpart.currentStatus.statusDTO.name], LabelFile.context[xpart.currentStatus.contextDTO.name]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }

        return this.validateProjectDetails(xpart);
    }

    cancelGroup = (e) => {
        this.clearCahe(true);
        this.goToScan();
    }

    cancelScan = (e) => {
        this.clearCahe(false);
        this.goToScan();
    }
}
