import React, { Component } from 'react'

import { FieldGenerator } from '../../Common/Helper/FieldGenerator'
import { LabelService } from '../../Common/Translations/LabelService';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import { zonedTimeToUtc } from 'date-fns-tz';

const LabelFile = LabelService.getLabelsByLocal('de');
const Fields = new FieldGenerator();
registerLocale('de', de);

export class DeliveryDialog extends Component {


    constructor(props) {
        super(props);
        this.warnings = LabelFile.warnings;
        this.Locations = [
            {
                "id": 1,
                "name": "KME-Wackerhagen (ES)"
            },
            {
                "id": 2,
                "name": "KME-Zinselbach (ZE)"
            },
            {
                "id": 3,
                "name": "KME-Treptow (GT)"
            },
            {
                "id": 4,
                "name": "KME-Elektrowerkstatt"
            },
            {
                "id": 5,
                "name": "KME-Kopfbau"
            },
            {
                "id": 6,
                "name": "KME-Versand"
            },
            {
                "id": 7,
                "name": "KME-Toedter (KDZE)"
            }
        ];

        this.state = this.initState();
        this.timeZone = 'Europe/Berlin';
        
    }

    

    getMaxDate() {
        return new Date(new Date().getFullYear() + 1, 11, 31);
    }

    initState() {

        if (!this.props.nextAvailabelDate) {
            return;
        }

        let startDate = this.props.nextAvailabelDate;
        let selectedPlaceId = null;

        if (localStorage.getItem(this.props.storagePrefixForDeliveryPlace)) {
            const dataFromLocalStorage = JSON.parse(localStorage.getItem(this.props.storagePrefixForDeliveryPlace));
            selectedPlaceId = dataFromLocalStorage.deliveryLocation.id;

            if (new Date(dataFromLocalStorage.deliveryDate).getTime() >= startDate) {
                startDate = (dataFromLocalStorage.deliveryDate);
            }

        }

        return {
            startDate: zonedTimeToUtc(startDate, this.timeZone),
            selectedPlaceId: selectedPlaceId,
            holidayList: this.props.holidayList,
            minDate: zonedTimeToUtc(this.props.nextAvailabelDate, this.timeZone)
        };

    }

    isWeekday(date) {
        let day = new Date(date).getDay();
        return day !== 0 && day !== 6;
    }

    isHoliday(date) {
        const dateTime = date.getTime();
        return this.state.holidayList.includes(dateTime);
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }
    getDialogValues() {
        let deliveryItem = {};
        deliveryItem['deliveryLocation'] = this.Locations[document.getElementById('deliveryLocation').value - 1];
        deliveryItem['deliveryDate'] = this.state.startDate;
        deliveryItem['startMultiSelect'] = true;
        localStorage.setItem(this.props.storagePrefixForDeliveryPlace, JSON.stringify(deliveryItem))
        return deliveryItem;
    }

    filterDatePickerDates = (date) => {
        return this.isWeekday(date) && !this.isHoliday(date)
    }

    render() {
        return (
            <div className="input-container" id="inputMain">
                <div>
                    <label>{LabelFile.labels["deliverydate"]}</label>
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={date => this.handleDateChange(date)}
                        locale="de"
                        minDate={this.state.minDate}
                        maxDate={this.getMaxDate()}
                        dateFormat="dd.MM.yyyy"
                        filterDate={date => this.filterDatePickerDates(date)}
                        showDisabledMonthNavigation
                    />
                </div>

                {Fields.generateInputSelect('deliveryLocation', this.Locations, true, [this.warnings['required']], false, this.state.selectedPlaceId)}

                <div className="xpm-button-container">
                    <button type="button" id="write-btn"
                        className="xpm-button"
                        onClick={() => this.props.deliveryResponse(this.getDialogValues())}
                    >{LabelFile.labels["forward"]}</button>

                    <button type="button"
                        className="xpm-button"
                        onClick={() => this.props.closeModal()}
                    >{LabelFile.labels["cancel"]}</button>
                </div>
            </div>
        )
    }
}
