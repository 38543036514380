import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { API } from '../Common/API/API';
import QrReader from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import { HelperFunctions } from '../Common/Helper/Helper';
import { CameraSwitcherComponent } from '../Camera/camera-switcher-component';
import { Constants } from '../Common/Helper/Constants';
import './Storage.css';

const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');

class ChangeStorage extends Component {
  constructor(props) {
    super(props);
    this.xpart = this.props.match.params.xpart;
    this.cameraSwitcherComponentRef = React.createRef();
    this.lastInputValue = '';
  }

  state = {
    result: [],
    scan: ""
  }

  onQrLoad() {
    if (this.cameraSwitcherComponentRef.current) {
      this.cameraSwitcherComponentRef.current.initSelectedCamera();
    }
    this.focusOnInputField();
  }

  createOKModalButtons() {

    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);

    return btns;
  }

  getXpartInformation() {
    axios.get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + this.props.match.params.xpart)
      .then(response => { //Global Status needed - or make this subcomponent of xpart...? - LB
        this.setState({
          result: response.data
        })
      })
      .catch(err => {
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      })
  }
  startValidation() {
    if (document.getElementById("StorageScan").value.length > 2) {
      this.setState({
        scan: document.getElementById("StorageScan").value
      })

      let storage_arr = document.getElementById("StorageScan").value.split("-");
      let data = {
        storageSequence: storage_arr,
        xpartId: this.state.result.id
      };
      this.switchSuccess(data);

    }
  }

  switchSuccess(data) {

    if (this.props.match.params.mode === "savestorage") {
      axios.patch(API_XPART.XPART_SERVICE_URL + '/one/storage/', data)
        .then(res => {
          this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
            header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
            buttons: this.createOKModalButtons()
          });
          if (this.props.match.params.mode === "assembly") {
            this.props.history.push('/assembly');
          }
          else {
            this.props.history.push('/storage/xscan' + HelperFunction.getContextOrNot(this.props.match.params.context));
          }
        })
        .catch(err => {
          this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]) + '\n' + document.getElementById("StorageScan").value,
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
          });
        })
    }
    if (this.props.match.params.mode === "reception") {
      this.props.history.push('/reception/true/receptionxpartstorage/' +
        this.props.match.params.xpart + '/setstorage/' + document.getElementById("StorageScan").value + HelperFunction.getContextOrNot(this.props.match.params.context))
    }
    else {
      this.props.history.push('/formfieldassembly/' + this.props.match.params.xpart + '/receiveAssembly/' + document.getElementById("StorageScan").value);
    }
  }

  componentWillUnmount() {
    if (this.cameraSwitcherComponentRef.current) {
      this.cameraSwitcherComponentRef.current.stopVideo();
    }
  }

  handleScan = data => {
    if (data) {
      if (data.length > 2) {
        this.setState({
          scan: data
        })
        document.getElementById("StorageScan").value = this.state.scan;
        this.startValidation();
      }
    }
  }

  componentDidMount() {
    this.getXpartInformation();
  }

  // simulates ENTER-taste
  keyPress(e) {
    e.stopPropagation();
    if (e.keyCode === 13) {
      this.startValidation()
    }
    e.stopPropagation();
  }

  focusOnInputField = () => {
    if (document.getElementById('StorageScan')) {
      document.getElementById("StorageScan").focus();
    }
  }

  onCameraSelected = () => {
    this.focusOnInputField();
  }
  handleInput = inputData => {
    if (inputData) {
      let trimmed = inputData.target.value.trim();
      if (trimmed.length > this.lastInputValue.length + 5) {
        this.setState({
          scan: trimmed
        })
        this.startValidation();
      }
      this.lastInputValue = trimmed;
    }
  }

  render() {
    return (
      <section className="results--section">
        <div className="input-container">
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            onLoad={() => this.onQrLoad()}
          />
          <div className="xpm-scanner-input-container" onKeyDown={this.keyPress.bind(this)}>
            <label className="xpm-scanner-label">{LabelFile.labels.change_storage}</label>
            <input onInput={(e) => this.handleInput(e)} className="xpm-scanner-input" type="text" id="StorageScan" ></input>
            <button type="button" id="val-btn"
              className="xpm-button xpm-scanner-button"
              onClick={this.startValidation.bind(this)}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} /></button>
          </div>

          <CameraSwitcherComponent preselectedCameraPrefix={Constants.SELECTED_CAMERA_SCAN_PREFIX}
            ref={this.cameraSwitcherComponentRef}
            onCameraSelected={() => this.onCameraSelected()}></CameraSwitcherComponent>
        </div>
      </section>

    )
  }
}

export default withRouter(ChangeStorage);