
import React, { Component } from 'react'
import './Scan.css' 
import Camera, { FACING_MODES,IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import {withRouter} from 'react-router';
 
class Photo extends Component {

    state = {
        resultlist : [],
        cameraMode: 0
    }

    componentDidMount() {
        if (localStorage.getItem("xpm-gallery-list")){
            let result = JSON.parse(localStorage.getItem("xpm-gallery-list"));
            
            this.setState({
                resultlist : result
            })
        }


        document.getElementById("Gallery").addEventListener("click", e => {
            if(e.target && ( e.target.nodeName === "IMG")) {
                let index = e.target.getAttribute("data-attr");
                this.handleDelete(index);
            }
        });

        this.getLocalCameraMode();
      }

  onTakePhoto (dataUri) {
    let results = this.state.resultlist;
    results.push(dataUri);
    this.setState({
        resultlist: results
    })

    localStorage.setItem("xpm-gallery-list", JSON.stringify(this.state.resultlist));
  }

  handleDelete(i){
      let results = this.state.resultlist;
      results.splice(i, 1);
      this.setState({
        resultlist: results
      })
      localStorage.setItem("xpm-gallery-list", JSON.stringify(this.state.resultlist));
  }
  switchCameraMode(){
    let cameraMode = this.state.cameraMode;
    cameraMode = this.state.cameraMode + 1
    if (cameraMode === 3){
      cameraMode = 0
    }

    this.setState({
      cameraMode: cameraMode
    })
    this.setLocalCameraMode(cameraMode);
  }

  setLocalCameraMode(cameraMode){
    localStorage.setItem("xpm-cameraMode", cameraMode);
  }

  getLocalCameraMode(){
    if (localStorage.getItem("xpm-cameraMode")) {
      this.setState({
        cameraMode: parseInt(localStorage.getItem("xpm-cameraMode"), 10)
      })
    }
  }


  render () {

    const isCameraMode = this.state.cameraMode;
    let camera;

    if(isCameraMode === 0){
      camera = <Camera
        onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
        isFullscreen = {false}
        isImageMirror ={false}
        imageType = {IMAGE_TYPES.JPG}
        idealFacingMode = {FACING_MODES.ENVIRONMENT}
        idealResolution = {{width: 1920, height: 1080}}
      />;
    } 
    if(isCameraMode === 1){
      camera = <Camera
        onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
        isFullscreen = {false}
        isImageMirror ={false}
        imageType = {IMAGE_TYPES.JPG}
        idealFacingMode = {FACING_MODES.USER}
        idealResolution = {{width: 1920, height: 1080}}
      />;
    }
    if(isCameraMode === 2){
      camera = <div>CAMERA OFF</div>;
    }
    return (
      <div className="App">
        <div className="cameralayout">
        {camera}
        </div>
       
        <div className="gallery" id="Gallery">
            {this.state.resultlist.map((result, index) => (
              <li>
                <img width="320" height="180" alt="Gallery" data-attr={index} src={result}/>
              </li>
            ))}
        </div>
        <button className="switchCameraMode" onClick={() => {this.switchCameraMode()}}>
          KAMERATEST ({this.state.cameraMode})
        </button>
      </div>
    );
  }

}

export default withRouter(Photo);