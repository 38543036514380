
import axios from 'axios';
import { API } from '../API/API';

const API_XPART = new API();

export class LocalStorageService {

    async  removeExtraXparts(localStorageName, expectedStatusId, expectedContextId, idFieldName) {
        let xpartsFromStorage = JSON.parse(localStorage.getItem(localStorageName));
        if (xpartsFromStorage === null || xpartsFromStorage.length === 0) {
            return await 1;
        }

        const methodAddress = isNaN(xpartsFromStorage[0]) ? 'numbers' : 'ids';

        return await axios.post(API_XPART.XPART_SERVICE_URL + '/xpartview/' + methodAddress, xpartsFromStorage)
            .then(response => {
                this.clearXparts(response.data, expectedStatusId, expectedContextId, localStorageName, !idFieldName ? 'id' : idFieldName);

            })
            .catch(err => {
                console.log(err);
            });

    }

    clearXparts(xpartList, expectedStatusId, expectedContextId, localStorageName, idFieldName) {
        let newXpartList = [];
        for (let i in xpartList) {
            let currentStatusDTO = xpartList[i].currentStatus;
            if (currentStatusDTO.statusDTO.id !== expectedStatusId * 1 ||
                currentStatusDTO.contextDTO.id !== expectedContextId * 1) {
                continue;
            }
            newXpartList.push(xpartList[i][idFieldName].toString());
        }

        if (newXpartList.length === 0) {
            localStorage.removeItem(localStorageName);
        } else {
            localStorage.setItem(localStorageName, JSON.stringify(newXpartList));
        }

    }
}