import React, { Component } from 'react';
import { CameraService } from '../../../Camera/CameraService';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export default class CommonNewPhotoComponent extends Component {

    state = {
        resultlist: []
    }
    componentDidMount() {

        if (localStorage.getItem(this.getLocalStoragePrefix())) {
            let result = CameraService.getFotosFromLocalStorage(this.getLocalStoragePrefix());

            this.setState({
                resultlist: result
            })
        }

        this.enablePhotoMode();
    }


    enablePhotoMode() {
        let element = document.getElementById('page-wrap');
        element.classList.add('xpm-camera-mode');
        element = document.getElementsByClassName("topheader")[0];
        element.classList.add('xpm-camera-mode');
    }

    disablePhotoMode() {
        let element = document.getElementById('page-wrap');
        element.classList.remove('xpm-camera-mode');
        element = document.getElementsByClassName("topheader")[0];
        element.classList.remove('xpm-camera-mode');
    }

    componentWillUnmount() {
        CameraService.killCamera();
        this.disablePhotoMode();
    }


    onTakePhoto(dataUri) {
        let results = this.state.resultlist;
        results.push(dataUri);
        this.setState({
            resultlist: results
        })

        localStorage.setItem(this.getLocalStoragePrefix(), JSON.stringify(this.state.resultlist));

        CameraService.killCamera();
        if (this.props.afterPhoto) {
            this.props.afterPhoto();
        }
    }

    getLocalStoragePrefix() {
        return this.props.moduleName + '-' + this.props.galleryIndex;
    }


    render() {
        return (
            <div className="App">
                <div className="cameralayout">
                <Camera
                    onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
                    isFullscreen={false}
                    imageType={IMAGE_TYPES.JPG}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{ width: 1920, height: 1440 }}
                    isImageMirror={false}
                    isMaxResolution={true}
                />
                </div>
            </div>
        );
    }
}
