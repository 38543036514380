import React, { Component } from 'react'
import { withRouter } from 'react-router';
import axios from 'axios';
import { FieldGenerator } from '../Common/Helper/FieldGenerator'
import { API } from '../Common/API/API';
import ReactDOMServer from 'react-dom/server'
import { LabelService } from '../Common/Translations/LabelService';
import { APINotifications } from '../Common/API/APINotifications';
import { HelperFunctions } from '../Common/Helper/Helper';
import { ContextService } from '../Common/Service/ContextService';
import './Reception.css';


const API_XPART = new API();
const Fields = new FieldGenerator();
const NOTIFICATIONS = new APINotifications();
const HelperFunction = new HelperFunctions();
const LabelFile = LabelService.getLabelsByLocal('de');
const XpartContextService = new ContextService();

class Reception extends Component {

  state = {
    result: [],
    overwriteStorage: false,
    resultlist: [],
    notUploadedPhotos: 0,
    serverphotos: 0,
    btnActive: false,
    context: {},
    isLoading: true
  }

  updateContext() {
    if (!this.props.match.params.context) {
      this.props.match.params.context = 'DEV';
    }

    XpartContextService.getCurrentContext(this.props.match.params.context).then(res => {
      this.setState({
        context: res.data
      })
    })

  };

  componentWillReceiveProps() {
    this.updateContext();
  }

  toggleLoading() {
    this.setState({
      isLoading: !this.state.isLoading
    })
  }

  generateStatusDropdown() {
    if (!document.getElementById("status")) {
      axios.get(API_XPART.XPART_SERVICE_URL + '/status/')
        .then(res => {
          this.status = res['data'];
          this.status.forEach(status => {
            status['name'] = LabelFile.statuses[status['name']];
          })
          let statuslist = Fields.generateInputSelect('status', this.status, true, "", true)
          var temp = ReactDOMServer.renderToString(statuslist);
          document.getElementById("inputMain").insertAdjacentHTML('afterbegin', temp);
          this.fillInfo();
        })
        .catch(err => {
          this.props.fireModal({
            content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
          });
        });
    }
  }

  getWeight(response) {
    if (this.props.match.params.localstorage && localStorage.getItem('reception-weight-' + this.props.match.params.xpart)) {
      return localStorage.getItem('reception-weight-' + this.props.match.params.xpart);
    }
    return response.data["weight"];
  }

  fillInfo() {
    axios.get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + this.props.match.params.xpart)
      .then(response => { //Global Status needed - or make this subcomponent of xpart...? - LB
        document.getElementById('status').value = LabelFile.statuses[response.data['currentStatus']['statusDTO']['name']] + " [" + LabelFile.context[response.data['currentStatus']['contextDTO']['name']] + "]";
        document.getElementById('xpart').value = response.data["xpartNumber"];
        document.getElementById('desc_de').value = response.data["descriptionDe"];
        document.getElementById('quantity').value = response.data["quantity"];
        document.getElementById('weight').value = this.getWeight(response);
        document.getElementById('id').value = response.data["id"];

        document.getElementById('weight').addEventListener('change', this.onWeightChange);

        //document.getElementById('status').value = response.data["status"]['id'];

        this.setState({
          result: response.data,
          serverphotos: response.data["filesCount"]
        })

        console.log("this.state.result", this.state.result);
        if (this.props.match.params.storage) {
          this.fillStorage(this.props.match.params.storage);
          this.setState({
            overwriteStorage: true
          })
        } else {
          if (response.data["storagePlace"]) {
            let storage = "";
            let storage_arr = response.data["storagePlace"]["parents"].reverse();
            storage_arr.forEach(function (parent) {
              storage += parent["name"] + "-";
            });
            this.fillStorage(storage + response.data["storagePlace"]["name"]);
          }
        }
        this.openSaveBtn();
      })
      .catch(err => {
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      });
  }

  fillStorage(storage) {
    document.getElementById('storage').value = storage;
  }

  handleReception() {
    if (!Fields.validateInputs()) {
      return;
    }
    if (!this.state.btnActive) {
      return;
    }


    if (this.state.result.status.name !== 'IN_DELIVERY') {
      this.props.fireModal({
        content: NOTIFICATIONS.getNotification('de', 'WRONG_RECEIVE_XPART_STATUS', LabelFile.statuses[this.state.result.status.name]),
        header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
        buttons: this.createWarningStatusModalButtons()
      });
    } else {
      this.saveStatus();
    }
  }

  saveStatus() {
    if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
      this.handleUpload();
    } else {
      this.handleSave();
    }
  }

  handleSave() {
    this.toggleLoading();
    localStorage.removeItem('reception-weight-' + this.props.match.params.xpart);
    let res = this.state.result;
    res.weight = document.getElementById('weight').value;
    res.statusID = 4;
    res.contextId = 4;
    res.unitID = res.unit.id
    this.setState({
      result: res
    })

    axios.patch(API_XPART.XPART_SERVICE_URL + '/one/status/receive', { "weight": this.state.result.weight, "xpartId": this.state.result.id, "contextId": this.state.context.id })
      .then(res => {
        if (this.state.overwriteStorage) {
          let storage_arr = document.getElementById("storage").value.split("-");
          console.log(storage_arr);
          let data = {
            storageSequence: storage_arr,
            xpartId: this.state.result.id
          };
          axios.patch(API_XPART.XPART_SERVICE_URL + '/one/storage/', data)
            .then(res => {
              // condition to check if the xpart is coming from KMe then only send to Myfactory
              if (this.state.result['currentStatus']['contextDTO'].id === 9 && this.state.result['currentStatus']['statusDTO'].id === 1) {
                this.uploadXpartInfoToMF();
              } else {
                this.props.fireModal({
                  content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                  header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
                  buttons: this.createOKModalButtons()
                });
                localStorage.removeItem("xpm-storagetemp-list-" + this.state.result.xpartNumber);
                this.props.history.push('/reception/xscan' + HelperFunction.getContextOrNot(this.props.match.params.context))
              }
            })
            .catch(err => {
              this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
              });
            })
        } else {
          // condition to check if the xpart is coming from KMe then only send to Myfactory
          if (this.state.result['currentStatus']['contextDTO'].id === 9 && this.state.result['currentStatus']['statusDTO'].id === 1) {
            this.uploadXpartInfoToMF();
          } else {
            this.props.fireModal({
              content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
              header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
              buttons: this.createOKModalButtons()
            });
            this.props.history.push('/reception/xscan' + HelperFunction.getContextOrNot(this.props.match.params.context))

          }
        }
      })
      .catch(err => {
        this.props.fireModal({
          content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
          header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
          buttons: this.createOKModalButtons()
        });
      });
  }

  goToPhotos() {
    localStorage.setItem('reception-weight-' + this.props.match.params.xpart, document.getElementById('weight').value);
    this.props.history.push('/reception/photo/true/gallery/' + this.props.match.params.xpart + HelperFunction.getContextOrNot(this.props.match.params.context));
  }

  setStorage() {
    localStorage.setItem('reception-weight-' + this.props.match.params.xpart, document.getElementById('weight').value)
    this.props.history.push('/reception/changestorage/' + this.state.result.xpartNumber + '/reception' + HelperFunction.getContextOrNot(this.props.match.params.context));
  }

  componentDidMount() {
    //this.generateStatusDropdown();
    this.updateContext();
    this.fillInfo();

    if (this.props.match.params.storage) {
      localStorage.setItem("xpm-storagetemp-list-" + this.props.match.params.xpart, this.props.match.params.storage);
    }

    if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
      let result = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart));

      this.setState({
        resultlist: result,
        notUploadedPhotos: result.length
      })
    }
  }


  createOKModalButtons() {

    var btns = [];
    var obj = {
      customClass: 'button',
      text: 'OK',
      callback: this.props.closeModal,
    };

    btns.push(obj);

    return btns;
  }

  handleWarningStatusYesButton = () => {
    this.saveStatus();
    this.props.closeModal();
  }

  createWarningStatusModalButtons() {

    var btns = [];
    var no = {
      customClass: 'button_no',
      text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
      callback: this.props.closeModal,
    };

    var yes = {
      customClass: 'button_yes',
      text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
      callback: this.handleWarningStatusYesButton,
    };

    btns.push(no);
    btns.push(yes);

    return btns;
  }

  handleDelete(i) {
    let results = this.state.resultlist;
    results.splice(i, 1);
    this.setState({
      resultlist: results
    })
    if (this.state.resultlist.length === 0) {
      localStorage.removeItem("xpm-gallery-list-" + this.props.match.params.xpart);
    } else {
      localStorage.setItem("xpm-gallery-list-" + this.props.match.params.xpart, JSON.stringify(this.state.resultlist));
    }
  }

  handleUpload() {
    if (localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart)) {
      this.setState({
        btnActive: false
      })
      let photos = JSON.parse(localStorage.getItem("xpm-gallery-list-" + this.props.match.params.xpart));
      let photolistsize = photos.length;
      let counter = 0;
      photos.forEach((element, index) => {
        fetch(element) //let me just quickly fetch a local data uri lol
          .then(res => res.blob())
          .then(blob => {
            var fd = new FormData()
            fd.append('file', blob, 'filename')

            axios.post(API_XPART.FILE_SERVICE_URL + '/file', fd, { headers: { 'Content-Type': 'multipart/form-data;' } })
              .then(img_id => {
                let data = {
                  "type": "IMAGE",
                  "xpartId": this.state.result.id,
                  "fileId": img_id.data
                }
                axios.post(API_XPART.XPART_SERVICE_URL + '/file', data)
                  .then(res => {
                    this.handleDelete(index);
                    counter += 1;
                    if (counter === photolistsize) {
                      localStorage.removeItem("xpm-gallery-list-" + this.props.match.params.xpart);
                      this.handleSave();
                    }
                  })
                  .catch(err => {
                    this.props.fireModal(NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                      NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                      this.createOKModalButtons());
                    this.setState({
                      btnActive: true
                    })
                  })
              })
              .catch(err => {
                this.props.fireModal({
                  content: NOTIFICATIONS.getErrorDescription(
                    "de",
                    err.response.status,
                    err.response.data
                      .errors[0]
                  ),
                  header: NOTIFICATIONS.getHeaderDescription(
                    "de",
                    "ERROR",
                    "COMMON_ERROR"
                  ),
                  buttons: this.createOKModalButtons()
                });
                this.setState({
                  btnActive: true
                });
              });
          });
      });
    }
  }



  openSaveBtn() {
    let btn = true;
    //if(this.state.notUploadedPhotos === 0 && this.state.serverphotos === 0){
    if (this.state.notUploadedPhotos === 0) { //Björn explicitly stated that server photos are irrelevant
      btn = false;
    }
    if (!(this.props.match.params.storage)) {
      btn = false;
    }
    if (btn === true) {
      this.setState({
        btnActive: true
      })
    } else {
      this.setState({
        btnActive: false
      })
    }
  }

  saveBtnClass() {
    if (this.state.btnActive) {
      return ""
    } else {
      return "xpm-disabled"
    }
  }

  renderStorageSelectButton() {
    return this.state.context.name === 'TECHNIKUM' ? null : <button type="button" id="rec-storage-btn"
      className="rec-storage-button xpm-button"
      onClick={this.setStorage.bind(this)}>{LabelFile.labels['change_storage']}</button>;

  }

  // return project info
  getProjectnr(xpartInfo) {
    if (xpartInfo['items'].length > 0) {
      for (let i = 0; i < xpartInfo['items'].length; i++) {
        if (xpartInfo['items'][i]['type']['name'] === 'PROJECT') {
          let projectInfo = xpartInfo['items'][i]['orders'][0]
          return projectInfo['number']
        }
      }
    } else { return 0; }
  }

  UnitNameMapping(unit) {
    switch (unit) {
      case 'PIECE':
        return 'Stk';
      case 'LITRE':
        return 'l';
      case 'METER':
        return 'm';

      default:
        return 'Stk';
    }
  }
  // Upload xpart info to Myfactory
  uploadXpartInfoToMF() {
    let MyFObject = {
      "XPartNumber": 0,
      "CustomerCode": 0,
      "DescriptionDe": '',
      "DescriptionEn": '',
      "Quantity": 0,
      "Unit": '',
      "Weight": 0,
      "StoragePlace": '',
      "CustomerMaterialNumber": 0,
      "CustomerMaterialEAN": 0,
      "ProjectNumber": 0,
      "ItemNumber":0
    }
    let xpartInfo = this.state.result;
    MyFObject["XPartNumber"] = xpartInfo["xpartNumber"];
    MyFObject["CustomerCode"] = 'KraussMaffei'
    MyFObject["DescriptionDe"] = xpartInfo["descriptionDe"];
    MyFObject["DescriptionEn"] = xpartInfo["descriptionEn"];
    MyFObject["Quantity"] = xpartInfo["quantity"];
    MyFObject["Unit"] = this.UnitNameMapping(xpartInfo["unit"]["name"]);
    MyFObject["Weight"] = xpartInfo["weight"];
    MyFObject["ProjectNumber"] = this.getProjectnr(xpartInfo);
    for (let i = 0; i < xpartInfo.items.length; i++) {
      if(xpartInfo.items[i].type.name==='PROJECT'){
      MyFObject["ItemNumber"]=xpartInfo.items[i].number;
      }
    }
    if (this.props.match.params.storage) {
      MyFObject["StoragePlace"] = this.props.match.params.storage;
    } else {
      if (xpartInfo["storagePlace"]) {
        let storage = "";
        let storage_arr = xpartInfo["storagePlace"]["parents"].reverse();
        storage_arr.forEach(function (parent) {
          storage += parent["name"] + "-";
        });
        MyFObject["StoragePlace"] = storage + xpartInfo["storagePlace"]["name"];
      }
    }
    MyFObject["CustomerMaterialNumber"] = xpartInfo["customerMaterialNumber"];

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    }

    axios.post(API_XPART.NODEJS_SERVICE_URL + '/uploadXpmXpartDataInMyF/', {
      headers: headers,
      timeout: 10000,
      data: MyFObject,
    })
      .then(res => {
        console.log("res", res);
        this.props.fireModal({
          content: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
          header: NOTIFICATIONS.getNotification('de', 'SUCCESS'),
          buttons: this.createOKModalButtons()
        });
        localStorage.removeItem("xpm-storagetemp-list-" + this.state.result.xpartNumber);
        this.props.history.push('/reception/xscan' + HelperFunction.getContextOrNot(this.props.match.params.context))
      })
      .catch(err => {
        console.log("error", err);
        this.props.history.push('/reception/xscan' + HelperFunction.getContextOrNot(this.props.match.params.context))
        // this.props.fireModal({
        //   content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
        //   header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
        //   buttons: this.createOKModalButtons()
        // });
      }).finally(() => {
        this.toggleLoading(false);
      });
  }

  render() {
    if (!this.state.context.id) {
      return (
        <div>context not found</div>
      )
    }

    return (
      <section className="results--section xpartform">
        <h1>{LabelFile.labels['reception']} {LabelFile.labels['xparts']}</h1>
        <div className="input-container" id="inputMain">
          {Fields.generateInputText("status", ".", false, [], true)}
          {Fields.generateInputText("xpart", "[0-9]{2}-[0-9]{1,10}", false, [], true)}
          {Fields.generateInputTextarea("desc_de", "255", true, [], true)}
          {Fields.generateInputNumber("quantity", "1", "10000", "1", true, [], true)}
          {Fields.generateInputNumber("weight", "0", "1000000", ".001", true, [LabelFile.warnings['required'], LabelFile.warnings['number']], false)}
          {Fields.generateInputText("storage", "", false, [], true)}

          {Fields.generateInputHidden("id")}
          <div className="xpm-button-container">
            {this.renderStorageSelectButton()}
            <button type="button" id="rec-btn"
              className={"rec-button xpm-button " + this.saveBtnClass()}
              onClick={this.handleReception.bind(this)}>{LabelFile.labels['save']}</button>
          </div>
          <div onClick={this.goToPhotos.bind(this)} className="xpm-button xpm-go-to-photos-button" ><span>{LabelFile.labels["photos"] + "("}{this.state.notUploadedPhotos}{")"}</span></div>
          <span className="xpm-info-txt">
            {LabelFile.texts['RECEPTION_SAVE_BTN']}
          </span>
        </div>



      </section>
    )
  }
}

export default withRouter(Reception);
