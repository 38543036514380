import React, { Component } from 'react';
import { LabelService } from '../Common/Translations/LabelService';

const LabelFile = LabelService.getLabelsByLocal('de');
export default class PackageViewInfoComponent extends Component {

    state = {
        showInfo: true,
        selectedCount: 0
    }

    changeSelectedCount(count) {
        this.setState({ selectedCount: count });
    }

    changeInfoDisplay(showInfo) {
        this.setState({ showInfo: showInfo });
    }

    renderInfoText() {
        if (this.state.showInfo && this.state.selectedCount > 0) {
            return (<span className="xpm-warning-span">{LabelFile.texts['localdeliveryrequest'] + ': ' + this.state.selectedCount}</span>);
        }
        return ('');
    }

    render() {

        return (
            this.renderInfoText()
        )
    }
}
