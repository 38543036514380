/* Api Helper file to prevent PROD/LOCAL api confusion */
/* ONLY USE THIS FOR API CALLS, NEVER USE INDIVIDUAL ONES */

import { API_ROOT } from '../../api-config';

export class API {

    FILE_SERVICE_URL = `${API_ROOT}/files`;
    XPART_SERVICE_URL = `${API_ROOT}/xpart`;
    AUTH_SERVICE_URL = `${API_ROOT}/auth`;
    MIGRATION_SERVICE_URL = `${API_ROOT}/migration`;
    NODEJS_SERVICE_URL=  `${API_ROOT}/nodejs`;
};
