

import React from 'react';
import WizardStep from '../../Common/Components/Wizrd/WizardStep';
import { CommonWizardComponent } from '../../Common/Components/Wizrd/CommonWizardComponent';
import EvsReceiveButtonsComponent from './EvsReceiveButtonsComponent';
import EvsReceiveGroupTableCheckComponent from './EvsReceiveGroupTableCheckComponent';
import Scan from '../../Scan/XpartScan/Scan';
import { LabelService } from '../../Common/Translations/LabelService';
import CommonPhotosComponent from '../../Common/Components/Photo/CommonPhotosComponent';
import axios from 'axios';
import { API } from '../../Common/API/API';
import { APINotifications } from '../../Common/API/APINotifications';
import FormGenerate from '../../Form/FormGenerate.js'
import { CameraService } from '../../Camera/CameraService';

const LabelFile = LabelService.getLabelsByLocal('de');
const API_XPART = new API();
const NOTIFICATIONS = new APINotifications();

export default class EvsReceiveWizard extends CommonWizardComponent {

    currentXpart = null;
    moduleName = 'xpart-gallery-evs-receive';

    componentDidMount() {
        //init all steps
        let steps = [];
        steps.push(this.getScanStep());
        steps.push(this.getFormGeneratorStep());
        steps.push(this.getPhotoStep());
        steps.push(this.getGroupTableCheckStep());
        steps.push(this.getScanStorageStep());
        this.initComponent(steps, steps[0]);
        this.currentXpart = null;
    }

    addValidationDTO = (request) => {
        let validationDTO = {
            expectedStatusIds: [1, 2],
            expectedContextsIds: [9]
        }
        request['validationDTO'] = validationDTO;
    }
    // after scan action is done
    postScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.getXpartData(scanValue);
    }

    postStorageScan = scanValue => {
        if (!scanValue) {
            return;
        }
        this.currentSoragePlace = scanValue;
        this.showStorageSaveDialog();

    }

    updateGalleryIndex = () => {
        this.currentStepRef.setGalleryIndex(this.currentXpart.xpartNumber, this.moduleName);
    }

    goToPhotos(e) {
        const xpartProperies = this.currentStepRef.passDataToParent();
        if (!xpartProperies) {
            return;
        }
        this.currentXpart.weight = xpartProperies.weight
        this.goToStep('photo-step', this.updateGalleryIndex);
        this.setState({ wizardControlComponent: null })
    }
    goToForm() {
        CameraService.killCamera();
        this.goToStep('form-step', this.fillForm);
        const photos = CameraService.getFotosFromLocalStorage(this.getPhotoLocalStoragePrefix());
        const receiveCondition = photos.length > 0;

        const controlButtonsComponent = <EvsReceiveButtonsComponent photosCount={photos.length}
            photo={true} goToPhotos={(e) => this.goToPhotos(e)} receive={receiveCondition} xpartsCount={0}
            saveXpart={(e) => this.saveXpart(e)} goToScan={() => this.goToScan()} />;

        this.setState({
            wizardControlComponent: controlButtonsComponent
        })
    }

    fillForm = () => {
        this.currentStepRef.fillForm(this.currentXpart)
    }

    getPhotoLocalStoragePrefix() {
        return this.moduleName + '-' + this.currentXpart.xpartNumber;
    }

    getActiveCompletionGroup(xpartDTO) {
        let groups = xpartDTO.activeGroups.filter(e => e.mode.name === 'COMPLETION_CONTROL');

        if (!groups || groups.length < 1) {
            return null;
        }
        return groups[0];
    }

    checkWarnings = (xpart) => {
        if (xpart.currentStatus.contextDTO.name === 'KME' && xpart.currentStatus.statusDTO.name === 'ORDERED') {

            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'STATUS_CONTEXT_EVS_RECIEVE_ACCEPTED'),
                header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
                buttons: this.createWarningAfterXpartScanButtons(xpart)
            });

            return true;
        }

        return false;
    }

    undoScan = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    }

    accecptScannedXpart = (xpart) => {
        this.props.closeModal();
        this.currentXpart = xpart;
        this.currentGroup = this.getActiveCompletionGroup(xpart);
        this.goToForm();
    }

    getXpartData(xpartNumber) {
        axios
            .get(API_XPART.XPART_SERVICE_URL + '/one/xp-number/' + xpartNumber)
            .then(res => {
                if (!this.validateXpart(res['data'])) {
                    this.currentStepRef.reset();
                    return;
                }

                if (this.isScannedBefore(res['data'])) {
                    this.handleScannedBefore(res['data']);
                    return;
                }

                if (this.checkWarnings(res['data'])) {
                    return;
                }
                this.accecptScannedXpart(res['data']);
            })
            .catch(error => {
                console.error(error)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', error.response.status, error.response.data.errors[0]) + '\n' + xpartNumber,
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'COMMON_ERROR'),
                    buttons: this.createOKModalButtons()
                })
                this.currentStepRef.reset();
            })
    }

    getScanStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['scanOrXpart'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['storagexpartNr'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-step', scanComponent);

    }

    getScanStorageStep() {

        let initialState = [
            {
                cam: {
                    result: LabelFile.texts['change_storage'], // --- camera or textinput change the state
                    flash: 'scan-container-flash'
                },
                title: {
                    view: true, // --- view title in browser
                    name: LabelFile.labels['change_storage'] // --- view title in browser
                },
                form: {
                    view: true, // --- view form in browser
                    alternative: true // view form width (alternative = full width)
                },
                button: {
                    view: false, // --- view button in browser
                    alternative: true // view button width (alternative = full width)
                }
            }
        ];
        const scanComponent =
            <Scan postScan={(data) => this.postStorageScan(data)} initialState={initialState} setRef={el => this.setCurrentStepRef(el)}></Scan>

        return new WizardStep('scan-storage-step', scanComponent);
    }

    getGroupTableCheckStep() {

        const tableCheckComponent =
            <EvsReceiveGroupTableCheckComponent
                setRef={el => this.setCurrentStepRef(el)}
                goToForm={() => this.goToForm()}
                goToScan={() => this.goToScan()}
                goToStorageScan={() => this.goToStorageScan()}
            >
            </EvsReceiveGroupTableCheckComponent>

        return new WizardStep('group-check-step', tableCheckComponent);

    }


    getFormGeneratorStep() {
        const formComponent =
            <FormGenerate
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                formname="receiveXpartTransportAreaWithGroup"
                header="ASSEMBLY"
                prefill="false"
                pdfPrint="false"
                receiveVariables={null}
                setRef={el => this.setCurrentStepRef(el)}
            />

        return new WizardStep('form-step', formComponent);
    }

    getPhotoStep() {
        const photoComponent =
            <CommonPhotosComponent
                fireModal={this.props.fireModal}
                closeModal={this.props.closeModal}
                goBack={() => this.goToForm()}
                setRef={el => this.setCurrentStepRef(el)}
            />


        return new WizardStep('photo-step', photoComponent);
    }

    getXpartInfo = () => {
        let xpartProperies = null;

        if (this.currentStepRef.passDataToParent) {
            xpartProperies = this.currentStepRef.passDataToParent();
        } else {
            xpartProperies = this.getXpatFormFromCurrentXpart();
        }


        const photos = CameraService.getPhotosToSend(this.getPhotoLocalStoragePrefix());
        if (!xpartProperies || photos.length < 1) {
            return null;
        }

        xpartProperies.id = this.currentXpart.id;
        xpartProperies.active = true;

        let data = {
            files: photos,
            storageSequence: null,
            xpartProperties: xpartProperies
        }

        return data;
    }


    saveXpart = (e) => {

        let xpartInfo = this.getXpartInfo();
        this.save(xpartInfo);
    }

    setGroupId = () => {
        this.currentStepRef.setGroupId(this.currentGroup.id);
    }

    goToTableCheck() {
        const xpartProperies = this.currentStepRef.passDataToParent();
        if (!xpartProperies) {
            return;
        }
        this.currentXpart.weight = xpartProperies.weight
        this.goToStep('group-check-step', this.setGroupId);
        this.setState({ wizardControlComponent: null })
    }

    goToScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.goToStep('scan-step');
    }

    getXpatFormFromCurrentXpart() {

        if (!this.currentXpart) {
            return null;
        }

        let xpartProperties = {
            id: this.currentXpart.id,
            active: this.currentXpart.active,
            contextId: this.currentXpart.currentStatus.contextDTO.id,
            statusID: this.currentXpart.currentStatus.statusDTO.id,
            unitID: this.currentXpart.unit.id,
            weight: this.currentXpart.weight,
            xpartNumber: this.currentXpart.xpartNumber,
            quantity: this.currentXpart.quantity,
            descriptionDe: this.currentXpart.descriptionDe,
            descriptionEn: this.currentXpart.descriptionEn

        }

        return xpartProperties;
    }


    goToStorageScan = () => {
        this.setState({
            wizardControlComponent: null
        })
        this.currentSoragePlace = null;
        this.goToStep('scan-storage-step');
    }

    updateGroupInfo(response) {
        if (!this.currentGroup) {
            return;
        }
        let activeCompletions = this.getActiveCompletionGroup(response);
        if (activeCompletions.length < 1) {
            return;
        }
        let group = activeCompletions;

        this.currentGroup = group;
    }

    async saveXpartInfoWithGroup(data) {
        data['completed'] = true;
        data['groupId'] = this.currentGroup.id;
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/group/reception/completion', data);
    }

    async saveXpartInfoNoGroup(data) {
        return await axios.post(API_XPART.XPART_SERVICE_URL + '/receive', data);
    }

    save = (data) => {
        this.toggleLoading(true);

        let promise = null;
        if (this.currentGroup) {
            promise = this.saveXpartInfoWithGroup(data);
        } else {
            promise = this.saveXpartInfoNoGroup(data);
        }

        promise.then(
            response => {
                this.updateGroupInfo(response['data']);
                this.currentXpart = response['data'];
                if (this.currentGroup) {
                    this.goToTableCheck();
                } else {
                    this.goToStorageScan();
                }
            }
        ).catch(err => {
            console.log(err)
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    isScannedBefore = (xpart) => {

        let group = this.getActiveCompletionGroup(xpart);
        if (!group) {
            return false;
        }

        let completedXpart = group.completionList.filter(e =>
            e.xpartNumber === xpart.xpartNumber && e.completed
        )
        return completedXpart && completedXpart.length > 0;
    }

    checkTruth = (sum, el) => {
        return el.completed && sum;
    }

    isCompleted(xpartList) {
        return xpartList.reduce(this.checkTruth, true);
    }

    isAllCompleted(xpart) {
        let group = this.getActiveCompletionGroup(xpart);
        if (!group) {
            return false;
        }

        return group.completionList.reduce(this.checkTruth, true);
    }

    handleScannedBefore = (xpart) => {
        if (this.isAllCompleted(xpart)) {
            this.currentXpart = xpart;
            this.currentGroup = this.getActiveCompletionGroup(xpart);
            this.goToStep('group-check-step', this.setGroupId);
            this.setState({ wizardControlComponent: null });
            return;
        }

        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'XPART_COMPLETED_IN_GROUP',
                xpart.xpartNumber),
            header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
            buttons: this.createOKModalButtons()
        });
    }

    validateXpart = (xpart) => {
        if ((xpart.currentStatus.statusDTO.name !== 'READY_FOR_SHIPMENT' && xpart.currentStatus.statusDTO.name !== 'ORDERED') ||
            xpart.currentStatus.contextDTO.name !== 'KME') {

            this.props.fireModal({
                content: NOTIFICATIONS.getNotification('de', 'WRONG_RECEIVE_XPART_STATUS_OR_CONTEXT_KME_READY_FOR_SHIPMENT',
                    LabelFile.statuses[xpart.currentStatus.statusDTO.name], LabelFile.context[xpart.currentStatus.contextDTO.name]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });

            return false;
        }

        return true;
    }

    receiveGroup = () => {

        let data = {
            xpartGroupId: this.currentGroup.id,
            targetStatusId: 4,
            targetContextId: 7,
            storageLocationSequence: this.currentSoragePlace.split('-')
        }

        this.addValidationDTO(data);

        this.toggleLoading(false);

        axios.post(API_XPART.XPART_SERVICE_URL + '/group/reception/completion/convenience', data)
            .then(
                response => {
                    this.clearCache();
                    this.refresh();
                }
            ).catch(err => {
                console.log(err)
                this.props.fireModal({
                    content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                    header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                    buttons: this.createOKModalButtons()
                });
            })
            .finally(() => {
                this.toggleLoading(false);
            });

    }

    clearCache() {
        localStorage.removeItem(this.getPhotoLocalStoragePrefix());
        this.currentXpart = null;
        this.currentSoragePlace = null;
        this.currentGroup = null;
    }

    receiveSingleXpart = () => {
        let xpartInfo = this.getXpartInfo();
        //EVS
        xpartInfo.xpartProperties.contextId = 7;
        // RECEIVED
        xpartInfo.xpartProperties.statusID = 4;
        xpartInfo.storageSequence = this.currentSoragePlace.split('-');

        this.addValidationDTO(xpartInfo);


        this.toggleLoading(true);
        this.saveXpartInfoNoGroup(xpartInfo).then(
            response => {
                this.refresh();
            }
        ).catch(err => {
            this.props.fireModal({
                content: NOTIFICATIONS.getErrorDescription('de', err.response.status, err.response.data.errors[0]),
                header: NOTIFICATIONS.getHeaderDescription('de', 'ERROR', 'VALIDATION_ERROR'),
                buttons: this.createOKModalButtons()
            });
        })
            .finally(() => {
                this.toggleLoading(false);
            });
    }

    handleSaveStorage = () => {
        this.toggleLoading(true);
        if (this.currentGroup) {
            this.receiveGroup();
        } else {
            this.receiveSingleXpart();
        }

        this.props.closeModal();
    };

    undoStorage = () => {
        this.props.closeModal();
        this.currentStepRef.reset();
    };

    showStorageSaveDialog() {
        this.props.fireModal({
            content: NOTIFICATIONS.getNotification('de', 'STORAGE_SAVE', this.currentSoragePlace),
            header: NOTIFICATIONS.getHeaderDescription('de', 'WARNING', 'COMMON_WARNING'),
            buttons: this.createWarningBeforeStorageSaveButtons()
        });
    }

    createWarningBeforeStorageSaveButtons() {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.undoStorage,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: this.handleSaveStorage,
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }


    createWarningAfterXpartScanButtons(xpart) {

        var btns = [];
        var no = {
            customClass: 'button_no',
            text: NOTIFICATIONS.getButton('de', 'NO_BUTTON'),
            callback: this.undoScan,
        };

        var yes = {
            customClass: 'button_yes',
            text: NOTIFICATIONS.getButton('de', 'YES_BUTTON'),
            callback: () => this.accecptScannedXpart(xpart),
        };

        btns.push(no);
        btns.push(yes);

        return btns;
    }


}
